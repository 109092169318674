import type { PdsCloseButtonProps } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";

import { tx } from "@/libs";
import { PdsIconClose } from "@/components/icons";

/**
 * `PdsCloseButton` is a pre-styled button that enable a user to trigger a remove/dismiss/close action.
 *
 * `PdsCloseButton` must have an [aria-label](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label)
 * in order to make it visible to screen readers, since it does not have any text content.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=12%3A539).
 */
export const PdsCloseButton = forwardRef<HTMLButtonElement, PdsCloseButtonProps>(
    ({ id, disabled = false, variant = "neutral", className, children, ...props }: PdsCloseButtonProps, ref) => {
        const closeButtonId = useMemo(() => id ?? `pds-close-button-${nanoid()}`, [id]);

        const { hoverProps, isHovered } = useHover({ isDisabled: disabled });

        return (
            <button
                id={closeButtonId}
                ref={ref}
                type="button"
                disabled={disabled}
                className={tx(
                    "inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:text-white focus:outline-none",
                    "transition duration-200 motion-reduce:transition-none",
                    {
                        red: tx(
                            "focus:bg-red-500 active:bg-red-700",
                            isHovered ? "bg-red-300 text-red-900" : "text-red-800"
                        ),
                        gold: tx(
                            "focus:bg-gold-500 active:bg-gold-700",
                            isHovered ? "bg-gold-300 text-gold-900" : "text-gold-800"
                        ),
                        green: tx(
                            "focus:bg-green-500 active:bg-green-700",
                            isHovered ? "bg-green-300 text-green-900" : "text-green-800"
                        ),
                        blue: tx(
                            "focus:bg-blue-500 active:bg-blue-700",
                            isHovered ? "bg-blue-300 text-blue-900" : "text-blue-800"
                        ),
                        purple: tx(
                            "focus:bg-purple-500 active:bg-purple-700",
                            isHovered ? "bg-purple-300 text-purple-900" : "text-purple-800"
                        ),
                        pink: tx(
                            "focus:bg-pink-500 active:bg-pink-700",
                            isHovered ? "bg-pink-300 text-pink-900" : "text-pink-800"
                        ),
                        neutral: tx(
                            "focus:bg-neutral-500 active:bg-neutral-700",
                            isHovered ? "bg-neutral-300 text-neutral-800" : "text-neutral-900"
                        ),
                        white: tx(
                            "focus:bg-neutral-500 active:bg-neutral-700",
                            isHovered ? "bg-neutral-300 text-neutral-800" : "text-neutral-900"
                        ),
                    }[variant],
                    className
                )}
                {...mergeProps(hoverProps, props)}
            >
                <PdsIconClose size="xsmall" />
            </button>
        );
    }
);

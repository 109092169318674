import type { PdsDropdownSelectSearchInputProps } from "./type";

import { forwardRef } from "react";
import { useFocusManager } from "@react-aria/focus";

import { ex, tx } from "@/libs";
import { useKeyDown } from "@/hooks";
import { PdsSearchInput } from "@/components/input";

export const PdsDropdownSelectSearchInput = forwardRef<HTMLInputElement, PdsDropdownSelectSearchInputProps>(
    (
        {
            disabled = false,
            placeholder = "Search...",
            className,
            onKeyDown: onKeyDownProps,
            ...props
        }: PdsDropdownSelectSearchInputProps,
        ref
    ) => {
        /**
         * Focus manager hooks.
         */
        const { focusNext, focusPrevious } = useFocusManager();

        /**
         * Keydown hook to handle keyboard navigation.
         */
        const { onKeyDown } = useKeyDown({
            isDisabled: disabled,
            ArrowDown: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => focusNext(),
            },
            ArrowUp: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => focusPrevious(),
            },
        });

        return (
            <PdsSearchInput
                ref={ref}
                placeholder={placeholder}
                onKeyDown={ex(onKeyDown, onKeyDownProps)}
                className={tx("mx-1", className)}
                {...props}
            />
        );
    }
);

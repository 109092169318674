import type { ChangeEvent } from "react";
import type { PdsCheckboxProps } from "./type";

import { nanoid } from "nanoid";
import { useHover } from "@react-aria/interactions";
import { useCallback, useMemo, useEffect, forwardRef } from "react";

import { ex, tx } from "@/libs";
import { useBoolean } from "@/hooks";

/**
 * `PdsCheckbox` is a form element that allows users to select one or more items from a set.
 *
 * `PdsCheckbox` presents a user with a binary (e.g. on/off) choice that is part of a form/state.
 * `PdsCheckbox` are used for multiple choices, not for mutually exclusive choices, and should not be used to apply
 * user's choice instantly.
 *
 * - `PdsCheckbox` can be checked, unchecked, or indeterminate. Indeterminate state is purely visual.
 * - `PdsCheckbox` should have labels. In most cases, this should be done through `PdsLabel`.
 * When a label cannot be used, it is necesssary to add [aria-label](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label)
 * or [aria-labelledby](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby).
 * - `PdsCheckbox` uses [aria-checked](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-checked)
 * to assistively display as being checked or mixed/indeterminate.
 * - `PdsCheckbox` uses [aria-required](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-required)
 * to identify as a required field.
 * - `PdsCheckbox` uses [aria-invalid](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-invalid)
 * to display as having an error.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=16%3A895).
 */
export const PdsCheckbox = forwardRef<HTMLInputElement, PdsCheckboxProps>(
    (
        {
            id,
            checked: checkedProps,
            indeterminate,
            error = false,
            disabled = false,
            defaultChecked = false,
            required = false,
            className,
            onChange,
            ...props
        }: PdsCheckboxProps,
        ref
    ) => {
        const [checked, { setValue }] = useBoolean({ initialValue: checkedProps ?? defaultChecked });

        /**
         * Hover handling hook.
         */
        const { isHovered, hoverProps } = useHover({ isDisabled: disabled });

        /**
         * Memo to intialize checkbox ID.
         */
        const checkboxId = useMemo(() => id ?? `pds-checkbox-${nanoid()}`, [id]);

        /**
         * Effect handler to update on props.
         */
        useEffect(() => setValue(checkedProps ?? defaultChecked), [checkedProps, defaultChecked, setValue]);

        /**
         * Change event handler for checkbox.
         *
         * If controlled (aka. `checked` set via props), internal state change is ignored.
         */
        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                if (checkedProps === undefined && indeterminate === undefined) {
                    setValue(e.target.checked);
                }
            },
            [checkedProps, indeterminate, setValue]
        );

        return (
            <div className={tx("relative flex h-5 min-h-5 w-4 min-w-4 items-center", className)} {...hoverProps}>
                <input
                    type="checkbox"
                    ref={ref}
                    id={checkboxId}
                    tabIndex={0}
                    disabled={disabled}
                    checked={checked}
                    required={required}
                    aria-required={required ? "true" : undefined}
                    aria-checked={indeterminate ? "mixed" : checked}
                    aria-invalid={error ? "true" : undefined}
                    onChange={ex(handleChange, onChange)}
                    className={tx(
                        "focusable h-4 w-4 cursor-pointer appearance-none rounded-xs border border-neutral-400",
                        "transition duration-200 motion-reduce:transition-none",
                        "disabled:cursor-not-allowed disabled:border-neutral-300 disabled:bg-neutral-300",
                        {
                            "border-neutral-500": isHovered,
                            "border-primary bg-primary": checked || indeterminate,
                            "border-theme-800 bg-theme-800": isHovered && (checked || indeterminate),
                            "border-danger !ring-danger": error,
                            "border-red-700": isHovered && error,
                            "bg-danger": error && (checked || indeterminate),
                            "bg-red-700": isHovered && error && (checked || indeterminate),
                        }
                    )}
                    {...props}
                />
                {/**
                 * The check icon to be injected if checked=`true`.
                 * By default, ARIA-hidden since it is for visual aid only.
                 */}
                <svg
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    className={tx(
                        "absolute top-1/2 h-4 w-4 -translate-y-1/2 fill-current",
                        "pointer-events-none opacity-0 transition-opacity duration-200",
                        "disabled:text-neutral-500",
                        {
                            "opacity-100": checked || indeterminate,
                            "text-white": checked || indeterminate || error,
                        }
                    )}
                >
                    {checked ? (
                        <path d="M7.13 8.87a1.25 1.25 0 0 0-1.76 1.76l2.5 2.5c.48.5 1.28.5 1.76 0l5-5a1.25 1.25 0 0 0-1.76-1.76l-4.12 4.11-1.62-1.61z" />
                    ) : (
                        indeterminate && <rect width="12" height="2" x="4" y="9" rx="1" />
                    )}
                </svg>
            </div>
        );
    }
);

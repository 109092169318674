import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconCheer = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M13.82 5.8L3.57 9.32c-.49.17-.88.7-.88 1.19v.29c0 .49.4 1.03.88 1.19l1.07.37 5.73 1.97 3.45 1.2c.48.16.87-.1.87-.6V6.39c0-.3-.15-.52-.38-.6a.73.73 0 0 0-.5 0zm-9.13 7.82l2 .62v4.5a1 1 0 1 1-2 0v-5.12zm12.35-1.3a.97.97 0 0 1 .51.08l2.77 1.17a1 1 0 0 1 .6.78v.04a1 1 0 0 1-1.38 1.02l-2.77-1.17a1 1 0 0 1 .27-1.91zm3.2-3.17a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-3a1 1 0 1 1 0-2zm-.9-4.36a1 1 0 0 1 1.37.36 1 1 0 0 1-.37 1.37l-2.6 1.5a1 1 0 0 1-1-1.73z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconSend = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M20.4 5c1.36 0 2.07 1.58 1.25 2.62l-.1.1-12 12.8c-.9.97-2.46.37-2.57-.9V12.4L2.42 7.57c-.88-.93-.29-2.46.94-2.56L3.5 5h16.9zm-.94 2.03H4.6l3.56 3.72 3.4-1.65a.98.98 0 0 1 1.27.38l.06.1c.22.48.05 1.04-.37 1.31l-.1.06-3.46 1.68v5.58l10.5-11.18z" />
            </svg>
        );
    }
);

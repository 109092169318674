import type { LabelAriaProps } from "@react-aria/label";

import React, { createContext } from "react";
import { useLabel } from "@react-aria/label";
import styled from "styled-components";
import tw, { theme } from "twin.macro";

import { createGuid } from "../../utils/guid";
import { filterClassName } from "../../utils/filterClassName";
/**
 * A segmented control is a set of two or more button segments. Within the control, all segments are equal in width.

A segmented control is often used to switch between views of some data. Use a segmented control only when the options are predefined and are at most 4 options.
 */
export type SegmentGroupProps = {
    children?: React.ReactNode;
    /** Callback fired when a choice card is selected */
    onChange: (value: string) => void;
    /** Selected value of segment */
    selection: string;
    /** The name of the SegmentControl, used when submitting an HTML form. */
    name?: string;
    /** The name of the SegmentControl, required for accessibility */
    label: string;
    /** Users can add spacing/sizing and screen-width specific but cannot modify the internal component desgin(color variant, font sizes, …). */
    className?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange" | "className">; // Replace HTMLAttributes and HTMLDivElement with the appropriate types for the root element of the component

export type SegmentContextData = {
    selectedValue: string;
    inputName: string;
    onChange: (value: string) => void;
};
const segmentDefaultContextData = {} as SegmentContextData;
export const SegmentContext = createContext<SegmentContextData>(segmentDefaultContextData);

const StyledSegmentGroupWrapper = styled.div<{ children: React.ReactNode }>`
    ${tw`mb-0 font-medium leading-normal align-baseline relative border-none `};
    div > label {
        border-right: 1px solid ${theme`colors.neutral.300`};

        :last-child {
            border-right: none;
        }
    }
`;
const StyledSegmentGroupLayout = styled.div`
    ${tw`grid grid-flow-col auto-cols-fr w-[fit-content] border rounded-sm border-neutral-300 `};
`;

const StyledLabel = styled.label`
    ${tw`hidden`};
`;
export const PdsSegmentGroup = ({
    children,
    label,
    onChange,
    selection,
    name,
    className,
    ...props
}: SegmentGroupProps): JSX.Element => {
    const contextData: SegmentContextData = {
        selectedValue: selection,
        inputName: name || `inputName-${createGuid()}`,
        onChange,
    };
    const labelPropsForHook: LabelAriaProps = {
        label,
        ...props,
    };
    const { labelProps, fieldProps } = useLabel(labelPropsForHook);
    return (
        <StyledSegmentGroupWrapper>
            <StyledLabel {...labelProps}>{label}</StyledLabel>
            <StyledSegmentGroupLayout
                {...fieldProps}
                className={className && filterClassName(className, ["rounded", "border"])}
            >
                <SegmentContext.Provider value={contextData}>{children}</SegmentContext.Provider>
            </StyledSegmentGroupLayout>
        </StyledSegmentGroupWrapper>
    );
};

import type { PdsBadgeProps, PdsBadgeSize, PdsBadgeVariant } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";

import { tx } from "@/libs";
import { PdsCloseButton } from "@/components/closeButton";

const LeadingDot = () => (
    <svg className={tx("h-1.5 w-1.5 me-1.5")} fill="currentColor" viewBox="0 0 8 8" aria-hidden="true">
        <circle cx={4} cy={4} r={3} />
    </svg>
);

// We list all these styles directly instead of computing it so that
// tailwind purges correctly.
const getVariantStyles = (variant: PdsBadgeVariant) => {
    const styles: Record<PdsBadgeVariant, string> = {
        neutral: "bg-neutral-200 text-neutral-900",
        red: "bg-red-200 text-red-900",
        gold: "bg-gold-200 text-gold-900",
        green: "bg-green-200 text-green-900",
        blue: "bg-blue-200 text-blue-900",
        purple: "bg-purple-200 text-purple-900",
        pink: "bg-pink-200 text-pink-900",
        white: "bg-white text-neutral-900 ring-1 ring-neutral-300 ring-inset",
    };

    return styles[variant];
};

const getSizeStyles = (size: PdsBadgeSize, isRemovable: boolean) => {
    const styles: Record<PdsBadgeSize, string> = {
        small: tx("ps-2 py-0.5 text-xs leading-4", isRemovable ? "pe-1" : "pe-2"),
        medium: tx("ps-2.5 py-1 text-sm leading-5", isRemovable ? "pe-1.5" : "pe-2.5"),
    };

    return styles[size];
};

/**
 * `PdsBadge` is a compact visual text label that describes an attribute of an object.
 *
 * It provides annotations on other information like a label or an object name.
 *
 * - `PdsBadge` content needs to be explicit enough to understand without relying on the color.
 * - `PdsBadge` content is best used concise, usually inline & 1-3 words.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=12%3A539).
 */
export const PdsBadge = forwardRef<HTMLSpanElement, PdsBadgeProps>(
    (
        {
            id,
            variant = "neutral",
            size = "medium",
            dot = false,
            rounded = false,
            removeButtonAriaLabel = "Remove badge",
            className,
            onRemove,
            children,
            ...props
        }: PdsBadgeProps,
        ref
    ) => {
        const badgeId = useMemo(() => id ?? `pds-badge-${nanoid()}`, [id]);

        return (
            <span
                ref={ref}
                id={badgeId}
                className={tx(
                    "inline-flex items-center",
                    rounded ? "rounded-full" : "rounded-sm",
                    getVariantStyles(variant),
                    getSizeStyles(size, Boolean(onRemove)),
                    className
                )}
                {...props}
            >
                {dot && <LeadingDot />}
                {children}
                {onRemove && (
                    <PdsCloseButton
                        variant={variant}
                        aria-label={removeButtonAriaLabel}
                        className="ms-1.5"
                        onClick={onRemove}
                    />
                )}
            </span>
        );
    }
);

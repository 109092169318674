import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconUnfoldLess = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M8.12 19.3a1 1 0 0 0 1.41 0L12 16.83l2.47 2.47a1 1 0 1 0 1.41-1.41l-3.17-3.17a1 1 0 0 0-1.41 0l-3.17 3.17a.99.99 0 0 0-.01 1.41zm7.76-14.6a1 1 0 0 0-1.41 0L12 7.17 9.53 4.7a1 1 0 0 0-1.41 0 1 1 0 0 0 0 1.42l3.17 3.17a1 1 0 0 0 1.41 0l3.17-3.17a1 1 0 0 0 .01-1.42z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconStary = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M17.53 13.47a.4.4 0 0 1 .2.2l.97 2.16a.4.4 0 0 0 .2.2l2.15.97a.4.4 0 0 1 0 .73l-2.15.97a.4.4 0 0 0-.2.2l-.97 2.15a.4.4 0 0 1-.73 0l-.97-2.15a.4.4 0 0 0-.2-.2l-2.16-.97a.4.4 0 0 1 0-.73l2.16-.97a.4.4 0 0 0 .2-.2l.97-2.16c.09-.2.33-.29.53-.2zM9.52 2.07c.11.06.2.14.25.26l1.95 4.33c.05.1.14.2.25.25l4.33 1.95a.5.5 0 0 1 0 .91l-4.33 1.95a.5.5 0 0 0-.25.25L9.77 16.3a.5.5 0 0 1-.91 0L6.9 11.97a.5.5 0 0 0-.25-.25L2.33 9.77a.5.5 0 0 1 0-.91L6.66 6.9a.5.5 0 0 0 .25-.25l1.95-4.33a.5.5 0 0 1 .66-.25z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconHelp = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92A3.5 3.5 0 0 0 13 15h-2v-.5a4.05 4.05 0 0 1 1.17-2.83l1.24-1.26c.46-.44.68-1.1.55-1.8a2 2 0 0 0-1.39-1.53 2.02 2.02 0 0 0-2.47 1.27c-.12.37-.43.65-.82.65h-.3a.87.87 0 0 1-.82-1.12 4 4 0 0 1 3.23-2.83 4.07 4.07 0 0 1 3.87 1.8c1.18 1.63.83 3.38-.19 4.4z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconFastForward = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19 12a1 1 0 0 0-.38-.77l.01-.01-5-4-.01.01A.99.99 0 0 0 13 7a1 1 0 0 0-1 1v2.72l-4.38-3.5v.01A.99.99 0 0 0 7 7a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1c.24 0 .44-.09.62-.23l.01.01 4.37-3.5V16a1 1 0 0 0 1 1c.24 0 .44-.09.62-.23l.01.01 5-4-.01-.01A1 1 0 0 0 19 12z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorHorizontalAlignCenter = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M5 9.21V11H2c-.55 0-1 .45-1 1s.45 1 1 1h3v1.79c0 .45.54.67.85.35l2.79-2.79c.2-.2.2-.51 0-.71L5.85 8.85a.5.5 0 00-.843.273L5 9.21zm14 5.58V13h3c.55 0 1-.45 1-1s-.45-1-1-1h-3V9.21a.5.5 0 00-.85-.36l-2.79 2.79c-.2.2-.2.51 0 .71l2.79 2.79c.31.32.85.1.85-.35zM12 4c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1s1-.45 1-1V5c0-.55-.45-1-1-1z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorInsertRowBottom = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M10 15h1v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-1v-1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1h-1a1 1 0 0 0-1 1 1 1 0 0 0 1 1zm9-11H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 14H6v-8h12v8zm0-9H6V6h12v3z" />
            </svg>
        );
    }
);

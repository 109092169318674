import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconAssess = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M11.63 5l.153.009a.737.737 0 01.46.211c.094.098.168.196.223.293l.068.147 4.43 12.722a.617.617 0 01.036.178c0 .12-.044.223-.13.31a.433.433 0 01-.197.116l-.116.014h-2.534l-.152-.007c-.19-.018-.33-.072-.421-.163a.901.901 0 01-.17-.222l-.041-.108-.564-1.66h-5.35L6.76 18.5l-.04.108a.901.901 0 01-.17.222c-.092.09-.232.145-.422.163L5.977 19H3.443l-.116-.014a.433.433 0 01-.196-.116.422.422 0 01-.131-.31c0-.044.012-.104.036-.178L7.466 5.66l.068-.147c.055-.097.13-.195.223-.293a.737.737 0 01.46-.211L8.371 5h3.258zM10 9.308l-1.615 4.307h3.23L10 9.308zM18.427 5l.078.01c.049.013.093.04.133.08.04.04.065.084.079.133l.01.079v2.022H20.7l.077.01c.05.013.094.04.133.08.04.04.066.084.08.133l.009.079v.737l-.01.078a.296.296 0 01-.079.134.293.293 0 01-.133.08l-.077.01h-1.975v2.033l-.01.079a.296.296 0 01-.078.134.293.293 0 01-.133.08l-.078.009h-.843l-.078-.01a.293.293 0 01-.133-.08.296.296 0 01-.078-.133l-.01-.079V8.665h-1.986l-.077-.01a.293.293 0 01-.133-.08.296.296 0 01-.08-.134L15 8.363v-.737l.01-.079a.296.296 0 01.079-.134.293.293 0 01.133-.079l.077-.01h1.986V5.302l.01-.079a.296.296 0 01.078-.134.293.293 0 01.133-.08L17.584 5h.843z" />
            </svg>
        );
    }
);

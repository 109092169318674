import { useApi } from "@api/.";

export interface Option {
    value: string;
    text?: string;
    type?: string;
}

export interface Learner {
    firstName: string;
    lastName: string;
    email?: string;
    academicLevelCode: string;
    curriculumCode: string;
    curriculumOptionCode?: string;
    countryCode?: string;
}

export interface Curriculum {
    code: string;
    name: string;
}

export interface CurriculumOption {
    code: string;
    name: string;
    curriculumCode: string;
}

interface CurriculumResponse {
    curriculums: Curriculum[];
}

interface CurriculumOptionsResponse {
    curriculumOptions: CurriculumOption[];
}

export const useCurriculum = () => {
    const { data, ...rest } = useApi<CurriculumResponse>(`/common/curriculums/forhome`);
    return { curriculums: data?.curriculums, ...rest };
};

export const useCurriculumOptions = () => {
    const { data, ...rest } = useApi<CurriculumOptionsResponse>(`/common/curriculum-options/forhome`);
    return { curriculumOptions: data?.curriculumOptions, ...rest };
};

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("tailwind-merge"), require("unidecode"), require("styled-components"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "tailwind-merge", "unidecode", "styled-components", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["@educationperfect/web-pds-react"] = factory(require("react"), require("tailwind-merge"), require("unidecode"), require("styled-components"), require("react-dom"));
	else
		root["@educationperfect/web-pds-react"] = factory(root["react"], root["tailwind-merge"], root["unidecode"], root["styled-components"], root["react-dom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__5891__, __WEBPACK_EXTERNAL_MODULE__6766__, __WEBPACK_EXTERNAL_MODULE__1608__, __WEBPACK_EXTERNAL_MODULE__7111__) {
return 
import { useEffect, useMemo, useState } from "react";

import screenBreakpoints from "../../styles/screens/screenBreakpoints";

const screens = screenBreakpoints;

export type Breakpoint = keyof typeof screenBreakpoints;

/**
 * A hook that validates whether the current viewport matches a certain breakpoint.
 *
 * @param breakpoint a tailwind-compatible breakpoint `('sm' | 'md' | 'lg' | 'xl' | '2xl')`
 * @param defaultValue a boolean default value for the check.
 */
export const useBreakpoint = (breakpoint: Breakpoint, defaultValue: boolean = false) => {
    const [match, setMatch] = useState(defaultValue);

    useEffect(() => {
        if (!("matchMedia" in window)) return;

        function track() {
            const value = (screens[breakpoint] as string) ?? "999999px";
            const query = window.matchMedia(`(min-width: ${value})`);

            setMatch(query.matches);
        }

        track();

        window.addEventListener("resize", track);
        return () => window.removeEventListener("resize", track);
    });

    return match;
};

/**
 * A side effect callback hook for when the current viewport matches a breakpoint.
 * @param breakpoint a tailwind-compatible breakpoint `('sm' | 'md' | 'lg' | 'xl' | '2xl')`.
 * @param effect a callback that is triggered when viewport matches.
 */
export const useBreakpointEffect = (breakpoint: Breakpoint, callback: (match: boolean) => void) => {
    const match = useBreakpoint(breakpoint);

    useEffect(() => {
        if (match) {
            callback(match);
        }
    }, [callback, match]);
};

/**
 * A hook that returns a value that toggles between a 'valid' and 'invalid' value
 * whether the current viewport matches a breakpoint.
 * @param breakpoint a tailwind-compatible breakpoint `('sm' | 'md' | 'lg' | 'xl' | '2xl')`
 * @param valid a valid value to use when viewport matches.
 * @param invalid an invalid value to use when viewport fails to match.
 */
export const useBreakpointValue = <T, U>(breakpoint: Breakpoint, valid: T, invalid: U) => {
    const match = useBreakpoint(breakpoint);
    const value = useMemo(() => (match ? valid : invalid), [invalid, match, valid]);

    return value;
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconTextHighlight = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M13 14H6v-4h7V8H5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8v-2zm4 3h-1V7h1a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-1a2 2 0 0 0-1 .28A2 2 0 0 0 14 5h-1a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1v10h-1a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1a2 2 0 0 0 1-.28 2 2 0 0 0 1 .28h1a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm2-9h-2v2h1v4h-1v2h2a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z" />
            </svg>
        );
    }
);

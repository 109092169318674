import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconDesktopOnly = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M17 16.44L3.61 3.05A1 1 0 1 0 2.2 4.46L5 7.27V21c0 1.1.9 2 2 2h10a2 2 0 0 0 1.98-1.75L20 22.27a1 1 0 1 0 1.41-1.41L19 18.44l-2-2zM7 19V9.27L16.73 19H7zM17 5v8.61l2 2V3a2 2 0 0 0-2-2H7c-.71 0-1.33.37-1.68.93L8.39 5H17z" />
            </svg>
        );
    }
);

import type { PdsChipNamespace, PdsChipProps } from "@/components/chip";
import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsChoiceGroupItemProps, PdsChoiceGroupChipProps } from "./type";

import { useMemo, forwardRef, useCallback } from "react";

import { tx } from "@/libs";
import { useSafeLayoutEffect } from "@/hooks";
import { PdsChip } from "@/components/chip";

import { usePdsChoiceGroupContext } from "./context";

export const PdsChoiceGroupChip = forwardRef<HTMLButtonElement, PdsChoiceGroupChipProps>(
    (
        {
            value,
            label,
            disabled: disabledProps = false,
            checked: checkedProps,
            defaultChecked = false,
            className,
            children,
            onChange,
            ...props
        }: PdsChoiceGroupItemProps,
        ref
    ) => {
        const [selected, { select, toggle }, { name, disabled }] = usePdsChoiceGroupContext();

        const checked = useMemo(() => checkedProps || selected.includes(value), [selected, checkedProps, value]);

        /** Set the initial state of the chip if specified. */
        useSafeLayoutEffect(() => {
            if (checked || defaultChecked) {
                select(value);
            }
        }, []);

        /** Change handler to toggle the chip on interation. */
        const handleClick = useCallback(() => toggle(value), [toggle, value]);

        return (
            <PdsChip
                ref={ref}
                name={name}
                value={value}
                disabled={disabled || disabledProps}
                checked={checked}
                defaultChecked={defaultChecked}
                onClick={handleClick}
                className={tx("h-9", className)}
                {...(props as PdsChipProps)}
            >
                {label}
                {children}
            </PdsChip>
        );
    }
) as CompoundComponentWithRef<PdsChipNamespace, PdsChoiceGroupChipProps, HTMLButtonElement>;

PdsChoiceGroupChip.Leading = PdsChip.Leading;
PdsChoiceGroupChip.Trailing = PdsChip.Trailing;

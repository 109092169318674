import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorClearFormatting = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M18.5 8a1.5 1.5 0 1 0 0-3H6.39l3 3h1.83l-.55 1.28 2.09 2.09L14.21 8h4.29zm-1.06 10.88L4.12 5.56a1 1 0 1 0-1.41 1.41l6.26 6.26-1.65 3.84a1.38 1.38 0 1 0 2.54 1.09l1.21-2.83 4.95 4.95a1 1 0 0 0 1.41 0c.4-.38.4-1.01.01-1.4z" />
            </svg>
        );
    }
);

import type { PdsDropdownSelectTriggerChipProps } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";

import { PdsChip } from "@/components/chip";
import { PdsBadge } from "@/components/badge";
import { PdsIconChevronDown } from "@/components/icons";

import { usePdsDropdownSelectContext } from "./context";

export const PdsDropdownSelectTriggerChip = forwardRef<HTMLButtonElement, PdsDropdownSelectTriggerChipProps>(
    ({ id, badgeVariant, badgeAriaLabel, children, ...props }, ref) => {
        /**
         * Memo to initialize dropdown select trigger chip.
         */
        const dropdownSelectTriggerChipId = useMemo(
            () => id ?? `pds-dropdown-select-item-trigger-chip-${nanoid()}`,
            [id]
        );

        /**
         * Get selected values array from context.
         */
        const [selected] = usePdsDropdownSelectContext();

        return (
            <PdsChip role="status" id={dropdownSelectTriggerChipId} ref={ref} {...props}>
                {children}
                {selected.length > 0 ? (
                    <PdsChip.Trailing
                        as={PdsBadge}
                        rounded
                        size="small"
                        aria-hidden={false}
                        variant={badgeVariant}
                        aria-label={badgeAriaLabel?.(selected.length) || `${selected.length} selected`}
                    >
                        {selected.length}
                    </PdsChip.Trailing>
                ) : (
                    <PdsChip.Trailing as={PdsIconChevronDown} />
                )}
            </PdsChip>
        );
    }
);

import { useApi } from "@api/index";
import regionCountries, { Region } from "country-region-data";

export interface Country {
    code: string;
    name: string;
    type: string;
}

interface CountriesResponse {
    countries: Country[];
}

export function useCountries() {
    const { data, ...rest } = useApi<CountriesResponse>("/common/countries");
    return { countries: data?.countries, ...rest };
}

export function getRegions(code?: string): Region[] {
    const regions = regionCountries.find((c) => c?.countryShortCode === code)?.regions ?? [];
    return regions || [];
}

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorRtl = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M7.76 14.28a1 1 0 0 1 1.41 0l.06.06a1 1 0 0 1 0 1.4L8 17h10a1 1 0 0 1 1 .88V18a1 1 0 0 1-1 1H7.99l1.24 1.25a1 1 0 0 1 .08 1.32l-.08.09-.06.06a1 1 0 0 1-1.42 0l-2.92-2.96-.06-.06a1 1 0 0 1 0-1.4l2.98-3.02zM6.33 3.25c.91 0 1.6.2 2.07.6.46.4.7.97.7 1.73 0 .6-.17 1.09-.5 1.48-.33.4-.8.66-1.4.78.22.05.42.13.6.26.16.13.33.31.5.56l.83 1.25c.1.16.16.32.16.47 0 .2-.08.38-.24.51a.9.9 0 0 1-.58.2.9.9 0 0 1-.4-.1.83.83 0 0 1-.34-.3L6.28 8.52a1.3 1.3 0 0 0-.4-.42 1 1 0 0 0-.52-.1h-.9v2.22c0 .26-.07.47-.22.63a.81.81 0 0 1-.61.23.85.85 0 0 1-.63-.23.85.85 0 0 1-.23-.63V4.05c0-.26.07-.46.2-.6.15-.14.35-.2.6-.2h2.76zm9.44 0c.54 0 .82.23.82.69 0 .47-.28.7-.82.7H14v5.57c0 .25-.08.46-.23.61a.85.85 0 0 1-.63.23.85.85 0 0 1-.62-.23.82.82 0 0 1-.24-.61V4.64H10.5c-.54 0-.81-.23-.81-.7 0-.46.27-.7.81-.7h5.28zm2.41-.06c.27 0 .47.08.63.23.15.16.23.36.23.62V9.6h2.82c.53 0 .8.24.8.7 0 .47-.27.7-.8.7h-3.73a.85.85 0 0 1-.6-.2.84.84 0 0 1-.2-.6V4.04c0-.26.07-.46.22-.62a.85.85 0 0 1 .63-.23zM6.08 4.55H4.44V6.7h1.64c.5 0 .85-.09 1.07-.26.22-.17.33-.44.33-.8 0-.38-.11-.65-.33-.82-.22-.17-.58-.26-1.07-.26z" />
            </svg>
        );
    }
);

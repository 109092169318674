import { PdsStepper } from "@educationperfect/web-pds-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.css";

export type Step = {
    step: number;
    path: string;
};

type StepperProps = {
    steps: Step[];
};

const Stepper = ({ steps }: StepperProps) => {
    const { pathname } = useLocation();

    const [active, setActive] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const existing = steps.find((s) => pathname.endsWith(s.path));

        if (existing) {
            setActive(existing.step);
            setVisible(true);
            return;
        }

        setVisible(false);
    }, [pathname, steps]);

    return (
        <>
            {visible && (
                <PdsStepper
                    className={`lg:w-[400px] w-7/12 ${styles.stepper}`}
                    activeStep={active}
                    orientation="horizontal"
                    onClick={() => {}}
                    tabIndex={-1}
                >
                    {steps.map((step) => (
                        <PdsStepper.Step key={step.step} tabIndex={-1} onClick={() => {}} />
                    ))}
                </PdsStepper>
            )}
        </>
    );
};

export default Stepper;

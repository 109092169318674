import type { PdsChoiceGroupCardProps } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";

import { PdsIconCheckCircle } from "@/components/icons";

import { PdsChoiceGroupCard } from "./ChoiceGroupCard";

export const PdsChoiceGroupCheckboxCard = forwardRef<HTMLInputElement, PdsChoiceGroupCardProps>(
    ({ id, ...props }: PdsChoiceGroupCardProps, ref) => {
        const checkboxCardId = useMemo((): string => id ?? `pds-checkbox-card-${nanoid()}`, [id]);

        return (
            <PdsChoiceGroupCard role="checkbox" ref={ref} id={checkboxCardId} {...props}>
                <PdsChoiceGroupCard.Icon as={PdsIconCheckCircle} />
            </PdsChoiceGroupCard>
        );
    }
);

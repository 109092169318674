import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorSubscript = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M16.34 12.6a2.8 2.8 0 0 1 1.51.46c.23.15.42.36.56.64a2 2 0 0 1 .21.94 2.1 2.1 0 0 1-.63 1.52 4.9 4.9 0 0 1-1.18.84c-.18.1-.34.2-.47.3-.14.1-.2.18-.22.26h2c.4 0 .6.2.6.59v.45c0 .39-.2.58-.6.58h-3.48a.65.65 0 0 1-.45-.15.55.55 0 0 1-.18-.43v-.21a2.81 2.81 0 0 1 .92-2.12c.26-.22.52-.42.78-.6.25-.16.47-.32.65-.46.18-.14.27-.26.27-.38 0-.16-.05-.27-.15-.34a.57.57 0 0 0-.32-.1.68.68 0 0 0-.49.2c-.3.28-.58.32-.83.12l-.44-.35c-.31-.25-.36-.51-.14-.8a2.45 2.45 0 0 1 2.08-.96zM6.7 5.3l2.47 2.47 2.48-2.48a1 1 0 0 1 1.41 1.42L10.6 9.18l2.47 2.48a1 1 0 0 1-1.41 1.41L9.18 10.6l-2.47 2.47a1 1 0 1 1-1.42-1.41l2.48-2.48-2.48-2.47a1 1 0 0 1 1.42-1.42z" />
            </svg>
        );
    }
);

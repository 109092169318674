import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconMaximise = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M10 13a1 1 0 0 0-.71.28l-3.3 3.3v-1.6a1 1 0 0 0-1-1 1 1 0 0 0-.99 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1 1 1 0 0 0-1-1H7.42l3.29-3.28A1 1 0 0 0 10 13zm9-9h-4a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1.58l-3.3 3.3a.99.99 0 0 0-.29.7 1 1 0 0 0 1.71.7L18 7.43V9a1 1 0 0 0 1 1 1 1 0 0 0 .99-1V5a1 1 0 0 0-1-1.01z" />
            </svg>
        );
    }
);

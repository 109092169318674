import type { Context } from "react";
import type { PdsChoiceGroupContextProps } from "@/components/choice/type";

import { createContext, useContext } from "react";

export const PdsChoiceGroupContext = createContext<PdsChoiceGroupContextProps<string>>([
    [],
    {
        toggle: () => {},
        select: () => {},
        unselect: () => {},
        reset: () => {},
        isEqual: () => false,
    },
    {
        name: "",
        disabled: false,
        error: false,
        options: [],
    },
]);

export const usePdsChoiceGroupContext = <T extends unknown = string>() =>
    useContext(PdsChoiceGroupContext as unknown as Context<PdsChoiceGroupContextProps<T>>);

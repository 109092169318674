import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorRemoveRow = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M20.5 18.5v-10a1 1 0 0 0-1-1h-3v2h2v3h-12v-3h2v-2h-3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1zm-14-1v-3h12v3h-12zm3.29-8.71a1 1 0 0 0 1.42 1.42l1.29-1.3 1.29 1.29c.18.19.43.3.71.3a1 1 0 0 0 .71-1.71l-1.3-1.29 1.3-1.29a1 1 0 0 0-1.42-1.42l-1.29 1.3-1.29-1.3a1 1 0 0 0-1.42 1.42l1.3 1.29-1.3 1.29z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconUnpaid = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 2a10.01 10.01 0 0 1 0 20 10.01 10.01 0 0 1 0-20zM5.59 7.22a8 8 0 0 0 11.19 11.2l-2.23-2.23a3 3 0 0 1-1.3.63v.13c-.03.52-.1.74-.32.95-.21.2-.49.31-.79.31-.69 0-1.09-.49-1.09-1.32v-.07a4.7 4.7 0 0 1-1.33-.59c-.72-.46-1.08-.99-1.08-1.59 0-.63.43-1.1 1.02-1.1.35 0 .65.14 1.05.45.87.74 1.06.84 1.58.84.26 0 .5-.08.69-.21l-2.38-2.38-.06-.02a2.32 2.32 0 0 1-1.62-1.66L5.59 7.22zM12 4a8 8 0 0 0-5.03 1.78l.48.48.17.17 1.8 1.8 7.99 7.99.8.8A8 8 0 0 0 12 4zm.09 2.02c.61 0 1.03.43 1.09 1.13l.24.09c.64.2.93.36 1.25.63.37.34.54.67.54 1.05 0 .57-.44 1.04-.96 1.04a1 1 0 0 1-.33-.06L11 6.98c.02-.16.06-.27.11-.39.19-.34.57-.57.98-.57z" />
            </svg>
        );
    }
);

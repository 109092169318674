import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorRedo = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19.71 8.29l-3-3a1 1 0 0 0-1.42 1.42L16.59 8H9a5 5 0 0 0 0 10h4v-2H9a3 3 0 1 1 0-6h7.59l-1.29 1.29a.97.97 0 0 0-.3.71 1 1 0 0 0 1.71.71l3-3A1 1 0 0 0 20 9a1 1 0 0 0-.29-.71z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconMove = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19.71 11.29l-2-2a1 1 0 0 0-1.42 1.42l.3.29H13V7.41l.29.29c.18.19.43.3.71.3a1 1 0 0 0 .71-1.71l-2-2A1 1 0 0 0 12 4a1 1 0 0 0-.71.29l-2 2a1 1 0 0 0 1.42 1.42l.29-.3V11H7.41l.29-.29c.19-.18.3-.43.3-.71a1 1 0 0 0-1.71-.71l-2 2A1 1 0 0 0 4 12a1 1 0 0 0 .29.71l2 2a1 1 0 0 0 1.42-1.42l-.3-.29H11v3.59l-.29-.29A.97.97 0 0 0 10 16a1 1 0 0 0-.71 1.71l2 2A1 1 0 0 0 12 20a1 1 0 0 0 .71-.29l2-2a1 1 0 0 0-1.42-1.42l-.29.3V13h3.59l-.29.29a.97.97 0 0 0-.3.71 1 1 0 0 0 1.71.71l2-2A1 1 0 0 0 20 12a1 1 0 0 0-.29-.71z" />
            </svg>
        );
    }
);

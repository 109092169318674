import type { PdsColorToken } from "@/typings/color";

import { get } from "lodash";

import pdsColors from "../../styles/colors/pdsColors";

/**
 * A hook to convert a color token to a CSS color value that can be used
 * to style a component.
 *
 * @example
 * useColor("neutral-1000"); // returns '#182552'
 * useColor("theme-300"); // returns '#ebeefd' (when theme two is selected)
 *
 * @param colorToken The color token string.
 * @returns The CSS color value.
 */
export const usePdsColor = (colorToken: PdsColorToken) => {
    const colorHex = get(pdsColors, colorToken.replaceAll("-", "."));

    if (!colorHex) {
        throw new Error(
            `${colorToken} is not a valid color token, please see Storybook documentation for a list of valid colors.`
        );
    }

    return colorHex;
};

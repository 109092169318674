import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconThumbUp = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12.95 3.36L8.41 7.91c-.3.3-.48.72-.48 1.16v8.2c0 .9.74 1.64 1.65 1.64h7.38c.65 0 1.24-.4 1.5-1l2.67-6.24a2.46 2.46 0 0 0-2.25-3.43h-4.64l.78-3.76c.08-.41-.04-.83-.34-1.12a1.22 1.22 0 0 0-1.63-.09l-.1.1zm-8.3 15.55c.9 0 1.63-.74 1.63-1.64V10.7c0-.9-.74-1.64-1.64-1.64-.9 0-1.64.74-1.64 1.64v6.57c0 .9.74 1.64 1.64 1.64z" />
            </svg>
        );
    }
);

import type { CompoundPolymorphicElement } from "@/typings/utilities";
import type { PdsButtonNamespace } from "@/components/button";
import type { PdsPopoverTriggerProps } from "./type";

import { polymorphComponent } from "@/libs";
import { PdsButton } from "@/components/button";

/**
 * `PdsPopover.Trigger` by default is a PdsButton, but could be polymorphed to any elements for design customisability.
 * Accessibility-wise, PdsPopover would still have to be triggered via onClick,
 * any other trigger method is **HEAVILY** discouraged.
 */
export const PdsPopoverTrigger = polymorphComponent<PdsPopoverTriggerProps>(PdsButton, (props) => ({
    type: "button",
    ...props,
})) as CompoundPolymorphicElement<PdsButtonNamespace, PdsPopoverTriggerProps>;

PdsPopoverTrigger.Icon = PdsButton.Icon;
PdsPopoverTrigger.Leading = PdsButton.Leading;
PdsPopoverTrigger.Trailing = PdsButton.Trailing;

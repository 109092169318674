import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconFilter = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M18.86 4H5.14a1.15 1.15 0 0 0-.8 1.95l5.37 5.38v7.53a1.15 1.15 0 0 0 1.96.8l2.28-2.28c.21-.2.34-.49.34-.8v-5.25l5.38-5.38c.2-.2.33-.49.33-.8C20 4.5 19.49 4 18.86 4z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconTrendDown = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19 11a1 1 0 0 0-1 1v.59l-4.29-4.3A1 1 0 0 0 13 8c-.16 0-.31.05-.44.11V8.1L9 9.88 5.45 8.11v.01A1 1 0 0 0 5 8a1 1 0 0 0-1 1 1 1 0 0 0 .56.89v.01l4 2v-.01c.13.06.28.11.44.11.16 0 .31-.05.44-.11v.01l3.36-1.68L16.59 14H16a1 1 0 0 0-1 1 1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            </svg>
        );
    }
);

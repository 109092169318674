import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconAttach = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M11.64 17.96a3.99 3.99 0 0 1-5.54 0 3.77 3.77 0 0 1 0-5.4l7.15-6.99c.9-.87 2.34-.88 3.23 0 .9.87.9 2.28 0 3.15l-6.7 6.55a.65.65 0 0 1-.91 0 .62.62 0 0 1 0-.9l6.23-6.1a.94.94 0 0 0 0-1.35 1 1 0 0 0-1.39 0L7.5 13.01a2.5 2.5 0 0 0 0 3.6c1.02 1 2.66 1 3.68.01l6.7-6.55a4.07 4.07 0 0 0 0-5.85 4.3 4.3 0 0 0-6 0L4.72 11.2a5.66 5.66 0 0 0 0 8.12 5.98 5.98 0 0 0 8.3 0l6.7-6.54a.94.94 0 0 0 0-1.35 1 1 0 0 0-1.4 0l-6.68 6.53z" />
            </svg>
        );
    }
);

import React, { useEffect, useMemo, useState } from "react";
import { PdsSpinner, PdsIconCheckCircle } from "@educationperfect/web-pds-react";
import Sidebar from "@pages/signup/step-complete/sidebar";
import store from "@services/store";
import { Payer, useTrialPeriod } from "@api/subscription";
import { capitalize } from "lodash";

type Item = {
    label: string;
    subLabel?: string;
};

type ProgressItem = Item & {
    loading?: boolean;
};

type ItemProps = {
    item: ProgressItem;
    cls?: string;
};

const SetupItem = ({ item, cls }: ItemProps) => {
    return (
        <div className={`ml-5 ${cls}`}>
            <div className="flex flex-row items-center">
                <div className="w-[50px]">
                    {item.loading ? (
                        <PdsSpinner variant="primary" aria-label={item.label} />
                    ) : (
                        <PdsIconCheckCircle variant="primary" size="large" aria-label={item.label} />
                    )}
                </div>
                <div>
                    <div className="text-base font-normal text-neutral-900">{item.label}</div>
                    {item.subLabel && <div className="text-xs font-normal text-neutral-900">{item.subLabel}</div>}
                </div>
            </div>
        </div>
    );
};

type Props = {
    setDone: React.Dispatch<React.SetStateAction<boolean>>;
};

const Setup = ({ setDone }: Props) => {
    const payer = store.get("forhome:payer") as Payer;
    const [activeItems, setActiveItems] = useState<Item[]>([]);
    const [current, setCurrent] = useState<number>(0);
    const trialDays = useTrialPeriod().data?.trialPeriod;

    const trialPeriod = useMemo((): string => `${trialDays} day${trialDays !== 1 ? "s" : ""}`, [trialDays]);

    const items: Item[] = useMemo(
        () => [
            {
                label: "Setting up parent",
            },
            {
                label: "Setting up learner(s)",
            },
            {
                label: "Setting up learning pathways",
            },
            {
                label: `Setting up subscription for ${trialPeriod} free trial`,
                subLabel: "No lock-in contracts. You can cancel any time.",
            },
        ],
        [trialPeriod]
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent(current + 1);
        }, 2000);

        if (current === items.length) {
            clearInterval(interval);
            setDone(true);
        }
        return () => clearInterval(interval);
    }, [current, items.length, setDone]);

    useEffect(() => {
        const list = items.slice(0, current < 4 ? current + 1 : 4).map((item, index) => {
            return {
                label: item.label,
                subLabel: item.subLabel,
                loading: index === current || index === 3,
            };
        });
        setActiveItems(list);
    }, [current, items, setActiveItems]);

    const name = useMemo(() => (payer ? capitalize(payer.firstName) : ""), [payer]);

    return (
        <Sidebar>
            <section className="w-full min-h-screen flex flex-col justify-between pb-12 px-12 sm:px-3">
                <div className="flex flex-col sm:ml-32">
                    <div>
                        <h1 className="text-lg mt-40 items-center text-left font-bold text-neutral-900 sm:text-2xl">
                            All done {name}
                        </h1>
                        <div className="text-xs mt-1 text-left font-normal sm:text-sm sm:font-normal whitespace-pre-wrap">
                            We’re just getting your EP for Home account set up now.
                        </div>
                        <div className="text-xs text-left font-normal sm:text-sm sm:font-normal whitespace-pre-wrap">
                            This won’t take long.
                        </div>
                    </div>
                    {activeItems.map((item: Item, index: number) => (
                        <SetupItem key={item.label} item={item} cls={index === 0 ? "mt-10" : "mt-5"} />
                    ))}
                </div>
            </section>
        </Sidebar>
    );
};

export default Setup;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconFlag = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M14.4 6l-.24-1.2a1 1 0 0 0-.98-.8H6a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-6h5.6l.24 1.2c.09.47.5.8.98.8H19a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-4.6z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconStarFill = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M14.85 19.7c1.59.86 2.98-.2 2.68-2.03l-.55-3.31 2.31-2.34c1.28-1.3.75-3.01-1.02-3.28l-3.19-.48-1.42-3c-.8-1.67-2.52-1.69-3.31 0l-1.43 3-3.19.48c-1.77.27-2.3 1.97-1.02 3.28L7 14.36l-.54 3.3c-.3 1.84 1.09 2.9 2.68 2.03L12 18.13l2.85 1.56z" />
            </svg>
        );
    }
);

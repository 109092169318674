import type { ElementType } from "react";
import type { PolymorphicComponent, PolymorphicProps, PolymorphicRef } from "@/typings/utilities";
import type { PdsExtendableProps } from "./type";

import { usePopper } from "react-popper";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { useContainerSize, useRtL } from "@/hooks";
import { polymorphComponent } from "@/libs";
import { PdsPortal } from "@/components/portal";

const PdsExtendableWrapper = polymorphComponent("div");

/**
 * `PdsExtendable` handles optional rendering with a trigger and an extension.
 * `PdsExtendable` is provided as a component to encapsulate open/close state model with basic positioning.
 *
 * `PdsExtendable` uses [popperJs 🍿](https://popper.js.org/react-popper/) to position around a trigger anchor.
 *
 * `PdsExtendable` is polymorphable into any other HTML tag or React component, while keeping its positioning features.
 *
 * `useBoolean` is also provided as a hook to encapsulate only the open/close state logic.
 */
export const PdsExtendable = forwardRef(
    <E extends ElementType = "div">(
        {
            as,
            trigger,
            open = false,
            placement,
            container,
            children,
            style,
            ...props
        }: PolymorphicProps<E, PdsExtendableProps>,
        ref: PolymorphicRef<E>
    ) => {
        /**
         * Refs to handle extendable positioning & trigger.
         */
        const [triggerEl, setTriggerEl] = useState<HTMLElement | null>(null);
        const [extendableEl, setExtendableEl] = useState<HTMLDivElement | null>(null);

        /**
         * Hook to handle RtL directional attr.
         * Added open to deps list to rerender when portaled.
         */
        const { isRtL } = useRtL(triggerEl, [open]);

        /**
         * Effect handlers to properly handle propagate trigger & extendable refs.
         */
        useEffect(() => setTriggerEl(trigger ?? null), [trigger]);

        useImperativeHandle(ref, () => extendableEl as HTMLDivElement, [extendableEl]);

        /**
         * PopperJs hook for placement.
         * Fallback placement differ when right-to-left is set.
         */
        const { styles, attributes, update } = usePopper(triggerEl, extendableEl, {
            placement: placement ?? (isRtL ? "bottom-end" : "bottom-start"),
        });

        /**
         * Explicityly re-render when trigger resizes.
         */
        const { width, height } = useContainerSize(triggerEl);

        useEffect(() => {
            if (update) {
                update();
            }
        }, [width, height, update]);

        const extendableProps = {
            dir: isRtL ? "rtl" : undefined,
            ref: setExtendableEl,
            style: { ...styles.popper, ...style },
            ...attributes.popper,
        };

        return open ? (
            <PdsPortal container={container}>
                <PdsExtendableWrapper as={as} {...extendableProps} {...(props as any)}>
                    {children}
                </PdsExtendableWrapper>
            </PdsPortal>
        ) : null;
    }
) as PolymorphicComponent<PdsExtendableProps, ElementType, "div">;

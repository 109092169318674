import React, { FC, useEffect, useRef } from "react";

/*
 * Scroll to the block when it's rendered
 */
const ScrollOnRenderWrapper: FC = ({ children }) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => ref.current?.scrollIntoView({ behavior: "smooth" }), [ref]);

    return <div ref={ref}>{children}</div>;
};

export default ScrollOnRenderWrapper;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconPrint = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M16 6a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v1h8V6zm3 2H5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h1v-3h12v3h1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zm-1 3h-2v-1h2v1zm-3 6H9v-3H7v4a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-4h-2v3z" />
            </svg>
        );
    }
);

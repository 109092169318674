import React, { FC, useEffect, useState } from "react";
import { createContext } from "@helpers/context";
import store from "@services/store";
import { getRegions, useCountries } from "@api/payer";
import { PdsIconHome, PdsIconSchool, PdsSelectOption } from "@educationperfect/web-pds-react";
import { loginRoute } from "@pages/signup/constants";

export interface Payer {
    email: string;
    firstName?: string;
    lastName?: string;
    country?: PdsSelectOption[];
    state?: PdsSelectOption[];
    type?: string;
    intent?: string;
    phoneNumber?: string;
    dialCode?: string;
}

interface PayerContext {
    payer: Payer;
    setPayer: React.Dispatch<React.SetStateAction<Payer>>;
    storePayer: () => void;
    countries?: PdsSelectOption[];
    states: PdsSelectOption[];
    updateStates: (country: string) => void;
    error: boolean;
    setError: (error: boolean) => void;
    loginRoute: string;
    isReady: Boolean;
}

export const payerTypeData: Record<string, string>[] = [
    { label: "Parent", value: "parent", ariaLabel: "Parent account type" },
    { label: "Tutoring Professional", value: "tutor", ariaLabel: "Tutoring Professional account type" },
    { label: "School Professional", value: "school", ariaLabel: "School Professional account type" },
    { label: "Other", value: "other", ariaLabel: "Other account type" },
];

export const payerIntentTypeData: Record<string, any>[] = [
    {
        label: "School Support",
        value: "tutoring",
        ariaLabel: "Parent intent type",
        caption: <span>Supporting my learner’s school education&nbsp;&nbsp;&nbsp;</span>,
        icon: <PdsIconSchool />,
    },
    {
        label: "Homeschooling",
        value: "homeschool",
        ariaLabel: "Parent intent type",
        caption: "Schooling & supporting my learner at home",
        icon: <PdsIconHome />,
    },
];

/**
 * Payers context
 */
const [usePayerContext, Provider] = createContext<PayerContext>();

export const PayerProvider: FC = ({ children }) => {
    const email = store.get("forhome:valid-email") as string;
    const existing = store.get("forhome:payer");
    const [payer, setPayer] = useState<Payer>(existing ? { email, ...existing } : { email });
    const { countries: countryData, loading: countriesLoading } = useCountries();
    const [error, setError] = useState(false);
    const [countries, setCountries] = useState<PdsSelectOption[]>([]);
    const [states, setStates] = useState<PdsSelectOption[]>([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!countriesLoading && countryData) {
            setCountries(
                countryData.map((c) => {
                    return {
                        value: c.code,
                        label: c.name,
                    };
                })
            );
            setIsReady(true);
        }
    }, [countriesLoading, countryData]);

    const updateStates = (country: string) => {
        const regions = getRegions(country);
        setStates(
            regions.map((r) => {
                return {
                    value: r.shortCode,
                    label: r.name,
                };
            })
        );
    };

    const storePayer = () => {
        store.set("forhome:payer", {
            ...payer,
            countryCode: payer.country,
            state: payer.state,
        });
    };

    return (
        <Provider
            value={{
                payer,
                setPayer,
                storePayer,
                countries,
                states,
                updateStates,
                error,
                setError,
                loginRoute,
                isReady,
            }}
        >
            {children}
        </Provider>
    );
};

export { usePayerContext, Provider };

import type { PdsChoiceGroupCardProps } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";

import { PdsIconTarget } from "@/components/icons";

import { PdsChoiceGroupCard } from "./ChoiceGroupCard";

export const PdsChoiceGroupRadioCard = forwardRef<HTMLInputElement, PdsChoiceGroupCardProps>(
    ({ id, ...props }: PdsChoiceGroupCardProps, ref) => {
        const radioCardId = useMemo((): string => id ?? `pds-radio-card-${nanoid()}`, [id]);

        return (
            <PdsChoiceGroupCard role="radio" ref={ref} id={radioCardId} {...props}>
                <PdsChoiceGroupCard.Icon as={PdsIconTarget} />
            </PdsChoiceGroupCard>
        );
    }
);

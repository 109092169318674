import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconSwap = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.14 11.86l-2.78 2.79a.51.51 0 0 0 0 .71l2.78 2.79c.31.32.85.09.85-.35V16H13a1 1 0 0 0 1-1 1 1 0 0 0-1-1H6.99v-1.79a.5.5 0 0 0-.85-.35zm14.51-3.21l-2.78-2.79c-.31-.32-.85-.09-.85.35V8H11a1 1 0 0 0-1 1 1 1 0 0 0 1 1h6.01v1.79c0 .45.54.67.85.35l2.78-2.79a.5.5 0 0 0 .01-.7z" />
            </svg>
        );
    }
);

import styled, { css } from "styled-components";
import tw from "twin.macro";
import { createContext, useContext } from "react";
import { getChildByType } from "react-nanny";

import { PdsIconAlert, PdsIconCheck, PdsIconClose, PdsIconCloseCircle, PdsIconInfo } from "../../icons/components";
import pdsColors from "../../../styles/colors/pdsColors";
import { filterClassName } from "../../../utils/filterClassName";

type AlertColor = "blue" | "green" | "gold" | "red";

export type AlertProps = {
    children: React.ReactNode;
    /** The type of the alert. */
    alertType: AlertTypes;
    /** Emitted when the close button is clicked. */
    onRemove?: () => void;
    /** Determined whether a alert can be closable by user. */
    closable?: boolean;
    /** A hook to target component instances when testing. */
    testId?: string;
    /** Users can add spacing/sizing and screen-width specific but cannot modify the internal component desgin(color variant, font sizes, …). */
    className?: string;
};
type AlertContextProps = {
    alertType?: AlertTypes;
};

type AlertActionsProps = {
    children: React.ReactChild | Array<React.ReactChild>;
} & React.HTMLAttributes<HTMLElement>;

type RemoveButtonProps = {
    alertType: AlertTypes;
    onRemove: () => void;
    testId?: string;
};
type StyledAlertProps = {
    alertType: AlertTypes;
};

type AlertBaseProps = {
    alertType: AlertTypes;
    children: React.ReactNode;
};

export const AlertContext = createContext<AlertContextProps>({});

export type AlertTypes = "info" | "success" | "warn" | "error";

const convertAlertTypeToAlertColor = (alertType: AlertTypes): AlertColor => {
    switch (alertType) {
        case "success":
            return "green";
        case "warn":
            return "gold";
        case "error":
            return "red";
        case "info":
        default:
            return "blue";
    }
};

export const getColorStyle = (alertType: AlertTypes) => {
    const color = convertAlertTypeToAlertColor(alertType);

    return css`
        color: ${pdsColors[color][900]};
    `;
};

export const getColorHoverStyle = (alertType: AlertTypes) => {
    const color = convertAlertTypeToAlertColor(alertType);

    return css`
        &:hover {
            color: ${pdsColors[color][600]};
        }
    `;
};

export const getBgColorHoverStyle = (alertType: AlertTypes) => {
    const color = convertAlertTypeToAlertColor(alertType);

    return css`
        &:hover {
            color: ${pdsColors[color][600]};
            background-color: ${pdsColors[color][300]};
        }
    `;
};

const getBackgroundColorStyle = (alertType: AlertTypes) => {
    const color = convertAlertTypeToAlertColor(alertType);

    return css`
        background-color: ${pdsColors[color][100]};
    `;
};

const StyledBackground = styled.div<AlertProps>`
    ${tw`rounded p-4`};
    ${(props) => getBackgroundColorStyle(props.alertType)};
`;

const StyledRemoveButton = styled.button<StyledAlertProps>`
    ${tw`rounded-full`};
    ${(props) => getColorStyle(props.alertType)};
    ${(props) => getBgColorHoverStyle(props.alertType)};
`;
export const RemoveButton = ({ onRemove, alertType, testId }: RemoveButtonProps) => {
    return (
        <StyledRemoveButton
            type="button"
            alertType={alertType}
            onClick={() => onRemove()}
            data-testid={testId && `${testId}-remove-alert`}
        >
            <PdsIconClose size="xsmall" />
        </StyledRemoveButton>
    );
};

const getIconByType = (alertType: AlertTypes) => {
    switch (alertType) {
        case "success":
            return <PdsIconCheck color="green-900" size="small" />;
        case "warn":
            return <PdsIconAlert color="gold-800" size="small" />;
        case "error":
            return <PdsIconCloseCircle color="red-900" size="small" />;
        case "info":
        default:
            return <PdsIconInfo color="blue-900" size="small" />;
    }
};
const StyledAlertBase = styled.div`
    ${tw`flex items-center`};
`;
const StyledAlertBaseChildren = styled.div`
    ${tw`ml-3 mb-0.5`};
`;
export const PdsAlertBase = ({ alertType, children }: AlertBaseProps): JSX.Element => {
    return (
        <StyledAlertBase>
            {getIconByType(alertType)}
            <StyledAlertBaseChildren>{children}</StyledAlertBaseChildren>
        </StyledAlertBase>
    );
};

const StyledHeader = styled.div`
    ${tw`flex justify-between items-center`};
`;
const StyledContent = styled.div`
    ${tw`ml-8`};
`;

export const PdsAlert = ({
    children,
    alertType = "info",
    closable = false,
    className,
    testId,
    ...props
}: AlertProps): JSX.Element => {
    const getPdsAlertTitle = getChildByType(children, PdsAlert.Title);
    const getPdsAlertDetail = getChildByType(children, PdsAlert.Detail);
    const getPdsAlertActions = getChildByType(children, PdsAlert.Actions);
    const handleRemove = () => {
        return props.onRemove?.();
    };
    return (
        <AlertContext.Provider value={{ alertType }}>
            <StyledBackground
                alertType={alertType}
                data-testid={testId}
                className={className && filterClassName(className, ["bg"])}
            >
                <StyledHeader>
                    <PdsAlertBase alertType={alertType}>{getPdsAlertTitle}</PdsAlertBase>
                    {closable && <RemoveButton alertType={alertType} onRemove={handleRemove} testId={testId} />}
                </StyledHeader>
                <StyledContent>
                    {getPdsAlertDetail}
                    {getPdsAlertActions}
                </StyledContent>
            </StyledBackground>
        </AlertContext.Provider>
    );
};

export const StyledTitle = styled.h3<StyledAlertProps>`
    ${tw`text-sm font-medium`};
    ${(props) => getColorStyle(props.alertType)};
`;

const PdsAlertTitle = ({ children, ...props }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
    const { alertType = "info" } = useContext(AlertContext);
    return (
        <StyledTitle alertType={alertType} {...props}>
            {children}
        </StyledTitle>
    );
};

const StyledDetail = styled.p<StyledAlertProps>`
    ${tw`text-sm font-normal mt-2`};
    ${(props) => getColorStyle(props.alertType)};
`;

const PdsAlertDetail = ({ children, ...props }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
    const { alertType = "info" } = useContext(AlertContext);
    return (
        <StyledDetail alertType={alertType} {...props}>
            {children}
        </StyledDetail>
    );
};

const StyledContainerAlertActions = styled.div`
    ${tw`mt-4`};
`;
const StyledAlertActions = styled.div`
    ${tw`flex`};
`;
const StyledButtonActions = styled.div`
    ${tw`mr-2`};
`;

const PdsAlertActions = ({ children, ...props }: AlertActionsProps): JSX.Element => {
    let items: Array<React.ReactChild> = [];
    if (Array.isArray(children)) {
        items = children as Array<React.ReactChild>;
    } else {
        items.push(children);
    }
    return (
        <StyledContainerAlertActions {...props}>
            <StyledAlertActions>
                {items.map((item: React.ReactChild, index) => (
                    <StyledButtonActions key={index?.toString()}>{item}</StyledButtonActions>
                ))}
            </StyledAlertActions>
        </StyledContainerAlertActions>
    );
};

PdsAlert.Title = PdsAlertTitle;
PdsAlert.Detail = PdsAlertDetail;
PdsAlert.Actions = PdsAlertActions;

import { createContext, useContext } from "react";

export type PdsDropdownContextProps = {
    disableAutofocus: boolean;
    handleItemClick: () => void;
};

export const PdsDropdownContext = createContext<PdsDropdownContextProps>({
    disableAutofocus: false,
    handleItemClick: () => {},
});

export const usePdsDropdownContext = () => useContext(PdsDropdownContext);

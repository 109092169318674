import type { ChangeEvent } from "react";
import type { PdsChoiceGroupItemProps } from "./type";

import { nanoid } from "nanoid";
import { useMemo, forwardRef } from "react";

import { ex, tx } from "@/libs";
import { useSafeLayoutEffect } from "@/hooks";
import { PdsRadio } from "@/components/radio";
import { PdsLabel, PdsHelpText } from "@/components/label";

import { usePdsChoiceGroupContext } from "./context";

export const PdsChoiceGroupRadio = forwardRef<HTMLInputElement, PdsChoiceGroupItemProps>(
    (
        {
            id,
            value,
            label,
            helpText,
            checked: checkedProps = false,
            disabled: disabledProps = false,
            error: errorProps = false,
            defaultChecked = false,
            className,
            onChange,
            ...props
        }: PdsChoiceGroupItemProps,
        ref
    ) => {
        const [selected, { select }, { name, disabled: disabledContext, error: errorContext }] =
            usePdsChoiceGroupContext();

        /**
         * Radio's local memo hooks.
         */
        const radioId = useMemo(() => id ?? `pds-choice-radio-${nanoid()}`, [id]);
        const helpTextId = useMemo(() => id ?? `pds-choice-radio-help-text-${nanoid()}`, [id]);
        const disabled = useMemo(() => disabledContext || disabledProps, [disabledContext, disabledProps]);
        const error = useMemo(() => errorContext || errorProps, [errorContext, errorProps]);
        const checked = useMemo(() => checkedProps || selected.includes(value), [selected, checkedProps, value]);

        /** Set the initial state of the radio if specified. */
        useSafeLayoutEffect(() => {
            if (checked || defaultChecked) {
                select(value);
            }
        }, []);

        /** Set the current value once a radio is interacted. */
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => select(e.target.value);

        return (
            <div className={tx("flex items-start", className)}>
                <PdsRadio
                    ref={ref}
                    value={value}
                    error={error}
                    defaultChecked={defaultChecked}
                    id={radioId}
                    name={name}
                    checked={checked}
                    disabled={disabled}
                    aria-describedby={helpTextId}
                    onChange={ex(handleChange, onChange)}
                    {...props}
                />
                <div className="flex flex-col ms-3">
                    <PdsLabel htmlFor={radioId} disabled={disabled || disabledProps} className="font-normal">
                        {label}
                    </PdsLabel>
                    {helpText && (
                        <PdsHelpText id={helpTextId} disabled={disabled || disabledProps}>
                            {helpText}
                        </PdsHelpText>
                    )}
                </div>
            </div>
        );
    }
);

import React, { useCallback, useEffect } from "react";
import { doValidate, Form, useFormControl, Validator } from "@components/common/form";
import { PdsChoiceGroup, PdsChoiceGroupProps } from "@educationperfect/web-pds-react";
import type { Orientation } from "@react-types/shared";
import Errors from "@components/common/form/errors";

type Props = {
    name: string;
    label: string;
    form: Form;
    value: string[];
    validators?: Validator[];
    orientation?: Orientation | undefined;
    showFirstError?: boolean;
    onChange?: (value: string[]) => void;
} & Omit<PdsChoiceGroupProps, "onChange" | "value" | "orientation" | "name" | "label" | "form" | "type" | "aria-label">;

const ChoiceCardGroup = ({
    name,
    label,
    value,
    form,
    validators,
    orientation,
    required,
    onChange,
    children,
    showFirstError = true,
    ...props
}: Props) => {
    const { id, errors } = useFormControl(form, value, validators);

    const handleChange = useCallback(
        (v: string[]) => {
            if (onChange) {
                onChange(v);
            }
        },
        [onChange]
    );

    useEffect(() => {
        form.register({ id, validate: () => doValidate(value, validators).length === 0 });

        /**
         * Controls must be unregistered on unmount
         * to avoid cases of incorrect form validation
         */
        return () => form.unregister(id);
    }, [form, validators, value, id]);

    return (
        <div className="flex flex-col relative">
            <PdsChoiceGroup
                label={label}
                name={name}
                value={value}
                type="radioCard"
                onChange={handleChange}
                className="gap-0 sm:gap-6"
                required
                {...props}
            >
                {children}
            </PdsChoiceGroup>
            <Errors errors={errors} showFirst={showFirstError} cls="mt-[2px]" />
        </div>
    );
};

ChoiceCardGroup.RadioCard = PdsChoiceGroup.RadioCard;

export default ChoiceCardGroup;

import { includes, isEmpty, isUndefined, takeRight, trimStart } from "lodash";
import store from "@services/store";
import Cookies from "js-cookie";

export const getQuery = () => {
    const search = window.location.search;
    if (!isEmpty(search)) {
        return trimStart(search, "?");
    }
    return null;
};

export const storeParams = () => {
    let params = store.get("forhome:params") as string[];
    if (!params) {
        params = [];
    }

    const query = getQuery();
    if (query && !includes(params, query)) {
        params.push(query);
    }
    store.set("forhome:params", takeRight(params, 10));
};

export const storePartnerizeRef = () => {
    const query = window.location.search;
    const params = new URLSearchParams(query);

    let clickRef = params.get("clickref");
    if (isEmpty(clickRef)) {
        const cookie = Cookies.get("_pz_clickref");
        if (!isUndefined(cookie)) {
            clickRef = cookie;
        }
    }

    if (!isEmpty(clickRef)) {
        store.set("forhome:affiliateref", { clickref: clickRef });
        store.set("forhome:affiliatename", "partnerize");
    }
};

export const storeReferralRockRef = () => {
    const query = window.location.search;
    const params = new URLSearchParams(query);

    let wcid = params.get("RR_WCID");
    if (isEmpty(wcid)) {
        const wcidCookie = Cookies.get("RR_WCID");
        if (!isUndefined(wcidCookie)) {
            wcid = wcidCookie;
        }
    }

    if (!isEmpty(wcid)) {
        store.set("forhome:affiliateref", { rrWcid: wcid });
        store.set("forhome:affiliatename", "referral-rock");
    }
};

import React, { useCallback, useEffect } from "react";
import { PdsIconAlert, PdsInput, PdsInputProps, PdsLabel } from "@educationperfect/web-pds-react";
import { doValidate, Form, useFormControl, Validator } from "@components/common/form";
import Errors from "@components/common/form/errors";

type Props = {
    value: string | number;
    form: Form;
    label?: string;
    required?: boolean;
    description?: string;
    validators?: Validator[];
    showFirstError?: boolean;
    onChange?: (value: string) => void;
} & Omit<PdsInputProps, "errorList" | "validationState" | "onChange" | "onBlur" | "value" | "form">;

const Input = ({
    value,
    form,
    label,
    description,
    validators,
    onChange,
    required,
    showFirstError = true,
    ...props
}: Props) => {
    const { id, errors, state, handleBlur } = useFormControl(form, value, validators);

    const handleChange = useCallback(
        (v: string) => {
            if (onChange) {
                onChange(v);
            }
        },
        [onChange]
    );

    useEffect(() => {
        form.register({ id, validate: () => doValidate(value, validators).length === 0 });

        /**
         * Controls must be unregistered on unmount
         * to avoid cases of incorrect form validation
         */
        return () => form.unregister(id);
    }, [form, value, validators, id]);

    return (
        <>
            {label && (
                <PdsLabel className="mb-1" required>
                    {label}
                </PdsLabel>
            )}
            <PdsInput
                value={value}
                error={state === "invalid"}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                {...props}
            >
                {errors.length > 0 && (
                    <PdsInput.Trailing>
                        <PdsIconAlert size="xsmall" color="red-600" />
                    </PdsInput.Trailing>
                )}
            </PdsInput>
            {description && <PdsLabel className="opacity-60">{description}</PdsLabel>}
            <Errors errors={errors} showFirst={showFirstError} cls="mt-[2px]" />
        </>
    );
};

export default Input;

import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HubspotTag = ({ children }: { children: ReactNode }): JSX.Element => {
    const { pathname } = useLocation();
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!loaded) {
            switch (pathname) {
                case "/for-parents/start":
                case "/for-parents/validate":
                case "/for-parents/register":
                case "/for-parents/learners":
                    loadTag();
                    setLoaded(true);
            }
        }
    }, [loaded, pathname]);

    return <>{children}</>;
};

const loadTag = () => {
    const el = document.getElementById("hs-script-loader");
    if (!el) {
        const script = document.createElement("script");
        script.src = "//js.hs-scripts.com/8086137.js";
        script.defer = true;
        script.async = true;
        script.id = "hs-script-loader";
        document.body.append(script);
    }
};

export { HubspotTag };

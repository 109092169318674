import React, { useCallback, useState } from "react";
import { PdsIconEp, PdsSelectOption } from "@educationperfect/web-pds-react";
import FormPayer from "@pages/signup/step-payer/payer-form";
import { Payer, usePayerContext } from "@common/context/payer";
import AlertContactSupport from "@components/alerts/contact-support";
import Stepper from "@components/common/header/stepper";
import { steps } from "@pages/signup/constants";
import ButtonLink from "@components/common/button-link";
import TrialPeriod from "@components/common/trial-period";
import { getDialCode } from "@helpers/dialCodes";
import { useForm } from "@components/common/form";
import { submitPayer, subscriptionExists } from "@api/signup";
import { useHistory } from "react-router-dom";

const SignupPayer = () => {
    const form = useForm();
    const history = useHistory();
    const { payer, setPayer, error, setError, updateStates, storePayer, loginRoute, isReady } = usePayerContext();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCountryChange = useCallback(
        (values: PdsSelectOption[]) => {
            setPayer((prevPayer) => {
                const code = values.length > 0 ? getDialCode(values[0]?.value) : undefined;
                return {
                    ...prevPayer,
                    country: values,
                    dialCode: code,
                    state: undefined,
                };
            });
            if (values.length > 0) {
                updateStates(values[0].value);
            }
        },
        [setPayer, updateStates]
    );

    const handleTypeChange = useCallback(
        (value: string[]) => {
            setPayer((prevPayer) => {
                return {
                    ...prevPayer,
                    type: value.length > 0 ? value[0] : undefined,
                    intent: undefined,
                };
            });
        },
        [setPayer]
    );

    const handleIntentChange = useCallback(
        (value: string[]) => {
            setPayer((prevPayer) => {
                return {
                    ...prevPayer,
                    intent: value.length > 0 ? value[0] : undefined,
                };
            });
        },
        [setPayer]
    );

    const handlePhoneChange = useCallback(
        (value: string) => {
            setPayer((prevPayer) => {
                return {
                    ...prevPayer,
                    phoneNumber: value.replace(/[^0-9]+/g, ""),
                };
            });
        },
        [setPayer]
    );

    const handleChange = useCallback(
        (field: string) => (value: string | PdsSelectOption[]) => {
            setPayer((prevPayer: Payer) => {
                return {
                    ...prevPayer,
                    [field]: value,
                };
            });
        },
        [setPayer]
    );

    const onSubmit = useCallback(
        async (e) => {
            e.preventDefault();

            if (!form.isValid()) {
                return;
            }

            setIsSubmitting(true);

            try {
                const exists = await subscriptionExists(payer.email);
                if (exists) {
                    setError(true);
                } else {
                    const payload = {
                        email: payer.email,
                        firstName: payer.firstName || "",
                        lastName: payer.lastName || "",
                        countryCode: payer.country && payer.country.length > 0 ? payer.country[0].value : "",
                        state: payer.state && payer.state.length > 0 ? payer.state[0].value : undefined,
                        type: payer.type,
                        intent: payer.type === "parent" && payer.intent ? payer.intent : undefined,
                    };

                    await submitPayer(payload);

                    storePayer();
                    history.push("/for-parents/learners");
                }
            } catch {
                setError(true);
            } finally {
                setIsSubmitting(false);
            }
        },
        [
            form,
            payer.email,
            payer.firstName,
            payer.lastName,
            payer.country,
            payer.state,
            payer.type,
            payer.intent,
            setError,
            storePayer,
            history,
        ]
    );

    return (
        <div className="w-full min-h-screen relative">
            <header className="flex flex-row align-middle justify-center">
                <PdsIconEp size="xlarge" className="absolute top-4 left-3" />
                <div className="mt-6">
                    <Stepper steps={steps} />
                </div>
                <ButtonLink link={loginRoute} className="absolute top-4 right-4" title="Sign in" />
            </header>

            <main className="w-full px-4 md:mx-auto sm:max-w-[600px] lg:max-w-[880px] sm:ml-[106px] sm:mr-10 pt-12 mt-4 sm:mt8">
                {error && <AlertContactSupport />}
                <h1 className="text-lg leading-7 font-bold text-neutral-900 sm:text-2xl sm:leading-8">
                    Provide parent details
                </h1>

                <TrialPeriod className="text-xs leading-7 text-neutral-600 sm:font-regular sm:leading-" />

                <div className="w-full flex sm:justify-between">
                    {isReady && (
                        <FormPayer
                            form={form}
                            onCountryChange={handleCountryChange}
                            onPhoneChange={handlePhoneChange}
                            onTypeChange={handleTypeChange}
                            onIntentChange={handleIntentChange}
                            onChange={handleChange}
                            onSubmit={onSubmit}
                            isSubmitting={isSubmitting}
                        />
                    )}
                </div>
            </main>
        </div>
    );
};

export default SignupPayer;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconArrowDown = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.3 13.7l5 5c.2.2.4.3.7.3.3 0 .5-.1.7-.3l5-5c.2-.2.3-.4.3-.7 0-.6-.5-1-1-1-.3 0-.5.1-.7.3L13 15.6V6c0-.6-.4-1-1-1s-1 .4-1 1v9.6l-3.3-3.3A.9.9 0 0 0 7 12c-.6 0-1 .4-1 1 0 .3.1.5.3.7z" />
            </svg>
        );
    }
);

import type { Placement } from "@popperjs/core";
import type { TooltipTriggerState } from "@react-stately/tooltip";
import type { AriaTooltipProps, TooltipTriggerProps } from "@react-types/tooltip";
import type { ReactElement, SetStateAction } from "react";

import { mergeProps } from "@react-aria/utils";
import { useTooltipTriggerState } from "@react-stately/tooltip";
import { useTooltip, useTooltipTrigger } from "react-aria";
import React, { cloneElement, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import tw from "twin.macro";

export type TooltipPlacement = "top" | "bottom" | "left" | "right";
type TooltipMode = "dark" | "light";

// The component's public interface.
export type ToolTipProps = {
    /** A hook to target component instances when testing. */
    testId?: string;
    /** Trigger element of tooltip. */
    children?: React.ReactNode;
    /** The message to be displayed in the tooltip. */
    content?: React.ReactText;
    /** The tooltips's position in relation to the triggering element. */
    placement?: TooltipPlacement;
} & TooltipTriggerProps &
    React.HTMLAttributes<HTMLElement>;

type TooltipContentProps = {
    state: TooltipTriggerState;
    children?: React.ReactNode;
    popperRef?: (instance: HTMLDivElement | null) => void | React.RefObject<HTMLSpanElement>;
    arrowRef?: (instance: HTMLDivElement | null) => void | React.RefObject<HTMLDivElement>;
    styles?: { [key: string]: React.CSSProperties };
    attributes: { [key: string]: { [key: string]: string } | undefined };
    mode: TooltipMode;
    testId?: string;
} & AriaTooltipProps;

type StyledTooltipContentProps = {
    mode: TooltipMode;
} & React.HTMLAttributes<HTMLDivElement>;

const StyledArrow = styled.div<StyledTooltipContentProps>`
    ${tw`absolute w-2.5 h-2.5 bg-transparent before:(absolute w-2.5 h-2.5 bg-neutral-1000 visible content[""] rotate-45 )`};
`;

const StyledTooltip = styled.div<StyledTooltipContentProps>`
    ${tw`absolute bg-neutral-1000 text-white px-3 break-words shadow-md z-[2040]
    py-1.5 rounded-xs max-w-[240px] text-center font-medium text-sm leading-5`};
    &[data-popper-placement^="top"] > ${StyledArrow} {
        ${tw`bottom-[-5px]`};
        left: -4px !important;
    }
    &[data-popper-placement^="bottom"] > ${StyledArrow} {
        ${tw`top-[-5px]`};
        left: -4px !important;
    }
    &[data-popper-placement^="right"] > ${StyledArrow} {
        ${tw`-left-2.5`};
    }
    &[data-popper-placement^="left"] > ${StyledArrow} {
        ${tw`right-0`};
    }
`;
export function TooltipContent({
    state,
    arrowRef,
    popperRef,
    attributes,
    styles,
    children,
    mode = "dark",
    testId,
    ...props
}: TooltipContentProps) {
    const { tooltipProps } = useTooltip(props, state);
    return (
        <StyledTooltip
            {...mergeProps(props, tooltipProps)}
            style={styles?.popper}
            ref={popperRef}
            {...attributes.popper}
            mode={mode}
            data-testid={testId}
        >
            {children}
            <StyledArrow ref={arrowRef} style={styles?.arrow} mode={mode} />
        </StyledTooltip>
    );
}

export function PdsToolTip({ testId, children, content, placement = "bottom", ...props }: ToolTipProps) {
    const mode = "dark";
    const state = useTooltipTriggerState({ delay: 200, ...props });
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);
    const setRefPopperElement = (instance: HTMLDivElement | null) => {
        setPopperElement(instance as SetStateAction<null>);
    };
    const setRefArrowElement = (instance: HTMLDivElement | null) => {
        setArrowElement(instance as SetStateAction<null>);
    };
    const setRefReferenceElement = (instance: HTMLDivElement | null) => {
        setReferenceElement(instance as SetStateAction<null>);
        ref.current = instance;
    };
    const clonedAnchor = cloneElement(children as ReactElement, {
        ref: setRefReferenceElement,
        onTouchStart: () => state.open(),
        onBlur: () => state.close(),
        ...triggerProps,
        style: { position: "relative" },
    });

    const fallBackPlacements = (tooltipPlacement: TooltipPlacement) => {
        switch (tooltipPlacement) {
            case "top":
                return ["bottom", "left", "right"] as Placement[];
            case "bottom":
                return ["top", "left", "right"] as Placement[];
            case "left":
                return ["right", "top", "bottom"] as Placement[];
            case "right":
                return ["left", "top", "bottom"] as Placement[];
        }
    };

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: "flip",
                options: {
                    fallbackPlacements: fallBackPlacements(placement),
                },
            },
            {
                name: "offset",
                enabled: true,
                options: {
                    offset: [0, 10],
                },
            },
            {
                name: "preventOverflow",
                options: {
                    padding: 8,
                },
            },
            { name: "arrow", options: { element: arrowElement } },
        ],
    });
    return (
        <>
            {clonedAnchor}
            {state.isOpen && (
                <TooltipContent
                    arrowRef={setRefArrowElement}
                    attributes={attributes}
                    styles={styles}
                    state={state}
                    {...tooltipProps}
                    popperRef={setRefPopperElement}
                    mode={mode}
                    testId={testId}
                >
                    {content}
                </TooltipContent>
            )}
        </>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from "react";

export type UseBooleanReturns = [
    boolean,
    {
        open: () => void;
        close: () => void;
        toggle: () => void;
        setValue: (value: boolean) => void;
    }
];

export type UseBooleanProps = {
    initialValue?: boolean;
    isDisabled?: boolean;
};

/**
 * A hook that provides a boolean state & basic callbacks to control the state.
 * @param initialValue the initial value of the boolean state
 */
export const useBoolean = (props?: UseBooleanProps): UseBooleanReturns => {
    const [value, setValue] = useState(props?.initialValue || false);

    const open = useCallback(() => {
        if (!props?.isDisabled) {
            setValue(true);
        }
    }, [props?.isDisabled]);

    const close = useCallback(() => {
        if (!props?.isDisabled) {
            setValue(false);
        }
    }, [props?.isDisabled]);

    const toggle = useCallback(() => {
        if (!props?.isDisabled) {
            setValue((prev) => !prev);
        }
    }, [props?.isDisabled]);

    useEffect(() => {
        if (props?.initialValue !== value) {
            setValue(props?.initialValue || false);
        }
    }, [props?.initialValue]);

    return [value, { open, close, toggle, setValue }];
};

const FOCUSABLE_SELECTOR =
    "input:not([type='hidden']):not([disabled]), select:not([disabled]), " +
    "textarea:not([disabled]), a[href], button:not([disabled]), [tabindex], " +
    "iframe, object, embed, area[href], audio[controls], video[controls], " +
    "[contenteditable]:not([contenteditable='false'])";

/**
 * Ponyfill for `Element.prototype.matches`
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
 */
export const matches = (element: Element, selectors: string): boolean => {
    if ("matches" in element) {
        return element.matches(selectors);
    }
    if ("msMatchesSelector" in element) {
        return (element as any).msMatchesSelector(selectors);
    }
    return (element as any).webkitMatchesSelector(selectors);
};

/**
 * Utility for checking if an element is focusable.
 * @param element the HTML element to check
 * @returns boolean
 */
export const isFocusable = (element: Element): boolean => {
    return matches(element, FOCUSABLE_SELECTOR);
};

/**
 * Returns all the focusable elements within an HTML container.
 * @param container the HTML element to dive into
 * @returns Element[]
 */
export const getAllFocusableIn = <T extends Element>(container: T): T[] => {
    const allFocusable = Array.from(container.querySelectorAll<T>(FOCUSABLE_SELECTOR));

    return allFocusable.filter(isFocusable);
};

/**
 * Returns the first the focusable elements within an HTML container.
 * @param container the HTML element to dive into
 * @returns Element
 */
export const getFirstFocusableIn = <T extends Element>(container: T): T | null => {
    const [first] = getAllFocusableIn(container);
    return first || null;
};

/**
 * Utility to update all element's children to be focusable/non-focusable.
 * @param el the HTML element to dive into
 * @param actionable a boolean flag to make all children focusable or not.
 */
export const updateChildrenTabIndex = (el: HTMLElement | null, actionable?: boolean): void => {
    if (!el) return;

    getAllFocusableIn(el).forEach((element: HTMLElement) => {
        // eslint-disable-next-line no-param-reassign
        element.tabIndex = actionable ? 0 : -1;
    });
};

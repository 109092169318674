import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconCaretDown = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M16 10.5a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.37.83l3.5 4c.09.1.22.17.37.17a.5.5 0 0 0 .37-.17l3.5-4c.08-.09.13-.2.13-.33z" />
            </svg>
        );
    }
);

import type { PdsSearchInputProps } from "./type";
import type { ChangeEvent } from "react";

import { nanoid } from "nanoid";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";

import { ex, tx } from "@/libs";
import { useMountTransition } from "@/hooks";
import { PdsButton } from "@/components/button";
import { PdsIconClose, PdsIconSearch } from "@/components/icons";

import { PdsInput } from "./Input";

export const PdsSearchInput = forwardRef<HTMLInputElement, PdsSearchInputProps>(
    ({ id, value, clearButtonAriaLabel = "Clear search", className, onChange, ...props }, ref) => {
        const searchInputId = useMemo(() => id ?? `pds-search-input-${nanoid()}`, [id]);

        /** Set up keyword value tracker. */
        const [keyword, setKeyword] = useState(value);

        /**
         * Side effect to make sure that any form control subscribe to ref & value/onChange
         * should be able to interact with the search input.
         */
        useEffect(() => setKeyword((value ?? "").toString()), [value]);

        /** Add transition state for un/mouting. */
        const hasKeyword = !!keyword?.toString()?.length;
        const { hasTransitionedIn } = useMountTransition(!!hasKeyword);

        /** Change handler to update the value. */
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value);

        /** Callback to clear the search input on click. */
        const handleClearSearch = useCallback(() => setKeyword(""), []);

        return (
            <PdsInput
                type="search"
                ref={ref}
                value={keyword}
                id={searchInputId}
                onChange={ex(handleChange, onChange)}
                className={tx("no-search border-neutral-300 bg-neutral-100", className)}
                {...props}
            >
                <PdsInput.Leading as={PdsIconSearch} />
                <PdsInput.Trailing
                    as="span"
                    className={tx("min-w-[26px] opacity-0 transition-opacity duration-200", {
                        "opacity-100": hasKeyword && hasTransitionedIn,
                    })}
                >
                    {(hasKeyword || hasTransitionedIn) && (
                        <PdsButton
                            size="xsmall"
                            variant="tertiary"
                            aria-label={clearButtonAriaLabel}
                            onClick={handleClearSearch}
                        >
                            <PdsButton.Icon as={PdsIconClose} size="xsmall" />
                        </PdsButton>
                    )}
                </PdsInput.Trailing>
            </PdsInput>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconOffice = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19 9h-3V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3v-4h4v4h7a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1zM9 14H6v-3h3v3zm0-5H6V6h3v3zm5 5h-3v-3h3v3zm0-5h-3V6h3v3zm4 9h-2v-2h2v2zm0-4h-2v-3h2v3z" />
            </svg>
        );
    }
);

export const filterClassName = (className: string, keys: Array<string>): string => {
    let classNames = className;
    keys.forEach((key) => {
        if (className?.includes(key.trim())) {
            classNames = classNames
                ?.trim()
                .split(" ")
                .filter((e) => e !== "" && !e.includes(key.trim()))
                .toString()
                .replaceAll(",", " ");
        }
    });
    return classNames;
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconFolderOpen = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.06 10.69A1 1 0 0 1 7 10h11V9a1 1 0 0 0-1-1h-6.59l-1.7-1.71A1 1 0 0 0 8 6H5a1 1 0 0 0-1 1v9.84l2.05-6.15h.01zM20 12a1 1 0 0 0-1-1H8a1 1 0 0 0-.94.69l-2 6A.78.78 0 0 0 5 18a1 1 0 0 0 1 1h11a1 1 0 0 0 .94-.69l2-6c.04-.09.06-.2.06-.31z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconActivitySpellingList = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path
                    d="M5.25 3H16.5L21 7.5v11.25c0 1.24-1 2.25-2.25 2.25H5.25C4.01 21 3 20 3 18.75V5.25C3 4.01 4 3 5.25 3z"
                    fill="#F35937"
                />
                <path
                    d="M9.17 15c.43 0 .74-.3.74-.71 0-.16-.02-.3-.1-.48L8.38 9.87C8.15 9.27 7.8 9 7.2 9c-.58 0-.94.27-1.15.87L4.6 13.8c-.06.17-.1.35-.1.48 0 .44.29.7.75.7.4 0 .62-.2.76-.69l.2-.62H8.2l.2.65c.12.47.35.66.77.66zm-1.3-2.43H6.5l.66-2.24h.03l.69 2.24zM11.24 15c.42 0 .72-.24.72-.73v-.12h.02c.23.51.71.82 1.35.82 1.1 0 1.74-.83 1.74-2.26 0-1.43-.64-2.26-1.75-2.26-.63 0-1.1.32-1.33.85h-.03V9.74c0-.5-.3-.74-.7-.74-.43 0-.73.24-.73.74v4.53c0 .49.3.73.71.73zm1.55-1.15c-.5 0-.83-.43-.83-1.14 0-.69.33-1.14.83-1.14.5 0 .82.45.82 1.14 0 .7-.32 1.14-.82 1.14zm4.9 1.16c.86 0 1.64-.4 1.93-1a.8.8 0 00.1-.37c0-.35-.28-.56-.6-.56-.3 0-.46.13-.64.4-.17.25-.39.45-.75.45-.53 0-.82-.43-.82-1.22 0-.78.3-1.2.83-1.2.32 0 .55.15.75.5.14.24.31.35.59.35.32 0 .61-.2.61-.54a.9.9 0 00-.09-.37c-.27-.63-1.01-1.04-1.92-1.04-1.36 0-2.22.88-2.22 2.3 0 1.43.86 2.3 2.23 2.3z"
                    fill="#ffffff"
                />
            </svg>
        );
    }
);

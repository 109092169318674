import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconSchool = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12.64 2.23l5 4.17a1 1 0 0 1 .36.77v2.91a1 1 0 0 0 .55.9l2.9 1.44c.3.15.5.44.54.77l.01.13v6.38a2 2 0 0 1-1.85 2H14v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v4H4a2 2 0 0 1-2-2v-6.38a1 1 0 0 1 .55-.9l2.9-1.45a1 1 0 0 0 .55-.89V7.17a1 1 0 0 1 .36-.77l5-4.17a1 1 0 0 1 1.28 0zM12 6.7a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
            </svg>
        );
    }
);

import type { PdsInputProps } from "./type";

import { forwardRef } from "react";

import { PdsIconVisibility, PdsIconVisibilityOff } from "@/components/icons";
import { useBoolean } from "@/hooks";
import { PdsButton } from "@/components/button";

import { PdsInput } from "./Input";

export const PdsPasswordInput = forwardRef<HTMLInputElement, PdsInputProps>(({ children, ...props }, ref) => {
    const [isPasswordVisible, { toggle }] = useBoolean();

    return (
        <PdsInput ref={ref} type={isPasswordVisible ? "text" : "password"} {...props}>
            <PdsInput.Trailing as={PdsButton} variant="tertiary" size="xsmall" onClick={toggle}>
                <PdsButton.Icon as={isPasswordVisible ? PdsIconVisibilityOff : PdsIconVisibility} />
            </PdsInput.Trailing>
            {children}
        </PdsInput>
    );
});

import type { PdsThemeContainerProps, PdsThemeKeys } from "./types";

import { forwardRef, useMemo } from "react";

import { tx } from "@/libs";

import { PdsThemeContainerContext } from "./context";

/**
 * There are some base styles (font, line-height, tab space)
 * that we want to add to the container.
 *
 * @see `tailwind.css` for more details.
 */
const BASE_THEME: string = "pds-theme-base";

/**
 * Extract the theme class from the specified theme key.
 *
 * @see `tailwind.css` for more details.
 */
const getTheme = (theme: PdsThemeKeys) => {
    const themes: Record<PdsThemeKeys, string> = {
        one: "pds-theme-one",
        two: "pds-theme-two",
        three: "pds-theme-three",
    };

    return themes[theme] ?? themes.one;
};

/**
 * A themable container that scopes tailwind classes to a specific
 * mfe application.
 *
 * It provides a `usePdsThemeContainer` hook that can be used
 * to extract both `mfeKey` and `theme` properties.
 */
export const PdsThemeContainer = forwardRef<HTMLDivElement, PdsThemeContainerProps>(
    ({ mfeKey, theme = "one", className, children, ...props }: PdsThemeContainerProps, ref) => {
        const themeClass: string = getTheme(theme);
        const cachedMfeKey = useMemo(() => ({ mfeKey, theme }), [mfeKey, theme]);

        return (
            <PdsThemeContainerContext.Provider value={cachedMfeKey}>
                <div className={tx(mfeKey, BASE_THEME, themeClass, className)} ref={ref} {...props}>
                    {children}
                </div>
            </PdsThemeContainerContext.Provider>
        );
    }
);

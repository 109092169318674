import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconHelpOutline = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm-1-4h2v2h-2v-2zm1.61-9.96a4 4 0 0 0-4.43 2.79.9.9 0 0 0 .87 1.17h.2c.41 0 .74-.29.88-.67a2 2 0 0 1 2.3-1.28c.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88l-.01.02-.03.05c-.09.15-.18.32-.25.5l-.04.08-.02.07c-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07l.05-.09c.08-.14.18-.27.28-.39l.03-.04c.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56a4.08 4.08 0 0 0-3.35-3.47z" />
            </svg>
        );
    }
);

import type { TableOptions } from "@tanstack/react-table";

import { useReactTable } from "@tanstack/react-table";

export const usePdsTable = <T>(options: TableOptions<T>) => {
    const table = useReactTable(options);

    return table;
};

export * from "@tanstack/react-table";

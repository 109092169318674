import type { Ref, ReactElement } from "react";
import type { CompoundPolymorphicElement, PolymorphicRef } from "@/typings/utilities";
import type { PdsButtonProps } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";

import { tx, polymorphComponent, getChildByType, removeChildrenByTypeDeep } from "@/libs";
import { PdsSpinner } from "@/components/spinner";

import { PdsButtonContext } from "./context";
import { PdsButtonIcon, PdsButtonLeading, PdsButtonTrailing } from "./ButtonIcon";
import { getPaddingSize, getSpinnerSize, getTextStyles, getVariantStyles } from "./styles";

export type PdsButtonNamespace = {
    Leading: typeof PdsButtonLeading;
    Trailing: typeof PdsButtonTrailing;
    Icon: typeof PdsButtonIcon;
};

const PdsButtonWrapper = polymorphComponent<Partial<PdsButtonProps>>("button");

/**
 * `PdsButton` provides cues for user actions/events, allowing users to process actions or navigate an experience.
 *
 * The `PdsButton` provides support for:
 *
 * - Leading & Trailing icons
 * - Standalone icon
 * - Loading indicator
 * - Theming
 * - Right-to-left languages
 * - Anchor support
 *
 * Plus any standard `HTMLButtonElement` or `HTMLAnchorElement` attributes.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=3%3A66).
 */
export const PdsButton = forwardRef(
    <E extends "button" | "a" = "button">(
        {
            id,
            as = "button",
            variant = "primary",
            type = "button",
            size = "medium",
            disabled = false,
            loading: loadingProps = false,
            loadingAriaLabel = "Loading",
            className,
            children,
            ...props
        }: PdsButtonProps,
        ref: PolymorphicRef<E> & Ref<HTMLButtonElement>
    ) => {
        const buttonId = useMemo(() => id ?? `pds-button-${nanoid()}`, [id]);

        /* We use react-aria here to fix issues on mobile devices where hover: style will always be applied. */
        const { hoverProps, isHovered } = useHover({ isDisabled: disabled });

        /** Loading state. */
        const loading = useMemo(() => loadingProps && !!loadingAriaLabel, [loadingProps, loadingAriaLabel]);

        /* Extract simple icon component */
        const icon = useMemo(() => getChildByType(children, "PdsButtonIcon") as ReactElement, [children]);
        const iconOnly = useMemo(() => Boolean(icon), [icon]);

        /* Extract leading / trailing icons for manual placement */
        const leadingIcon = useMemo(() => getChildByType(children, "PdsButtonLeading") as ReactElement, [children]);
        const trailingIcon = useMemo(() => getChildByType(children, "PdsButtonTrailing") as ReactElement, [children]);

        /* Remove extracted children so we don't have multiple occurrences */
        const content = useMemo(
            () => removeChildrenByTypeDeep(children, ["PdsButtonLeading", "PdsButtonTrailing", "PdsButtonIcon"]),
            [children]
        );

        /* If an icon was provided (iconOnly) then we don't support any other content, including leading or trailing icons. */
        if (iconOnly && (content.length > 0 || leadingIcon || trailingIcon)) {
            throw new Error(
                "PdsButton.Icon cannot be used with any other content, including PdsButton.Leading & PdsButton.Trailing"
            );
        }

        return (
            <PdsButtonWrapper
                as={as}
                ref={ref}
                id={buttonId}
                disabled={as === "button" ? disabled || loading : undefined}
                type={as === "button" ? type : undefined}
                aria-busy={loading ? true : undefined}
                className={tx(
                    "relative inline-flex items-center justify-center rounded-sm border font-medium outline-none",
                    "focusable transition disabled:cursor-not-allowed",
                    getVariantStyles(variant, isHovered, loading),
                    getPaddingSize(size, iconOnly),
                    getTextStyles(size),
                    className
                )}
                {...mergeProps(hoverProps, props)}
            >
                <PdsButtonContext.Provider value={{ size, loading }}>
                    {loading && (
                        <PdsSpinner
                            size={getSpinnerSize(size)}
                            aria-label={loadingAriaLabel}
                            className="absolute inset-x-0 mx-auto"
                        />
                    )}
                    {/* Render only the `PdsButton.Icon` if it is provided */}
                    {iconOnly && icon}

                    {/* Render everything else if `PdsButton.Icon` is not provided */}
                    {!iconOnly && (
                        <>
                            {leadingIcon}
                            {loading ? <span className="invisible contents">{content}</span> : content}
                            {trailingIcon}
                        </>
                    )}
                </PdsButtonContext.Provider>
            </PdsButtonWrapper>
        );
    }
) as unknown as CompoundPolymorphicElement<PdsButtonNamespace, PdsButtonProps, "button" | "a">;

PdsButton.Leading = PdsButtonLeading;
PdsButton.Trailing = PdsButtonTrailing;
PdsButton.Icon = PdsButtonIcon;

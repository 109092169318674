import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorBold = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12.83 6C13.91 6 16 6.5 16 9c0 1.33-.33 2.17-1 2.5 1.33.33 2 1.33 2 3 0 .5 0 3.5-4 3.5H8a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h4.83zm-.33 7H9v3h3.5c1 0 2-.25 2-1.5s-1.1-1.5-2-1.5zM12 8H9v3.03h3c.5 0 2 0 2-1.53S12 8 12 8z" />
            </svg>
        );
    }
);

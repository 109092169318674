import type { PdsMinimalSizeKeys, PdsSizeKeys } from "@/typings/size";
import type { PdsSemanticVariantKeys } from "@/typings/variant";
import type { PdsIconProps } from "./type";

import { tx } from "@/libs";

const getIconVariantClassnames = (variant: PdsSemanticVariantKeys) => {
    return tx({
        "text-primary": variant === "primary",
        "text-white": variant === "secondary",
        "text-black": variant === "tertiary",
        "text-success": variant === "success",
        "text-info": variant === "info",
        "text-warning": variant === "warning",
        "text-danger": variant === "danger",
        "text-neutral-600": variant === "neutral",
    });
};

export const getIconClassnames = (
    className?: string,
    size: PdsSizeKeys = "medium",
    variant: PdsSemanticVariantKeys = "inherit"
): string => {
    return tx(
        "fill-current",
        {
            "w-4 h-4": size === "xsmall",
            "w-5 h-5": size === "small",
            "w-6 h-6": size === "medium",
            "w-7 h-7": size === "large",
            "w-9 h-9": size === "xlarge",
        },
        getIconVariantClassnames(variant),
        className
    );
};

export const getLogoClassnames = (
    className?: string,
    size: PdsMinimalSizeKeys = "medium",
    variant: PdsSemanticVariantKeys = "inherit"
): string => {
    return tx(
        "fill-current",
        {
            "h-8 w-8": size === "small",
            "h-16 w-16": size === "medium",
            "h-24 w-24": size === "large",
        },
        getIconVariantClassnames(variant),
        className
    );
};

// This is a stub component for generating Storybook documentation.
/**
 * Each icon is exported as a separate component with the icon name prefixed by
 * `PdsIcon*`.
 *
 * - If `PdsIcon` is used in an informative/standalone context, an aria-label must be provided.
 * In provided, its role will default to [role="img"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/img_role#svg_and_roleimg)
 * - If `PdsIcon` is used in a decorative context, no aria-label needs provided.
 * In so, the icon will assistively hide itself via [aria-hidden](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-hidden) attribute
 *
 * `PdsIcon`'s color can be customised with semantic `variant` props or by passing a `className` props.
 *
 * See this guide on decorative/informative icons: [Accessible SVG Icons](https://css-tricks.com/accessible-svg-icons/)
 */
export const PdsIcon = ({ size = "medium", variant = "inherit", ...props }: PdsIconProps) => (
    // @ts-ignore
    <svg size={size} variant={variant} {...props} />
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconLogOut = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 3v2H5v14h7v2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h7zm5.03 5c.27 0 .54.1.74.3l2.93 2.96c.4.41.4 1.07 0 1.48l-2.93 2.95c-.4.41-1.06.41-1.47 0-.4-.4-.4-1.07 0-1.47L17.5 13H7.99a.99.99 0 0 1-.97-.88L7 12a1 1 0 0 1 .98-1h9.53l-1.2-1.22c-.38-.37-.4-.97-.1-1.38l.1-.1c.2-.2.46-.3.72-.3z" />
            </svg>
        );
    }
);

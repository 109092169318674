import {
    getChildByType as getChildByTypeUtils,
    getChildrenByType as getChildrenByTypeUtils,
    getChildByTypeDeep as getChildByTypeDeepUtils,
    getChildrenByTypeDeep as getChildrenByTypeDeepUtils,
    removeChildrenByType as removeChildrenByTypeUtils,
    removeChildrenByTypeDeep as removeChildrenByTypeDeepUtils,
    typeOfComponent as typeOfComponentUtils,
} from "react-nanny";

export * from "react-nanny";

const CUSTOM_TYPE_KEY = "__type";

const defaultConfig = {
    // This is the same key injected by the babel plugin libs/babelType
    customTypeKey: CUSTOM_TYPE_KEY,
};

export const getChildByType = <T = React.ReactNode, TC = unknown>(
    children: T,
    types: TC | TC[],
    config = defaultConfig
) => getChildByTypeUtils(children, types, config);

export const getChildByTypeDeep = <T = React.ReactNode, TC = unknown>(
    children: T,
    types: TC | TC[],
    config = defaultConfig
) => getChildByTypeDeepUtils(children, types, config);

export const getChildrenByType = <T = React.ReactNode, TC = unknown>(
    children: T,
    types: TC | TC[],
    config = defaultConfig
) => getChildrenByTypeUtils(children, types, config);

export const getChildrenByTypeDeep = <T = React.ReactNode, TC = unknown>(
    children: T,
    types: TC | TC[],
    config = defaultConfig
) => getChildrenByTypeDeepUtils(children, types, config);

export const removeChildrenByType = <T = React.ReactNode, TC = unknown>(
    children: T,
    types: TC | TC[],
    config = defaultConfig
) => removeChildrenByTypeUtils(children, types, config);

export const removeChildrenByTypeDeep = <T = React.ReactNode, TC = unknown>(
    children: T,
    types: TC | TC[],
    config = defaultConfig
) => removeChildrenByTypeDeepUtils(children, types, config);

export const typeOfComponent = <T = React.ReactNode>(children: T, config = CUSTOM_TYPE_KEY) =>
    typeOfComponentUtils(children, config);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconComment = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M5 11.51c0-3.03 3.14-5.5 7-5.5s7 2.47 7 5.5c0 3.04-3.14 5.51-7 5.51-3.78 0-6.86-2.36-7-5.3v-.2zm14.86 7.84l-.02-.07-.17-.26c-.38-.61-1.23-2.18-.6-2.85l-.04.02A6.75 6.75 0 0 0 21 11.5C21 7.37 16.96 4 12 4s-9 3.37-9 7.51c0 4.15 4.04 7.52 9 7.52 1.42 0 2.76-.29 3.96-.78a6.07 6.07 0 0 0 3.24 1.72c.05.01.1.03.16.03a.52.52 0 0 0 .5-.65zM11 12a1 1 0 0 1 1 .88V13a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1 1 1 0 0 1 1-1h3zm5-3a1 1 0 0 1 1 .88V10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1 1 1 0 0 1 1-1h8z" />
            </svg>
        );
    }
);

import React, { ReactNode } from "react";
import Sidebar from "@components/sidebar";
import { PdsIconEp } from "@educationperfect/web-pds-react";
import { loginRoute } from "@pages/signup/constants";
import ButtonLink from "@components/common/button-link";
import { advantages } from "@pages/signup/step-email/data";

const Wrapper = ({ children }: { children: ReactNode }) => (
    <div className="flex min-h-screen">
        <Sidebar advantages={advantages} />

        <main className="w-full flex justify-center">
            <PdsIconEp size="xlarge" className="sm:hidden absolute top-4 left-3" />
            <ButtonLink link={loginRoute} className="absolute top-4 right-4" title="Sign in" />

            <div className="h-full w-full flex flex-col pt-4 sm:pt-0 items-center sm:justify-start">
                <div className="w-full px-4 sm:px-0 sm:w-4/5 lg:w-5/7 flex flex-col items-center justify-center pt-12 pb-6">
                    <h1 className="mt-9 lg:mt-[95px] text-lg items-center text-center leading-7 font-bold text-neutral-900 sm:text-2xl sm:leading-8">
                        Create your EP parent account
                    </h1>
                    {children}
                </div>
            </div>
        </main>
    </div>
);

export default Wrapper;

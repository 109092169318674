/* eslint-disable no-console */
import { isUndefined, startsWith, trim } from "lodash";
import { useState } from "react";

export interface Store {
    get(key: string): unknown | null;

    set(key: string, value: unknown): void;

    each(callback: (val: unknown, key: string) => void): void;

    clearAll(): void;

    remove(key: string): void;
}

interface Adapter {
    name: string;
    read: (key: string) => string | null;
    write: (key: string, data: string) => void;
    each: (callback: (val: string, key: string) => unknown) => void;
    remove: (key: string) => void;
}

class LocalStorage implements Adapter {
    name = "local";

    storage: Storage = window.localStorage;

    read = (key: string): string | null => {
        return this.storage.getItem(key);
    };

    write = (key: string, data: string) => {
        return this.storage.setItem(key, data);
    };

    each = (callback: (val: string, key: string) => unknown) => {
        Object.entries(this.storage).forEach((entry) => {
            const [key, val] = entry;
            if (val) {
                callback(key, val);
            }
        });
    };

    remove = (key: string) => {
        return this.storage.removeItem(key);
    };
}

class CookieStorage implements Adapter {
    name = "cookie";

    read = (key: string): string | null => {
        if (!key || !this._has(key)) {
            return null;
        }
        const re = `(?:^|.*;\\s*)${escape(key).replace(/[-.+*]/g, "\\$&")}\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*`;

        return unescape(document.cookie.replace(new RegExp(re), "$1"));
    };

    // eslint-disable-next-line class-methods-use-this
    write = (key: string, data: string) => {
        if (!key) {
            return;
        }

        document.cookie = `${escape(key)}=${escape(data)}; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/`;
    };

    // eslint-disable-next-line class-methods-use-this
    each = (callback: (val: string, key: string) => unknown) => {
        const cookies = document.cookie.split(/; ?/g);
        for (let i = cookies.length - 1; i >= 0; i -= 1) {
            if (!trim(cookies[i])) {
                continue;
            }

            const kvp = cookies[i].split("=");
            const key = unescape(kvp[0]);
            const val = unescape(kvp[1]);

            callback(val, key);
        }
    };

    remove = (key: string) => {
        if (!key || !this._has(key)) {
            return;
        }

        document.cookie = `${escape(key)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    };

    // eslint-disable-next-line class-methods-use-this
    _has = (key: string) => {
        return new RegExp(`(?:^|;\\s*)${escape(key).replace(/[-.+*]/g, "\\$&")}\\s*\\=`).test(document.cookie);
    };
}

class SessionStorage implements Adapter {
    name = "session";

    storage: Storage = window.sessionStorage;

    read = (key: string): string | null => {
        return this.storage.getItem(key);
    };

    write = (key: string, data: string) => {
        return this.storage.setItem(key, data);
    };

    each = (callback: (val: string, key: string) => void) => {
        Object.entries(this.storage).forEach((entry) => {
            const [key, val] = entry;
            if (val) {
                callback(key, val);
            }
        });
    };

    remove = (key: string) => {
        return this.storage.removeItem(key);
    };

    clearAll = () => {
        return this.storage.clear();
    };
}

export class BaseStore {
    adapter: Adapter | null = null;

    namespace = "__ep_store_";

    nsRe!: RegExp;

    adapters: Adapter[] = [new LocalStorage(), new CookieStorage(), new SessionStorage()];

    constructor() {
        Object.entries(this.adapters).forEach((adapter) => {
            const [, val] = adapter;
            if (this._test(val)) {
                this.adapter = val;
            }
        });

        this.nsRe = new RegExp(`^${this.namespace}`);
    }

    get = (key: string): unknown | null => {
        if (this.adapter) {
            const val = this.adapter.read(`${this.namespace}${key}`);
            if (val) {
                return this._deserialize(val);
            }
        }

        return null;
    };

    set = (key: string, value: unknown): void => {
        if (this.adapter) {
            this.adapter.write(`${this.namespace}${key}`, this._serialize(value));
        }
    };

    each = (callback: (val: unknown, key: string) => void): void => {
        if (this.adapter) {
            this.adapter.each((val, key) => {
                if (startsWith(key, this.namespace)) {
                    callback(this._deserialize(val), (key || "").replace(this.nsRe, ""));
                }
            });
        }
    };

    clearAll = (): void => {
        if (this.adapter) {
            this.each((_, key) => {
                this.remove(key);
            });
        }
    };

    remove = (key: string): void => {
        if (this.adapter) {
            this.adapter.remove(`${this.namespace}${key}`);
        }
    };

    // eslint-disable-next-line class-methods-use-this
    _test = (adapter: Adapter): boolean => {
        try {
            const s = "__store__";
            adapter.write(s, s);
            const ok = adapter.read(s) === s;
            adapter.remove(s);
            return ok;
        } catch {
            return false;
        }
    };

    // eslint-disable-next-line class-methods-use-this
    _serialize = (o: unknown): string => {
        return JSON.stringify(o);
    };

    // eslint-disable-next-line class-methods-use-this
    _deserialize = (s: string): unknown | null => {
        if (!s) {
            return null;
        }

        let val: string;
        try {
            val = JSON.parse(s);
        } catch (e) {
            val = s;
        }

        return !isUndefined(val) ? val : null;
    };
}

const store: Store = new BaseStore();

export default store;

export function useStore<T = any>(key: string, initialValue: any) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = store.get(key);
            return item || initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = (value: (prev: T) => void | string) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            store.set(key, valueToStore);
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
}

import type { ElementType } from "react";
import type { CompoundPolymorphicElement } from "@/typings/utilities";
import type { PdsSkeletonProps, PdsSkeletonBoneProps } from "@/components/skeleton/type";

import { tx } from "@/libs/tailwindMerge";
import { polymorphComponent } from "@/libs";

/**
 * `PdsSkeletonBone` displays a single skeleton loading item.
 *
 * It handles the basic color & rounded design by default and could be further customised with `className` props.
 */
export const PdsSkeletonBone = polymorphComponent<PdsSkeletonBoneProps, ElementType, "div">(
    "div",
    ({ className, ...props }) => ({
        className: tx("bg-neutral-400 rounded", className),
        ...props,
    })
);

/**
 * `PdsSkeleton` component is a simple wrapper that animates any children with a `background-color` using a pulse effect.
 *
 * - It handles the [aria-busy="true"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-busy)
 * attribute automatically.
 * - An [aria-live="polite | assertive"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions)
 * should still be set for `PdsSkeleton`'s parent depends on whether to make loading state explicit on assitive technologies.
 *
 * `PdsSkeleton.Bone` can be used to display a single skeleton loading item, and could be customised or polymorphed
 * if needed.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=6929%3A8721).
 */
export const PdsSkeleton = polymorphComponent<PdsSkeletonProps, ElementType, "div">(
    "div",
    ({ "aria-busy": ariaBusy = true, className, ...props }) => ({
        "aria-busy": ariaBusy,
        className: tx("motion-safe:animate-pulse", className),
        ...props,
    })
) as CompoundPolymorphicElement<{ Bone: typeof PdsSkeletonBone }, PdsSkeletonProps, ElementType, "div">;

PdsSkeleton.Bone = PdsSkeletonBone;

import { createContext as createReactContext, useContext as useReactContext } from "react";

export const createContext = <T extends unknown>() => {
    const context = createReactContext<T | undefined>(undefined);

    const useContext = () => {
        const contextIsDefined = useReactContext(context);
        if (!contextIsDefined) {
            throw new Error("useGenericContext must be used within a Provider");
        }
        return contextIsDefined;
    };

    return [useContext, context.Provider] as const;
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorRemoveColumn = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19 4H9a1 1 0 0 0-1 1v3h2V6h3v12h-3v-2H8v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 14h-3V6h3v12zm-8.71-3.29a1 1 0 0 0 1.42-1.42L9.41 12l1.29-1.29c.19-.18.3-.43.3-.71a1 1 0 0 0-1.71-.71L8 10.59l-1.29-1.3a1 1 0 0 0-1.42 1.42L6.59 12l-1.3 1.29a1 1 0 0 0 1.42 1.42L8 13.41l1.29 1.3z" />
            </svg>
        );
    }
);

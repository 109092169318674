import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorLtr = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M3.57 11a.89.89 0 0 1-.6-.2.89.89 0 0 1-.2-.6V4.04c0-.26.08-.46.23-.62a.85.85 0 0 1 .63-.23c.26 0 .47.08.63.23.15.16.23.36.23.62V9.6H7.3c.54 0 .8.24.8.7 0 .47-.26.7-.8.7H3.57zm8.04.05a.85.85 0 0 1-.62-.23.82.82 0 0 1-.23-.61V4.64H8.97c-.54 0-.81-.23-.81-.7 0-.46.27-.7.81-.7h5.28c.55 0 .82.24.82.7 0 .47-.27.7-.82.7h-1.78v5.57a.8.8 0 0 1-.23.61.85.85 0 0 1-.63.23zm4.63 3.23l3 3.02a1 1 0 0 1 0 1.4l-.07.06-2.92 2.96a1 1 0 0 1-1.42 0l-.06-.06a1 1 0 0 1 0-1.4L16 19H6a1 1 0 0 1 0-2h10l-1.23-1.25a1 1 0 0 1 0-1.4l.06-.07a1 1 0 0 1 1.4 0zm3.13-11.03c.9 0 1.6.2 2.06.6.47.4.7.97.7 1.73 0 .6-.16 1.09-.5 1.48-.32.4-.8.66-1.4.78.23.05.42.13.6.26.17.13.34.31.5.56l.83 1.25c.11.16.17.32.17.47 0 .2-.08.38-.25.51a.9.9 0 0 1-.58.2.9.9 0 0 1-.4-.1.83.83 0 0 1-.33-.3L19.3 8.52a1.3 1.3 0 0 0-.4-.42 1 1 0 0 0-.5-.1h-.9v2.22c0 .26-.07.47-.22.63a.81.81 0 0 1-.6.23.85.85 0 0 1-.63-.23.85.85 0 0 1-.23-.63V4.05c0-.26.06-.46.2-.6.14-.14.34-.2.6-.2h2.76zm-.25 1.3h-1.64V6.7h1.64c.49 0 .84-.09 1.06-.26.22-.17.33-.44.33-.8 0-.38-.1-.65-.33-.82-.22-.17-.57-.26-1.06-.26z" />
            </svg>
        );
    }
);

import type { ElementType, ReactElement } from "react";
import type { CompoundPolymorphicElement, PolymorphicProps, PolymorphicRef } from "@/typings/utilities";
import type { PdsPopoverPanelProps, PdsPopoverPanelCloseButtonProps } from "./type";

import { FocusScope } from "@react-aria/focus";
import { useMemo, cloneElement, forwardRef, useImperativeHandle, useState } from "react";

import { getChildByType, removeChildrenByType, polymorphComponent, tx } from "@/libs";
import { PdsButton } from "@/components/button";
import { PdsIconClose } from "@/components/icons";

import { usePdsPopoverContext } from "./context";

const PdsPopoverPanelWrapper = polymorphComponent<PdsPopoverPanelProps>("div");

/**
 * `PdsPopoverPanel.CloseButton` is a visually optional button in a PdsPopover's panel, visible whether an
 * onClose event handler is passed to `PdsPopover`.
 *
 * If none provided, it is visible to screen readers only.
 */
export const PdsPopoverPanelCloseButton = forwardRef<HTMLButtonElement, PdsPopoverPanelCloseButtonProps>(
    (
        {
            "aria-label": ariaLabel = "Close popover",
            visible = false,
            className,
            ...props
        }: PdsPopoverPanelCloseButtonProps,
        ref
    ) => {
        return (
            <PdsButton
                ref={ref}
                size="xsmall"
                variant="tertiary"
                aria-label={ariaLabel}
                className={tx("absolute top-1.5 right-1.5", { "sr-only": !visible }, className)}
                {...props}
            >
                <PdsButton.Icon>
                    <PdsIconClose size="xsmall" />
                </PdsButton.Icon>
            </PdsButton>
        );
    }
);

/**
 * `PdsPopoverPanel` displays the panel of the `PdsPopover`, with added animation & origin handling.
 *
 * `PdsPopoverPanel` has a close button that could be aria-labelled with `closeButtonAriaLabel`.
 *
 * Could be polymorphed & customised via the `as` and `className` props.
 */
export const PdsPopoverPanel = forwardRef(
    <E extends ElementType = "div">(
        {
            disableAutofocus = false,
            disablePersistentCloseButton = false,
            closeButtonAriaLabel,
            className,
            children,
            ...props
        }: PolymorphicProps<E, PdsPopoverPanelProps>,
        ref: PolymorphicRef<E>
    ) => {
        const { isOpen, placement, handleClose } = usePdsPopoverContext();

        /**
         * Ref management hook for extendable positioning & re-pass to useImperative() to expose to external user.
         */
        const [panelRef, setPanelRef] = useState<HTMLDivElement | null>(null);

        useImperativeHandle(ref, () => panelRef as HTMLDivElement, [panelRef]);

        /**
         * Retrieve & inject close button subcomponent with `aria-label` & `onClick`
         */
        const closeButtonVisible = useMemo(
            () => !!closeButtonAriaLabel && closeButtonAriaLabel.length > 0,
            [closeButtonAriaLabel]
        );
        const closeButtonComponent = getChildByType(children, "PdsPopoverPanelCloseButton") as ReactElement;
        const closeButtonProps = {
            visible: closeButtonVisible,
            "aria-label": closeButtonAriaLabel,
            onClick: handleClose,
        };
        const closeButtonEl = closeButtonComponent ? (
            cloneElement(closeButtonComponent, closeButtonProps)
        ) : (
            <PdsPopoverPanelCloseButton {...closeButtonProps} />
        );

        const panelContent = removeChildrenByType(children, "PdsPopoverPanelCloseButton");

        return (
            <PdsPopoverPanelWrapper
                ref={setPanelRef}
                className={tx(
                    /**
                     * Basic panel design.
                     */
                    "rounded border border-neutral-100 bg-white py-3.5 px-4 text-sm shadow-md",
                    /**
                     * Additional top padding if close button is visible.
                     */
                    {
                        "pt-9": closeButtonVisible,
                        "pt-3.5": !closeButtonVisible,
                    },
                    /**
                     * Mount and unmount transition.
                     */
                    "scale-95 transform opacity-0 transition duration-200 motion-reduce:transition-none",
                    {
                        "scale-100 opacity-100": isOpen,
                    },
                    /**
                     * Transform origin-top to account for placement.
                     */
                    {
                        "origin-top": placement === "bottom",
                        "origin-top-left": placement === "bottom-start" || placement === "right-start",
                        "origin-top-right": placement === "bottom-end" || placement === "left-start",
                        "origin-bottom": placement === "top",
                        "origin-bottom-left": placement === "top-start" || placement === "right-start",
                        "origin-bottom-right": placement === "top-end" || placement === "left-end",
                        "origin-right": placement === "left",
                        "origin-left": placement === "right",
                    },
                    /**
                     * Classname overrides
                     */
                    className
                )}
                {...props}
            >
                <FocusScope autoFocus={!disableAutofocus} contain={!disableAutofocus} restoreFocus>
                    {panelContent}
                    {!disablePersistentCloseButton && closeButtonEl}
                </FocusScope>
            </PdsPopoverPanelWrapper>
        );
    }
) as unknown as CompoundPolymorphicElement<PdsPopoverPanelNamespace, PdsPopoverPanelProps>;

type PdsPopoverPanelNamespace = {
    CloseButton: typeof PdsPopoverPanelCloseButton;
};

PdsPopoverPanel.CloseButton = PdsPopoverPanelCloseButton;

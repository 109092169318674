import type { PdsDataGridHeaderCellProps } from "./type";

import { forwardRef, useEffect, useMemo, useRef } from "react";
import { mergeProps } from "@react-aria/utils";
import { useHover } from "@react-aria/interactions";

import { ex, rx, tx } from "@/libs";
import { PdsTable } from "@/components/table";
import { PdsButton } from "@/components/button";
import { PdsIconChevronDown, PdsIconUnfoldMore } from "@/components/icons";
import { updateChildrenTabIndex } from "@/libs/focusable";

import { usePdsDataGridContext } from "./context";

export const PdsDataGridHeaderCell = forwardRef<HTMLTableCellElement, PdsDataGridHeaderCellProps>(
    (
        {
            rowIndex,
            colIndex,
            placeholder = false,
            sortDirection = false,
            onToggleSort,
            onKeyDown,
            onClick,
            className,
            children,
            ...props
        },
        ref
    ) => {
        const { isHovered, hoverProps } = useHover({});

        /** Get roving tab utils from context. */
        const { mode, handleCellKeydown, isCellActive } = usePdsDataGridContext();

        /** Congregate all relevant attributes & roving tab index for data grid headers. */
        const isActive = useMemo(() => isCellActive?.(rowIndex, colIndex), [colIndex, rowIndex, isCellActive]);

        const ariaSort = useMemo(() => {
            if (!sortDirection) return "none";
            if (sortDirection === "asc") return "ascending";
            return "descending";
        }, [sortDirection]);

        const sortButtonAriaLabel = useMemo(() => {
            if (!sortDirection) return "Sort ascending";
            if (sortDirection === "asc") return "Sort descending";
            return "Reset sort";
        }, [sortDirection]);

        /** Enforce one tab-stop for all children. */
        const cellRef = useRef<HTMLTableCellElement | null>(null);
        useEffect(() => updateChildrenTabIndex(cellRef.current, mode === "action"), [mode]);

        return (
            <PdsTable.HeaderCell
                ref={rx(ref, cellRef)}
                tabIndex={mode === "navigation" && isActive ? 0 : -1}
                aria-sort={ariaSort}
                aria-rowindex={rowIndex + 1}
                aria-colindex={colIndex + 1}
                className={tx(
                    "focusable cursor-pointer ring-inset",
                    {
                        "bg-neutral-200": isHovered,
                    },
                    className
                )}
                onFocus={(e) => updateChildrenTabIndex(e.target)}
                onClick={ex(onToggleSort, onClick)}
                onKeyDown={ex(handleCellKeydown, onKeyDown)}
                {...mergeProps(hoverProps, props)}
            >
                {!placeholder && (
                    <div className="flex items-center gap-2">
                        {children}
                        {onToggleSort && (
                            <PdsButton
                                size="small"
                                variant="tertiary"
                                tabIndex={mode === "action" ? 0 : -1}
                                aria-label={sortButtonAriaLabel}
                                className={tx(
                                    "invisible opacity-0 transition-opacity duration-200 motion-reduce:transition-none",
                                    {
                                        "visible opacity-100": sortDirection || isHovered || mode === "action",
                                    }
                                )}
                            >
                                <PdsButton.Icon
                                    as={sortDirection ? PdsIconChevronDown : PdsIconUnfoldMore}
                                    className={tx(
                                        "rotate-0 transition-transform duration-200 motion-reduce:transition-none",
                                        {
                                            "-rotate-180": sortDirection === "desc",
                                        }
                                    )}
                                />
                            </PdsButton>
                        )}
                    </div>
                )}
            </PdsTable.HeaderCell>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconVisibilityOff = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 6.5a5 5 0 0 1 4.76 6.46l3.06 3.06A11.8 11.8 0 0 0 23 11.49 11.83 11.83 0 0 0 8.36 4.57l2.17 2.17c.47-.14.96-.24 1.47-.24zM2.71 3.16a1 1 0 0 0 0 1.41l1.97 1.97A11.9 11.9 0 0 0 1 11.5a11.83 11.83 0 0 0 15.31 6.68l2.72 2.72a1 1 0 1 0 1.41-1.41L4.13 3.16a1 1 0 0 0-1.42 0zM12 16.5a5 5 0 0 1-4.51-7.14l1.57 1.57a3 3 0 0 0 3.51 3.5L14.14 16c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 0 0-2.64-2.64l2.64 2.64z" />
            </svg>
        );
    }
);

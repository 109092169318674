import type { ElementType } from "react";
import type { PolymorphicProps, PolymorphicRef, PropsOf, CompoundPolymorphicElement } from "@/typings/utilities";
import type { PdsDropdownSubmenuProps } from "./type";

import { nanoid } from "nanoid";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { useMemo, forwardRef, useImperativeHandle, useState } from "react";

import { useRtL, useKeyDown, useBoolean } from "@/hooks";
import { ex, getChildrenByType, removeChildrenByType } from "@/libs";
import { usePdsPopoverTrigger } from "@/components/popover";
import { PdsIconChevronLeft, PdsIconChevronRight } from "@/components/icons";

import { PdsDropdown } from "./Dropdown";
import { PdsDropdownItem, PdsDropdownItemLabel, PdsDropdownItemLeading } from "./DropdownItem";

/**
 * `PdsDropdown.Submenu` is necessarily a special `PdsDropdown.Item`.
 *
 * It renders an extra trailing chevron icon by default, as well as handling the opening of a nested `PdsDropdown`
 * with it as the anchor.
 *
 * The submenu is shown on hover or on Left/Right arrow (dependent on RtL direction).
 */
export const PdsDropdownSubmenu = forwardRef(
    <E extends ElementType = "button">(
        {
            as,
            id,
            open: openProps = false,
            disabled = false,
            placement,
            children,
            onKeyDown: onKeyDownProps,
            ...props
        }: PolymorphicProps<E, PdsDropdownSubmenuProps>,
        ref: PolymorphicRef<E>
    ) => {
        /**
         * Ref handlers
         */
        const [triggerRef, setTriggerRef] = useState<HTMLButtonElement | null>(null);

        useImperativeHandle(ref, () => triggerRef as HTMLButtonElement, [triggerRef]);

        /**
         * RtL detection hook.
         */
        const { isRtL } = useRtL(triggerRef);

        /**
         * Hover state to initiate open submenu.
         */
        const { isHovered, hoverProps } = useHover({ isDisabled: disabled });
        const [isOpen, { open, close }] = useBoolean({ initialValue: isHovered || openProps, isDisabled: disabled });

        /**
         * Memo to set an initial submenu ID.
         */
        const submenuId = useMemo(() => id ?? `pds-dropdown-submenu-${nanoid()}`, [id]);

        /**
         * Subcomponent filters.
         */
        const itemSubcomponents = getChildrenByType(children, ["PdsDropdownItemLeading", "PdsDropdownItemLabel"]);
        const remainingChildren = removeChildrenByType(children, ["PdsDropdownItemLeading", "PdsDropdownItemLabel"]);

        /**
         * Keyboard navigation handlers.
         * RtL consideration is included.
         */
        const { onKeyDown } = useKeyDown({
            isDisabled: disabled,
            ArrowRight: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => !isRtL && open(),
            },
            ArrowLeft: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => isRtL && open(),
            },
        });

        /**
         * Popover-specific attributes that need to be handled separately.
         */
        const { triggerProps } = usePdsPopoverTrigger({
            disabled,
            open: isOpen,
            id: submenuId,
            role: "menu",
        });

        return (
            <>
                <PdsDropdownItem
                    as={as as ElementType}
                    ref={setTriggerRef}
                    disabled={disabled}
                    active={isOpen}
                    onKeyDown={ex(onKeyDownProps, onKeyDown)}
                    {...(mergeProps(hoverProps, triggerProps, props) as unknown as PropsOf<E>)}
                >
                    {itemSubcomponents}
                    {isRtL ? (
                        <PdsIconChevronLeft className="-me-2 ms-auto" />
                    ) : (
                        <PdsIconChevronRight className="-me-2 ms-auto" />
                    )}
                </PdsDropdownItem>
                <PdsDropdown
                    id={submenuId}
                    open={isOpen}
                    trigger={triggerRef}
                    placement={placement ?? (isRtL ? "left-start" : "right-start")}
                    onOpen={open}
                    onClose={close}
                    {...hoverProps}
                >
                    {remainingChildren}
                </PdsDropdown>
            </>
        );
    }
) as unknown as CompoundPolymorphicElement<
    {
        Leading: typeof PdsDropdownItemLeading;
        Label: typeof PdsDropdownItemLabel;
    },
    PdsDropdownSubmenuProps,
    ElementType,
    "button"
>;

PdsDropdownSubmenu.Leading = PdsDropdownItemLeading;
PdsDropdownSubmenu.Label = PdsDropdownItemLabel;

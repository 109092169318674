import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconSettings = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 17.18a5.17 5.17 0 1 1 0-10.35 5.17 5.17 0 0 1 0 10.35m8.66-3.23a2.06 2.06 0 0 1 0-3.9.5.5 0 0 0 .32-.6c-.2-.72-.5-1.41-.86-2.06a.5.5 0 0 0-.62-.2 2.06 2.06 0 0 1-2.7-2.7.5.5 0 0 0-.21-.62 9.29 9.29 0 0 0-2.04-.85.5.5 0 0 0-.6.32 2.06 2.06 0 0 1-3.9 0 .5.5 0 0 0-.6-.32c-.72.2-1.42.5-2.07.87-.2.12-.3.38-.2.6A2.07 2.07 0 0 1 4.5 7.2a.5.5 0 0 0-.62.2 9.26 9.26 0 0 0-.86 2.07.5.5 0 0 0 .32.59 2.06 2.06 0 0 1 0 3.88.5.5 0 0 0-.32.6c.18.64.43 1.26.75 1.84.12.23.4.31.64.2a2.19 2.19 0 0 1 2.18.25c.12.08.23.2.31.31.54.83.53 1.64.2 2.26a.49.49 0 0 0 .18.65c.67.4 1.4.72 2.18.93a.5.5 0 0 0 .59-.32 2.06 2.06 0 0 1 3.9 0c.09.24.34.4.6.32a9.27 9.27 0 0 0 2.15-.91c.22-.13.3-.42.17-.65a2.16 2.16 0 0 1 .51-2.59c.8-.53 1.58-.53 2.2-.24a.5.5 0 0 0 .65-.2c.31-.58.57-1.2.75-1.85a.5.5 0 0 0-.32-.59" />
            </svg>
        );
    }
);

import { useEffect, useRef } from "react";

/**
 * A hook that store the previous value of a variable/state.
 * @param value the value to watch for previous values
 * @returns the previous value
 */
export const usePrevious = <T>(value: T) => {
    /** Generic ref holder. */
    const ref = useRef<T>();

    /** Effect handler to store current value in ref. */
    useEffect(() => {
        ref.current = value;
    }, [value]);

    /** Returns previous value, happens before useEffect(). */
    return ref.current;
};

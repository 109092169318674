import LaptopImage from "@assets/images/laptop.png";
import MotherAndDaughterImage from "@assets/images/mother-and-daughter.png";
import ParentChildImage from "@assets/images/parent-and-child.png";

export const termsOfService = "https://www.educationperfect.com/platform-terms-of-use/";

export const termsAndConditionsConsumer = "https://www.educationperfect.com/standard-terms-and-conditions-consumer/";

export const privacyPolicy = "https://www.educationperfect.com/privacy-policy/";

export const forHomeSupport = "https://www.educationperfect.com/contact-us-form/";

export const advantages: string[] = [
    "Payment is not required",
    "Sign up only takes a moment",
    "Have your learner's information handy",
];

export const slidesData: Record<string, string>[] = [
    { image: ParentChildImage, alt: "Parent helping child at studying", description: "Payment is not required" },
    { image: LaptopImage, alt: "Laptop and hands", description: "Sign up only takes a moment" },
    { image: MotherAndDaughterImage, alt: "Mother and daughter", description: "Have your learner's information handy" },
];

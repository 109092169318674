import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconActivityExam = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path
                    d="M5.25 3H16.5L21 7.5v11.25c0 1.24-1 2.25-2.25 2.25H5.25C4.01 21 3 20 3 18.75V5.25C3 4.01 4 3 5.25 3z"
                    fill="#11AF82"
                />
                <path
                    d="M12.35 8.25h.09c.1.02.2.06.26.12a.8.8 0 01.16.25l2.47 7.13.02.07.02.1c0 .07-.02.12-.07.17a.24.24 0 01-.18.08h-1.51a.53.53 0 01-.24-.1.42.42 0 01-.11-.19l-.32-.94h-3l-.33.94-.02.06a.5.5 0 01-.1.13c-.06.06-.17.1-.32.1H7.75l-.07-.01a.24.24 0 01-.1-.07.24.24 0 01-.08-.17c0-.04.02-.1.05-.17L10 8.62l.04-.08a.83.83 0 01.12-.17c.08-.08.2-.12.35-.12h1.83zm-.91 2.23l-.92 2.71h1.83l-.91-2.71zm5.44-2.1h.05l.09.05c.03.04.05.08.05.14v1.27h1.26l.05.01.09.05c.03.04.05.08.05.14v.51l-.05.09a.18.18 0 01-.14.05h-1.26v1.34l-.05.08a.18.18 0 01-.14.06h-.58a.19.19 0 01-.09-.06.18.18 0 01-.06-.13v-1.29h-1.31a.19.19 0 01-.09-.05.18.18 0 01-.05-.14V10c.01-.04.03-.06.05-.09a.18.18 0 01.14-.06h1.26V8.57l.01-.05c0-.04.03-.06.05-.09a.18.18 0 01.13-.06h.54z"
                    fill="#ffffff"
                />
            </svg>
        );
    }
);

import React, { FC, useMemo } from "react";
import { useTrialPeriod } from "@api/subscription";

interface ITrialPeriod {
    className?: string;
}

const TrialPeriod: FC<ITrialPeriod> = ({ className }) => {
    const { loading, data } = useTrialPeriod();

    const trialPeriod = useMemo((): string => {
        const days = data?.trialPeriod;

        return `${days} day${days !== 1 ? "s" : ""}`;
    }, [data?.trialPeriod]);

    return loading ? <></> : <div className={className}>Free for {trialPeriod}. No obligations.</div>;
};

export default TrialPeriod;

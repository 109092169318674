import type { ColumnDef, RowSelectionState, SortingState } from "@/components/table";
import type { PdsDataGridProps } from "./type";

import { nanoid } from "nanoid";
import { useState, useMemo, useEffect } from "react";
import { FocusScope } from "react-aria";
import { getChildrenByType, removeChildrenByType } from "react-nanny";
import { isEqual } from "lodash";

import { usePrevious } from "@/hooks";
import { getChildByType, tx } from "@/libs";
import {
    flexRender,
    getPaginationRowModel,
    getCoreRowModel,
    getSortedRowModel,
    usePdsTable,
    PdsTable,
} from "@/components/table";
import { PdsCheckbox } from "@/components/checkbox";
import { PdsPagination } from "@/components/pagination";

import { PdsDataGridCell } from "./DataGridCell";
import { PdsDataGridHeaderCell } from "./DataGridHeaderCell";
import { PdsDataGridRovingTabProvider } from "./DataGridRovingTabProvider";

/**
 * `PdsDataGrid` is an opinionated high-level combination of the `PdsTable` design with `react-table` logic.
 *
 * It is intended to reduce the cognitive overhead of building table of low or mid level of complexity.
 * If further customisations are still needed, perhaps the unopinionated `PdsTable` & `usePdsTable()` would be a more
 * suitable choice.
 *
 * It is best used to streamlined common tabular data use cases. This list of use cases could expand as needed:
 * - Responsive scrolling: the data grid is scrollable both horizontally & vertically without violating viewport accessibility.
 * - Sortable by columns: the data grid has clickable headers that sorts a column asc/desc.
 * - Paginated: the data grid has an accommpanying `PdsPagination` that helps users navigate through paged contents.
 * - Row selection: the data grid has an additional first column that has a series of checkboxes to mark a row as selected.
 * - Keyboard navigation: the data grid has an active tab-index management system that helps keyboard users navigate the grid
 * more easily.
 * - Editable cells: **coming soon **
 *
 * `PdsDataGrid` is made with accessibility in mind, with the following augmentations on top of those provided with `PdsTable`:
 * - `PdsDataGrid` by default has a [role="grid"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/grid_role).
 * - `PdsDataGrid` has [aria-multiselectable](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-multiselectable) if `onRowSelected` is specified.
 * - `PdsDataGrid` comes with [single-tab-stop keyboard navigation support](https://www.w3.org/WAI/ARIA/apg/patterns/grid/#keyboardinteraction-settingfocusandnavigatinginsidecells),
 * so that elements could be skipped quickly via a single keypress & making keyboard navigation less clunky.
 * - `PdsDataGrid` also comes with arrow-key navigation where focus could be moved to the adjacent cells in the table via [Arrow keys](https://www.w3.org/WAI/ARIA/apg/patterns/grid/#datagridsforpresentingtabularinformation).
 * - `PdsDataGrid.HeaderCell` has sorting list the direction with [aria-sort](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-sort).
 */
export const PdsDataGrid = <T extends unknown>({
    id,
    data,
    columns,
    initialState,
    className,
    children,
    onRowSelected,
    ...props
}: PdsDataGridProps<T>) => {
    const dataGridId = useMemo(() => id ?? `pds-data-grid-${nanoid()}`, [id]);

    const rowSelectColumn: ColumnDef<T, any> = useMemo(
        () => ({
            id: `pds-data-grid-select-column-${nanoid()}`,
            header: ({ table }) => (
                <PdsCheckbox
                    checked={table.getIsAllRowsSelected()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    onChange={table.getToggleAllRowsSelectedHandler()}
                />
            ),
            cell: ({ row }) => (
                <PdsCheckbox
                    disabled={!row.getCanSelect()}
                    checked={row.getIsSelected()}
                    indeterminate={row.getIsSomeSelected()}
                    onChange={row.getToggleSelectedHandler()}
                />
            ),
        }),
        []
    );

    /** Table state management hooks. */
    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const prevSelection = usePrevious(rowSelection);

    /** Construct the table model. */
    const table = usePdsTable<T>({
        data,
        columns: onRowSelected ? [rowSelectColumn, ...columns] : columns,
        initialState,
        state: { sorting, rowSelection },
        enableSorting: true,
        enableRowSelection: true,
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    /** Extract most relevant models for headers, rows, and columns. */
    const tableHeaderGroups = table.getHeaderGroups();
    const tableRowModel = table.getRowModel();
    const tableColumnModel = table.getAllColumns();

    /** Pre-aggregate some useful grid attributes, length & depth. */
    const rowDepth = tableRowModel.rows.length;
    const colDepth = tableColumnModel.length;
    const headerDepth = useMemo(
        () => tableHeaderGroups.reduce((depth, headerGroups) => depth + headerGroups.depth + 1, 0),
        [tableHeaderGroups]
    );

    /** Emit the current selected rows (across pagintion) to the change handler. */
    useEffect(() => {
        if (!isEqual(prevSelection, rowSelection)) {
            onRowSelected?.(table.getSelectedRowModel().flatRows);
        }
    }, [onRowSelected, prevSelection, rowSelection, table]);

    const headerRowEls = getChildrenByType(getChildByType(children, "PdsTableHeader"), "PdsTableRow");
    const bodyRowEl = getChildrenByType(getChildByType(children, "PdsTableBody"), "PdsTableRow");
    const restEls = removeChildrenByType(children, ["PdsTableHeader", "PdsTableBody"]);

    return (
        <div className={tx("flex w-full flex-col items-center justify-center gap-4", className)}>
            <FocusScope>
                <PdsTable id={dataGridId} role="grid" aria-multiselectable={!!onRowSelected} {...props}>
                    <PdsDataGridRovingTabProvider value={{ headerDepth, rowDepth, colDepth }}>
                        <PdsTable.Header>
                            {/** Reserved slot for extra header rows. */}
                            {headerRowEls}
                            {tableHeaderGroups.map((headerGroup) => (
                                <PdsTable.Row key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <PdsDataGridHeaderCell
                                            key={header.id}
                                            rowIndex={headerGroup.depth}
                                            colIndex={header.index}
                                            colSpan={header.colSpan}
                                            placeholder={header.isPlaceholder}
                                            sortDirection={header.column.getIsSorted()}
                                            onToggleSort={
                                                header.column.getCanSort()
                                                    ? header.column.getToggleSortingHandler()
                                                    : undefined
                                            }
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </PdsDataGridHeaderCell>
                                    ))}
                                </PdsTable.Row>
                            ))}
                        </PdsTable.Header>
                        <PdsTable.Body>
                            {/** Reserved slot for extra body rows. */}
                            {bodyRowEl}
                            {tableRowModel.rows.map((row) => (
                                <PdsTable.Row key={row.id}>
                                    {row.getVisibleCells().map((cell, j) => (
                                        <PdsDataGridCell key={cell.id} colIndex={j} rowIndex={row.index + headerDepth}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </PdsDataGridCell>
                                    ))}
                                </PdsTable.Row>
                            ))}
                        </PdsTable.Body>
                        {restEls}
                    </PdsDataGridRovingTabProvider>
                </PdsTable>
            </FocusScope>
            <PdsPagination
                total={table.getPageCount()}
                value={table.getState().pagination.pageIndex}
                onPageChange={(index) => table.setPageIndex(index)}
            />
        </div>
    );
};

PdsDataGrid.Skeleton = PdsTable.Skeleton;
PdsDataGrid.Caption = PdsTable.Caption;
PdsDataGrid.Header = PdsTable.Header;
PdsDataGrid.Body = PdsTable.Body;
PdsDataGrid.Footer = PdsTable.Footer;
PdsDataGrid.Row = PdsTable.Row;
PdsDataGrid.HeaderCell = PdsDataGridHeaderCell;
PdsDataGrid.Cell = PdsDataGridCell;

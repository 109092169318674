import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconPaid = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 2a10.01 10.01 0 0 1 0 20 10.01 10.01 0 0 1 0-20zm0 2a8 8 0 0 0 0 16 8 8 0 0 0 0-16zm.09 2.02c.61 0 1.03.43 1.09 1.13l.24.09c.64.2.93.36 1.25.63.37.34.54.67.54 1.05 0 .57-.44 1.04-.96 1.04-.2 0-.43-.07-.62-.21-.05-.04-.23-.17-.48-.39-.38-.29-.68-.4-1.06-.4-.6 0-1.05.36-1.05.87 0 .32.17.53.53.7.14.06.33.1.97.27 2.18.48 3.1 1.39 3.1 3.02 0 1.45-.99 2.7-2.4 3v.13c-.02.52-.1.74-.3.95-.22.2-.5.31-.8.31-.69 0-1.09-.49-1.09-1.32v-.07a4.7 4.7 0 0 1-1.33-.59c-.72-.46-1.08-.99-1.08-1.59 0-.63.43-1.1 1.02-1.1.35 0 .65.14 1.05.45.87.74 1.06.84 1.58.84.64 0 1.13-.46 1.13-1.05 0-.6-.43-.96-1.52-1.22l-1.18-.29a2.33 2.33 0 0 1-1.88-2.32c0-.85.36-1.64.98-2.1.31-.25.62-.41 1.16-.63.02-.3.06-.45.13-.63.19-.34.57-.57.98-.57z" />
            </svg>
        );
    }
);

import type { Placement } from "@popperjs/core";

import { createContext, useContext } from "react";

export type PdsPopoverContextProps = {
    /**
     * Current state of popover.
     */
    isOpen?: boolean;
    /**
     * Control function to force closure of popover panel.
     */
    handleClose?: () => void;
    /**
     * The placement of the component in relative to the anchor.
     * @default bottom-start
     */
    placement?: Placement;
};

export const PdsPopoverContext = createContext<PdsPopoverContextProps>({});

export const usePdsPopoverContext = () => useContext(PdsPopoverContext);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconCode = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M8.8 7.3a.96.96 0 0 1 1.4 0c.4.42.4 1.08 0 1.5l-3.5 3.7 3.5 3.7c.4.42.4 1.08 0 1.5a.96.96 0 0 1-1.4 0l-3.86-4.1a1.63 1.63 0 0 1 0-2.22zm6.4 0l3.86 4.09c.59.61.59 1.6 0 2.22L15.2 17.7a.96.96 0 0 1-1.4 0 1.09 1.09 0 0 1 0-1.48l3.5-3.7-3.5-3.72a1.09 1.09 0 0 1 0-1.48.96.96 0 0 1 1.4 0z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconArrowLeft = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M18 11H8.41l3.29-3.29a1 1 0 0 0-1.42-1.42l-5 5a1.01 1.01 0 0 0 0 1.42l5 5a1 1 0 0 0 1.42-1.42L8.4 13H18a1 1 0 0 0 .99-1 1 1 0 0 0-1-1z" />
            </svg>
        );
    }
);

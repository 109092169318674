import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconDiscover = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm0-16a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm2.76 5.24l-3.9 1.95c-.25.12-.55.42-.67.67l-1.95 3.9c-.56 1.11-.1 1.56 1 1l3.9-1.95c.25-.12.55-.42.67-.67l1.95-3.9c.56-1.11.1-1.56-1-1zM12 11a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
        );
    }
);

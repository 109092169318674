import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorUnmergeCells = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M10 14v2.5A3.5 3.5 0 0 1 6.5 20H4v-2h2.5c.83 0 1.5-.67 1.5-1.5V14h2zm6 0v2.5c0 .83.67 1.5 1.5 1.5H20v2h-2.5a3.5 3.5 0 0 1-3.5-3.5V14h2zm3-5l3 3-3 3v-2h-4a1 1 0 0 1-.12-2H19V9zM5 9v2h4a1 1 0 0 1 .12 2H5v2l-3-3 3-3zm1.5-5A3.5 3.5 0 0 1 10 7.5V10H8V7.5C8 6.67 7.33 6 6.5 6H4V4h2.5zM20 4v2h-2.5c-.83 0-1.5.67-1.5 1.5V10h-2V7.5A3.5 3.5 0 0 1 17.5 4H20z" />
            </svg>
        );
    }
);

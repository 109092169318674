import React from "react";
import EnterEmail from "@pages/signup/step-email/views/enter-email";
import SignupCode from "@pages/signup/step-email/views/enter-code";
import SignupExists from "@pages/signup/step-email/views/email-exists";
import { Route, Switch } from "react-router-dom";
import { EmailGuard, ExistsGuard, ValidateGuard } from "@pages/signup/guards";
import Wrapper from "@pages/signup/step-email/views/wrapper";

const SignupEmail = () => {
    return (
        <Wrapper>
            <Switch>
                <Route path="/for-parents/start" exact>
                    <EmailGuard>
                        <EnterEmail />
                    </EmailGuard>
                </Route>
                <Route path="/for-parents/exists" exact>
                    <ExistsGuard>
                        <SignupExists />
                    </ExistsGuard>
                </Route>
                <Route path="/for-parents/validate" exact>
                    <ValidateGuard>
                        <SignupCode />
                    </ValidateGuard>
                </Route>
            </Switch>
        </Wrapper>
    );
};

export default SignupEmail;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconBolt = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M7 4v9a1 1 0 0 0 1 1h2v7.15c0 .51.67.69.93.25l5.19-8.9a1 1 0 0 0-.86-1.5H13l2.49-6.65A1 1 0 0 0 14.56 3H8a1 1 0 0 0-1 1z" />
            </svg>
        );
    }
);

import type { PdsMinimalSizeKeys } from "@/typings/size";
import type { PdsSemanticVariantKeys } from "@/typings/variant";
import type { InheritableElementProps } from "@/typings/utilities";

import { forwardRef } from "react";
import { useProgressBar } from "@react-aria/progress";

import { tx } from "@/libs";

const getSizeStyle = (size: PdsMinimalSizeKeys) => {
    const sizes: Record<PdsMinimalSizeKeys, string> = {
        small: "w-4 h-4",
        medium: "w-6 h-6",
        large: "w-12 h-12",
    };

    return sizes[size];
};

const getVariantStyle = (variant: PdsSemanticVariantKeys) => {
    const variants: Record<PdsSemanticVariantKeys, string> = {
        primary: "text-primary",
        secondary: "text-white",
        tertiary: "text-black",
        success: "text-success",
        info: "text-info",
        warning: "text-warning",
        danger: "text-danger",
        neutral: "text-neutral-600",
        inherit: "text-current",
    };

    return variants[variant];
};

export type PdsSpinnerProps = InheritableElementProps<
    "div",
    {
        /**
         * The size of the spinner.
         * @default medium
         */
        size?: PdsMinimalSizeKeys;

        /**
         * The semantic variant of the spinner.
         * @default inherit
         */
        variant?: PdsSemanticVariantKeys;

        /**
         * Provide text for screen readers. This is required
         * to give context to users about what is loading.
         */
        "aria-label": string;

        /**
         * Allows no children.
         */
        children?: never;
    }
>;

/**
 * `<PdsSpinner />` component is used to display an indeterminate loading state to user.
 *
 * When `<PdsSpinner />` is placed within a parent container, the parent should include:
 *
 * - [aria-live="polite"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions) for screen readers to announce changes only if user is idle.
 *
 * - [aria-atomic="true"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-atomic) for cases when a summary of the element content is needed and not only changes.
 *
 * - [aria-relevant="additions | text | removals"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-relevant) in consideration of how content would be modified after load.
 *
 * `<PdsSpinner />` will inherit its color from its parents, use semantic `variant` props, or be explicitly set via `className` prop.
 *
 * Reference:
 *
 * - [U.S. Government CMS Spinner](https://design.cms.gov/components/spinner/)
 *
 * - [U.K Government Loading patterns discussion](https://paper.dropbox.com/doc/Loading-patterns-v0x8Xych1PbZQXOlbP3L6)
 *
 * - [React-aria indeterminate progress bar](https://react-spectrum.adobe.com/react-aria/useProgressBar.html#indeterminate)
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=3287%3A8597).
 */
export const PdsSpinner = forwardRef<HTMLDivElement, PdsSpinnerProps>(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsSpinnerProps,
        ref
    ): JSX.Element => {
        const { progressBarProps } = useProgressBar({
            isIndeterminate: true,
            "aria-label": ariaLabel,
        });

        return (
            <div
                ref={ref}
                className={tx(getSizeStyle(size), getVariantStyle(variant), className)}
                {...progressBarProps}
                {...props}
            >
                <svg
                    className="duration-700 motion-safe:animate-spin"
                    viewBox="0 0 48 48"
                    stroke="currentColor"
                    role="presentation"
                >
                    <circle
                        className="motion-safe:animate-[spinnerStokeDashOffset_linear_2s_infinite_alternate]"
                        cx="24"
                        cy="24"
                        r="21"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="130"
                        strokeDashoffset="26"
                        strokeWidth="6px"
                    />
                </svg>
            </div>
        );
    }
);

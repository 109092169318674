import type { ReactElement } from "react";
import type { CompoundComponentWithRef, PropsOf } from "@/typings/utilities";
import type { PdsInputProps } from "./type";

import { nanoid } from "nanoid";
import { mergeProps } from "@react-aria/utils";
import { useHover } from "@react-aria/interactions";
import { useRef, useMemo, forwardRef, cloneElement, useCallback } from "react";

import { useFocus } from "@/hooks";
import { ex, tx, getChildByType, rx } from "@/libs";

import { PdsInputLeading, PdsInputTrailing } from "./InputIcon";

/**
 * `PdsInput` is a form element that allows a user to enter and edit a single line of text.
 *
 * - `PdsInput` must have a visible label on all form fields.
 * - `PdsInput` could have an error state when its value is deemed invalid before/when submitting a form.
 * - `PdsInput` could have an associated help/error text that provides additional actionable contexts for users.
 * - `PdsInput` could be marked as required via the label of the input as well as proper ARIA attributes.
 *
 * `PdsInput` is built using `PdsLabel` & `PdsHelpText`, and retains all of their accessibility properties & features:
 *
 * - Visible label assitively linked to the input via [htmlFor](https://developer.mozilla.org/en-US/docs/Web/API/HTMLLabelElement/htmlFor)
 * - Help text assitively linked to the input via [aria-describedby](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby)
 * - Identification of a required field assitively displayed via [aria-required](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-required)
 * - Read-only state assistively displayed via [aria-readonly](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-readonly)
 * - Error state assistively displayed via [aria-invalid](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-invalid)
 *
 * `PdsInput` provides customisation via subcomponents:
 * - `PdsInput.Leading` & `PdsInput.Trailing`: allows to insert leading & trailing icons, default to `PdsIcon*`.
 *
 * These icons should provide further visual context to what value should be entered to make the field's purpose more immediately visible.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=114%3A1861).
 */
export const PdsInput = forwardRef<HTMLInputElement, PdsInputProps>(
    (
        {
            id,
            type = "text",
            autoComplete = "off",
            required = false,
            readOnly = false,
            disabled = false,
            error = false,
            wrapperRef,
            className,
            children,
            onClick,
            ...props
        }: PdsInputProps,
        ref
    ) => {
        /**
         * ID for the input & helpText to ensure they ARIA alignments.
         */
        const inputId = useMemo(() => id ?? `pds-input-${nanoid()}`, [id]);

        /**
         * Focus & hover management hook.
         */
        const inputRef = useRef<HTMLInputElement | null>(null);
        const { isHovered, hoverProps } = useHover({ isDisabled: disabled });
        const { isFocused, focusProps } = useFocus({ isDisabled: disabled });

        /** Click event handler to redirect focus from subcomponents to the input ref. */
        const handleSubcomponentClick = useCallback(() => inputRef.current?.focus(), [inputRef]);

        /**
         * Subcomponent filter. Only the first valid child is selected.
         * Inject with click event handler to focus on the input if click on leading / trailing subcomponents.
         */
        const leadingSubcomponent = getChildByType(children, "PdsInputLeading") as ReactElement;
        const trailingSubcomponent = getChildByType(children, "PdsInputTrailing") as ReactElement;

        const leadingEl =
            leadingSubcomponent &&
            cloneElement(leadingSubcomponent, {
                onClick: ex(handleSubcomponentClick, leadingSubcomponent?.props?.onClick),
            });
        const trailingEl =
            trailingSubcomponent &&
            cloneElement(trailingSubcomponent, {
                onClick: ex(handleSubcomponentClick, trailingSubcomponent?.props?.onClick),
            });

        /**
         * Overrides for `type="number"` input type for accessibility improvements.
         */
        let typeProps: PropsOf<"input"> = { type };
        if (type === "number") {
            typeProps = {
                type: "text",
                inputMode: "numeric",
                pattern: "[0-9]*",
            };
        }

        return (
            <div
                role="presentation"
                ref={wrapperRef}
                className={tx(
                    "flex cursor-text items-center px-3",
                    "rounded-sm border border-neutral-400 bg-white text-sm",
                    "transition duration-200 motion-reduce:transition-none",
                    {
                        "border-neutral-600": isHovered,
                        "border-primary shadow-[0_0_0_1px] shadow-primary": isFocused,
                        "bg-neutral-100": readOnly,
                        "border-danger": error,
                        "shadow-[0_0_0_1px] shadow-danger": error && isFocused,
                        "cursor-not-allowed border-neutral-300 bg-neutral-300": disabled,
                    },
                    className
                )}
                onClick={handleSubcomponentClick}
                {...hoverProps}
            >
                {leadingEl}
                <input
                    id={inputId}
                    ref={rx(ref, inputRef)}
                    readOnly={readOnly}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    required={required}
                    aria-required={required ? "true" : undefined}
                    aria-readonly={readOnly ? "true" : undefined}
                    aria-invalid={error ? "true" : undefined}
                    onClick={onClick}
                    className={tx(
                        "min-h-10 outline-none text-start",
                        "flex-1 rounded-sm bg-transparent font-normal placeholder-neutral-500",
                        "transition duration-200 motion-reduce:transition-none",
                        {
                            "cursor-not-allowed": disabled,
                        }
                    )}
                    {...(mergeProps(typeProps, focusProps, props) as PdsInputProps)}
                />
                {trailingEl}
            </div>
        );
    }
) as CompoundComponentWithRef<PdsInputNamespace, PdsInputProps, HTMLInputElement>;

export type PdsInputNamespace = {
    Leading: typeof PdsInputLeading;
    Trailing: typeof PdsInputTrailing;
};

PdsInput.Leading = PdsInputLeading;
PdsInput.Trailing = PdsInputTrailing;

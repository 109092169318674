/* eslint-disable react-hooks/exhaustive-deps */
import type { Comparator } from "lodash";

import { useEffect, useState, useCallback } from "react";
import { isEqual as isEqualUtil, unionWith, xorWith, differenceWith } from "lodash";

import { usePrevious } from "@/hooks/usePrevious";

export type UseMultiSelectProps<T extends unknown = string> = {
    single?: boolean;
    initialValues?: T[];
    isEqual?: Comparator<T>;
    onChange?: (values: T[]) => void;
};

export type UseMultiSelectValues<T extends unknown = string> = [
    selected: T[],
    callbacks: {
        isEqual: (a: T, b: T) => boolean;
        toggle: (values: T | T[]) => void;
        select: (values: T | T[]) => void;
        unselect: (values: T | T[]) => void;
        reset: () => void;
    }
];

/**
 * A hook that encapsulates basic multi-select state management.
 *
 * @param initialValues the array of initial values to be set.
 * @param isEqual the comparator function to validate if two values are equal.
 * @param single a boolean toggle to turn on single-select mode.
 */
export const useMultiSelect = <T extends unknown = string>({
    single = false,
    initialValues = [],
    isEqual = isEqualUtil,
    onChange,
}: UseMultiSelectProps<T>): UseMultiSelectValues<T> => {
    /** Callback to transform values into array if needed. */
    const arrayizeValues = useCallback(
        (values: T | T[]) => {
            if (!Array.isArray(values)) {
                return [values];
            }

            if (single && values.length > 0) {
                return [values[0] as T];
            }

            return values;
        },
        [single]
    );

    const [selected, setSelected] = useState<T[]>(arrayizeValues(initialValues));

    /** Side effects to update/emit change to initial values. */
    const prevSelected = usePrevious(selected);
    const prevInitialValues = usePrevious(initialValues);

    useEffect(() => {
        /** Skip syncing the selected state if nothing changes. */
        if (isEqualUtil(prevInitialValues, initialValues)) return;
        if (isEqualUtil(selected, initialValues)) return;

        setSelected(initialValues);
    }, [initialValues, prevInitialValues, selected]);

    useEffect(() => {
        /** Skip onChange() if no selection has been made. */
        if (!prevSelected?.length && !selected.length) return;

        /** Skip if selected state stay the same. */
        if (isEqualUtil(prevSelected, selected)) return;

        onChange?.(selected);
    }, [onChange, prevSelected, selected]);

    const toggle = useCallback(
        (values: T | T[]) =>
            single
                ? setSelected(arrayizeValues(values))
                : setSelected((prev) => xorWith<T>(prev, arrayizeValues(values), isEqual)),
        [arrayizeValues, isEqual, single]
    );

    const select = useCallback(
        (values: T | T[]) =>
            single
                ? setSelected(arrayizeValues(values))
                : setSelected((prev) => unionWith<T>(prev, arrayizeValues(values), isEqual)),
        [arrayizeValues, isEqual, single]
    );

    const unselect = useCallback(
        (values: T | T[]) =>
            single
                ? setSelected([])
                : setSelected((prev) => differenceWith<T, T>(prev, arrayizeValues(values), isEqual)),
        [arrayizeValues, isEqual, single]
    );

    const reset = useCallback(() => setSelected([]), []);

    return [
        selected,
        {
            isEqual,
            toggle,
            select,
            unselect,
            reset,
        },
    ];
};

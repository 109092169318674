import api from "@api/index";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import { Payer } from "@api/subscription";

export interface EmailExistsResponse {
    exists: boolean;
}

export interface SubscriptionExistsResponse {
    exists: boolean;
}

export type InstantAccessStatus = "active" | "pending" | "failed";

export type InstantAccessResponse = {
    status: InstantAccessStatus;
    url?: string;
};

/*
 * Send code to the entered email
 */
export const sendCode = async (email: string): Promise<number> => {
    try {
        const response = await api.post<any>("subscriptions/code", {
            email,
        });

        return response.status;
    } catch (e) {
        return Promise.reject(e);
    }
};

/*
 * Validate the code which was sent to email
 */
export const validateCode = async (email: string, code: string): Promise<any> => {
    return api.post<void>("subscriptions/validate", {
        email,
        code,
    });
};

/*
 * Check if subscription already exists
 */
export const subscriptionExists = async (email: string): Promise<boolean> => {
    const res = await api.post<any>("subscriptions/exists", {
        email,
    });

    return res.data.exists;
};

/*
 * Check if subscription is valid
 */
export const isValid = async (email: string): Promise<boolean> => {
    const res = await api.post<EmailExistsResponse>("subscriptions/is-valid", {
        email,
    });

    return res.data.exists;
};

/*
 * Set validated email to cache
 */
export const setValidated = async (email: string): Promise<void> => {
    await api.post<void>("subscriptions/set-validated", {
        email,
    });
};

/*
 * Submit email to hubspot
 */
export const submitEmail = async (email: string): Promise<void> => {
    await api.post<void>("common/hubspot/form/for-parents", {
        stage: "email",
        email,
        hutk: Cookies.get("hubspotutk"),
    });
};

/*
 * Submit payer
 */
export const submitPayer = async (payer: Payer): Promise<void> => {
    const newPayer = {
        stage: "payer",
        payer,
        type: payer.type,
        intent: !isEmpty(payer.intent) ? payer.intent : null,
        hutk: Cookies.get("hubspotutk"),
    };

    if (newPayer.type !== "parent") {
        delete newPayer.intent;
    }

    await api.post<void>("common/hubspot/form/for-parents", newPayer);
};

/*
 * Check if account is ready
 */
export const instantAccess = async (guid: string): Promise<InstantAccessResponse> => {
    const res = await api.get<InstantAccessResponse>(`user/instant-access/${guid}`);
    return res.data;
};

import React, { FC, useMemo } from "react";
import { PdsButton, PdsIconDeletion, PdsIconInfo, PdsToolTip } from "@educationperfect/web-pds-react";
import { useLearnersContext } from "@common/context/learner";
import Select from "@components/common/form/select";
import { Form, isAlphabetical, isPresent } from "@components/common/form";
import Input from "@components/common/form/input";

interface ISignupLearnerForm {
    id: number;
    form: Form;
    onDeleteLearner: (id: number) => void;
    isShowDeleteIcon: (id: number) => boolean;
    onCurriculumChange: (field: string) => (value: any) => void;
    onChange: (id: number, field: string) => (value: any) => void;
}

/**
 * Learner details form
 * @constructor
 */
const SignupLearnerForm: FC<ISignupLearnerForm> = ({
    id,
    form,
    onChange,
    onDeleteLearner,
    isShowDeleteIcon,
    onCurriculumChange,
}) => {
    const { curriculumOptions, learners, curriculums } = useLearnersContext();

    const isShowOptions: boolean = useMemo(() => Boolean(curriculumOptions.length), [curriculumOptions]);

    return (
        <div className="mt-20">
            <div className="mt-7 flex flex-col gap-4">
                <div className="w-full flex justify-between align-middle sm:w-80">
                    <div className="text-neutral-900 text-2xl leading-8 font-bold ">Learner #{id + 1}</div>
                    {isShowDeleteIcon(id) && (
                        <PdsButton
                            type="button"
                            onClick={() => onDeleteLearner(id)}
                            className="w-9 h-8 p"
                            variant="danger"
                            size="large"
                            aria-label="Delete learner"
                        >
                            <PdsButton.Icon as={PdsIconDeletion} size="xsmall" />
                        </PdsButton>
                    )}
                </div>
                <div className="w-full sm:w-80">
                    <label>
                        <Input
                            form={form}
                            autoFocus
                            value={learners[id].firstName}
                            onChange={onChange(id, "firstName")}
                            label="First name"
                            validators={[isPresent(), isAlphabetical()]}
                            required
                        />
                    </label>
                </div>
                <div className="w-full sm:w-80">
                    <label>
                        <Input
                            form={form}
                            value={learners[id].lastName}
                            onChange={onChange(id, "lastName")}
                            label="Last name"
                            validators={[isPresent(), isAlphabetical()]}
                            required
                        />
                    </label>
                </div>
            </div>
            <div className="mt-14 flex flex-col gap-4">
                <div className="flex">
                    <div className="w-full sm:w-80">
                        <Select
                            form={form}
                            disabled={id > 0}
                            placeholder="Select a curriculum"
                            validators={[isPresent()]}
                            label="Which curriculum do they follow?"
                            selected={learners[id].curriculumCode ?? []}
                            onChange={onCurriculumChange("curriculumCode")}
                        >
                            {(curriculums ?? []).map(({ code, name }) => (
                                <Select.Item value={code} label={name} />
                            ))}
                        </Select>
                    </div>
                    <PdsToolTip
                        content="Please contact our Support Team if your curriculum does not appear here. (support@educationperfect.com)."
                        placement="right"
                    >
                        <PdsButton className="ml-1 mt-7" variant="tertiary">
                            <PdsIconInfo />
                        </PdsButton>
                    </PdsToolTip>
                </div>
                {isShowOptions && (
                    <div className="w-full sm:w-80">
                        <Select
                            form={form}
                            label="Curriculum state"
                            placeholder="Select a curriculum state"
                            onChange={onCurriculumChange("curriculumOptionCode")}
                            selected={learners[id]?.curriculumOptionCode ?? []}
                            validators={[isPresent()]}
                            disabled={id > 0}
                        >
                            {curriculumOptions.map(({ name, code }) => (
                                <Select.Item value={code} label={name} />
                            ))}
                        </Select>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignupLearnerForm;

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import store from "@services/store";
import SetupView from "@pages/signup/step-complete/views/setup";
import FailedView from "@pages/signup/step-complete/views/failed";
import { instantAccess } from "@api/signup";
import { Subscription } from "@api/subscription";

const clearStore = () => {
    store.remove("forhome:payer");
    store.remove("forhome:learners");
    store.remove("forhome:intent");
    store.remove("forhome:email");
    store.remove("forhome:valid-email");
    store.remove("forhome:affiliateref");
    store.remove("forhome:affiliatename");
    store.remove("forhome:email-exists");
    store.remove("forhome:curriculumOptions");
    store.remove("forhome:subscription");
};

const enum State {
    Setup,
    Failed,
}

const SignupComplete: FC = () => {
    const subscription = store.get("forhome:subscription") as Subscription;
    const [state, setState] = useState<State>(subscription ? State.Setup : State.Failed);
    const [done, setDone] = useState<boolean>(false);
    const [url, setUrl] = useState<string | null>(null);
    const [start] = useState<number>(new Date().getTime());
    const [counter, setCounter] = useState<number>(0);

    const timer = useRef<ReturnType<typeof setInterval> | null>(null);

    const clearTimer = useCallback(() => {
        if (timer.current) {
            clearInterval(timer.current);
        }
    }, []);

    useEffect(() => {
        if (subscription && state === State.Setup) {
            timer.current = setInterval(async () => {
                setCounter((prevState) => prevState + 1);

                try {
                    const res = await instantAccess(subscription.guid);
                    if (res.status === "active" && res.url) {
                        setUrl(res.url);
                    } else if (res.status === "failed") {
                        setState(State.Failed);
                    }
                } catch {
                    setState(State.Failed);
                }
            }, 2000);
        }

        return () => {
            clearTimer();
        };
    }, [clearTimer, state, subscription, timer]);

    useEffect(() => {
        if (state === State.Failed) {
            clearTimer();
            clearStore();
        }
    }, [clearTimer, state]);

    useEffect(() => {
        if (url && done) {
            clearTimer();
            clearStore();
            window.location.href = url;
        }
    }, [clearTimer, done, url]);

    useEffect(() => {
        if (state === State.Setup && !url) {
            if ((new Date().getTime() - start) / 1000 >= 20) {
                setState(State.Failed);
            }
        }
    }, [start, state, url, counter]);

    return useMemo(() => {
        switch (state) {
            case State.Setup:
                return <SetupView setDone={setDone} />;
            case State.Failed:
                return <FailedView />;
        }
    }, [state]);
};

export default SignupComplete;

import React, { FC } from "react";
import LoginImg from "@assets/images/parent-and-child.png";
import { PdsIconEp, PdsIconStarFill } from "@educationperfect/web-pds-react";

interface ISidebar {
    advantages: string[];
}

const Sidebar: FC<ISidebar> = ({ advantages }) => (
    <aside className="hidden sm:flex relative flex-col items-center justify-between w-[375px] p-6 bg-sky-200">
        <PdsIconEp size="xlarge" className="absolute top-4 left-6" />

        <div className="w-full flex flex-col items-center justify-between h-4/5 lg:h-full">
            <ul className="ml-6 pt-32 pb-10">
                {advantages.map((advantage) => (
                    <li key={advantage} className="mb-4 flex justify-start">
                        <div className="min-w-min">
                            <PdsIconStarFill size="medium" className="mr-3 w-[18px] text-sky-700" />
                        </div>
                        <div className="flex self-center text-sm text-neutral-900 leading-5 font-regular w-[280px]">
                            {advantage}
                        </div>
                    </li>
                ))}
            </ul>

            <img
                className="max-w-[245px] sm:px-3 lg:pb-10"
                src={LoginImg}
                alt="Parent helping a child on their learning journey"
            />
        </div>
    </aside>
);

export default Sidebar;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorFillColour = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M10.07 7H8.5a.5.5 0 0 1-.5-.5v-1c0-.28.22-.5.5-.5h3.09a1 1 0 0 1 .7.3l.23.22a1 1 0 0 1 .26.19l5.65 5.65a1 1 0 0 1 0 1.42l-5.65 5.65a1 1 0 0 1-1.42 0l-5.65-5.65a1 1 0 0 1 0-1.42L10.07 7zm2.7.78a1 1 0 0 0-1.4 0l-3.3 3.3L16 11l-3.22-3.22zm5.98 10.18c-.69 0-1.25-.56-1.25-1.25 0-.41.34-1.32 1.03-2.71a.25.25 0 0 1 .44 0c.69 1.4 1.03 2.3 1.03 2.71 0 .7-.56 1.25-1.25 1.25z" />
            </svg>
        );
    }
);

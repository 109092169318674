import type { ElementType, ForwardedRef, ReactElement } from "react";
import type { PolymorphicComponent, PolymorphicProps, PolymorphicRef, PropsOf } from "@/typings/utilities";
import type { PdsInputIconProps } from "./type";

import { useImperativeHandle, useState, createElement, cloneElement, forwardRef, Children } from "react";

import { tx } from "@/libs";
import { useRtL } from "@/hooks";

const PdsInputAddonWrapper = forwardRef(
    ({ className, children, ...props }: PropsOf<"button">, ref: ForwardedRef<HTMLButtonElement>) => {
        return (
            <button
                type="button"
                ref={ref}
                className={tx(
                    "py-auto h-full min-h-10 border-neutral-400 bg-neutral-100 px-3 text-neutral-600",
                    className
                )}
                {...props}
            >
                {children}
            </button>
        );
    }
);

export const PdsInputLeading = forwardRef(
    <E extends ElementType>(
        { as, addon, children, className, ...props }: PolymorphicProps<E, PdsInputIconProps>,
        ref: PolymorphicRef<E>
    ) => {
        const [el, setEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
        const { isRtL } = useRtL(el);

        useImperativeHandle(ref, () => el as HTMLButtonElement | HTMLDivElement, [el]);

        const childProps = {
            ref: setEl,
            size: "small",
            "aria-hidden": true,
            className: tx("me-2 text-neutral-600", className),
            children,
            ...props,
        };

        if (addon) {
            return (
                <PdsInputAddonWrapper
                    ref={setEl}
                    className={tx(
                        "me-2 -ms-3",
                        { "rounded-l-sm border-r": !isRtL, "rounded-r-sm border-l": isRtL },
                        className
                    )}
                    {...props}
                >
                    {children ?? (as && createElement(as, { size: "small" }))}
                </PdsInputAddonWrapper>
            );
        }

        if (as) {
            return createElement(as, childProps);
        }

        if (Children.count(children) === 1) {
            const iconChild = Children.only(children) as ReactElement;

            return cloneElement(iconChild, {
                ...childProps,
                className: tx(iconChild.props?.className, childProps.className, className),
            });
        }

        return <>{children}</>;
    }
) as PolymorphicComponent<PdsInputIconProps, ElementType, "button" | "div">;

export const PdsInputTrailing = forwardRef(
    <E extends ElementType>(
        { as, addon, children, className, ...props }: PolymorphicProps<E, PdsInputIconProps>,
        ref: PolymorphicRef<E>
    ) => {
        const [el, setEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
        const { isRtL } = useRtL(el);

        useImperativeHandle(ref, () => el as HTMLButtonElement | HTMLDivElement, [el]);

        if (addon) {
            return (
                <PdsInputAddonWrapper
                    ref={setEl}
                    className={tx(
                        "ms-2 -me-3",
                        { "rounded-r-sm border-l": !isRtL, "rounded-l-sm border-r": isRtL },
                        className
                    )}
                    {...props}
                >
                    {children ?? (as && createElement(as, { size: "small" }))}
                </PdsInputAddonWrapper>
            );
        }

        const childProps = {
            ref: setEl,
            size: "small",
            "aria-hidden": true,
            className: tx("ms-2 text-neutral-600", className),
            children,
            ...props,
        };

        if (as) {
            return createElement(as, childProps);
        }

        if (Children.count(children) === 1) {
            const iconChild = Children.only(children) as ReactElement;

            return cloneElement(iconChild, {
                ...childProps,
                className: tx(iconChild.props?.className, childProps.className, className),
            });
        }

        return <>{children}</>;
    }
) as PolymorphicComponent<PdsInputIconProps, ElementType, "button" | "div">;

import type { PdsMfeKeyProp, PdsThemeProp } from "./types";

import { createContext, useContext } from "react";

type PdsThemeContainerContextProps = PdsMfeKeyProp & PdsThemeProp;
export const PdsThemeContainerContext = createContext<PdsThemeContainerContextProps>({
    mfeKey: "",
    theme: "one",
});

export const usePdsThemeContainer = () => {
    return useContext(PdsThemeContainerContext);
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconSortAlphabeticalDesc = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M10 15.99a1 1 0 0 0-.71.29l-.29.29v-3.58a1 1 0 0 0-1-1 1 1 0 0 0-1 1v3.59l-.29-.29a1 1 0 0 0-1.42 1.42l2 2A1 1 0 0 0 8 20a1 1 0 0 0 .71-.29l2-2A1 1 0 0 0 11 17a1 1 0 0 0-1-1.01zM16.71 14h-1.38l-2.32 5.99h1.36l.48-1.33h2.3l.46 1.33h1.4L16.71 14zm-1.51 3.67l.8-2.2h.02l.77 2.2H15.2zM19 10.5h-4.57l4.45-5.12V4h-6.53v1.48h4.1L12 10.59v1.39h7V10.5z" />
            </svg>
        );
    }
);

import type { UseMultiSelectValues } from "@/hooks";
import type { ChangeEvent, ReactNode } from "react";
import type { EitherOr, InheritableElementProps, Merge } from "@/typings/utilities";

export const PdsChoiceGroupSubcomponentMapping: Record<PdsChoiceGroupProps["type"], string> = {
    "chip": "PdsChoiceGroupChip",
    "radio": "PdsChoiceGroupRadio",
    "checkbox": "PdsChoiceGroupCheckbox",
    "radioCard": "PdsChoiceGroupRadioCard",
    "checkboxCard": "PdsChoiceGroupCheckboxCard",
};

export type PdsChoiceGroupContextProps<T extends unknown = string> = [
    ...UseMultiSelectValues<T>,
    {
        name: string;
        disabled: boolean;
        error: boolean;
        options: T[];
    }
];

export type PdsChoiceGroupProps<T extends unknown = string> = InheritableElementProps<
    "fieldset",
    EitherOr<
        {
            /**
             * The text label to provide a visible & accessible naem to the choice group as a form element.
             */
            label: string;
        },
        {
            /**
             * The aria label to provides a visible & accessible name to the choice group.
             *
             * Required if no `label` is provided.
             */
            "aria-label": string;
        }
    > & {
        /**
         * The type of choice items to be used.
         */
        type: "chip" | "checkbox" | "radio" | "checkboxCard" | "radioCard";
        /**
         * The `name` of the form element that all choice items will share.
         */
        name: string;
        /**
         * If `true`, the choice group is foced into single-select mode.
         * @default false
         */
        single?: boolean;
        /**
         * If `true`, the choice group is displayed as required.
         * @default false
         */
        required?: boolean;
        /**
         * If `true`, all items in the choice group is displayed as disabled & non-focusable.
         * @default false
         */
        disabled?: boolean;
        /**
         * The error text to display as help text for users.
         */
        error?: string;
        /**
         * The normal help text displayed underneath the input for additional info.
         *
         * Only visible if no error is present.
         */
        helpText?: string;
        /**
         * The component orientation (layout flow direction).
         * @default horizontal
         * */
        orientation?: "horizontal" | "vertical";
        /**
         * The array of selected values within the choice group.
         * @default []
         */
        value?: T[];
        /**
         * Change event handler for controlled choice group.
         */
        onChange?: (values: T[]) => void;
        /**
         * Children elements are required to render choice items.
         */
        children: ReactNode;
    }
>;

export type PdsChoiceGroupItemProps = InheritableElementProps<
    "input",
    {
        /**
         * The value of the choice item.
         */
        value: string;
        /**
         * If `true`, the choice item is displayed as errorneous.
         * @default false
         */
        error?: boolean;
        /**
         * If `true`, the choice item is checked. If `false`, the choice item is unchecked.
         * Controlled choice item require changes in `checked` props to change.
         *
         * If unspecified, the choice item is uncontrolled.
         * @default false
         */
        checked?: boolean;
        /**
         * Set a default state for uncontrolled choice item.
         *
         * Only use when `checked` is not set.
         * @default false
         */
        defaultChecked?: boolean;
        /**
         * If `true`, the choice item is indeterminate.
         * @default false
         */
        indeterminate?: boolean;
        /**
         * The text label to describe the choice item option.
         *
         * **Note**: Required if no `aria-label` is provided.
         */
        label: string;
        /**
         * The help text to add secondary description the choice item label.
         */
        helpText?: string;
        /**
         * Change event handler for controlled choice item.
         */
        onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    }
>;

/**
 * `PdsChoiceGroupChip` does not allow for presence of help text.
 * Choice chips represents concise filter options that should be accompanied with meaningful label.
 */
export type PdsChoiceGroupChipProps = Omit<PdsChoiceGroupItemProps, "helpText">;

/**
 * `PdsChoiceGroupCard`'s recommended presets for content flow in a choice card.
 */
export type PdsChoiceGroupCardProps = Merge<
    PdsChoiceGroupItemProps,
    InheritableElementProps<
        "label",
        {
            /**
             * The component orientation (layout flow direction).
             * @default horizontal
             * */
            orientation?: "horizontal" | "vertical";
            /**
             * The change event handler for the underlying input.
             */
            onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
        }
    >
>;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconPhone = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M18.18 19.41c-1.7 1.7-7.13-.53-10.1-3.5-2.96-2.96-5.19-8.4-3.5-10.09l1.5-1.49a1.13 1.13 0 0 1 1.67.08l2.88 3.52c.37.45.34 1.1-.07 1.52l-1.59 1.58c-.09.1-.1.23-.04.34a10.3 10.3 0 0 0 1.59 2.11c.57.57 1.27 1.1 2.1 1.6a.3.3 0 0 0 .35-.05l1.58-1.59a1.13 1.13 0 0 1 1.52-.07l3.52 2.88a1.13 1.13 0 0 1 .08 1.67l-1.5 1.5z" />
            </svg>
        );
    }
);

import type { FocusScopeProps } from "@react-aria/focus";
import type { OverlayTriggerState } from "@react-stately/overlays";
import type { AriaDialogProps } from "@react-types/dialog";

import { OverlayContainer, useOverlay, usePreventScroll } from "@react-aria/overlays";
import { FocusScope } from "@react-aria/focus";
import { useDialog } from "@react-aria/dialog";
import { isValidElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { PdsThemeContainer, usePdsThemeContainer } from "@/styles/theme";

import { filterClassName } from "../../utils/filterClassName";
import { PdsIconClose } from "../icons/components";

export type DialogSizes = "s" | "m" | "l" | "xl";

export type DialogProps = {
    children: React.ReactNode;
    /** The size of the dialog. */
    size?: DialogSizes;
    /** If `true`, the close button is rendered */
    closeButton?: boolean;
    /**
     * Closes the dialog. useButton and useOverlayTriggerState ensure that focus management is handled correctly,
     * across all browsers. Focus is restored to the button once the dialog closes.
     */
    /** Whether the Dialog is dismissable by click outside */
    dismissable?: boolean;
    contain?: boolean;
    restoreFocus?: boolean;
    autoFocus?: boolean;
    /**
     * Manages state for an overlay trigger. Tracks whether the overlay is open, and provides
     * methods to toggle this state.
     */
    state: OverlayTriggerState;
    /**
     * The container element in which the overlay portal will be placed.
     * @default document.body
     */
    portalContainer?: HTMLElement;
    /** Users can add spacing/sizing and screen-width specific but cannot modify the internal component desgin(color variant, font sizes, …). */
    className?: string;
} & Omit<AriaDialogProps & FocusScopeProps, "isDismissable" | "isOpen">;

/** Change to style instead of class for unit test purpose */
const getDialogWidth = (size: DialogSizes | undefined) => {
    switch (size) {
        case "s":
            return 400;
        case "m":
            return 600;
        case "l":
            return 800;
        case "xl":
            return 968;
    }
};

const OverlayMask = styled.div<{ portalContainer: HTMLElement | undefined }>`
    ${(props) => (props.portalContainer ? tw`absolute` : tw`fixed`)};
    ${tw`inset-0 z-50 flex items-center justify-center bg-neutral-1000 bg-opacity-60`};
`;

const Dialog = styled.div`
    ${tw`relative inline-block text-black bg-white rounded-xl`};
`;

const HeaderWrapper = styled.div`
    ${tw`text-xl font-bold leading-7`};
`;

const DialogContent = styled.div`
    ${tw`max-h-[65vh] overflow-auto mt-12`};
`;

const MarginContent = styled.div<{ overlayState: boolean | undefined }>`
    ${(props) => (props.overlayState ? tw`-mb-24` : ``)};
`;

const Overlay = styled.div`
    ${tw`sticky h-24 bottom-[-37px] ease-in-out delay-300`};
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

const Header = styled.div`
    ${tw`p-12 pb-5`};
`;

const Body = styled.div`
    ${tw`px-12 pb-6`};
`;

const Footer = styled.div`
    ${tw`p-12`};
`;

const filterNode = (nodes: React.ReactNode, name: string): any => {
    if (Array.isArray(nodes)) {
        return nodes.filter(
            (children: React.ReactNode) => isValidElement(children) && (children.type as any).name === name
        );
    }
    return isValidElement(nodes) && (nodes.type as any).name === name ? nodes : undefined;
};

export const PdsDialog = ({
    size,
    closeButton = true,
    dismissable = true,
    contain = true,
    restoreFocus = true,
    autoFocus = true,
    state,
    portalContainer,
    children,
    className,
    ...props
}: DialogProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const maskRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [overlayState, setOverlayState] = useState(false);

    usePreventScroll();

    const shouldCloseOnInteractOutside = (element: HTMLElement) => {
        if (maskRef.current && maskRef.current.contains(element)) {
            return maskRef.current.contains(element);
        }
        return false;
    };

    const { overlayProps, underlayProps } = useOverlay(
        {
            isDismissable: dismissable,
            isOpen: state.isOpen,
            onClose: state.close,
            shouldCloseOnInteractOutside,
            ...props,
        } as any,
        ref
    );

    const { dialogProps, titleProps } = useDialog(props as DialogProps, ref);

    useEffect(() => {
        if (contentRef.current) {
            setOverlayState(contentRef.current.scrollHeight > contentRef.current.clientHeight);
        }
    }, [state.isOpen]);

    const onScrollContent = () => {
        if (contentRef.current) {
            setOverlayState(
                contentRef.current.scrollHeight - contentRef.current.scrollTop !== contentRef.current.clientHeight
            );
        }
    };

    const header = filterNode(children, PdsDialogHeader.name);
    const body = filterNode(children, PdsDialogBody.name);
    const footer = filterNode(children, PdsDialogFooter.name);

    const { mfeKey, theme } = usePdsThemeContainer();

    return (
        <OverlayContainer portalContainer={portalContainer}>
            <OverlayMask {...underlayProps} ref={maskRef} portalContainer={portalContainer} data-testid="overlay-mask">
                <FocusScope contain={contain} restoreFocus={restoreFocus} autoFocus={autoFocus}>
                    <PdsThemeContainer mfeKey={mfeKey} theme={theme}>
                        <Dialog
                            style={{ width: getDialogWidth(size) }}
                            {...overlayProps}
                            {...dialogProps}
                            {...props}
                            ref={ref}
                            className={className && filterClassName(className, ["bg", "text", "rounded"])}
                        >
                            {closeButton && (
                                <PdsIconClose
                                    size="small"
                                    className="absolute top-5 right-5 cursor-pointer"
                                    onClick={state.close}
                                    data-testid="dialog-close-icon"
                                />
                            )}
                            <HeaderWrapper {...titleProps}>{header}</HeaderWrapper>
                            <DialogContent ref={contentRef} onScroll={onScrollContent}>
                                <MarginContent overlayState={overlayState}>{body}</MarginContent>
                                {overlayState && <Overlay />}
                            </DialogContent>
                            {footer}
                        </Dialog>
                    </PdsThemeContainer>
                </FocusScope>
            </OverlayMask>
        </OverlayContainer>
    );
};

export const PdsDialogHeader = ({ children, ...props }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
    return <Header {...props}>{children}</Header>;
};

export const PdsDialogBody = ({ children, ...props }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
    return <Body {...props}>{children}</Body>;
};

export const PdsDialogFooter = ({ children, ...props }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
    return <Footer {...props}>{children}</Footer>;
};

PdsDialog.Header = PdsDialogHeader;
PdsDialog.Body = PdsDialogBody;
PdsDialog.Footer = PdsDialogFooter;

import type { PdsIconLogoProps } from "./type";

import { forwardRef } from "react";

import { getLogoClassnames } from "./iconCommon";

export const PdsIconLogo = forwardRef<SVGSVGElement, PdsIconLogoProps>(
    ({ size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconLogoProps, ref) => {
        return (
            <svg
                ref={ref}
                viewBox="0 0 24 24"
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getLogoClassnames(className, size, variant)}
                {...props}
            >
                <path d="M16.17 2.03a4.03 4.03 0 0 1 4.51 3.54l1.29 10.56a4.06 4.06 0 0 1-3.52 4.53L7.83 21.97a4.03 4.03 0 0 1-4.5-3.54L2.02 7.87a4.08 4.08 0 0 1 3.52-4.53zm-.56 6.63a2.78 2.78 0 0 0-1.17.25 2.01 2.01 0 0 0-.62.46V9.1a.33.33 0 0 0-.32-.32h-.9a.33.33 0 0 0-.32.32v7.8c0 .17.15.3.32.31h.9c.17 0 .32-.14.32-.32v-2.53a2.34 2.34 0 0 0 .62.46 2.49 2.49 0 0 0 1.17.25 2.42 2.42 0 0 0 1.88-.85 3.26 3.26 0 0 0 .75-1.94 6.16 6.16 0 0 0 0-.83 3.26 3.26 0 0 0-.75-1.94 2.52 2.52 0 0 0-1.88-.85zm-6.81 0a2.75 2.75 0 0 0-2.12.94c-.26.3-.45.64-.58 1.02a3.97 3.97 0 0 0 0 2.55c.13.37.32.72.58 1.02.26.28.57.5.92.66a3.48 3.48 0 0 0 3.21-.38c.2-.14.38-.31.52-.51.1-.13.15-.28.18-.43a.27.27 0 0 0-.09-.23.33.33 0 0 0-.23-.1h-.94a.85.85 0 0 0-.23.04 1.2 1.2 0 0 0-.22.13 1.3 1.3 0 0 1-.39.2 2.04 2.04 0 0 1-1.12 0 1.3 1.3 0 0 1-.44-.2c-.12-.1-.22-.22-.3-.36a1 1 0 0 1-.11-.48h3.95a.3.3 0 0 0 .22-.1c.06-.05.1-.13.1-.22v-.53c0-.4-.07-.78-.21-1.15A2.88 2.88 0 0 0 10 8.9a2.89 2.89 0 0 0-1.2-.25zm6.45 1.43c.36-.01.7.1.98.34.27.22.43.59.47 1.1.03.22.03.45 0 .67-.04.5-.2.88-.47 1.1-.28.23-.63.35-.98.34a1.4 1.4 0 0 1-.98-.36c-.27-.25-.43-.6-.45-.96a3.42 3.42 0 0 1 0-.9c.01-.38.18-.74.46-1 .27-.22.62-.35.97-.33zm-6.45 0c.21-.01.42.02.62.1a1 1 0 0 1 .6.62 2 2 0 0 1 .09.37H7.44a1.33 1.33 0 0 1 .36-.73c.12-.12.26-.2.42-.26.18-.08.38-.11.58-.1z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconCheck = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.74 12.32a1 1 0 0 0-1.48 1.36l3.62 3.92a1.3 1.3 0 0 0 1.83.03l.37-.36A2335.64 2335.64 0 0 0 15 13.38l.04-.04A492.6 492.6 0 0 0 18.7 9.7a1 1 0 0 0-1.42-1.4l-3.64 3.62-.04.04a2049.11 2049.11 0 0 1-3.78 3.73l-3.1-3.37z" />
            </svg>
        );
    }
);

import type { Ref, MutableRefObject } from "react";

/**
 * Refs merger for elements whose refs need to be handled in not only child & parent.
 *
 * Useful where useImperativeHandler is not usable (i.e. merging children refs).
 *
 * @param refs the array of refs to be merged
 * @returns a single ref function that handle ref assignment for all refs provided.
 */
export const refMerge = <T extends HTMLElement = HTMLElement, R extends Ref<T> | undefined = Ref<T> | undefined>(
    ...refs: R[]
): R => {
    return ((instance: T | null) => {
        for (let i = 0; i < refs.length; i += 1) {
            const ref = refs[i];

            if (typeof ref === "function") {
                ref(instance);
            } else if (ref && typeof ref === "object") {
                (ref as MutableRefObject<T | null>).current = instance;
            }
        }
    }) as R;
};

export { refMerge as rx };

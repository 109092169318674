import type { ElementType } from "react";
import type { PolymorphicProps } from "@/typings/utilities";

import { tx, polymorphComponent } from "@/libs";

export type PdsCardProps = PolymorphicProps<ElementType, {}>;

/**
 * `PdsCard` is a container for content & actions related to a subject.
 *
 * At its core, `PdsCard` provides a pre-styled contained & stand-alone contextual wrapper unit. This type of layout
 * can support extremely flexible types of use cases & does not require specific components inside of it.
 *
 * A `PdsCard` should not explicitly handle any interactive events such as hover, click, or focus, though its children
 * may commonly have event handlers.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=12391%3A10814).
 */
export const PdsCard = polymorphComponent("div", ({ className, ...props }) => ({
    className: tx("focusable rounded py-4 px-6", "border border-neutral-400", className),
    ...props,
}));

import type { CompoundPolymorphicElement } from "@/typings/utilities";
import type { PdsButtonNamespace } from "@/components/button";
import type { PdsDropdownTriggerProps } from "./type";

import { polymorphComponent } from "@/libs";
import { PdsButton } from "@/components/button";

/**
 * `PdsDropdown.Trigger` by default is a PdsButton, but could be polymorphed to any elements if needed.
 * Accessibility-wise, similar to PdsPopover, Dropdown would still have to be triggered via onClick,
 * any other trigger method is **HEAVILY** discouraged.
 */
export const PdsDropdownTrigger = polymorphComponent<PdsDropdownTriggerProps>(PdsButton) as CompoundPolymorphicElement<
    PdsButtonNamespace,
    PdsDropdownTriggerProps
>;

PdsDropdownTrigger.Icon = PdsButton.Icon;
PdsDropdownTrigger.Leading = PdsButton.Leading;
PdsDropdownTrigger.Trailing = PdsButton.Trailing;

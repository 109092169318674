import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconGlobe = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm-.9-1.86a7.19 7.19 0 0 1-6.11-8.75L9.3 14.7v.9c0 .99.81 1.8 1.8 1.8v1.74zm6.21-2.29a1.79 1.79 0 0 0-1.71-1.25h-.9v-2.7c0-.5-.4-.9-.9-.9H8.4v-1.8h1.8c.5 0 .9-.4.9-.9V7.5h1.8c.99 0 1.8-.81 1.8-1.8v-.37a7.2 7.2 0 0 1 2.61 11.52z" />
            </svg>
        );
    }
);

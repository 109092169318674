import type { AlertTypes } from "../alert/Alert";

import { PdsAlertBase, StyledTitle } from "../alert/Alert";

type AlertInlineProps = {
    children: string;
    /** The type of the alert. */
    alertType: AlertTypes;
};

export const PdsAlertInline = ({ ...props }: AlertInlineProps): JSX.Element => {
    const { alertType } = { ...props };
    return (
        <PdsAlertBase alertType={alertType}>
            <StyledTitle {...props} />
        </PdsAlertBase>
    );
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconThumbDown = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M11.39 19.55L15.93 15c.3-.3.47-.72.47-1.16v-8.2c0-.9-.74-1.64-1.64-1.64H7.38c-.66 0-1.25.4-1.5 1L3.2 11.23c-.7 1.63.5 3.43 2.26 3.43h4.64l-.78 3.76a1.23 1.23 0 0 0 2.07 1.12zM19.69 4c-.9 0-1.64.74-1.64 1.64v6.57a1.65 1.65 0 0 0 3.29 0V5.64c0-.9-.74-1.64-1.65-1.64z" />
            </svg>
        );
    }
);

import type { ChangeEvent } from "react";
import type { PdsSwitchProps } from "./type";

import { nanoid } from "nanoid";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { forwardRef, useMemo, useEffect, useCallback } from "react";

import { tx, ex } from "@/libs";
import { useBoolean, useFocus } from "@/hooks";

/**
 * `PdsSwitch` allows user to toggle the state of a feature on or off with immediate feedback.
 *
 * `PdsSwitch` presents a user with a strictly binary (on/off) option that triggers an instant response of application
 * without any other explicit review/confirmation.
 * `PdsSwitch` is used for singular & unrelated choices, and should not be used for mutually inclusive choices.
 *
 * - `PdsSwitch` can be on or off.
 * - `PdsSwitch` should have labels. In cases where a label cannot be used, it is mandatory to provide [aria-label](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label)
 * - `PdsSwitch` uses [aria-checked](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-checked)
 * to assistively display as being on/off.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=122%3A3294).
 */
export const PdsSwitch = forwardRef<HTMLInputElement, PdsSwitchProps>(
    (
        {
            id,
            size = "medium",
            required = false,
            disabled = false,
            checked: checkedProps,
            defaultChecked = false,
            className,
            onChange,
            ...props
        }: PdsSwitchProps,
        ref
    ) => {
        const [checked, { setValue }] = useBoolean({ initialValue: checkedProps ?? defaultChecked });

        /**
         * Focus & hover management hook.
         */
        const { isHovered, hoverProps } = useHover({ isDisabled: disabled });

        const { isFocused, focusProps } = useFocus({ isDisabled: disabled });

        /**
         * Memo to intialize switch ID.
         */
        const switchId = useMemo(() => id ?? `pds-switch-${nanoid()}`, [id]);

        /**
         * Effect handler to update on props.
         */
        useEffect(() => setValue(checkedProps ?? defaultChecked), [checkedProps, defaultChecked, setValue]);

        /**
         * Change event handler for checkbox.
         *
         * If controlled (aka. `checked` set via props), internal state change is ignored.
         */
        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                if (checkedProps === undefined) {
                    setValue(e.target.checked);
                }
            },
            [checkedProps, setValue]
        );

        return (
            <label
                htmlFor={switchId}
                className={tx(
                    "relative flex flex-shrink-0 cursor-pointer rounded-full bg-neutral-400 p-0.5",
                    "transition duration-200 motion-reduce:transition-none",
                    {
                        "h-4 w-8": size === "small",
                        "h-6 w-12": size === "medium",
                        "ring-2 ring-primary ring-offset-2": isFocused,
                        "bg-neutral-500": isHovered,
                        "bg-primary": checked,
                        "bg-theme-800": checked && isHovered,
                        "cursor-not-allowed bg-neutral-300": disabled,
                        "bg-theme-400": disabled && checked,
                    },
                    className
                )}
                {...hoverProps}
            >
                <input
                    type="checkbox"
                    role="switch"
                    className="sr-only"
                    ref={ref}
                    id={switchId}
                    disabled={disabled}
                    required={required}
                    checked={checked}
                    aria-checked={checked}
                    onChange={ex(handleChange, onChange)}
                    {...mergeProps(focusProps, props)}
                />
                <div
                    aria-hidden="true"
                    className={tx("transform rounded-full bg-white shadow transition-transform duration-200", {
                        "h-3 w-3": size === "small",
                        "h-5 w-5": size === "medium",
                        "translate-x-4": size === "small" && checked,
                        "translate-x-6": size === "medium" && checked,
                    })}
                />
            </label>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconPreferences = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M3 18a1 1 0 0 0 1 1h5v-2H4a1 1 0 0 0-1 1zM3 6a1 1 0 0 0 1 1h9V5H4a1 1 0 0 0-1 1zm10 14v-1h7a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-7v-1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4a1 1 0 0 0 1 1 1 1 0 0 0 1-1zM7 10v1H4a1 1 0 0 0-1 1 1 1 0 0 0 1 1h3v1a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1 1 1 0 0 0-1 1zm14 2a1 1 0 0 0-1-1h-9v2h9a1 1 0 0 0 1-1zm-5-3a1 1 0 0 0 1-1V7h3a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-3V4a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4a1 1 0 0 0 1 1z" />
            </svg>
        );
    }
);

import React, { useCallback, useState } from "react";
import CodeInputGroup from "@components/code-input-group";
import { PdsLink } from "@educationperfect/web-pds-react";
import { subscriptionExists, validateCode } from "@api/signup";
import { useHistory } from "react-router-dom";
import store from "@services/store";
import GmailIcon from "@assets/icons/gmail-icon.svg";
import OutlookIcon from "@assets/icons/outlook-icon.svg";

const SignupCode = () => {
    const history = useHistory();

    const [isFetching, setIsFetching] = useState(false);
    const [email] = useState(store.get("forhome:email") as string);
    const [isShowCodeError, setIsShowCodeError] = useState(false);

    const gmailLink = `https://mail.google.com/mail/u/${email}/#search/from%3A%40educationperfect+in%3Aanywhere+newer_than%3A1h`;
    const outlookLink = `https://outlook.live.com/mail/?login_hint=${encodeURIComponent(email)}`;

    const sendCode = useCallback(
        async (code: string) => {
            try {
                setIsFetching(true);
                await validateCode(email, code);
                store.set("forhome:valid-email", email);

                const exists = await subscriptionExists(email);
                store.set("forhome:email-exists", exists);

                if (exists) {
                    history.push("/for-parents/exists");
                } else {
                    history.push("/for-parents/register");
                }
            } catch (e) {
                setIsShowCodeError(true);
            } finally {
                setIsFetching(false);
            }
        },
        [email, history]
    );

    return (
        <>
            <h2 className="whitespace-pre-line text-xs text-center text-neutral-900 mb-10 font-normal sm:text-base sm:font-regular md:text-sm lg:mt-4">
                {`We’ve sent a 4-digit pin to ${email}. \n
                The code expires shortly, so please enter it soon.`}
            </h2>

            <section className="flex w-full justify-center gap-10 mb-4">
                <PdsLink href={gmailLink} target="_blank">
                    <img src={GmailIcon} className="mr-[5px] h-[19px]" alt="Gmail link icon" />
                    Open Gmail
                </PdsLink>
                <PdsLink href={outlookLink} target="_blank">
                    <img src={OutlookIcon} className="mr-[5px] h-[21px]" alt="Outlook link icon" />
                    Open Outlook
                </PdsLink>
            </section>

            <section className="sm:mx-7 lg:max-w-xl">
                <CodeInputGroup
                    sendCode={sendCode}
                    isFetching={isFetching}
                    isError={isShowCodeError}
                    setIsShowCodeError={setIsShowCodeError}
                />
            </section>

            <p className="mt-3 sm:mt-24 flex-col px-3 text-center text-neutral-600 text-sm leading-8 font-regular">
                Can&apos;t find your code? Check your <strong>spam</strong> or <strong>promotions</strong> folder.
            </p>
        </>
    );
};

export default SignupCode;

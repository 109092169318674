import { useRef, useEffect } from "react";

/**
 * A hook that keeps track of the changed props passed in.
 * Useful for debugging lifecycle in components.
 */
export const useTraceUpdate = (props: Record<string, unknown>) => {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                Object.assign(ps, { [k]: [prev.current[k], v] });
            }

            return ps;
        }, {} as Record<string, unknown>);

        if (Object.keys(changedProps).length > 0) {
            // eslint-disable-next-line no-console
            console.log("Changed props:", changedProps);
        }

        prev.current = props;
    });
};

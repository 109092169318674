/* eslint-disable jsx-a11y/role-supports-aria-props */
import type { ReactElement } from "react";
import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsChoiceGroupCardProps } from "./type";

import { nanoid } from "nanoid";
import { useFocusVisible, useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { forwardRef, cloneElement, useMemo, useRef } from "react";

import { useFocus, useKeyDown, useMountTransition, useRtL, useSafeLayoutEffect } from "@/hooks";
import { ex, getChildByType, polymorphComponent, rx, tx } from "@/libs";
import { PdsCard } from "@/components/card";
import { PdsIconTarget } from "@/components/icons";
import { PdsHelpText, PdsLabel } from "@/components/label";

import { usePdsChoiceGroupContext } from "./context";

export const PdsChoiceGroupCardIcon = polymorphComponent<{
    open?: boolean;
    disabled?: boolean;
    isRtL?: boolean;
    orientation?: "horizontal" | "vertical";
}>(
    PdsIconTarget,
    ({ open = false, disabled = false, orientation = "horizontal", isRtL = false, className, ...props }) => ({
        size: "small",
        variant: disabled ? "neutral" : "primary",
        className: tx("absolute opacity-0 transition-opacity duration-200 motion-reduce:transition-none", {
            "opacity-100": open,
            "top-1/2 -translate-y-1/2": orientation === "horizontal",
            "left-1.5": orientation === "horizontal" && isRtL,
            "top-2 left-2": orientation === "vertical" && isRtL,
            "right-1.5": orientation === "horizontal" && !isRtL,
            "top-2 right-2": orientation === "vertical" && !isRtL,
        }),
        ...props,
    })
);

export const PdsChoiceGroupCard = forwardRef<HTMLInputElement, PdsChoiceGroupCardProps>(
    (
        {
            id,
            role,
            label,
            helpText,
            value,
            name,
            checked: checkedProps,
            disabled: disabledProps = false,
            error: errorProps = false,
            defaultChecked = false,
            orientation = "horizontal",
            className,
            children,
            onClick,
            onChange,
            onKeyDown: onKeyDownProps,
            ...props
        }: PdsChoiceGroupCardProps,
        ref
    ) => {
        const helpTextId = useMemo(() => `pds-choice-card-help-text-${nanoid()}`, []);

        /**
         * Get selection state from context.
         */
        const [selected, { toggle, select }, { name: nameContext, disabled: disabledContext, error: errorContext }] =
            usePdsChoiceGroupContext();

        /**
         * Ref management hook & Right-to-left check hook.
         */
        const cardRef = useRef<HTMLLabelElement | null>(null);
        const inputRef = useRef<HTMLInputElement | null>(null);
        const { isRtL } = useRtL(cardRef.current);

        /** Checked state management hook. */
        const disabled = useMemo(() => disabledContext || disabledProps, [disabledContext, disabledProps]);
        const error = useMemo(() => errorContext || errorProps, [errorContext, errorProps]);
        const checked = useMemo(() => checkedProps || selected.includes(value), [checkedProps, selected, value]);

        /** Transition mount delay hook to handle the icon mount & unmount with transition. */
        const { isFocusVisible } = useFocusVisible();
        const { isHovered, hoverProps } = useHover({ isDisabled: disabled });
        const { isFocused, focusProps } = useFocus({ isDisabled: disabled });
        const { hasTransitionedIn } = useMountTransition(checked);

        /**
         * Click handler to toggle the radio on click.
         */
        const handleToggle = () => {
            if (disabled) return;
            toggle(value);
        };

        /**
         * Space event is return with key of " " on certain browsers.
         */
        const { onKeyDown } = useKeyDown({
            isDisabled: disabled,
            Enter: {
                preventDefault: true,
                callback: handleToggle,
            },
            Space: {
                preventDefault: true,
                callback: handleToggle,
            },
            " ": {
                preventDefault: true,
                callback: handleToggle,
            },
        });

        /**
         * Set the initial state of the radio if specified.
         */
        useSafeLayoutEffect(() => {
            if (checked || defaultChecked) {
                select(value);
            }
        }, []);

        /** Extract & inject the icon subcomponent. */
        const iconEl = cloneElement(getChildByType(children, "PdsChoiceGroupCardIcon") as ReactElement, {
            isRtL,
            disabled,
            orientation,
            open: checked && hasTransitionedIn,
        });

        return (
            <PdsCard
                as="label"
                ref={cardRef}
                htmlFor={id}
                className={tx(
                    "focusable relative flex flex-1 cursor-pointer transition-colors duration-200",
                    {
                        "border-neutral-600": isHovered,
                        "border-primary": checked,
                        "border-theme-800": checked && isHovered,
                        "border-danger !ring-danger": error,
                        "border-red-700": error && isHovered,
                        "cursor-not-allowed border-neutral-300 bg-neutral-300": disabled,
                        "outline-none ring-2 ring-primary ring-offset-2 transition duration-200 motion-reduce:transition-none":
                            isFocused && isFocusVisible,
                    },
                    className
                )}
                onClick={ex(handleToggle, onClick)}
                onKeyDown={ex(onKeyDown, onKeyDownProps)}
                {...hoverProps}
            >
                <input
                    ref={rx(ref, inputRef)}
                    id={id}
                    type={role === "radio" ? "radio" : "checkbox"}
                    disabled={disabled}
                    checked={checked}
                    name={name ?? nameContext}
                    value={value}
                    aria-checked={checked}
                    aria-disabled={disabled ? "true" : undefined}
                    aria-describedby={helpTextId}
                    className="sr-only"
                    onChange={ex(() => handleToggle(), onChange)}
                    {...mergeProps(focusProps, props)}
                />
                <div
                    className={tx("flex w-full flex-col", {
                        "items-start": orientation === "horizontal",
                        "items-center justify-center": orientation === "vertical",
                    })}
                >
                    <PdsLabel htmlFor={id}>{label}</PdsLabel>
                    {helpText && (
                        <PdsHelpText
                            id={helpTextId}
                            className={tx({ "pointer-events-none text-center": orientation === "vertical" })}
                        >
                            {helpText}
                        </PdsHelpText>
                    )}
                </div>
                {(checked || hasTransitionedIn) && iconEl}
            </PdsCard>
        );
    }
) as CompoundComponentWithRef<{ Icon: typeof PdsChoiceGroupCardIcon }, PdsChoiceGroupCardProps, HTMLDivElement>;

PdsChoiceGroupCard.Icon = PdsChoiceGroupCardIcon;

import type { KeyboardEvent } from "react";

import { useCallback } from "react";

type ModifierKeys =
    | "Alt"
    | "AltGraph"
    | "CapsLock"
    | "Control"
    | "Fn"
    | "FnLock"
    | "Hyper"
    | "Meta"
    | "NumLock"
    | "ScrollLock"
    | "Shift"
    | "Super"
    | "Symbol"
    | "SymbolLock";
type WhitespaceKeys = "Enter" | "Tab" | " " | "Space";
type NavigationKeys =
    | "ArrowDown"
    | "ArrowLeft"
    | "ArrowRight"
    | "ArrowUp"
    | "End"
    | "Home"
    | "PageDown"
    | "PageUp"
    | "Escape";
type EditingKeys = "Backspace" | "Delete";
type FunctionKeys =
    | "F1"
    | "F2"
    | "F3"
    | "F4"
    | "F5"
    | "F6"
    | "F7"
    | "F8"
    | "F9"
    | "F10"
    | "F11"
    | "F12"
    | "F13"
    | "F14"
    | "F15"
    | "F16"
    | "F17"
    | "F18"
    | "F19"
    | "F20"
    | "Soft1"
    | "Soft2"
    | "Soft3"
    | "Soft4";
type NumericKeypadKeys =
    | "Decimal"
    | "Key11"
    | "Key12"
    | "Multiply"
    | "Add"
    | "Clear"
    | "Divide"
    | "Subtract"
    | "Separator"
    | "0"
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9";

type KeyboardEventKey = ModifierKeys | WhitespaceKeys | NavigationKeys | EditingKeys | FunctionKeys | NumericKeypadKeys;

type KeyOptions = {
    callback: (e: KeyboardEvent) => void;
    isDisabled?: boolean;
    preventDefault?: boolean;
    stopPropagation?: boolean;
};

type UseKeyConfig = { isDisabled?: boolean } & { [key in KeyboardEventKey]?: KeyOptions };

export const useKeyDown = ({ isDisabled, ...configs }: UseKeyConfig) => {
    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (isDisabled) {
                return;
            }

            const keyConfig = configs[e.key as KeyboardEventKey];

            if (keyConfig) {
                if (keyConfig.isDisabled) {
                    return;
                }

                if (keyConfig.preventDefault) {
                    e.preventDefault();
                }

                if (keyConfig.stopPropagation) {
                    e.stopPropagation();
                }

                keyConfig.callback(e);
            }
        },
        [configs, isDisabled]
    );

    return { onKeyDown };
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconGreekSymbols = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M15.98 16.74v.06h2.8v1.9h-5.54v-1.48a6.73 6.73 0 0 0 2.74-5.54c0-2.36-1.34-4.72-3.78-4.72-2.42 0-3.88 2.24-3.88 4.74 0 2.34 1.26 4.6 2.76 5.52v1.48H5.5v-1.9h2.76v-.06a6.9 6.9 0 0 1-2.5-5.32c0-3.66 2.7-6.42 6.5-6.42 3.94 0 6.26 2.9 6.26 6.22a7.2 7.2 0 0 1-2.54 5.52z" />
            </svg>
        );
    }
);

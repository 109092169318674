import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { useEffect, useState } from "react";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST || "/api/v1",
    withCredentials: true,
});

api.interceptors.response.use((response: AxiosResponse) => {
    if (
        response.data &&
        response.headers["content-type"] &&
        response.headers["content-type"].includes("application/json")
    ) {
        response.data = camelizeKeys(response.data);
    }
    return response;
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
    const c = { ...config };
    if (config.data) {
        c.data = decamelizeKeys(config.data);
    }
    return c;
});

export default api;

export function useApi<T = any>(url: string, config?: Omit<AxiosRequestConfig, "url">) {
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const doFetch = async () => {
            setError(false);
            setLoading(true);

            try {
                setData((await api.request<T>({ url, ...config })).data);
            } catch (e) {
                setError(true);
            }

            setLoading(false);
        };

        doFetch();
    }, [url, config]);

    return { data, error, loading };
}

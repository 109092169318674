import { useState } from "react";
import { useFocus as useAriaFocus } from "@react-aria/interactions";

/**
 * Adding custom lib extension of useFocus to keep return type set consistent.
 * Similar to useHover(), returns a state and list of props that could be attached to an element.
 */
export const useFocus = ({ onFocusChange, ...args }: Parameters<typeof useAriaFocus>[0]) => {
    const [isFocused, setIsFocused] = useState(false);

    const { focusProps } = useAriaFocus({
        ...args,
        onFocusChange: (focused) => {
            setIsFocused(focused);
            onFocusChange?.(focused);
        },
    });

    return { isFocused, focusProps };
};

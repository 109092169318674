import React, { useCallback, useEffect } from "react";
import { PdsLabel, PdsSelect, PdsSelectOption, PdsSelectProps } from "@educationperfect/web-pds-react";
import { doValidate, Form, useFormControl, Validator } from "@components/common/form";
import Errors from "@components/common/form/errors";
import styles from "./styles.module.css";

type Props = {
    form: Form;
    selected: PdsSelectOption[];
    label?: string;
    validators?: Validator[];
    onChange?: (value: PdsSelectOption[]) => void;
    required?: boolean;
    showFirstError?: boolean;
} & Omit<
    PdsSelectProps,
    | "errorList"
    | "validationState"
    | "onChange"
    | "onBlur"
    | "value"
    | "onSelectionChange"
    | "onInputChange"
    | "required"
    | "form"
>;

const Select = ({
    form,
    label,
    selected,
    validators,
    onChange,
    children,
    required,
    showFirstError = true,
    ...props
}: Props) => {
    const { id, errors } = useFormControl(form, selected, validators);

    const handleSelectionChange = useCallback(
        (values: PdsSelectOption[]) => {
            onChange?.(values);
        },
        [onChange]
    );

    useEffect(() => {
        form.register({ id, validate: () => doValidate(selected, validators).length === 0 });

        /**
         * Controls must be unregistered on unmount
         * to avoid cases of incorrect form validation
         */
        return () => form.unregister(id);
    }, [form, validators, id, selected]);

    return (
        <div className={`${styles.select} ${errors && errors.length > 0 ? styles.error : ""}`}>
            {label && (
                <PdsLabel className="mb-1" required>
                    {label}
                </PdsLabel>
            )}
            <PdsSelect
                onSelectedValueChange={handleSelectionChange}
                selectedValues={selected}
                value={selected?.[0]?.label ?? ""}
                {...props}
            >
                {children}
            </PdsSelect>
            <Errors errors={errors} showFirst={showFirstError} cls="mt-[2px]" />
        </div>
    );
};

Select.Item = PdsSelect.Item;
Select.Section = PdsSelect.Section;

export default Select;

import type { PdsDataGridCellProps } from "./type";

import { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";

import { ex, rx, tx } from "@/libs";
import { PdsTable } from "@/components/table";
import { updateChildrenTabIndex } from "@/libs/focusable";

import { usePdsDataGridContext } from "./context";

export const PdsDataGridCell = forwardRef<HTMLTableCellElement, PdsDataGridCellProps>(
    ({ rowIndex, colIndex, className, onClick, onKeyDown, ...props }, ref) => {
        /** Get roving tab utils from context. */
        const { mode, handleCellKeydown, isCellActive } = usePdsDataGridContext();

        /** Enforce one tab-stop for all children. */
        const cellRef = useRef<HTMLTableCellElement | null>(null);
        useEffect(() => updateChildrenTabIndex(cellRef.current, mode === "action"), [mode]);

        /** Calculate roving tab index for data grid cells. */
        const isActive = useMemo(() => isCellActive?.(rowIndex, colIndex), [colIndex, rowIndex, isCellActive]);

        /** Ensure focus onto the current cell on click. */
        const handleCellClick = useCallback((e) => e.currentTarget?.focus(), []);

        return (
            <PdsTable.Cell
                ref={rx(ref, cellRef)}
                tabIndex={mode === "navigation" && isActive ? 0 : -1}
                rowHeader={colIndex === 0}
                aria-rowindex={rowIndex + 1}
                aria-colindex={colIndex + 1}
                onClick={ex(handleCellClick, onClick)}
                onKeyDown={ex(handleCellKeydown, onKeyDown)}
                className={tx("focusable ring-inset", className)}
                {...props}
            />
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconStar = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M13.66 5.27l1.42 2.99 3.19.48c1.77.27 2.3 1.98 1.02 3.28L17 14.36l.54 3.3c.3 1.85-1.1 2.9-2.68 2.03L12 18.13l-2.85 1.56c-1.59.87-2.98-.19-2.68-2.02l.55-3.31-2.31-2.34C3.42 10.72 3.96 9 5.73 8.74l3.19-.48 1.43-3c.8-1.69 2.52-1.67 3.31 0zM12 6.43l-1.73 3.65-4.06.61 2.94 3-.67 4.09L12 15.85l3.52 1.93-.67-4.1 2.94-2.99-4.06-.61L12 6.43z" />
            </svg>
        );
    }
);

import React, { useCallback, useMemo, useState } from "react";
import { PdsButton, PdsIconEp, PdsSelectOption } from "@educationperfect/web-pds-react";
import ArtBoardImage from "@assets/images/artboard.svg";
import { CurriculumOption, useCurriculumOptions } from "@api/learner";
import fp from "lodash/fp";
import omit from "lodash/omit";
import { createSubscription } from "@api/subscription";
import { defaultLearner, ILearner, useLearnersContext } from "@common/context/learner";
import store, { useStore } from "@services/store";
import SignupLearnerForm from "@pages/signup/step-learners/learner-form";
import { useHistory } from "react-router-dom";
import AlertContactSupport from "@components/alerts/contact-support";
import Stepper from "@components/common/header/stepper";
import { steps } from "@pages/signup/constants";
import { useForm } from "@components/common/form";
import ButtonLink from "@components/common/button-link";

/*
 * Get curriculum options depending on countryCode
 */
const getFilteredOptions = (options: CurriculumOption[], countryCode: string) =>
    options.filter((option) => option.curriculumCode === countryCode);

const SignupLearner = () => {
    const form = useForm();
    const history = useHistory();
    const [payer] = useStore("forhome:payer", fp);
    const allCurriculumOptions = useCurriculumOptions().curriculumOptions;
    const { learners, setLearners, setCurriculumOptions, loginRoute } = useLearnersContext();

    const [isFetching, setIsFetching] = useState(false);
    const [isError, setIsError] = useState(false);

    /**
     * Back to parent's details
     */
    const goBack = () => history.push("/for-parents/register");

    const onSubmit = useCallback(
        async (event) => {
            event.preventDefault();
            if (!form.isValid()) {
                return;
            }

            const newLearners = learners.map((learner) => {
                const { curriculumCode, curriculumOptionCode } = learner;

                return {
                    ...omit(learner, ["id"]),
                    curriculumCode: (curriculumCode ?? [])[0]?.value ?? "",
                    academicLevelCode: "5",
                    curriculumOptionCode: (curriculumOptionCode ?? [])[0]?.value ?? "",
                };
            });

            const subscription = {
                params: store.get("forhome:params") as string[],
                affiliateName: store.get("forhome:affiliatename") as string,
                affiliateRef: store.get("forhome:affiliateref") as object,
                type: payer.type,
                product: "forhome",
                intent: payer.intent,
                learners: newLearners,
                payer: {
                    ...payer,
                    countryCode: payer.countryCode[0]?.value,
                    state: payer.state[0]?.value,
                },
            };

            setIsFetching(true);

            try {
                const res = await createSubscription(subscription);
                store.set("forhome:learners", learners);
                store.set("forhome:subscription", res);
                history.push("/for-parents/complete");
            } catch (e) {
                setIsError(true);
            } finally {
                setIsFetching(false);
            }
        },
        [form, learners, payer, history]
    );

    const onChange = useCallback(
        (id: number, field: string) => (value: any) => {
            setLearners((prevLearners) =>
                prevLearners.map((learner) => {
                    return learner.id === id ? { ...learner, [field]: value } : learner;
                })
            );
        },
        [setLearners]
    );

    /*
     * Save curriculum, clear curriculum options and get new ones if curriculum changed
     */
    const onCurriculumChange = useCallback(
        (field: string) => (value: PdsSelectOption[]) => {
            setLearners((prevLearners) => {
                if (field === "curriculumCode") {
                    const options = getFilteredOptions(allCurriculumOptions ?? [], value?.[0]?.value);
                    setCurriculumOptions(options);

                    return prevLearners.map((learner) => ({
                        ...omit(learner, ["curriculumOptionCode"]),
                        curriculumCode: value,
                    }));
                }

                if (field === "curriculumOptionCode") {
                    return prevLearners.map((learner) => ({
                        ...learner,
                        curriculumOptionCode: value,
                    }));
                }

                return prevLearners;
            });
        },
        [allCurriculumOptions, setCurriculumOptions, setLearners]
    );

    const onAddLearner = useCallback(() => {
        setLearners((prevLearners) => {
            if (prevLearners.length === 5) {
                return;
            }

            return [
                ...prevLearners,
                {
                    ...defaultLearner,
                    id: prevLearners.length,
                    curriculumCode: prevLearners[0].curriculumCode,
                    curriculumOptionCode: prevLearners[0].curriculumOptionCode,
                } as ILearner,
            ];
        });
    }, [setLearners]);

    const onDeleteLearner = useCallback(
        (id: number) => setLearners((prevLearners) => prevLearners.filter((learner) => learner.id !== id)),
        [setLearners]
    );

    /*
     * Show the delete icon only if it's the last learner
     */
    const isShowDeleteIcon = useCallback(
        (id: number) => {
            if (learners.length <= 1) {
                return false;
            }

            const maxID = Math.max(...learners.map((l) => l.id));
            return id === maxID;
        },
        [learners]
    );

    /*
     * Return the enumerator of a next added learner
     */
    const nextLearnerEnumerator = useMemo(
        () => ["second", "third", "fourth", "fifth"][learners.length - 1],
        [learners.length]
    );

    return (
        <div className="w-full min-h-screen">
            <header className="flex flex-row align-middle justify-center">
                <PdsIconEp size="xlarge" className="absolute top-4 left-3" />
                <div className="mt-6">
                    <Stepper steps={steps} />
                </div>
                <ButtonLink link={loginRoute} className="absolute top-4 right-4" title="Sign in" />
            </header>

            <main>
                <form
                    onSubmit={onSubmit}
                    noValidate
                    className="w-full px-4 md:mx-auto sm:max-w-[600px] lg:max-w-[880px] sm:ml-[106px] sm:mr-10 py-12"
                >
                    {isError && <AlertContactSupport />}

                    <h1 className="text-lg leading-7 font-bold text-neutral-900 sm:text-2xl sm:leading-8 mt-4 sm:mt8">
                        Provide your learner(s) details
                    </h1>

                    <div className="w-full flex sm:justify-between">
                        <div className="w-full">
                            {learners.map(({ id }, index) => (
                                <SignupLearnerForm
                                    key={id}
                                    id={index}
                                    form={form}
                                    onChange={onChange}
                                    onCurriculumChange={onCurriculumChange}
                                    onDeleteLearner={onDeleteLearner}
                                    isShowDeleteIcon={isShowDeleteIcon}
                                />
                            ))}
                        </div>
                        <img
                            className="hidden sm:flex sm:w-[169px] lg:w-[282px] self-start"
                            src={ArtBoardImage}
                            alt="Artboard"
                        />
                    </div>

                    {learners.length < 5 && (
                        <PdsButton
                            onClick={onAddLearner}
                            className="w-full mt-16 sm:w-[200px]"
                            type="button"
                            variant="secondary"
                        >
                            {`
                        + Add ${nextLearnerEnumerator} learner`}
                        </PdsButton>
                    )}

                    <div className="mt-16 w-full flex flex-col-reverse sm:flex-row gap-5 sm:gap-0 justify-between">
                        <PdsButton
                            onClick={goBack}
                            className="w-full sm:w-[210px] sm:mr-[66px]"
                            type="button"
                            variant="secondary"
                        >
                            Back
                        </PdsButton>
                        <PdsButton
                            id="start-trial-btn"
                            type="submit"
                            loading={isFetching}
                            disabled={isFetching}
                            loadingAriaLabel="Verifying learners' info..."
                            className="w-full sm:w-[210px] sm:mr-[66px]"
                        >
                            Finish
                        </PdsButton>
                    </div>
                </form>
            </main>
        </div>
    );
};

export default SignupLearner;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconRecord = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 15a3 3 0 0 0 3-3V6a3 3 0 1 0-6 0v6a3 3 0 0 0 3 3zm5.91-3a1 1 0 0 0-.98.85 5.02 5.02 0 0 1-9.86 0 1 1 0 0 0-.98-.85 1 1 0 0 0-1 1.14A7 7 0 0 0 11 18.92V21a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-2.08a7 7 0 0 0 5.91-5.78 1 1 0 0 0-1-1.14z" />
            </svg>
        );
    }
);

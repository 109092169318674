import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconOptionList = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M17 15a1 1 0 0 1 1 .88V16a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1 1 1 0 0 1 1-1h6zM7 15a1 1 0 0 1 1 .88V16a1 1 0 0 1-.88 1H7a1 1 0 0 1-.12-2H7zm10-4a1 1 0 0 1 1 .88V12a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1 1 1 0 0 1 1-1h6zM7 11a1 1 0 0 1 1 .88V12a1 1 0 0 1-.88 1H7a1 1 0 0 1-.12-2H7zm0-4a1 1 0 0 1 1 .88V8a1 1 0 0 1-.88 1H7a1 1 0 0 1-.12-2H7zm10 0a1 1 0 0 1 1 .88V8a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1 1 1 0 0 1 1-1h6z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconLayers = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M4.55 8.89l7 3.5a1 1 0 0 0 .9 0l7-3.5a1 1 0 0 0-.06-1.81l-6.99-3a1 1 0 0 0-.79 0l-6.99 3a1 1 0 0 0-.07 1.81zM19 14a1 1 0 0 0-.45.11L12 17.38 5.45 14.1A1 1 0 0 0 4 15a1 1 0 0 0 .55.89l7 3.5a1 1 0 0 0 .9 0l7-3.5A1 1 0 0 0 20 15a1 1 0 0 0-1-1zm0-3.5a1 1 0 0 0-.45.11L12 13.88l-6.55-3.27a1 1 0 1 0-.9 1.78l7 3.5a1 1 0 0 0 .9 0l7-3.5a1 1 0 0 0 .55-.89 1 1 0 0 0-1-1z" />
            </svg>
        );
    }
);

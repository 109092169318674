import type { PdsStepperProps } from "./type";

import { xor, range } from "lodash";
import { useCallback, useEffect, useState } from "react";

export type UsePdsStepperProps = Partial<Pick<PdsStepperProps, "activeStep" | "nonLinear">>;

/**
 * Hook to handle stepper active & completed step state management.
 * @param initialValues initial values to set non-linear mode, active & completed states.
 */
export const usePdsStepper = (initialValues: UsePdsStepperProps = {}) => {
    const { nonLinear = false, activeStep: activeStepProps = 0 } = {
        ...initialValues,
    };

    const [activeStep, setActiveStep] = useState(() => activeStepProps);
    const [completedSteps, setCompleted] = useState<number[]>([]);

    /**
     * Change handler to set active step.
     */
    const handleStepChange = useCallback((index: number) => setActiveStep(index), [setActiveStep]);

    /**
     * Trigger to proactively update completed steps.
     * Useful when stepper is non-linear.
     */
    const handleCompleteStep = useCallback(
        (index: number) => setCompleted(xor(completedSteps, [index])),
        [completedSteps]
    );

    /**
     * Effect handler to update state from props.
     */
    useEffect(() => setActiveStep(activeStepProps), [activeStepProps]);

    /**
     * Effect handler to update completed steps if linear.
     * All previous steps are marked as complete.
     */
    useEffect(() => {
        if (!nonLinear) {
            setCompleted(range(activeStep > 0 ? activeStep : 0));
        }
    }, [nonLinear, activeStep]);

    return {
        activeStep,
        completedSteps,
        handleStepChange,
        handleCompleteStep,
    };
};

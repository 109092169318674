import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconActivityTranlationList = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path
                    d="M5.25 3H16.5L21 7.5v11.25c0 1.24-1 2.25-2.25 2.25H5.25C4.01 21 3 20 3 18.75V5.25C3 4.01 4 3 5.25 3z"
                    fill="#3838ea"
                />
                <path
                    d="M10.5 6c.4 0 .72.3.75.7v.05h2.25c.41 0 .75.34.75.75 0 .4-.3.72-.7.75h-.8V9a3 3 0 01-1.12 2.34l-.25.2.77.58c.14-.09.3-.17.47-.24.45-.18 1-.27 1.68-.27.74 0 1.33.09 1.76.26.43.18.73.46.9.84.18.38.27.89.27 1.52v1.18l-.01.76c0 .32.05.65.16 1l.02.08.05.16.03.13.03.11.01.1.01.09c0 .17-.08.33-.24.48a.82.82 0 01-.56.21c-.17 0-.34-.08-.51-.25-.18-.16-.36-.4-.55-.7-.4.31-.8.55-1.19.71-.38.16-.81.24-1.3.24-.43 0-.8-.09-1.14-.26a1.84 1.84 0 01-1.03-1.66c0-.46.15-.86.44-1.19.27-.3.64-.5 1.1-.63l.2-.05 1.03-.2.42-.1.25-.06.12-.02 1-.27a1.77 1.77 0 00-.28-.98c-.16-.21-.49-.32-1-.32-.42 0-.75.06-.97.18-.21.12-.4.3-.56.54l-.14.22-.07.1-.07.1-.05.06c-.06.08-.2.11-.41.11a.72.72 0 01-.5-.18.6.6 0 01-.2-.47v-.08l-1.2-.9-2.17 1.63a.75.75 0 01-.45.15.75.75 0 01-.75-.75c0-.23.1-.41.26-.55l.05-.04-.01-.01 1.82-1.37-.17-.13a3 3 0 01-1.2-2.29V9H9c0 .47.21.89.55 1.16l.06.05.51.38.51-.38c.36-.26.6-.66.62-1.12v-.84h-4.5A.75.75 0 016 7.5c0-.4.3-.72.7-.75H9c0-.4.3-.72.7-.75h.8zm5.06 9.11l-.07.03c-.1.04-.24.07-.38.11l-.23.06-.26.06-.63.14-.35.08-.15.04a1.2 1.2 0 00-.52.28.72.72 0 00-.25.58c0 .26.1.47.29.66.2.18.45.27.76.27a2 2 0 00.94-.22c.28-.15.49-.34.62-.57.14-.24.22-.63.23-1.16v-.36z"
                    fill="#ffffff"
                />
            </svg>
        );
    }
);

import type { Context, ProviderProps } from "react";
import type { PdsSelectOption } from "./type";

import { createContext, useContext } from "react";

type PdsSelectContextProps<T extends unknown = string> = [
    state: {
        isOpen: boolean;
        activeIndex: number;
        selectListboxId: string;
        selected: PdsSelectOption<T>[];
        items: PdsSelectOption<T>[];
    },
    callbacks: {
        close: () => void;
        toggleOpen: () => void;
        resetSelect: () => void;
        toggleSelect: (value: PdsSelectOption<T> | PdsSelectOption<T>[]) => void;
        unselect: (value: PdsSelectOption<T> | PdsSelectOption<T>[]) => void;
        isEqual: (valueA: PdsSelectOption<T>, valueB: PdsSelectOption<T>) => boolean;
        setKeyword: (keyword: string) => void;
        setActiveIndex: (index: number | ((index: number) => number)) => void;
    }
];

export const PdsSelectContext = createContext<PdsSelectContextProps<string>>([
    {
        isOpen: false,
        activeIndex: 0,
        selectListboxId: "",
        selected: [],
        items: [],
    },
    {
        close: () => {},
        toggleOpen: () => {},
        toggleSelect: () => {},
        resetSelect: () => {},
        unselect: () => {},
        isEqual: () => false,
        setKeyword: () => {},
        setActiveIndex: () => {},
    },
]);

export const PdsSelectContextProvider = <T extends unknown = string>({
    value,
    children,
}: ProviderProps<PdsSelectContextProps<T>>) => (
    <PdsSelectContext.Provider value={value as unknown as PdsSelectContextProps<string>}>
        {children}
    </PdsSelectContext.Provider>
);

export const usePdsSelectContext = <T extends unknown = string>() => {
    return useContext(PdsSelectContext as unknown as Context<PdsSelectContextProps<T>>);
};

import type { ElementType } from "react";
import type { PdsLabelProps, PdsHelpTextProps } from "./type";

import { tx, polymorphComponent } from "@/libs";

/**
 * `PdsLabel` renders a <label> element (by default) that represents a caption for an item in a user interface.
 * It ideally should contain short and clear text.
 *
 * [Use-case scenarios](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label) for the <label> element.
 */
export const PdsLabel = polymorphComponent<PdsLabelProps, ElementType, "label">(
    "label",
    ({ required = false, disabled = false, className, ...props }: PdsLabelProps) => ({
        disabled,
        className: tx(
            "inline-flex text-current text-sm font-medium cursor-pointer",
            {
                "cursor-not-allowed": disabled,
                "after:content-['*'] after:text-red-600 after:ps-0.5": required,
            },
            className
        ),
        ...props,
    })
);

/**
 * `PdsHelpText` renders a label that represents the accompanying help text on a form element.
 */
export const PdsHelpText = polymorphComponent<PdsHelpTextProps, ElementType, "label">(
    "label",
    ({ className, error, ...props }) => ({
        role: error ? "alert" : undefined,
        className: tx("inline-flex text-sm font-normal text-neutral-600", { "text-danger": error }, className),
        ...props,
    })
);

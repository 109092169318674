import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { every, filter, isArray, isEmpty, isNumber, map, toString, uniqueId } from "lodash";
import { ValidationState } from "@react-types/shared/src/inputs";
import { PdsSelectOption } from "@educationperfect/web-pds-react";

export type Validator = (value: string | number | string[] | PdsSelectOption[]) => string | false;

const emailRegExp = /\S+@\S+\.\S+/;
const nonAlphanumericRegExp = /[\W_]+/;

/**
 * Shows error message if value is empty
 */
export const isPresent =
    (msg = "A value is required") =>
    (input: string | number | string[] | PdsSelectOption[] | undefined) =>
        !isEmpty(isArray(input) ? input : toString(input)) ? false : msg;

export const isEmail =
    (msg = "Must be a valid email address") =>
    (input: string | number | string[] | PdsSelectOption[] | undefined) => {
        if (isArray(input)) {
            return false;
        }
        return emailRegExp.test(toString(input)) ? false : msg;
    };

export const isAlphabetical =
    (msg = "Must not contain any non-alphanumeric characters") =>
    (input: string | number | string[] | PdsSelectOption[]) => {
        if (isArray(input)) {
            return false;
        }
        return nonAlphanumericRegExp.test(toString(input)) ? msg : false;
    };

export const minLength = (min: number, msg = `Value must be at least ${min} characters`) => {
    return (input: string | number | string[]) => {
        switch (true) {
            case isArray(input):
                return false;
            case undefined:
            case isNumber(input):
                return input < min ? msg : false;
            default:
                return (input as string).length < min ? msg : false;
        }
    };
};

export const maxLength = (max: number, msg = `Value must be at most ${max} characters`) => {
    return (input: string | number | string[]) => {
        switch (true) {
            case isArray(input):
                return false;
            case isNumber(input):
                return input > max ? msg : false;
            default:
                return (input as string).length > max ? msg : false;
        }
    };
};

export type FormControlRef = {
    id: RefObject<string>;
    validate: () => boolean;
};

export type Form = {
    register: (control: FormControlRef) => void;
    unregister: (id?: RefObject<string>) => void;
    force: boolean;
    isValid: () => boolean;
};

export function doValidate(value: string | number | string[] | PdsSelectOption[], validators?: Validator[]): string[] {
    return filter(
        map(validators, (v) => v(value)),
        (v) => v !== false
    ) as string[];
}

export function useForm(): Form {
    const controls = useRef<{ [id: string]: FormControlRef }>({});
    const [force, setForce] = useState<boolean>(false);

    const register = useCallback((control: FormControlRef) => {
        controls.current[control.id.current!] = control;
    }, []);

    const unregister = useCallback((id?: RefObject<string>) => {
        if (!id?.current) return;
        delete controls.current[id.current];
    }, []);

    const isValid = useCallback(() => {
        setForce(true);
        return every(controls.current, (c) => c.validate());
    }, []);

    return {
        register,
        unregister,
        force,
        isValid,
    };
}

export function useFormControl(
    form: Form,
    value: string | number | string[] | PdsSelectOption[],
    validators?: Validator[]
) {
    const id = useRef(uniqueId());
    const [errors, setErrors] = useState<string[]>([]);
    const [touched, setTouched] = useState(false);

    const isValid = useMemo(() => !errors.length, [errors]);
    const state = useMemo<ValidationState>(() => (isValid ? "valid" : "invalid"), [isValid]);

    const validate = useCallback(() => setErrors(doValidate(value, validators!)), [value, validators]);

    useEffect(() => {
        if (touched || form.force) {
            validate();
        }
    }, [touched, validate, value, form.force]);

    const handleBlur = useCallback(() => {
        setTouched(true);
        validate();
    }, [validate]);

    const handleTouched = useCallback(() => setTouched(true), []);

    return {
        id,
        errors,
        state,
        isValid,
        handleBlur,
        handleTouched,
    };
}

import React, { FC } from "react";
import { PdsButton, PdsChoiceGroup, PdsSelectOption } from "@educationperfect/web-pds-react";
import LightBulb from "@assets/images/light-bulb.svg";
import { payerIntentTypeData, payerTypeData, usePayerContext } from "@common/context/payer";
import { Form, isAlphabetical, isPresent } from "@components/common/form";
import Input from "@components/common/form/input";
import Select from "@components/common/form/select";
import ChoiceCardGroup from "@components/common/form/choicegroup";
import ScrollOnRenderWrapper from "@components/wrappers/scroll-on-render";

interface ISignupPayerForm {
    form: Form;
    onCountryChange: (values: PdsSelectOption[]) => void;
    onPhoneChange: (value: string) => void;
    onTypeChange: (value: string[]) => void;
    onIntentChange: (value: string[]) => void;
    onChange: (field: string) => (value: string | PdsSelectOption[]) => void;
    onSubmit: (e: any) => Promise<void>;
    isSubmitting: boolean;
}

/**
 * Parent details form
 * @constructor
 */
const FormPayer: FC<ISignupPayerForm> = ({
    form,
    onCountryChange,
    onTypeChange,
    onIntentChange,
    onPhoneChange,
    onChange,
    onSubmit,
    isSubmitting,
}) => {
    const { payer, states, countries } = usePayerContext();

    const MainCountriesOptionGroup = (countries ?? []).map(({ value, label }) => (
        <Select.Item value={value} label={label} key={value} />
    ));

    const StatesSelectOptions = (states ?? []).map(({ value, label }) => (
        <Select.Item value={value} label={label} key={value} />
    ));

    const PayerTypeChoiceOptions = payerTypeData.map(({ label, ariaLabel, value }) => (
        <PdsChoiceGroup.RadioCard
            key={label}
            className="!w-full rounded-sm"
            label={label}
            aria-label={ariaLabel}
            value={value}
        />
    ));

    const PayerIntentChoiceOptions = payerIntentTypeData.map(({ label, ariaLabel, value, caption }) => (
        <PdsChoiceGroup.RadioCard
            key={label}
            className="!w-full rounded-sm"
            label={label}
            aria-label={ariaLabel}
            value={value}
            helpText={caption}
        />
    ));

    return (
        <form className="w-full" onSubmit={onSubmit} noValidate>
            <div className="mt-2">
                <div className="sm:flex sm:justify-between">
                    <div>
                        <div className="mt-2 flex flex-col gap-4">
                            <div className="w-full sm:w-80">
                                <label>
                                    <Input
                                        value={payer.firstName ?? ""}
                                        form={form}
                                        placeholder=""
                                        label="First name"
                                        inputMode="text"
                                        type="text"
                                        onChange={onChange("firstName")}
                                        name="firstName"
                                        validators={[isPresent(), isAlphabetical()]}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="w-full sm:w-80">
                                <label>
                                    <Input
                                        value={payer.lastName ?? ""}
                                        form={form}
                                        placeholder=""
                                        label="Last name"
                                        inputMode="text"
                                        type="text"
                                        onChange={onChange("lastName")}
                                        name="lastName"
                                        validators={[isPresent(), isAlphabetical()]}
                                        required
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="mt-14">
                            <div className="w-full sm:w-80">
                                <Select
                                    label="Country"
                                    placeholder="Select a country"
                                    onChange={onCountryChange}
                                    selected={payer.country ?? []}
                                    validators={[isPresent()]}
                                    form={form}
                                    required
                                >
                                    {MainCountriesOptionGroup}
                                </Select>
                            </div>
                            <div className="flex mt-3">
                                <div className="w-full sm:w-80">
                                    <Select
                                        label="State"
                                        placeholder="Select a state"
                                        onChange={onChange("state")}
                                        selected={payer.state ?? []}
                                        validators={[isPresent()]}
                                        form={form}
                                        required
                                    >
                                        {StatesSelectOptions}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="mt-14 font-medium mb-1 text-sm leading-5">
                            Phone number (optional)
                            <div className="w-full sm:w-80 flex flex-row mt-1">
                                <label className="w-1/4">
                                    <Input
                                        value={payer.dialCode ?? ""}
                                        form={form}
                                        placeholder=""
                                        label=""
                                        inputMode="text"
                                        type="text"
                                        name="dialCode"
                                        disabled
                                    />
                                </label>
                                <label className="ml-4 w-full">
                                    <Input
                                        value={payer.phoneNumber ?? ""}
                                        form={form}
                                        placeholder=""
                                        label=""
                                        inputMode="numeric"
                                        type="text"
                                        onChange={onPhoneChange}
                                        name="phoneNumber"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <img className="hidden sm:block sm:mt-16 sm:w-[169px] self-start" src={LightBulb} alt="Artboard" />
                </div>

                <div className="w-full flex flex-col mt-8 sm:mt-12 lg:w-[660px]">
                    <ChoiceCardGroup
                        label="I am a? "
                        name="Parent Type"
                        orientation="horizontal"
                        form={form}
                        value={payer.type ? [payer.type] : []}
                        validators={[isPresent()]}
                        className="w-full sm:grid sm:grid-cols-2 gap-x-4"
                        onChange={onTypeChange}
                    >
                        {PayerTypeChoiceOptions}
                    </ChoiceCardGroup>

                    {payer.type === "parent" && (
                        <ScrollOnRenderWrapper>
                            <div className="mt-2 sm:mt-14">
                                <ChoiceCardGroup
                                    label="What best describes your needs? "
                                    name="Intent Type"
                                    orientation="horizontal"
                                    form={form}
                                    value={payer.intent ? [payer.intent] : []}
                                    className="w-full sm:grid sm:grid-cols-2 gap-x-4"
                                    validators={payer.type === "parent" ? [isPresent()] : []}
                                    onChange={onIntentChange}
                                >
                                    {PayerIntentChoiceOptions}
                                </ChoiceCardGroup>
                            </div>
                        </ScrollOnRenderWrapper>
                    )}
                </div>
            </div>
            <div className="mb-4 sm:mb-8 mt-4 sm:mt-8 w-full flex flex-col-reverse sm:flex-row gap-5 sm:gap-0 justify-end">
                <PdsButton
                    id="continue-to-learner-setup-btn"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    loadingAriaLabel="Verifying payer's info..."
                    className="w-full sm:w-[210px] lg:mr-14"
                    aria-label="Next"
                >
                    Next
                </PdsButton>
            </div>
        </form>
    );
};

export default FormPayer;

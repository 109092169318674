import type { CompoundPolymorphicElement } from "@/typings/utilities";
import type { PdsDropdownProps } from "./type";

import { PdsDropdownItem } from "./DropdownItem";
import { PdsDropdownTrigger } from "./DropdownTrigger";
import { PdsDropdownSection } from "./DropdownSection";
import { PdsDropdownSubmenu } from "./DropdownSubmenu";
import { PdsDropdown as PdsDropdownComponent } from "./Dropdown";

export type PdsDropdownNamespace = {
    Item: typeof PdsDropdownItem;
    Trigger: typeof PdsDropdownTrigger;
    Section: typeof PdsDropdownSection;
    Submenu: typeof PdsDropdownSubmenu;
};

/**
 * Compound components are assigned here to avoid cyclic imports between PdsDropdown & PdsDropdownSubmenu.
 */
export const PdsDropdown = PdsDropdownComponent as unknown as CompoundPolymorphicElement<
    PdsDropdownNamespace,
    PdsDropdownProps
>;

PdsDropdown.Item = PdsDropdownItem;
PdsDropdown.Trigger = PdsDropdownTrigger;
PdsDropdown.Section = PdsDropdownSection;
PdsDropdown.Submenu = PdsDropdownSubmenu;

export * from "./type";

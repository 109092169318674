import type { UsePdsPopoverProps, UsePdsPopoverTriggerProps } from "./type";

import { nanoid } from "nanoid";
import { useMemo, useState } from "react";

import { useBoolean } from "@/hooks";

/**
 * `usePdsPopoverTrigger` hook is provided as a hook to provide a11y features of a popover trigger.
 *
 * `usePdsPopoverTrigger` provides:
 * - [aria-haspopup](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-haspopup) to assistively display the element as related to a popup.
 * - [aria-controls](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-controls) to assistvely set
 *  the element to be the trigger of the popover region.
 * - [aria-expanded](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-expanded) to assistively
 * display whether its control is expanded or collapsed.
 */
export const usePdsPopoverTrigger = ({
    id,
    open = false,
    disabled = false,
    role = "dialog",
}: UsePdsPopoverTriggerProps) => {
    const triggerProps = {
        disabled,
        "aria-haspopup": role,
        "aria-controls": id,
        "aria-expanded": open,
    };

    return { triggerProps };
};

/**
 * `usePdsPopover` hook is provided as a hook to encapsulate the state & behaviour logic of a Popover.
 *
 * `usePdsPopover` exposes:
 * - the current open/close state of the popover.
 * - the proper attributes, ref, and click handler for trigger
 * - the proper props for the popover panel
 */
export const usePdsPopover = ({ id, open: openProps, disabled, role }: UsePdsPopoverProps) => {
    /** Ref handling hook. */
    const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);

    /**
     * Memo to prefix id.
     */
    const popoverId = useMemo(() => id ?? `pds-popover-${nanoid()}`, [id]);

    /**
     * Open/closed state hook.
     */
    const [isOpen, { open, close, toggle }] = useBoolean({
        isDisabled: disabled,
    });

    const { triggerProps } = usePdsPopoverTrigger({
        id: popoverId,
        open: openProps,
        disabled,
        role,
    });

    return {
        triggerProps: {
            ref: setTriggerRef,
            onClick: toggle,
            ...triggerProps,
        },
        popoverProps: {
            id: popoverId,
            trigger: triggerRef,
            open: isOpen,
            onOpen: open,
            onClose: close,
        },
    };
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconActivityFactList = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path
                    d="M5.25 3H16.5L21 7.5v11.25c0 1.24-1 2.25-2.25 2.25H5.25C4.01 21 3 20 3 18.75V5.25C3 4.01 4 3 5.25 3z"
                    fill="#a145db"
                />
                <path
                    d="M8.25 14.63c.38 0 .7.27.74.64l.01.1c0 .38-.28.7-.65.75h-.1a.75.75 0 01-.1-1.49zm7.5 0a.75.75 0 110 1.5h-4.5a.75.75 0 110-1.5zm-7.5-3c.38 0 .7.27.74.64l.01.1c0 .38-.28.7-.65.75h-.1a.75.75 0 01-.1-1.49zm7.5 0a.75.75 0 110 1.5h-4.5a.75.75 0 010-1.5zm-7.5-3c.38 0 .7.27.74.64l.01.1c0 .38-.28.7-.65.75h-.1a.75.75 0 01-.1-1.49zm7.5 0a.75.75 0 110 1.5h-4.5a.75.75 0 010-1.5z"
                    fill="#ffffff"
                />
            </svg>
        );
    }
);

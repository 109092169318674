import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconKey = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M15 4a5 5 0 0 0-4.17 7.75l-6.54 6.54a1 1 0 0 0 1.42 1.42L7 18.41l1.29 1.29a1 1 0 0 0 1.42.01l2-2A1 1 0 0 0 12 17a1 1 0 0 0-.29-.71L10.41 15l1.83-1.83A5 5 0 1 0 15 4zm0 8c-.23 0-.45-.03-.66-.08l-.03-.01a3.01 3.01 0 0 1-2.22-2.21l-.01-.03A3 3 0 1 1 18 9a3 3 0 0 1-3 3z" />
            </svg>
        );
    }
);

import type { ChangeEvent, ForwardedRef, ReactElement } from "react";
import type { PdsDropdownSelectProps } from "./type";

import { useState, forwardRef, cloneElement, Children, useEffect } from "react";
import { mergeProps } from "@react-aria/utils";

import { tx, typeOfComponent, isKeywordMatch, ex } from "@/libs";
import { useMultiSelect } from "@/hooks";
import { PdsDropdown } from "@/components/dropdown";

import { PdsDropdownSelectContextProvider } from "./context";
import { PdsDropdownSelectItem } from "./DropdownSelectItem";
import { PdsDropdownSelectSearchInput } from "./DropdownSelectSearchInput";

/**
 * `PdsDropdownSelect` displays a customised dropdown where users can select & search among various number of options.
 *
 * `PdsDropdownSelect` is an official customised use case of the `PdsDropdown` that includes multi-select using checkbox,
 * filter item via an input, & and grouping using sections. `PdsDropdownSelect` comprises of:
 *
 * - `PdsDropdownSelect.Item`: checkbox menu item that can be used to toggle a selected value.
 * - `PdsDropdownSelect.Section`: a group of checkbox menu items that toggles all of its children items.
 * - `PdsDropdownSelect.SearchInput`: a text input that will trigger a filter of the option list based on the keyword entered.
 * - `PdsDropdownSelect.Trigger`: is a button by default.
 * - `PdsDropdownSelect.TriggerChip`: is a `PdsChip` by default.
 *
 * `PdsDropdownSelect` comes with these all of `PdsDropdown`'s keyboard interactions & accessibility attributes, with the
 * added properties of:
 *
 * - `PdsDropdownSelect` stays persistent after click by default via `disableDismissByClickInside=true`.
 * - `PdsDropdownSelect.Item` does not allow customisation of Leading & Label subcomponent like `PdsDropdown.Item`.
 * - `PdsDropdownSelect.Item` have required `value` & `label` props to ensure they can be used multi-select values.
 * - `PdsDropdownSelect.Section` toggles the checked & disabled state of all of its children items.
 * - If not all of its items are checked, `PdsDropdownSelect.Section` marked as indeterminate.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=2706%3A8566).
 */
export const PdsDropdownSelect = forwardRef(
    <T extends unknown = string>(
        {
            items = [],
            values = [],
            filterable = false,
            disableDismissByClickInside = true,
            searchInputAriaLabel = "Search...",
            className,
            children: childrenProps,
            predicate,
            onChange,
            ...props
        }: PdsDropdownSelectProps<T>,
        ref: ForwardedRef<HTMLUListElement>
    ) => {
        /**
         * Filter keyword & select value state management hook.
         */
        const [keyword, setKeyword] = useState("");
        const [selected, { toggle, select, unselect, reset, isEqual }] = useMultiSelect<T>({
            initialValues: values,
            isEqual: predicate,
            onChange,
        });

        /**
         * Side effect to trigger on change event handler.
         */
        useEffect(() => onChange?.(selected), [onChange, selected]);

        /**
         * PdsDropdownSelect-specific section, item, and search input prop injection.
         * All sections & items that does not match its label with the keyword is filtered out.
         */
        let hasSearchInput = false;
        let children = Children.toArray(childrenProps).map((child) => {
            const el = child as ReactElement;
            const elType = typeOfComponent(el);

            /** Filter all items within a section, fallback to the section label itself if none found. */
            if (elType === "PdsDropdownSelectSection") {
                const sectionChildren = Children.toArray(el.props?.children);
                const filteredItems = sectionChildren.filter((item) =>
                    isKeywordMatch(keyword, (item as ReactElement).props?.label)
                );

                return filteredItems.length > 0
                    ? cloneElement(el, {}, filteredItems)
                    : isKeywordMatch(keyword, el.props?.label) && el;
            }

            if (elType === "PdsDropdownSelectSearchInput") {
                hasSearchInput = true;
                return cloneElement(
                    el,
                    mergeProps(el.props, {
                        onChange: ex(el.props.onChange, (e: ChangeEvent<HTMLInputElement>) =>
                            setKeyword(e.target.value)
                        ),
                        "aria-label": searchInputAriaLabel,
                    })
                );
            }

            if (elType === "PdsDropdownSelectItem" && !isKeywordMatch(keyword, el.props?.label)) {
                return null;
            }

            return el;
        });

        /**
         * Fallback to shorthand props if no items are found.
         * Shorthand props by design are very limited, and only allow direct render of a text-only list of items.
         */
        if (children.length === 0) {
            children = (items ?? [])
                .filter(({ label }) => isKeywordMatch(keyword, label))
                .map(({ label, ...itemProps }) => <PdsDropdownSelectItem key={label} label={label} {...itemProps} />);
        }

        /**
         * Insert a search input to the top if filterable & no search input subcomponent is present.
         */
        if (filterable && !hasSearchInput) {
            children.unshift(
                <PdsDropdownSelectSearchInput
                    onChange={(e) => setKeyword(e.target.value)}
                    aria-label={searchInputAriaLabel}
                />
            );
        }

        return (
            <PdsDropdownSelectContextProvider<T> value={[selected, { toggle, select, unselect, reset, isEqual }]}>
                <PdsDropdown
                    ref={ref}
                    as="ul"
                    role="listbox"
                    aria-multiselectable="true"
                    className={tx("min-w-64", className)}
                    disableDismissByClickInside={disableDismissByClickInside}
                    {...props}
                >
                    {children}
                </PdsDropdown>
            </PdsDropdownSelectContextProvider>
        );
    }
) as <T extends unknown = string>(
    props: PdsDropdownSelectProps<T> & { ref?: ForwardedRef<HTMLUListElement> }
) => ReactElement | null;

import type { ForwardedRef, PropsWithRef, ReactElement } from "react";
import type { PdsSelectItemProps } from "./type";

import { useCallback, useMemo, forwardRef } from "react";

import { ex, tx, getChildByType, removeChildrenByType } from "@/libs";
import { PdsIconCheck } from "@/components/icons";
import { PdsDropdown } from "@/components/dropdown";

import { usePdsSelectContext } from "./context";

type PdsSelectItemNamespace = {
    Leading: typeof PdsDropdown.Item.Leading;
    Label: typeof PdsDropdown.Item.Label;
};

type PdsSelectItemComponent = PdsSelectItemNamespace &
    (<T extends unknown = string>(props: PropsWithRef<PdsSelectItemProps<T>>) => ReactElement | null);

export const PdsSelectItem = forwardRef(
    <T extends unknown = string>(
        {
            id,
            value,
            label,
            index = 0,
            selected: selectedProps = false,
            onClick,
            onMouseMove,
            children,
            ...props
        }: PdsSelectItemProps<T>,
        ref: ForwardedRef<HTMLLIElement>
    ) => {
        /**
         * Get selected values array from context.
         */
        const [
            { items, activeIndex, selected: selectedItems, selectListboxId },
            { toggleSelect, isEqual, setActiveIndex },
        ] = usePdsSelectContext<T>();

        const selectItemId = useMemo(() => id ?? `${selectListboxId}-item-${value}`, [id, selectListboxId, value]);
        const selected = useMemo(
            () => selectedProps || selectedItems.some((item) => isEqual(item, { value, label })),
            [selectedProps, label, value, selectedItems, isEqual]
        );

        /**
         * Any leading subcomponent that is not the default checkmark needs to handle its own selected state. */
        const leadingEl = getChildByType(children, "PdsDropdownItemLeading") ?? (
            <PdsDropdown.Item.Leading
                as={PdsIconCheck}
                aria-hidden="true"
                className={tx("opacity-0 transition-opacity duration-200 motion-reduce:transition-none", {
                    "opacity-100": selected,
                })}
            />
        );

        const labelEl = getChildByType(children, "PdsDropdownItemLabel") ?? (
            <PdsDropdown.Item.Label>{label}</PdsDropdown.Item.Label>
        );

        const remainingChildren = removeChildrenByType(children, ["PdsDropdownItemLeading", "PdsDropdownItemLabel"]);

        /**
         * Event handler for hovering & clicking on the item.
         */
        const handleSelectItemMouseMove = useCallback(() => {
            if (index !== undefined) {
                setActiveIndex(index);
            }
        }, [setActiveIndex, index]);

        const handleSelectItemClick = useCallback(() => toggleSelect({ label, value }), [label, value, toggleSelect]);

        return (
            <PdsDropdown.Item
                as="li"
                role="option"
                ref={ref}
                id={selectItemId}
                active={value === items[activeIndex]?.value}
                aria-posinset={index}
                aria-setsize={items.length}
                aria-selected={selected ? "true" : "false"}
                className={tx("transition-colors duration-200 motion-reduce:transition-none", {
                    "font-semibold text-primary": selected,
                })}
                onClick={ex(handleSelectItemClick, onClick)}
                onMouseMove={ex(handleSelectItemMouseMove, onMouseMove)}
                {...props}
            >
                {leadingEl}
                {labelEl}
                {remainingChildren}
            </PdsDropdown.Item>
        );
    }
) as unknown as PdsSelectItemComponent;

PdsSelectItem.Leading = PdsDropdown.Item.Leading;
PdsSelectItem.Label = PdsDropdown.Item.Label;

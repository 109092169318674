import { useEffect, useRef } from "react";

/**
 * Get previous value (prevProps) in functional component
 *
 * @param {any} value
 *
 * @return {any}
 */
const usePrevious = (value: any): any => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export default usePrevious;

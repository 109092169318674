import React, { FC } from "react";
import { PdsButton } from "@educationperfect/web-pds-react";

interface IButtonLink {
    link: string;
    title: string;
    className: string;
}

const ButtonLink: FC<IButtonLink> = ({ title, link, className }) => {
    const onClick = () => window.open(link, "_blank", "noreferrer");

    return (
        <PdsButton onClick={onClick} className={className} type="button" size="small" variant="secondary">
            {title}
        </PdsButton>
    );
};

export default ButtonLink;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconPulse = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19 12h-1.46l-1.7-2.55-.02.01A.98.98 0 0 0 15 9a.99.99 0 0 0-.93.65h-.01l-1.69 4.51-1.38-8.32h-.02a.99.99 0 0 0-1.89-.23L6.34 12H5a1 1 0 0 0-1 1 1 1 0 0 0 1 1h2a1 1 0 0 0 .92-.61l1.65-3.86 1.44 8.63h.02c.08.47.47.84.97.84.43 0 .79-.27.93-.65h.01l2.31-6.17.92 1.38.02-.01c.17.26.46.45.81.45h2a1 1 0 0 0 1-1 1 1 0 0 0-1-1z" />
            </svg>
        );
    }
);

import type { AriaProgressBarProps } from "@react-types/progress";

import tw from "twin.macro";
import styled from "styled-components";
import { useProgressBar } from "@react-aria/progress";

import pdsColors from "../../styles/colors/pdsColors";

export type RingTypes = "neutral" | "positive" | "warning" | "error";
export type RingSizes = "regular" | "large";

export type ProgressRingProps = {
    /** The type of the ring. */
    type?: RingTypes;
    /** The size of the ring. */
    size?: RingSizes;
    /** A percentage value shows how much progress is completed. */
    value: number;
    /** The label text is **always** required for accessibility purposes */
    "aria-label": string;
    /** Users can add spacing/sizing and screen-width specific but cannot modify the internal component desgin(color variant, font sizes, …). */
    className?: string;
} & Omit<AriaProgressBarProps, "value" | "aria-label">;

export const defaultOptions = {
    backgroundColor: pdsColors.neutral[300],
    strokeWidth: 12,
    radiusCircle: 54,
};

const getSizeStyle = (size: RingSizes) => {
    switch (size) {
        case "large":
            return tw`w-8 h-8`;
        default:
            return tw`w-5 h-5`;
    }
};

const getTypeStyle = (type: RingTypes) => {
    switch (type) {
        case "neutral":
            return pdsColors.neutral[1000];
        case "positive":
            return pdsColors.green[700];
        case "warning":
            return pdsColors.gold[500];
        case "error":
            return pdsColors.red[700];
        default:
            return defaultOptions.backgroundColor;
    }
};

const StyledSvg = styled.svg<{ size: RingSizes }>`
    ${(props) => getSizeStyle(props.size)};
`;

const StyledCircle = styled.circle<{ isForceground: boolean }>`
    transform: ${(props) => (props.isForceground ? "rotate(-90deg)" : "rotate(0deg)")};
`;

export const PdsProgressRing = ({
    value = 0,
    type = "positive",
    size = "regular",
    className,
    ...props
}: ProgressRingProps) => {
    const { progressBarProps, labelProps } = useProgressBar({
        value,
        ...props,
    });
    const strokeCircle = 2 * Math.PI * defaultOptions.radiusCircle;
    const strokeCircleOffset = strokeCircle * ((100 - value) / 100);

    return (
        <div {...progressBarProps} {...labelProps} className={className}>
            <StyledSvg size={size} viewBox="0 0 120 120">
                <StyledCircle
                    role="presentation"
                    r={defaultOptions.radiusCircle}
                    cx={60}
                    cy={60}
                    fill="none"
                    stroke={defaultOptions.backgroundColor}
                    strokeWidth={defaultOptions.strokeWidth}
                    isForceground={false}
                />
                <StyledCircle
                    role="presentation"
                    r={defaultOptions.radiusCircle}
                    cx={-60}
                    cy={60}
                    fill="none"
                    stroke={getTypeStyle(type)}
                    strokeWidth={defaultOptions.strokeWidth}
                    strokeDasharray={strokeCircle}
                    strokeDashoffset={strokeCircleOffset}
                    isForceground
                />
            </StyledSvg>
        </div>
    );
};

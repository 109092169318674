import React, { useEffect, useState } from "react";
import SwiperComponent from "@components/swiper";
import { slidesData, termsOfService, termsAndConditionsConsumer, privacyPolicy } from "@pages/signup/step-email/data";
import AlertContactSupport from "@components/alerts/contact-support";
import { storeReferralRockRef, storePartnerizeRef, storeParams } from "@pages/signup/step-email/views/store-helpers";
import EmailForm from "@pages/signup/step-email/views/enter-email/form";
import TrialPeriod from "@components/common/trial-period";

const EnterEmail = () => {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        storeParams();
        storePartnerizeRef();
        storeReferralRockRef();
    }, []);

    return (
        <>
            <TrialPeriod className="text-xs text-center mb-4 sm:mt-3 lg:mt-4 leading-4 font-normal sm:text-base sm:leading-6 sm:font-regular text-neutral-900" />

            <div className="max-w-[320px] sm:hidden">
                <SwiperComponent slides={slidesData} />
            </div>

            {isError && <AlertContactSupport />}

            <EmailForm setIsError={setIsError} />

            <div className="mt-8 sm:mt-28 flex-col px-4 text-center text-xs leading-4 font-normal text-neutral-600">
                By creating an account, you acknowledge that you have read and agree to our
                <a
                    href={termsAndConditionsConsumer}
                    target="_blank"
                    className="block sm:inline sm:pl-1 text-blue-700 font-medium"
                    rel="noreferrer"
                >
                    Terms and Conditions,
                </a>
                <a
                    href={termsOfService}
                    target="_blank"
                    className="block sm:inline sm:pl-1 text-blue-700 font-medium"
                    rel="noreferrer"
                >
                    Terms of Use,
                </a>
                <a
                    href={privacyPolicy}
                    target="_blank"
                    className="block sm:inline sm:pl-1 text-blue-700 font-medium"
                    rel="noreferrer"
                >
                    Privacy Policy.
                </a>
            </div>
        </>
    );
};

export default EnterEmail;

import type { ElementType, MouseEventHandler, ReactElement, Ref } from "react";
import type { PdsIconProps } from "@/components/icons";
import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsStepperProps, PdsStepProps, PdsStepIconProps, PdsStepLabelProps } from "./type";
import type { PdsIcon } from "@/components/icons/iconCommon";

import { useHover } from "@react-aria/interactions";
import { cloneElement, useCallback, forwardRef, Children } from "react";

import { tx, removeChildrenByType, getChildByType, polymorphComponent } from "@/libs";

import { useStepperContext } from "./context";

const PdsStepWrapper = polymorphComponent<{ disabled?: boolean }>("li");

const PdsStepConnector = ({
    orientation,
    completed,
}: Pick<PdsStepProps, "completed"> & Pick<PdsStepperProps, "orientation">) => (
    <div
        className={tx("flex-1 justify-self-center bg-neutral-400 transition-colors duration-300 ease-in", {
            "bg-primary": completed,
            "h-0.5 w-full": orientation === "horizontal",
            "h-full min-h-12 w-0.5": orientation === "vertical",
        })}
    />
);

/**
 * `PdsStep.Icon` is a reserved slot with default wrapper around a PdsIcon.
 *
 * It is styled as a decorative & medium-sized `PdsIcon` by default.
 */
export const PdsStepIcon = polymorphComponent<PdsStepIconProps, ElementType, typeof PdsIcon>(
    ({ disabled, active, completed, isHovered }: PdsStepIconProps & PdsIconProps) => (
        <span
            className={tx(
                "flex-shrink-0 self-center justify-self-center rounded-full transition-all duration-300 ease-out",
                "h-3 w-3 border-2 border-neutral-400",
                {
                    "border-2 border-neutral-600": isHovered,
                    "h-5 w-5 border-4 border-primary": active,
                    "border-0 bg-primary": completed,
                    "border-2 border-neutral-200": disabled,
                }
            )}
        />
    ),
    ({ as, className, disabled, ...props }) => ({
        as,
        "aria-hidden": true,
        className: tx(
            "flex-shrink-0 self-center justify-self-center rounded-sm bg-theme-200 w-12 h-8 py-1 px-auto",
            "transition-all duration-300 ease-out",
            className
        ),
        ...props,
    })
);

/**
 * `PdsStep.Label` is a reserved slot to customise label of each step.
 */
export const PdsStepLabel = polymorphComponent<PdsStepLabelProps, ElementType, "p">(
    "p",
    ({ disabled = false, active = false, className, children, ...props }) => ({
        className: tx(
            "row-span-2 mx-0.5 transition-colors duration-300 ease-out",
            {
                "font-semibold": !disabled && active,
                "text-neutral-500": disabled,
                "px-1": Children.toArray(children).length > 0,
            },
            className
        ),
        children,
        ...props,
    })
);

/**
 * `PdsStep` displays the single step inside a `PdsStepper`.
 * `PdsStep` uses [aria-current="step"](https://www.w3.org/TR/wai-aria-1.1/#aria-current)
 * to assistively display as being active.
 * `PdsStep` uses [data-status](https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes)
 * to assistively display as being completed.
 */
export const PdsStep = forwardRef<HTMLLIElement | HTMLButtonElement, PdsStepProps>(
    (
        {
            index = 0,
            tabIndex = 0,
            disabled = false,
            active: activeProps = false,
            completed: completedProps = false,
            label,
            className,
            children,
            onClick,
            ...props
        }: PdsStepProps,
        ref
    ) => {
        const {
            length = 0,
            activeStep = 0,
            completedSteps = [],
            nonLinear = false,
            orientation = "horizontal",
            handleStepChange,
        } = useStepperContext();
        const isUninteractive = disabled || !nonLinear;
        const { isHovered, hoverProps } = useHover({ isDisabled: isUninteractive });

        const active = activeProps || index === activeStep;
        const completed = completedProps || completedSteps?.includes(index);
        const last = index >= length - 1;

        const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
            (e) => {
                if (isUninteractive) {
                    return;
                }

                if (handleStepChange) {
                    handleStepChange(index);
                }

                onClick?.(e);
            },
            [isUninteractive, handleStepChange, onClick, index]
        );

        const iconSubcomponent = getChildByType(children, "PdsStepIcon") as ReactElement;
        const labelSubComponent = getChildByType(children, "PdsStepLabel") as ReactElement;
        const remainingChildren = removeChildrenByType(children, ["PdsStepLabel", "PdsStepIcon"]);

        const iconEl = iconSubcomponent ? (
            cloneElement(iconSubcomponent, { isHovered, disabled, active, completed })
        ) : (
            <PdsStepIcon isHovered={isHovered} disabled={disabled} active={active} completed={completed} />
        );

        const labelEl = labelSubComponent ? (
            cloneElement(labelSubComponent, { disabled, active })
        ) : (
            <PdsStepLabel disabled={disabled} active={active}>
                {/** Fallback to render children if no subcomponent or label props provided. */}
                {label ?? remainingChildren}
            </PdsStepLabel>
        );

        return (
            <PdsStepWrapper
                as={nonLinear ? "button" : "li"}
                ref={ref as Ref<HTMLLIElement>}
                tabIndex={tabIndex}
                disabled={disabled}
                data-status={completed ? "complete" : undefined}
                aria-current={active ? "step" : undefined}
                onClick={handleClick}
                className={tx(
                    "min-h-5 flex-1 grid-cols-[1.25rem_auto] grid-rows-[1.25rem_auto] text-start",
                    {
                        "grid gap-1": orientation === "vertical",
                        "flex h-full w-full items-center space-s-1 last:max-w-[fit-content]":
                            orientation === "horizontal",
                        "cursor-not-allowed": disabled,
                        "grid-cols-[2rem_auto] grid-rows-[3rem_auto]": iconEl.props?.as,
                    },
                    className
                )}
                {...hoverProps}
                {...props}
            >
                {iconEl}
                {labelEl}
                {!last && <PdsStepConnector orientation={orientation} completed={completed} />}
            </PdsStepWrapper>
        );
    }
) as CompoundComponentWithRef<PdsStepNamespace, PdsStepProps, HTMLLIElement | HTMLButtonElement>;

type PdsStepNamespace = {
    Icon: typeof PdsStepIcon;
    Label: typeof PdsStepLabel;
};

PdsStep.Icon = PdsStepIcon;
PdsStep.Label = PdsStepLabel;

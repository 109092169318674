import type { ReactElement } from "react";
import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsChoiceGroupProps } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useEffect, useMemo } from "react";

import { useMultiSelect } from "@/hooks";
import { tx, getChildrenByType } from "@/libs";
import { PdsHelpText, PdsLabel } from "@/components/label";

import { PdsChoiceGroupSubcomponentMapping } from "./type";
import { PdsChoiceGroupContext } from "./context";
import { PdsChoiceGroupChip } from "./ChoiceGroupChip";
import { PdsChoiceGroupRadio } from "./ChoiceGroupRadio";
import { PdsChoiceGroupCheckbox } from "./ChoiceGroupCheckbox";
import { PdsChoiceGroupRadioCard } from "./ChoiceGroupRadioCard";
import { PdsChoiceGroupCheckboxCard } from "./ChoiceGroupCheckboxCard";

export type PdsChoiceGroupNamespace = {
    Chip: typeof PdsChoiceGroupChip;
    Radio: typeof PdsChoiceGroupRadio;
    Checkbox: typeof PdsChoiceGroupCheckbox;
    RadioCard: typeof PdsChoiceGroupRadioCard;
    CheckboxCard: typeof PdsChoiceGroupCheckboxCard;
};

/**
 * `PdsChoiceGroup` is a form item that let user choose between related binary options in a group.
 *
 * By placing them in a group, it is better communicated to users that together these options make up
 * a collection and are related to each other.
 *
 * - `PdsChoiceGroup` is by nature a [fieldset](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset)
 * - `PdsChoiceGroup` features a mandatory [legend](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/legend)
 * that describes the collection.
 * - `PdsChoiceGroup` aligns [disabed](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#disabled) and
 * [name](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#name) props on all of its choice items,
 * to ensure that they submit to a single form item.
 * - `PdsChoiceGroup` wraps its choice items in a [FocusScope](https://react-spectrum.adobe.com/react-aria/FocusScope.html)
 * for focus management.
 *
 * `PdsChoiceGroup` comes the with 5 built-in types of choice items:
 * - `PdsChoiceGroup.Chip` displays a list of compact & focused chip badges for users to multi-select from for *immediate effects*.
 * - `PdsChoiceGroup.Radio` displays a list of options to single select from.
 * - `PdsChoiceGroup.Checkbox` displays a list of options to muli-select from.
 * - `PdsChoiceGroup.RadioCard` & `PdsChoiceGroup.CheckboxCard` are contentful card counterparts of radios & checkboxes.
 *
 * `PdsChoiceGroup` features strict children filtering, which means that only the valid type choice items would be rendered.
 *
 * When the user focuses on the choice items, the following keyboard interactions apply:
 * - `Space` selects the currently focused choice item
 * - `Tab` & `Shift+Tab` to focus different options if the type is `chip`, `checkbox` or `checkboxCard`
 * - `Left` & `Right` to navigate between options if the type is `radio` or `radioCard`
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=5179%3A6873).
 */
export const PdsChoiceGroup = forwardRef<HTMLFieldSetElement, PdsChoiceGroupProps>(
    (
        {
            id,
            type,
            name,
            label,
            error,
            helpText,
            single = false,
            disabled = false,
            required = false,
            orientation = "vertical",
            value = [],
            className,
            children,
            onChange,
            ...props
        }: PdsChoiceGroupProps,
        ref
    ) => {
        const groupId = useMemo(() => id ?? `pds-choice-group-${nanoid()}`, [id]);

        /**
         * Filter memo to inject child items with the group's share-able props.
         * Renders only valid type child items.
         */
        const choiceItems = useMemo(
            () => getChildrenByType(children, PdsChoiceGroupSubcomponentMapping[type]),
            [children, type]
        );

        /**
         * Memo to retrieve all non-disabled options.
         */
        const options = useMemo(
            () =>
                choiceItems
                    .filter((itemEl) => !(itemEl as ReactElement).props?.disabled)
                    .map((itemEl) => (itemEl as ReactElement).props?.value),
            [choiceItems]
        );

        /**
         * Enforce single select if radio type is used.
         * */
        const [selected, { toggle, select, unselect, reset, isEqual }] = useMultiSelect({
            initialValues: value,
            single: single || type === "radio" || type === "radioCard",
            onChange,
        });

        /**
         * Effect hook to emit change events.
         */
        useEffect(() => onChange?.(selected), [selected, onChange]);

        return (
            <fieldset
                id={groupId}
                ref={ref}
                disabled={disabled}
                aria-required={required ? "true" : undefined}
                aria-invalid={error ? "true" : undefined}
                className={tx(
                    "flex flex-col flex-wrap gap-2",
                    {
                        "flex-row": orientation === "horizontal",
                    },
                    className
                )}
                {...props}
            >
                {label && (
                    <PdsLabel as="legend" disabled={disabled} required={required} className="mb-1 w-full">
                        {label}
                    </PdsLabel>
                )}

                <PdsChoiceGroupContext.Provider
                    value={[
                        selected,
                        { toggle, select, unselect, reset, isEqual },
                        { name, disabled, error: !!error, options },
                    ]}
                >
                    {choiceItems}
                </PdsChoiceGroupContext.Provider>

                {!disabled && (error || helpText) && (
                    <PdsHelpText htmlFor={groupId} disabled={disabled} error={!!error} className="w-full">
                        {error || helpText}
                    </PdsHelpText>
                )}
            </fieldset>
        );
    }
) as CompoundComponentWithRef<PdsChoiceGroupNamespace, PdsChoiceGroupProps, HTMLFieldSetElement>;

PdsChoiceGroup.Chip = PdsChoiceGroupChip;
PdsChoiceGroup.Radio = PdsChoiceGroupRadio;
PdsChoiceGroup.Checkbox = PdsChoiceGroupCheckbox;
PdsChoiceGroup.RadioCard = PdsChoiceGroupRadioCard;
PdsChoiceGroup.CheckboxCard = PdsChoiceGroupCheckboxCard;

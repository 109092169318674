import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorStrikethrough = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12.06 6c1.79 0 3.16.96 3.62 2.41.14.47-.25.93-.73.93h-.15c-.4 0-.72-.25-.9-.61-.3-.64-.98-1.03-1.85-1.03-1.18 0-1.95.64-1.95 1.61 0 .77.51 1.22 1.78 1.54l.56.15H18a1 1 0 0 1 0 2h-2.25c.17.4.25.86.25 1.39C16 16.6 14.46 18 11.99 18c-1.98 0-3.39-.93-3.84-2.46-.14-.47.25-.93.73-.93h.16c.41 0 .74.26.93.62.35.66 1.12 1.06 2.13 1.06 1.2 0 2.07-.69 2.07-1.65 0-.82-.55-1.3-1.87-1.65l.03.01H6a1 1 0 0 1 0-2h2.58a3.41 3.41 0 0 1-.32-1.55C8.26 7.38 9.77 6 12.05 6z" />
            </svg>
        );
    }
);

import { useEffect, useState } from "react";

/**
 * Recursive checker, ends if no more parents or, found a `dir` attr.
 */
const isParentDirectionRtL = (ref?: HTMLElement | null): boolean => {
    if (!ref) {
        return false;
    }

    const dir = ref.getAttribute("dir");
    if (dir !== null) {
        if (dir === "rtl") {
            return true;
        }

        return false;
    }

    return isParentDirectionRtL(ref.parentElement);
};

/**
 * A hook that recursively looks forward to parents to check if dir="ltr|rtl|auto" is set.
 *
 * @param ref the DOM element as the starting point of search.
 */
export const useRtL = (ref?: HTMLElement | null, deps?: ReadonlyArray<any>) => {
    const [isRtL, setRtL] = useState(false);

    useEffect(() => {
        if (ref) {
            /* Check recursively on parents */
            setRtL(isParentDirectionRtL(ref));
        } else {
            /* Check `body` if no starting point is specified */
            setRtL(document.querySelector("body")?.getAttribute("dir") === "rtl");
        }
    }, [ref, deps]);

    return { isRtL };
};

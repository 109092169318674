import type { ReactElement, ElementType, Ref } from "react";
import type { PolymorphicProps, PolymorphicRef } from "@/typings/utilities";
import type { PdsDropdownProps } from "./type";

import { nanoid } from "nanoid";
import { useMemo, useCallback, cloneElement, forwardRef, Children } from "react";

import { ex, tx, getChildByType, removeChildrenByType, typeOfComponent } from "@/libs";
import { useBoolean } from "@/hooks";
import { PdsPopover } from "@/components/popover";

import { PdsDropdownContext } from "./context";
import { PdsDropdownItem } from "./DropdownItem";

/**
 * `PdsDropdown` presents a list of items that users can choose to trigger a specific action with.
 *
 * `PdsDropdown` includes a dropdown trigger (a button by default) and a dropdown menu comprising of:
 *
 * - `PdsDropdown.Item`: menu items that can be used to perform an action, a page navigation, or show a submenu.
 * - `PdsDropdown.Section`: sections of items or custom content that could have dividers and/or headings.
 * - `PdsDropdown.Submenu`: a nested sub-menu that is triggered upon hovered or focus on the trigger menu item.
 *
 * When user focuses on a menu trigger, the following keyboard interactions apply:
 *
 * - `Enter` or `Space` opens the menu & select the first non-disabled menu item.
 * - `Up` or `Down` arrows moves the user between menu items.
 *  - Disabled menu items, dividers, and section labels are never focused.
 * - `Home` or `End` focuses the first/last menu item, respectively.
 * - `Right` arrow on a menu item with a chevron arrow `>` opens the nested submneu.
 * - `Left` arrow in a submenu closes only the current submenu & re-focuses to its triggering menu item.
 * - `Esc` closes the menu & all of its submenus.
 *
 * `PdsDropdown` is built using `PdsPopover` & retains all of its accessibility properties & features.
 *
 * - `PdsDropdown` has a [role='menu'](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/menu_role) by default
 *
 * `PdsDropdown.Submenu` has all the properties & features of both `PdsDropdown` and `PdsDropdown.Item`, since it uses
 * an item as a trigger for a nested dropdown.
 *
 * `PdsDropdown.Section` provides a way to group items via dividers, headings, or both.
 *
 * `PdsDropdown.Item` display a single menu item in the dropdown.
 *
 * - `PdsDropdown.Item` is a button by default.
 * - `PdsDropdown.Item` has a [role='menuitem'](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/menuitem_role) by default.
 * - `PdsDropdown.Item.Leading` is `PdsIcon` by default, and should be decorative in most use cases.
 * - `PdsDropdown.Item.Label` is `p` by default.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=1458%3A10313).
 */
export const PdsDropdown = forwardRef(
    <E extends ElementType = "div">(
        {
            id,
            trigger,
            items = [],
            open: openProps = false,
            disabled = false,
            disableAutofocus = false,
            disableDismissByClickInside = false,
            closeButtonAriaLabel,
            className,
            children: childrenProps,
            onOpen,
            onClose,
            ...props
        }: PolymorphicProps<E, PdsDropdownProps>,
        ref: PolymorphicRef<ElementType> & Ref<E>
    ) => {
        /**
         * Memo to initialize dropdown ID.
         */
        const dropdownId = useMemo(() => id ?? `pds-dropdown-${nanoid()}`, [id]);

        /**
         * Open state & popover-based behaviour management hook.
         */
        const [isOpen, { open, close }] = useBoolean({
            isDisabled: disabled,
            initialValue: openProps,
        });

        /**
         * Trigger subcomponent filter. Only select the first valid child of each type.
         * Fallbacks to shorthand props if none is found.
         */
        const triggerSubcomponent = getChildByType(childrenProps, [
            "PdsDropdownTrigger",
            "PdsDropdownSelectTriggerChip",
        ]);
        const triggerEl = triggerSubcomponent ?? trigger;

        /**
         * Handler to close the dropdown by default when clicking inside.
         */
        const handeAutoDismissByClickInside = useCallback(() => {
            if (!disableDismissByClickInside) {
                close();
                onClose?.();
            }
        }, [disableDismissByClickInside, close, onClose]);

        /**
         * Dropdown content filter. Any child not a trigger is treated as the dropdown content.
         * This enables total custom content with dependency on PdsDropdownItem as the default design.
         */
        const childrenArray = Children.toArray(
            removeChildrenByType(childrenProps, ["PdsDropdownTrigger", "PdsDropdownSelectTriggerChip"])
        );

        /**
         * Recursive iterator through children to customise sections & items without changing the DOM structure.
         */
        const cloneDropdownItemDeep = useCallback(
            (el: ReactElement, order?: number): ReactElement => {
                const componentType = typeOfComponent(el);

                /**
                 * (1) PdsDropdownSection | PdsDropdownSelectSection: needs to take into account whether it is
                 * first, last, and whether its next child is also a section to properly space itself.
                 */
                if (componentType === "PdsDropdownSection" || componentType === "PdsDropdownSelectSection") {
                    const isFirst = order === 0;
                    const isLast = order === childrenArray.length - 1;

                    const nextChild = childrenArray[(order ?? 0) + 1] as ReactElement;
                    const typeOfNextChild = typeOfComponent(nextChild);

                    const isNextChildSection =
                        nextChild &&
                        (typeOfNextChild === "PdsDropdownSection" || typeOfNextChild === "PdsDropdownSelectSection") &&
                        nextChild.props.divider;

                    return cloneElement(
                        el,
                        {
                            first: isFirst,
                            last: isLast || isNextChildSection,
                        },
                        Children.map(el.props.children, (subEl) => cloneDropdownItemDeep(subEl as ReactElement, order))
                    );
                }

                /**
                 * (2) None of above: any custom content or item is left as is.
                 */
                return el;
            },
            [childrenArray]
        );

        /**
         * Iterate through children to handle sectioning.
         */
        let children = childrenArray.map((el, order) => cloneDropdownItemDeep(el as ReactElement, order));

        /**
         * Fallback to shorthand props if no items are found.
         * Shorthand props by design are very limited, and only allow direct render of a text-only list of items.
         */
        if (children.length === 0) {
            children = items?.map(({ label, ...itemProps }) => (
                <PdsDropdownItem key={label} label={label} {...itemProps} />
            ));
        }

        return (
            <PdsPopover
                role="menu"
                ref={ref}
                id={dropdownId}
                open={isOpen}
                trigger={triggerEl}
                disabled={disabled}
                disableAutofocus={disableAutofocus}
                disablePersistentCloseButton={children && children.length > 0}
                onOpen={ex(onOpen, open)}
                onClose={ex(onClose, close)}
                {...props}
            >
                <PdsPopover.Panel
                    className={tx("flex max-h-96 min-w-64 flex-col overflow-y-auto px-0 py-1", className)}
                >
                    <PdsDropdownContext.Provider
                        value={{ disableAutofocus, handleItemClick: handeAutoDismissByClickInside }}
                    >
                        {children}
                    </PdsDropdownContext.Provider>
                </PdsPopover.Panel>
            </PdsPopover>
        );
    }
);

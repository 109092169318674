import type { CompoundComponentWithRef, InheritableElementProps } from "@/typings/utilities";
import type { PdsTableProps } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";

import { getChildByType, getChildrenByTypeDeep, removeChildrenByType } from "@/libs/childrenUtils";
import { tx } from "@/libs";

import {
    PdsTableHeader,
    PdsTableBody,
    PdsTableFooter,
    PdsTableRow,
    PdsTableHeaderCell,
    PdsTableCell,
    PdsTableCaption,
    PdsTableSkeleton,
} from "./TableComponents";
import { PdsTableContext } from "./context";

const PdsTableResponsiveWrapper = forwardRef<HTMLDivElement, InheritableElementProps<"div", {}>>(
    ({ className, children, ...props }: InheritableElementProps<"div", {}>, ref) => {
        return (
            <div
                role="region"
                ref={ref}
                className={tx(
                    "focusable relative max-h-full w-full overflow-auto",
                    "rounded border border-neutral-300",
                    className
                )}
                {...props}
            >
                {children}
            </div>
        );
    }
);

/**
 * `PdsTable` is a grid display of information, presented in rows & columns of cells.
 * It is an effective method of representing a list of data so in a scannable way for an user to identify patterns
 * and insights.
 *
 * `PdsTable` is used to organise data with logical relationships. Its accessibility features require subcomponents that indicate
 * header & data cells:
 * - `PdsTable` must have a `PdsTable.Header`, containing a `PdsTable.HeaderCell` for each column.
 * - `PdsTable` must have a `PdsTable.Body`, containing all the data rows.
 * for the `PdsTable.Cell` that is the key for the row, (e.g. username, email, id...)
 * - `PdsTable` is also recommended to include a `PdsTable.Caption` that provides a contextual description of the datasets in the table.
 * Use `srOnly` props if that caption does not need to be visible.
 * - `PdsTable` should have [aria-colcount="index"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-colcount) with the current number of columns.
 * - `PdsTable` should have [aria-rowcount="index"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-rowcount) with the current number of rows.
 * - `PdsTable.Row` has [role="row"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/row_role) by default
 * - `PdsTable.Row` should have [aria-rowindex="index"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-rowindex) with the current index of the row, starting from 1 from the
 * header row.
 * - `PdsTable.HeaderCell` & `PdsTable.Cell` should have [aria-colindex="index"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-colindex) with the current index of the column, starting from 1
 * from the header column.
 * - `PdsTable.Cell` has [role="gridcell"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/gridcell_role) by default, and it
 * is best practice that each row includes a row header `PdsTable.Cell` via the `rowHeader` props, which has [role="rowheader"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/rowheader_role) by default.
 *
 * Generally, data should rarely be centered, as text should be start-aligned, while numeric data should be end-aligned,
 * while non-comparative numbers (e.g. ID, phone number, ...) should stay start-aligned. Leverage the `align` & `verticalAlign` props
 * of `PdsTable.Cell` to do this.
 *
 * Most content can be placed in `PdsTable`, but only static textual & numeric data are recommended. If interactive elements,
 * (e.g. links, dropdown, and buttons, ...) need to be included, consider `PdsDataGrid` instead, since it fashions
 * additional support for navigating to & between interactive & static cells.
 *
 * Reference: https://www.w3.org/WAI/tutorials/tables/
 */
export const PdsTable = forwardRef<HTMLTableElement, PdsTableProps>(
    (
        {
            id,
            loading = false,
            striped = false,
            stickyHeader = false,
            stickyFirstColumn = false,
            className,
            children,
            ...props
        }: PdsTableProps,
        ref
    ) => {
        const tableId = useMemo(() => id ?? `pds-table-${nanoid()}`, [id]);

        const headerEl = getChildByType(children, "PdsTableHeader");
        const captionEl = getChildByType(children, "PdsTableCaption");
        const content = removeChildrenByType(children, ["PdsTableHeader", "PdsTableCaption", "PdsTableSkeleton"]);

        const noColumns = getChildrenByTypeDeep(headerEl, "PdsTableHeaderCell").length;
        const noRows = getChildrenByTypeDeep(content, "PdsTableRow").length;
        const loadingEl = getChildByType(children, "PdsTableSkeleton") ?? (
            <PdsTableSkeleton columns={noColumns} rows={noRows} />
        );

        return (
            <PdsTableResponsiveWrapper className={className}>
                <table
                    ref={ref}
                    id={tableId}
                    className="table w-full table-auto border-separate border-spacing-0 whitespace-nowrap text-sm"
                    {...props}
                >
                    <PdsTableContext.Provider value={{ loading, striped, stickyHeader, stickyFirstColumn }}>
                        {headerEl}
                        {loading ? loadingEl : content}
                    </PdsTableContext.Provider>
                    {captionEl}
                </table>
            </PdsTableResponsiveWrapper>
        );
    }
) as CompoundComponentWithRef<
    {
        Skeleton: typeof PdsTableSkeleton;
        Caption: typeof PdsTableCaption;
        Header: typeof PdsTableHeader;
        Body: typeof PdsTableBody;
        Footer: typeof PdsTableFooter;
        Row: typeof PdsTableRow;
        HeaderCell: typeof PdsTableHeaderCell;
        Cell: typeof PdsTableCell;
    },
    PdsTableProps,
    HTMLTableElement
>;

PdsTable.Skeleton = PdsTableSkeleton;
PdsTable.Caption = PdsTableCaption;
PdsTable.Header = PdsTableHeader;
PdsTable.Body = PdsTableBody;
PdsTable.Footer = PdsTableFooter;
PdsTable.Row = PdsTableRow;
PdsTable.HeaderCell = PdsTableHeaderCell;
PdsTable.Cell = PdsTableCell;

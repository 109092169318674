import type { KeyboardEvent } from "react";

import { createContext, useContext } from "react";

type PdsDataGridContextProps = {
    mode?: "navigation" | "action";
    isCellActive?: (rowIndex: number, colIndex: number) => boolean;
    handleCellKeydown?: (e: KeyboardEvent<HTMLTableCellElement>) => void;
};

export const PdsDataGridContext = createContext<PdsDataGridContextProps>({});

export const usePdsDataGridContext = () => useContext(PdsDataGridContext);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconMap = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19.55 7.17l-4.49-3a.98.98 0 0 0-1.07-.02L9.53 6.82 5.56 4.17A1 1 0 0 0 4 5v11a1 1 0 0 0 .45.83l4.49 3a.98.98 0 0 0 1.07.02l4.46-2.67 3.97 2.65A1 1 0 0 0 20 19V8a1 1 0 0 0-.45-.83zM9 17.46l-3-2v-8.6l2.94 1.96a.2.2 0 0 0 .06.04v8.6zm5-2.32l-.01.01L10 17.53V8.86l.01-.01L14 6.47v8.67zm4 1.99l-2.94-1.96a.22.22 0 0 1-.05-.03v-8.6l3 2v8.59H18z" />
            </svg>
        );
    }
);

import type {
    PdsTableBodyProps,
    PdsTableRowProps,
    PdsTableHeaderCellProps,
    PdsTableCellProps,
    PdsTableCaptionProps,
    PdsTableHeaderProps,
    PdsTableFooterProps,
    PdsTableSkeletonProps,
} from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";

import { polymorphComponent, tx } from "@/libs";
import { PdsSkeleton } from "@/components/skeleton";

import { usePdsTableContext } from "./context";
import { PDS_TABLE_SKELETON_COUNT } from "./type";

export const PdsTableSkeleton = ({ rows, columns, children, ...props }: PdsTableSkeletonProps) => {
    return (
        <PdsSkeleton as={PdsTableBody} {...props}>
            {children ??
                Array.from(Array(rows || PDS_TABLE_SKELETON_COUNT).keys()).map((i) => (
                    <PdsTableRow key={i}>
                        {Array.from(Array(columns || PDS_TABLE_SKELETON_COUNT).keys()).map((j) => (
                            <PdsTableCell key={j}>
                                <PdsSkeleton.Bone className="h-4 min-w-4" />
                            </PdsTableCell>
                        ))}
                    </PdsTableRow>
                ))}
        </PdsSkeleton>
    );
};

export const PdsTableCaption = forwardRef<HTMLTableCaptionElement, PdsTableCaptionProps>(
    ({ id, srOnly, className, ...props }: PdsTableCaptionProps, ref) => {
        const captionId = useMemo(() => id || `pds-caption-${nanoid()}`, [id]);

        return (
            <caption
                ref={ref}
                id={captionId}
                style={{ captionSide: "bottom" }}
                className={tx(
                    "text-subheading w-full py-5 px-6 text-start",
                    {
                        "sr-only p-0": srOnly,
                    },
                    className
                )}
                {...props}
            />
        );
    }
);

export const PdsTableHeader = forwardRef<HTMLTableSectionElement, PdsTableHeaderProps>(
    ({ className, ...props }: PdsTableHeaderProps, ref) => {
        return <thead ref={ref} className={tx("table-header-group", className)} {...props} />;
    }
);

export const PdsTableBody = forwardRef<HTMLTableSectionElement, PdsTableBodyProps>(
    ({ className, ...props }: PdsTableBodyProps, ref) => {
        return <tbody ref={ref} className={tx("table-row-group", className)} {...props} />;
    }
);

export const PdsTableFooter = forwardRef<HTMLTableSectionElement, PdsTableFooterProps>(
    ({ className, ...props }: PdsTableFooterProps, ref) => {
        return <tfoot ref={ref} className={tx("table-footer-group", className)} {...props} />;
    }
);

export const PdsTableRow = forwardRef<HTMLTableRowElement, PdsTableRowProps>(
    ({ className, ...props }: PdsTableRowProps, ref) => {
        return <tr role="row" ref={ref} className={tx("group table-row", className)} {...props} />;
    }
);

export const PdsTableHeaderCell = forwardRef<HTMLTableCellElement, PdsTableHeaderCellProps>(
    ({ className, ...props }: PdsTableHeaderCellProps, ref) => {
        const { stickyHeader, stickyFirstColumn } = usePdsTableContext();

        return (
            <th
                role="columnheader"
                ref={ref}
                className={tx(
                    "table-cell border-b border-neutral-300 bg-neutral-100 px-6 py-3 text-xs font-normal uppercase text-start",
                    {
                        "sticky top-0 z-10": stickyHeader,
                        "first-of-type:left-0 first-of-type:z-20 first-of-type:shadow-[0_0_10px_4px_rgba(0,0,0,0.1)] first-of-type:[clip-path:inset(0_-100%_0_0)]":
                            stickyFirstColumn,
                    },
                    className
                )}
                {...props}
            />
        );
    }
);

const PdsTableCellEl = polymorphComponent("td");

export const PdsTableCell = forwardRef<HTMLTableCellElement, PdsTableCellProps>(
    ({ align = "start", verticalAlign = "middle", rowHeader = false, className, ...props }: PdsTableCellProps, ref) => {
        const { striped, stickyFirstColumn } = usePdsTableContext();

        return (
            <PdsTableCellEl
                ref={ref}
                as={rowHeader ? "th" : "td"}
                role={rowHeader ? "rowheader" : "gridcell"}
                scope={rowHeader ? "row" : undefined}
                className={tx(
                    "table-cell border-b border-neutral-300 py-5 px-6 align-middle text-start",
                    {
                        "font-semibold": rowHeader,
                        "group-even:bg-neutral-100": striped,
                        "sticky bg-white first-of-type:left-0 first-of-type:shadow-[0_0_10px_4px_rgba(0,0,0,0.1)] first-of-type:[clip-path:inset(0_-100%_0_0)]":
                            stickyFirstColumn && rowHeader,
                        "text-center": align === "center",
                        "text-end": align === "end",
                        "align-top": verticalAlign === "top",
                        "align-bottom": verticalAlign === "bottom",
                    },
                    className
                )}
                {...props}
            />
        );
    }
);

import "@educationperfect/web-pds-react/dist/tailwind.css";
import "./main.css";

import { PdsThemeContainer } from "@educationperfect/web-pds-react";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import SignUp from "@pages/signup";

function App() {
    return (
        <React.StrictMode>
            <PdsThemeContainer mfeKey="" theme="one">
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/for-parents" />
                        </Route>
                        <Route path="/for-parents" component={SignUp} />
                        <Route
                            path="*"
                            render={() => (
                                <div role="alert" aria-live="assertive">
                                    Page not found
                                </div>
                            )}
                        />
                    </Switch>
                </BrowserRouter>
            </PdsThemeContainer>
        </React.StrictMode>
    );
}

export default App;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconScanCode = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M21 14v3a4 4 0 0 1-3.8 4H14v-2h3a2 2 0 0 0 2-1.85V14h2zM5 14v3.15a2 2 0 0 0 1.84 1.84L7 19h3v2H6.8A4 4 0 0 1 3 17.2V14h2zm5-11v2H7a2 2 0 0 0-2 1.85V10H3V7a4 4 0 0 1 3.8-4H10zm7 0h.2A4 4 0 0 1 21 6.8V10h-2V6.85a2 2 0 0 0-1.84-1.84L17 5h-3V3h3z" />
            </svg>
        );
    }
);

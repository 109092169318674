import type { PdsRadioProps } from "./type";
import type { ChangeEvent } from "react";

import { nanoid } from "nanoid";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { useEffect, forwardRef, useCallback, useMemo } from "react";

import { ex, tx } from "@/libs";
import { useBoolean } from "@/hooks";

/**
 * `PdsRadio` is a form element that allows users to select a single choice from a list of at least two options.
 *
 * `PdsRadio` represents a user with a single choice from mutually exclusive choices, and should only be used to choose
 * a singular user's choice.
 *
 * - `PdsRadio` can be checked or unchcked.
 * - `PdsRadio` should have labels. In most cases, this should be done via `PdsLabel`.
 * When a label cannot be used, it is necesssary to add [aria-label](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label)
 * - `PdsRadio` uses [aria-checked](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-checked)
 * to assistively display as being checked.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=124%3A2807).
 */
export const PdsRadio = forwardRef<HTMLInputElement, PdsRadioProps>(
    (
        {
            id,
            error = false,
            disabled = false,
            required = false,
            checked: checkedProps,
            defaultChecked = false,
            className,
            onChange,
            ...props
        }: PdsRadioProps,
        ref
    ) => {
        const [checked, { setValue }] = useBoolean({ initialValue: checkedProps ?? defaultChecked });

        /**
         * Memo to intialize radio ID.
         */
        const radioId = useMemo(() => id ?? `pds-radio-${nanoid()}`, [id]);

        /**
         * Effect handler to update on props.
         */
        useEffect(() => setValue(checkedProps ?? defaultChecked), [defaultChecked, checkedProps, setValue]);

        /**
         * Hover handling hook.
         */
        const { isHovered, hoverProps } = useHover({ isDisabled: disabled });

        /**
         * Change event handler for checkbox.
         *
         * If controlled (aka. `checked` set via props), internal state change is ignored.
         */
        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                if (checkedProps === undefined) {
                    setValue(e.target.checked);
                }
            },
            [checkedProps, setValue]
        );

        return (
            <input
                type="radio"
                ref={ref}
                id={radioId}
                checked={checked}
                disabled={disabled}
                required={required}
                aria-checked={checked}
                className={tx(
                    "my-0.5 h-4 w-4 flex-shrink-0 appearance-none rounded-full border border-neutral-400",
                    "focusable cursor-pointer transition duration-200 motion-reduce:transition-none",
                    "disabled:cursor-not-allowed disabled:border-neutral-300 disabled:bg-neutral-300",
                    {
                        "border-neutral-500": isHovered,
                        "border-[5px] border-primary": checked,
                        "border-theme-800": checked && isHovered,
                        "border-danger !ring-danger": error,
                        "border-red-700": error && isHovered,
                    },
                    className
                )}
                onChange={ex(handleChange, onChange)}
                {...mergeProps(hoverProps, props)}
            />
        );
    }
);

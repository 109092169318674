import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconRead = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19.99 5.13a.96.96 0 0 0-.78-.87c-2.95-.62-5.37-.13-7.21 1.44-1.84-1.57-4.26-2.06-7.22-1.44a.94.94 0 0 0-.77.87H4v12c0 .08 0 .17.02.26.12.51.65.82 1.19.71 2.63-.55 4.59-.04 6.01 1.57.02.03.06.04.08.06l.05.06.13.07.14.07c.11.04.23.07.35.07h.04c.12 0 .24-.03.35-.07l.14-.07.13-.07.05-.06c.03-.02.06-.03.08-.06 1.42-1.6 3.39-2.12 6.01-1.57.54.11 1.07-.21 1.19-.71.04-.09.04-.18.04-.26l-.01-12zM11 16.99a7.9 7.9 0 0 0-5-.93V5.96c2.11-.28 3.75.2 5 1.46v9.57zm7-.92a7.9 7.9 0 0 0-5 .93V7.42c1.25-1.26 2.89-1.74 5-1.46v10.11z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconCheckCircle = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12 22C6.475 22 2 17.525 2 12S6.475 2 12 2s10 4.475 10 10-4.475 10-10 10zm5-13.75c-.35 0-.6625.1375-.8875.3625l-5.3625 5.375-2.8625-2.875C7.6625 10.8875 7.35 10.75 7 10.75c-.6875 0-1.25.5625-1.25 1.25 0 .35.1375.6625.3625.8875l3.75 3.75c.225.225.5375.3625.8875.3625s.6625-.1375.8875-.3625l6.25-6.25c.225-.225.3625-.5375.3625-.8875 0-.6875-.5625-1.25-1.25-1.25z" />
            </svg>
        );
    }
);

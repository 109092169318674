import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconSortAlphabeticalAsc = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M10 15.99a1 1 0 0 0-.71.29l-.29.3v-3.59a1 1 0 0 0-1-1 1 1 0 0 0-1 1v3.59l-.29-.29a.97.97 0 0 0-.71-.3 1 1 0 0 0-.71 1.71l2 2c.19.18.44.29.71.29a1 1 0 0 0 .71-.29l2-2a1 1 0 0 0 .29-.71.99.99 0 0 0-1-1zm7.93-.95V14h-4.68v1.11h2.94L13 18.95v1.04h5.02v-1.11h-3.27l3.18-3.84zm-1.42-4.84l.62 1.78H19L15.94 4H14.1L11 11.98h1.81l.64-1.78h3.06zm-1.52-4.24h.02l1.03 2.93h-2.12l1.07-2.93z" />
            </svg>
        );
    }
);

import type { PdsButtonProps } from "./type";

import { createContext, useContext } from "react";

export type PdsButtonContextProps = Required<Pick<PdsButtonProps, "size" | "loading">>;

export const PdsButtonContext = createContext<PdsButtonContextProps>({
    loading: false,
    size: "medium",
});

export const usePdsButtonContext = () => useContext(PdsButtonContext);

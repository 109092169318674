import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconFullscreen = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M7.41 6H9a1 1 0 0 0 1-1 1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1 1 1 0 0 0 1-1V7.41l3.29 3.29c.18.19.43.3.71.3a1 1 0 0 0 .71-1.71L7.41 6zM10 13a1 1 0 0 0-.71.29L6 16.59V15a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1 1 1 0 0 0-1-1H7.41l3.29-3.29c.19-.18.3-.43.3-.71a1 1 0 0 0-1-1zm9 1a1 1 0 0 0-1 1v1.59l-3.29-3.29A.97.97 0 0 0 14 13a1 1 0 0 0-.71 1.71l3.3 3.29H15a1 1 0 0 0-1 1 1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm0-10h-4a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1.59l-3.3 3.29a1 1 0 0 0 1.42 1.42L18 7.41V9a1 1 0 0 0 1 1 1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconAddToFolder = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M17 10.5l-3-3h2v-3h2v3h2l-3 3zm-10.9.1c.2-.4.5-.6.9-.6h8l-2-2h-2.6L8.7 6.3l-.1-.1C8.4 6.1 8.2 6 8 6H4.9c-.5.1-.9.5-.9 1v9.8l2.1-6.1v-.1zM19 11H7.9c-.4 0-.7.3-.8.7l-2 6v.3c0 .5.5 1 1 1h11.1c.4 0 .7-.3.8-.7l2-6V12c0-.5-.5-1-1-1z" />
            </svg>
        );
    }
);

import type { ElementType, FormEventHandler, ForwardedRef, ReactElement } from "react";
import type { PdsIconProps } from "@/components/icons";
import type { PdsDropdownSelectItemProps } from "./type";

import { nanoid } from "nanoid";
import { useMemo, forwardRef } from "react";

import { ex, tx, getChildByType, polymorphComponent, removeChildrenByType } from "@/libs";
import { PdsCheckbox } from "@/components/checkbox";
import { PdsDropdown } from "@/components/dropdown";

import { usePdsDropdownSelectContext } from "./context";

/**
 * `PdsDropdownSelecttem.Leading` reseves a slot before all item content but after the checkbox.
 *
 * It is styled as a decorative & medium-sized `PdsIcon` by default.
 */
export const PdsDropdownSelectItemLeading = polymorphComponent<PdsIconProps, ElementType>(
    "div",
    ({ className, ...props }: PdsIconProps) => ({
        className: tx("me-1", className),
        ...props,
    })
);

type PdsDropdownSelectItemNamespace = {
    Leading: typeof PdsDropdownSelectItemLeading;
};

export const PdsDropdownSelectItem = forwardRef(
    <T extends unknown = string>(
        {
            id,
            value,
            label,
            checked: checkedProps,
            indeterminate,
            disabled = false,
            children,
            onChange,
            onClick,
            ...props
        }: PdsDropdownSelectItemProps<T>,
        ref: ForwardedRef<HTMLLIElement>
    ) => {
        /**
         * Memo to initialize dropdown select item ID.
         */
        const dropdownSelectItemId = useMemo(() => id ?? `pds-dropdown-select-item-label-${nanoid()}`, [id]);

        /**
         * Get selected values array from context.
         */
        const [selected, { toggle, isEqual }] = usePdsDropdownSelectContext<T>();

        const checked = useMemo(
            () => (!disabled && checkedProps) ?? selected.some((val) => isEqual(val, value)),
            [checkedProps, disabled, value, selected, isEqual]
        );

        const leadingEl = getChildByType(children, "PdsDropdownSelectItemLeading");
        const remainingChildren = removeChildrenByType(children, [
            "PdsDropdownItemLeading",
            "PdsDropdownItemLabel",
            "PdsDropdownSelectItemLeading",
        ]);

        return (
            <PdsDropdown.Item
                as="li"
                role="option"
                ref={ref}
                tabIndex={0}
                disabled={disabled}
                aria-selected={checked ? "true" : "false"}
                aria-checked={checked ? "true" : "false"}
                aria-labelledby={dropdownSelectItemId}
                onClick={ex(() => toggle(value), onClick)}
                {...props}
            >
                <PdsDropdown.Item.Leading
                    as={PdsCheckbox}
                    tabIndex={-1}
                    disabled={disabled}
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={onChange as unknown as FormEventHandler<SVGElement>}
                />
                <PdsDropdown.Item.Label id={dropdownSelectItemId} className="flex items-center">
                    {leadingEl}
                    {label}
                </PdsDropdown.Item.Label>
                {remainingChildren}
            </PdsDropdown.Item>
        );
    }
) as unknown as PdsDropdownSelectItemNamespace &
    (<T extends unknown = string>(
        props: PdsDropdownSelectItemProps<T> & { ref?: ForwardedRef<HTMLButtonElement> }
    ) => ReactElement | null);

PdsDropdownSelectItem.Leading = PdsDropdownSelectItemLeading;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconLink = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M7.27 10.34a4.76 4.76 0 0 1 6.45-.16l.17.16L15 11.43l-1.46 1.44-1.11-1.1c-.98-.95-2.53-1-3.56-.12l-.14.13-2.9 2.85a2.54 2.54 0 0 0 .2 3.81c1.01.8 2.5.68 3.47-.16l.13-.13.99-.96c.37-.37.96-.4 1.36-.09l.1.09.09.1a1 1 0 0 1 0 1.24l-.09.1-.94.91a4.9 4.9 0 0 1-6.42.47 4.56 4.56 0 0 1-.52-6.65l.17-.17 2.9-2.85zm5.59-4.88a4.9 4.9 0 0 1 6.42-.47 4.56 4.56 0 0 1 .52 6.65l-.17.17-2.9 2.85a4.76 4.76 0 0 1-6.45.16l-.17-.16L9 13.57l1.46-1.44 1.11 1.1c.98.95 2.53 1 3.56.12l.14-.13 2.9-2.85a2.54 2.54 0 0 0-.2-3.81 2.76 2.76 0 0 0-3.47.16l-.13.13-.99.96c-.37.37-.96.4-1.36.09l-.1-.09-.09-.1a1 1 0 0 1 0-1.24l.09-.1.94-.91z" />
            </svg>
        );
    }
);

import type { ReactElement } from "react";
import type { PdsButtonIconProps } from "./type";

import { forwardRef, Children, cloneElement, createElement } from "react";

import { tx } from "@/libs";

import { getIconSize } from "./styles";
import { usePdsButtonContext } from "./context";

export const PdsButtonIcon = forwardRef(({ as, children, className, ...props }: PdsButtonIconProps, ref) => {
    const { loading, size: buttonSize } = usePdsButtonContext();

    /** Get default icon sizing, responsive to button sizing */
    const size = getIconSize(buttonSize);

    const iconProps = {
        ref,
        size,
        "aria-hidden": true,
        className: tx({ "invisible": loading }, className),
        ...props,
    };

    if (as) {
        return createElement(as, iconProps);
    }

    if (children) {
        const iconChild = Children.only(children) as ReactElement;

        return cloneElement(iconChild, {
            ...iconProps,
            ...iconChild.props,
            className: tx({ "invisible": loading }, className, iconChild.props?.className),
        });
    }

    throw new Error("PdsButton.Icon must have either an `as` prop or a `children` prop");
});

// We create separate instances of the ButtonIcon component for each
// sub-component to ensure that react-nanny can correctly identify
// each them as a different components.

export const PdsButtonLeading = forwardRef(({ className, ...props }: PdsButtonIconProps, ref) => (
    <PdsButtonIcon ref={ref} className={tx("me-2", className)} {...props} />
));

export const PdsButtonTrailing = forwardRef(({ className, ...props }: PdsButtonIconProps, ref) => (
    <PdsButtonIcon ref={ref} className={tx("ms-2", className)} {...props} />
));

import type { ChangeEvent, ForwardedRef, ReactElement, ReactNode } from "react";
import type { PdsSelectOption, PdsSelectProps } from "./type";

import { Children, forwardRef, cloneElement, useMemo, useState, useEffect, useCallback } from "react";
import { nanoid } from "nanoid";

import { ex, isKeywordMatch, typeOfComponent } from "@/libs";
import { useBoolean, useContainerSize, useMultiSelect } from "@/hooks";
import { PdsDropdown } from "@/components/dropdown";

import { PdsSelectContextProvider } from "./context";
import { PdsSelectInput } from "./SelectInput";
import { PdsSelectItem } from "./SelectItem";
import { PdsSelectEmpty } from "./SelectEmpty";

export type PdsSelectNamespace = {
    Item: typeof PdsSelectItem;
    Section: typeof PdsDropdown.Section;
    Empty: typeof PdsSelectEmpty;
};

/**
 * `PdsSelect` is a pre-styled dropdown form element that allows a user to either select a value from a list of
 * otpions or type a text value to filter through such list.
 *
 * `PdsSelect` is an opinionated implementation of the `combobox` & `listbox` combination, built with
 * `PdsInput` & `PdsDropdown` as its core building blocks, both of which comes with pre-styled subcomponents:
 *
 * `PdsSelect.Input` has all the a11y features of `PdsPopover.Trigger`, including
 * [aria-haspopup="listbox"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-haspopup),
 * [aria-expanded](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-expanded),
 * [aria-controls](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-controls). Additionally:
 *
 * - `PdsSelect.Input` provides the ability to open the dropdown via `Down` arrow key.
 * - `PdsSelect.Input` is read-only by default if `autoComplete` mode is off, ensuring clear visual differentiation between two mode.
 * - `PdsSelect.Input` by default has a [role="combobox"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/combobox_role)
 * - `PdsSelect.Input` uses [aria-autocomplete="list"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-autocomplete)
 * to assistvely display whether it allows users to type into it to filter the list of options.
 * - `PdsSelect.Input` comes with a visual-only trailing icon button of a carret to toggle the visibility of the listbox. It has
 * a default [tabIndex=-1](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/combobox_role#:~:text=If%20the%20combobox%20UI%20includes%20a%20visible%20control%2C%20such%20as%20an%20icon%2C%20that%20allows%20the%20visibility%20of%20the%20popup%20to%20be%20controlled%20via%20pointer%20and%20touch%20events%2C%20that%20control%20should%20be%20a%20%3Cbutton%3E%2C%20%3Cinput%3E%20of%20type%20button%2C%20or%20a%20button%20role%20element%20with%20a%20tabindex%20of%20%2D1)
 *  to ensure that it doesn't block user's keyboard tab sequence
 * - `PdsSelect.Input` uses [aria-activedescendant](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-activedescendant)
 * to show the highlighted item without moving focus away from the autocomplete input.
 *
 * `PdsSelect.Dropdown` comes with all the a11y features of `PdsDropdown` (and by extension, `PdsPopover`), including
 * focus-trapping, keyboard navigation between the options, etc. On top of that:
 * - `PdsSelect.Dropdown` by default has a [role="listbox"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/list_role)
 * - `PdsSelect.Dropdown` populates the `PdsSelect.Input` with the select value(s) on selecting via click or `Enter`/`Space` & re-focus the input.
 * - `PdsSelect.Dropdown` uses [aria-multiselectable](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-multiselectable)
 * to assistively display whether it allows multi-selection
 * - `PdsSelect.Dropdown` uses [aria-required](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-required)
 * to indicate that an option with non-empty string value must be selected.
 * - `PdsSelect.Dropdown` uses [aria-readonly](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-readonly)
 * to show that the selected options cannot be changed or unselected.
 */
export const PdsSelect = forwardRef(
    <T extends unknown = string>(
        {
            value,
            container,
            open: openProps,
            selectedValues = [],
            multiSelectable = false,
            autoComplete = false,
            disabled = false,
            required = false,
            readOnly = false,
            disableAutofocus = false,
            disableDismissByClickInside = false,
            disableDismissByClickOutside = false,
            disableDismissByEscKeydown = false,
            disableDismissByEnterKeydown = false,
            placement = "bottom",
            "aria-labelledby": ariaLabelledBy,
            selectedItemsAriaLabel,
            predicate,
            onChange,
            onSelectedValueChange,
            onKeyDown: onKeyDownProps,
            children: childrenProps,
            ...props
        }: PdsSelectProps<T>,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        const selectListboxId = useMemo(() => `pds-select-listbox-${nanoid()}`, []);

        /**
         * Pull the wrapper ref to properly size & position the dropdown.
         */
        const [inputWrapperRef, setInputWrapperRef] = useState<HTMLDivElement | null>(null);
        const { width: inputWidth } = useContainerSize(inputWrapperRef);

        /**
         * Boolean hook to manage open/close state.
         */
        const [isOpen, { open, close, toggle: toggleOpen }] = useBoolean({
            isDisabled: disabled,
            initialValue: openProps,
        });

        /**
         * Filter keyword & select value state management hook.
         */
        const [keyword, setKeyword] = useState<string | undefined>(value?.toString());
        const [activeIndex, setActiveIndex] = useState(0);
        const [selected, { toggle: toggleSelect, unselect, reset: resetSelect, isEqual }] = useMultiSelect<
            PdsSelectOption<T>
        >({
            isEqual: predicate,
            single: !multiSelectable,
            initialValues: selectedValues,
            onChange: onSelectedValueChange,
        });

        /**
         * Side effect to make sure that any form control subscribe to ref & value/onChange
         * should be able to interact with the combobox input.
         */
        useEffect(() => setKeyword((value ?? "").toString()), [value]);

        /**
         * Side effect to trigger re-setup upon the selection action.
         */
        useEffect(() => {
            if (!disableDismissByEnterKeydown) {
                close();
            }

            /** Reset active index if on multi-select mode. */
            if (multiSelectable) {
                setActiveIndex(0);
            }

            /** Clear the keyword filter if on auto-complete mode. */
            if (autoComplete) {
                setKeyword(undefined);
            }
        }, [autoComplete, disableDismissByEnterKeydown, multiSelectable, close, selected]);

        /**
         * Handle interactions & input on the PdsSelect.Input.
         */
        const handleInputChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                if (autoComplete) {
                    setKeyword(e.target.value);
                }
            },
            [autoComplete]
        );

        /**
         * Iterate & filter relevant PdsSelectItem while keeping the DOM structure/order.
         * This is more performant than maninpulating an option array & re-render on every interaction.
         */
        let epmtyEl: ReactNode;
        const items: PdsSelectOption<T>[] = [];

        const children = Children.toArray(childrenProps).map((child) => {
            const el = child as ReactElement;
            const elType = typeOfComponent(el);

            /** Filter all items within a section, fallback to the section label itself if none found. */
            if (elType === "PdsDropdownSection") {
                const filteredItems = (Children.toArray(el.props?.children) as ReactElement[]).filter((item) =>
                    isKeywordMatch(keyword, item.props?.label)
                );

                if (filteredItems.length > 0) {
                    items.push(...filteredItems.map((item) => item.props));
                    return cloneElement(el, {}, filteredItems);
                }

                return null;
            }

            /** Extract out the empty & input subcomponents. */
            if (elType === "PdsSelectEmpty") {
                epmtyEl = el;
                return null;
            }

            /** Filter out non-matching items. */
            if (elType === "PdsSelectItem") {
                if (isKeywordMatch(keyword, el.props?.label)) {
                    items.push(el.props);
                    return cloneElement(el, { index: items.length - 1 });
                }

                return null;
            }

            return el;
        });

        return (
            <PdsSelectContextProvider
                value={[
                    { isOpen, activeIndex, selectListboxId, selected, items },
                    { close, toggleOpen, toggleSelect, resetSelect, unselect, isEqual, setKeyword, setActiveIndex },
                ]}
            >
                <PdsSelectInput
                    ref={ref}
                    wrapperRef={setInputWrapperRef}
                    value={keyword}
                    disabled={disabled}
                    required={required}
                    autoComplete={autoComplete}
                    multiSelectable={multiSelectable}
                    selectedItemsAriaLabel={selectedItemsAriaLabel ?? ""}
                    aria-labelledby={ariaLabelledBy}
                    onChange={ex(handleInputChange, onChange)}
                    {...props}
                />
                <PdsDropdown
                    as="ul"
                    role="listbox"
                    placement={placement}
                    open={isOpen}
                    id={selectListboxId}
                    trigger={inputWrapperRef}
                    container={container}
                    style={{ minWidth: inputWidth, maxWidth: inputWidth * 1.5 }}
                    disableAutofocus={autoComplete || disableAutofocus}
                    disableDismissByClickInside={disableDismissByClickInside}
                    disableDismissByClickOutside={disableDismissByClickOutside}
                    disableDismissByEscKeydown={disableDismissByEscKeydown}
                    aria-required={required ? "true" : undefined}
                    aria-readonly={readOnly ? "true" : undefined}
                    aria-labelledby={ariaLabelledBy}
                    aria-multiselectable={multiSelectable ? "true" : undefined}
                    className="mt-0.5"
                    onOpen={open}
                    onClose={close}
                >
                    {children}
                    {items.length === 0 && epmtyEl}
                </PdsDropdown>
            </PdsSelectContextProvider>
        );
    }
) as unknown as PdsSelectNamespace &
    (<T extends unknown = string>(
        props: PdsSelectProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
    ) => ReactElement | null);

PdsSelect.Item = PdsSelectItem;
PdsSelect.Section = PdsDropdown.Section;
PdsSelect.Empty = PdsSelectEmpty;

import type { InheritableElementProps, Merge } from "@/typings/utilities";
import type { PdsSkeletonProps } from "@/components/skeleton";

export const PDS_TABLE_SKELETON_COUNT = 5;

export type PdsTableProps = InheritableElementProps<
    "table",
    {
        /**
         * If `true`, the table has a default skeleton loading state.
         * @default false
         */
        loading?: boolean;
        /**
         * If `true`, the table has a different background color on every other row.
         * @default false
         */
        striped?: boolean;
        /**
         * If `true`, the table has a sticky header row.
         * @default false
         */
        stickyHeader?: boolean;
        /**
         * If `true`, the table has a sticky first column.
         * @default false
         */
        stickyFirstColumn?: boolean;
    }
>;

export type PdsTableSkeletonProps = Merge<
    PdsSkeletonProps,
    {
        /**
         * The default number of rows the table skeleton would display.
         * @default 5
         */
        rows?: number;
        /**
         * The default number of columns the table skeleton would display.
         * @default 5
         */
        columns?: number;
    }
>;

export type PdsTableCaptionProps = InheritableElementProps<
    "caption",
    {
        /**
         * If `true`, the caption is for screen-readers access only & not visible.
         * @default false
         */
        srOnly?: boolean;
    }
>;

export type PdsTableHeaderProps = InheritableElementProps<"thead", {}>;
export type PdsTableBodyProps = InheritableElementProps<"tbody", {}>;
export type PdsTableFooterProps = InheritableElementProps<"tfoot", {}>;

export type PdsTableRowProps = InheritableElementProps<"tr", {}>;

export type PdsTableHeaderCellProps = InheritableElementProps<"th", {}>;
export type PdsTableCellProps = InheritableElementProps<
    "td",
    {
        /**
         * The horizontal alignment of the content within a table cell.
         * @default "start"
         */
        align?: "start" | "end" | "center";
        /**
         * The vertical alignment of the content within a table cell.
         * @default "middle"
         */
        verticalAlign?: "top" | "bottom" | "middle";
        /**
         * If `true`, the cell is treated as the header of the entire row.
         * If `stickyFirstColumn` is true, this cell would be the only floating on top of other cells.
         * @default false
         */
        rowHeader?: boolean;
    }
>;

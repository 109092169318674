/**
 * Default tailwind screen breakpoint: https://tailwindcss.com/docs/screens
 */
const screenBreakpoints = {
  'sm': '640px',

  'md': '768px',

  'lg': '1024px',

  'xl': '1280px',

  '2xl': '1536px',
}

module.exports = {
  ...screenBreakpoints
}
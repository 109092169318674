import type { ElementType, ReactElement } from "react";
import type { WithAsProps } from "@/typings/utilities";
import type { PdsLinkIconProps } from "./type";

import { createElement, Children, cloneElement } from "react";

import { polymorphComponent, tx } from "@/libs";

export const PdsLinkIcon = polymorphComponent(
    ({ as, children, className, ...props }: WithAsProps<ElementType, PdsLinkIconProps>) => {
        if (as) {
            return createElement(as, {
                size: "xsmall",
                className,
                ...props,
            });
        }

        if (children) {
            const iconChild = Children.only(children) as ReactElement;

            return cloneElement(iconChild, {
                size: "xsmall",
                ...props,
                ...iconChild.props,
                className: tx(iconChild.props?.className, className),
            });
        }

        throw new Error("PdsLink.Icon must have either an `as` prop or a `children` prop");
    }
);

export const PdsLinkLeading = polymorphComponent(PdsLinkIcon, ({ className, ...props }) => ({
    "aria-hidden": true,
    size: "xsmall",
    className: tx("me-2", className),
    ...props,
}));

export const PdsLinkTrailing = polymorphComponent(PdsLinkIcon, ({ className, ...props }) => ({
    "aria-hidden": true,
    size: "xsmall",
    className: tx("ms-2 -me-2", className),
    ...props,
}));

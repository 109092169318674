import type { InheritableElementProps } from "@/typings/utilities";

import { forwardRef } from "react";
import { createPortal } from "react-dom";

import { tx } from "@/libs";
import { PdsThemeContainer, usePdsThemeContainer } from "@/styles/theme";

export type PdsPortalProps = InheritableElementProps<
    "div",
    {
        /**
         * The container element in which the overlay portal will be placed.
         * @default document.body
         */
        container?: HTMLElement | null;
    }
>;

/**
 * `PdsPortal` handles the rendering of content into another DOM destination other than its direct parent.
 *
 * `PdsPortal` uses [@react-aria/overlays](https://react-spectrum.adobe.com/react-aria/useOverlay.html) to handle its portals.
 *
 * MFE's specific key and theme are automatically injected before content to ensure that all children are rendered properly regardless of destination.
 */
export const PdsPortal = forwardRef<HTMLDivElement, PdsPortalProps>(
    ({ container, children, className, ...props }: PdsPortalProps, ref) => {
        /** App container key to ensure Tailwind works when injected into body */
        const { mfeKey, theme } = usePdsThemeContainer();

        /** Search for the mfe app shell & attempts to render into it by default. */
        const defaultContainer = document.querySelector<HTMLElement>(`[data-mfe-key="${mfeKey}"]`);

        return createPortal(
            <PdsThemeContainer ref={ref} mfeKey={mfeKey} theme={theme} className={tx(mfeKey, className)} {...props}>
                {children}
            </PdsThemeContainer>,
            container ?? defaultContainer ?? document.body
        );
    }
);

import React, { useEffect } from "react";
import SignupEmail from "@pages/signup/step-email";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import SignupPayer from "@pages/signup/step-payer";
import { LearnersProvider } from "@common/context/learner";
import SignupLearner from "@pages/signup/step-learners";
import SignupComplete from "@pages/signup/step-complete";
import { PayerProvider } from "@common/context/payer";
import { CompleteGuard, LearnersGuard, RegisterGuard } from "@pages/signup/guards";
import { HubspotTag } from "@pages/signup/hubspot";

const SignUp = () => {
    const { path } = useRouteMatch();

    useEffect(() => {
        window.location.assign("https://www.educationperfect.com/goodbye-to-ep-for-home/");
    }, []);

    return (
        <div className="w-full">
            <HubspotTag>
                <Switch>
                    <Route path={[`${path}/start`, `${path}/exists`, `${path}/validate`]} exact>
                        <SignupEmail />
                    </Route>
                    <Route path={`${path}/register`} exact>
                        <RegisterGuard>
                            <PayerProvider>
                                <SignupPayer />
                            </PayerProvider>
                        </RegisterGuard>
                    </Route>
                    <Route path={`${path}/learners`} exact>
                        <LearnersGuard>
                            <LearnersProvider>
                                <SignupLearner />
                            </LearnersProvider>
                        </LearnersGuard>
                    </Route>
                    <Route path={`${path}/complete`} exact>
                        <CompleteGuard>
                            <SignupComplete />
                        </CompleteGuard>
                    </Route>
                </Switch>
            </HubspotTag>
        </div>
    );
};

export default SignUp;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEdit = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M9.94 16.61l-2.12-2.12L16.31 6l2.12 2.12-8.49 8.49zM4.8 15.42l4.21 4.2-3.8.79a1 1 0 0 1-1.19-1.18l.78-3.81zM16.3 4a2 2 0 0 1 1.42.58l2.12 2.13a2 2 0 0 1 0 2.83l-9.75 9.75-4.95-4.95 9.75-9.75A2 2 0 0 1 16.31 4z" />
            </svg>
        );
    }
);

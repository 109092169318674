import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconActivitySmartLesson = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path
                    d="M5.2 3h11.2L21 7.5v11.2c0 1.2-1 2.2-2.2 2.2H5.2C4 21 3 20 3 18.8V5.2C3 4 4 3 5.2 3z"
                    fill="#ffb100"
                />
                <path
                    d="M12 6c-2.5 0-4.5 2-4.5 4.5 0 1.7 1.7 3.4 1.7 3.4.3.3.5.8.5 1.2s.3.7.8.7h3c.4 0 .8-.3.8-.7 0-.4.2-.9.5-1.2 0 0 1.7-1.7 1.7-3.4C16.5 8 14.5 6 12 6zm0 1.5c1.7 0 3 1.3 3 3 0 .8-.9 1.9-1.3 2.3-.4.4-.7.9-.9 1.4H11c-.2-.5-.4-1-.8-1.4-.3-.4-1.2-1.5-1.2-2.3 0-1.7 1.3-3 3-3zm2.2 9.7c0-.4-.3-.8-.8-.8h-3c-.4 0-.8.3-.8.8 0 .4.3.8.8.8h3c.5 0 .8-.3.8-.8z"
                    fill="#ffffff"
                />
            </svg>
        );
    }
);

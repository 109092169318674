import type { PdsStepperProps } from "./type";

import { createContext, useContext } from "react";

export type PdsStepperContextProps = Pick<PdsStepperProps, "activeStep" | "nonLinear" | "orientation"> & {
    length?: number;
    completedSteps?: number[];
    handleStepChange?: (index: number) => void;
};

export const PdsStepperContext = createContext<PdsStepperContextProps>({});

export const useStepperContext = () => useContext(PdsStepperContext);

import type { PdsTableProps } from "@/components/table/type";

import { createContext, useContext } from "react";

export const PdsTableContext = createContext<PdsTableProps>({
    stickyHeader: false,
    stickyFirstColumn: false,
});

export const usePdsTableContext = () => useContext(PdsTableContext);

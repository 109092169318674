import type { ProgressBarAria } from "@react-aria/progress";
import type { AriaProgressBarProps } from "@react-types/progress";

import { useProgressBar } from "@react-aria/progress";
import styled from "styled-components";
import tw from "twin.macro";

export type ProgressBarSize = "base" | "large";

export type ProgressaBarTypes = "positive" | "negative" | "warning" | "neutral";

export type ProgressBarProps = {
    /** Define the size of a progress bar */
    size?: ProgressBarSize;
    /** The type of progress bar  */
    type?: ProgressaBarTypes;
    /** A percentage value shows how much progress is completed. */
    value: number;
    /** Add aria-label for accessibility for propgressbar */
    label?: string;
} & AriaProgressBarProps;

// The styled component's interface.
type StyledProgressBarProps = {
    size?: ProgressBarSize;
    type?: ProgressaBarTypes;
    barWidth?: string;
};

const StyledProgressBar = styled.div<StyledProgressBarProps>`
    ${tw`rounded-l-[99px]`};
    ${(props) => props.barWidth && `width: ${props.barWidth}`};
    ${(props) => getVariantColorStyle(props.type)};
    ${(props) => getSizeStyle(props.size)};
`;
const StyledWrapper = styled.div<StyledProgressBarProps>`
    ${tw`w-full bg-neutral-300 rounded-[99px]`};
`;

const getSizeStyle = (size: ProgressBarSize | undefined) => {
    switch (size) {
        case "base":
            return tw`h-1.5`;
        case "large":
            return tw`h-3`;
        default:
            return tw`h-1.5`;
    }
};

const getVariantColorStyle = (type: ProgressaBarTypes | undefined) => {
    switch (type) {
        case "positive":
            return tw`bg-green-500`;
        case "negative":
            return tw`bg-red-600`;
        case "warning":
            return tw`bg-gold-600`;
        case "neutral":
            return tw`bg-neutral-800`;
        default:
            return tw`bg-neutral-800`;
    }
};

const validateValue = (value: number, minValue: number, maxValue: number) => {
    if (value < minValue || value > maxValue) {
        throw new Error(`Your value should be in range [${minValue}, ${maxValue}]`);
    }
};

export const PdsProgressBar = ({
    size = "base",
    type = "neutral",
    value,
    label,
    ...props
}: ProgressBarProps): JSX.Element => {
    const showValueLabel = false;
    const minValue = 0;
    const maxValue = 100;
    validateValue(value, minValue, maxValue);
    const progressBarPropsForHook: AriaProgressBarProps = {
        showValueLabel,
        value,
        minValue,
        maxValue,
    };
    const { progressBarProps }: ProgressBarAria = useProgressBar(progressBarPropsForHook);
    const percentage = (value - minValue) / (maxValue - minValue);
    const barWidth = `${Math.round(percentage * 100)}%`;

    return (
        <StyledWrapper {...progressBarProps} aria-label={label}>
            <StyledProgressBar size={size} type={type} {...props} barWidth={barWidth} />
        </StyledWrapper>
    );
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconTarget = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path
                    fillRule="evenodd"
                    d="M12 2A10.003 10.003 0 0 0 2 12c0 5.52 4.48 10 10 10S22 17.52 22 12 17.52 2 12 2Zm0 4.999A5.002 5.002 0 0 0 7 12c0 2.76 2.24 5 5 5S17 14.76 17 12 14.76 7 12 7ZM4 12A7.998 7.998 0 0 0 12 20 7.998 7.998 0 0 0 20 12 7.998 7.998 0 0 0 12 4 7.998 7.998 0 0 0 4 12Z"
                />
            </svg>
        );
    }
);

import type { UseMultiSelectValues } from "@/hooks";
import type { Context, ProviderProps } from "react";

import { createContext, useContext } from "react";

type PdsDropdownSelectContextProps<T extends unknown = string> = UseMultiSelectValues<T>;

export const PdsDropdownSelectContext = createContext<PdsDropdownSelectContextProps<string>>([
    [],
    {
        toggle: () => {},
        select: () => {},
        unselect: () => {},
        reset: () => {},
        isEqual: () => false,
    },
]);

export const PdsDropdownSelectContextProvider = <T extends unknown = string>({
    value,
    children,
}: ProviderProps<PdsDropdownSelectContextProps<T>>) => (
    <PdsDropdownSelectContext.Provider value={value as unknown as PdsDropdownSelectContextProps<string>}>
        {children}
    </PdsDropdownSelectContext.Provider>
);

export const usePdsDropdownSelectContext = <T extends unknown = string>() => {
    return useContext(PdsDropdownSelectContext as unknown as Context<PdsDropdownSelectContextProps<T>>);
};

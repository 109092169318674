import type { PdsAvatarProps, PdsAvatarVariants } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useMemo } from "react";

import { tx } from "@/libs";

export const PDS_AVATAR_VARIANT_ENUMS: PdsAvatarVariants[] = [
    "neutral",
    "red",
    "gold",
    "green",
    "blue",
    "purple",
    "pink",
    "white",
];

export const getAvatarInitials = (name: string) => {
    const nameArr = name.split(" ");

    if (!name || nameArr.length === 0) {
        return "?";
    }

    const lastIndex = nameArr.length - 1;
    const firstName = nameArr[0];
    const lastName = nameArr[lastIndex];

    if (lastIndex === 0) {
        return firstName?.[0];
    }

    return `${firstName?.[0]}${lastName?.[0]}`.toLocaleUpperCase();
};

export const getAvatarDefaultVariant = (name: string) => {
    const nameArr = name.split("");
    const nameHash = nameArr.reduce((hash, char) => hash + char.charCodeAt(0), 0);

    return PDS_AVATAR_VARIANT_ENUMS[nameHash % PDS_AVATAR_VARIANT_ENUMS.length];
};

/**
 * `PdsAvatar` is a visual representation of a user or object. The component displays a image or
 * the initials of that user or object.
 *
 * - The user's initials are inferred via the `title` props & assistively displayed using an `abbr` HTML tag
 * - If an image is displayed, the avatar's `title` props is the fallback `alt` of such image
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=19%3A1012).
 */
export const PdsAvatar = forwardRef<HTMLDivElement, PdsAvatarProps>(
    (
        { id, size = "medium", variant: variantProps, src, title, className, children, ...props }: PdsAvatarProps,
        ref
    ) => {
        if (children) {
            throw new Error("PdsAvatar must not have any children");
        }

        /** Memo setup for the avatar id and alt. */
        const avatarId = useMemo(() => id ?? `pds-avatar-${nanoid()}`, [id]);
        const altName = useMemo(() => getAvatarInitials(title), [title]);
        const variant = useMemo(() => variantProps ?? getAvatarDefaultVariant(title), [variantProps, title]);

        return (
            <div
                ref={ref}
                id={avatarId}
                className={tx(
                    "relative flex flex-shrink-0 items-center justify-center overflow-hidden rounded-full font-bold text-text-default",
                    {
                        "h-6 w-6 text-xs": size === "xsmall",
                        "h-8 w-8 text-sm": size === "small",
                        "h-16 w-16 text-2xl": size === "medium",
                        "h-24 w-24 text-4xl font-extrabold": size === "large",
                        "h-32 w-32 text-6xl font-extrabold": size === "xlarge",
                        "bg-neutral-200": variant === "neutral",
                        "bg-red-200": variant === "red",
                        "bg-gold-200": variant === "gold",
                        "bg-green-200": variant === "green",
                        "bg-blue-200": variant === "blue",
                        "bg-purple-200": variant === "purple",
                        "bg-pink-200": variant === "pink",
                        "bg-white ring-1 ring-inset ring-neutral-300": variant === "white",
                    },
                    className
                )}
                {...props}
            >
                {src ? (
                    <img src={src} alt={title} className="min-h-full min-w-full flex-shrink-0 object-cover" />
                ) : (
                    <abbr title={title} className="!cursor-auto !no-underline">
                        {altName}
                    </abbr>
                )}
            </div>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconRandom = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M15.48 8h1.11l-.29.29A.97.97 0 0 0 16 9a1 1 0 0 0 1.71.71l2-2A1 1 0 0 0 20 7a1 1 0 0 0-.29-.71l-2-2a1 1 0 0 0-1.42 1.42l.3.29H15a1 1 0 0 0-.77.38l-.01-.01-1.94 2.43 1.28 1.6L15.48 8zm2.23 6.29a1 1 0 0 0-1.42 1.42l.3.29h-1.11l-7.7-9.62h-.01A1 1 0 0 0 7 6H5a1 1 0 0 0-1 1 1 1 0 0 0 1 1h1.52l7.7 9.62.01-.01c.18.23.45.39.77.39h1.59l-.29.29a.97.97 0 0 0-.3.71 1 1 0 0 0 1.71.71l2-2A1 1 0 0 0 20 17a1 1 0 0 0-.29-.71l-2-2zM6.52 16H5a1 1 0 0 0-1 1 1 1 0 0 0 1 1h2a1 1 0 0 0 .77-.38l.01.01 1.94-2.42-1.28-1.61L6.52 16z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconTimer = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M14 1h-4a1 1 0 0 0-1 1 1 1 0 0 0 1 1h4a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm-2 13a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4a1 1 0 0 0 1 1zm7.03-6.61l.75-.75a1 1 0 0 0 0-1.4l-.01-.01a1 1 0 0 0-1.4 0l-.75.75A8.96 8.96 0 0 0 12 4a9.1 9.1 0 0 0-9 8.76 9 9 0 1 0 16.03-5.37zM12 20a7 7 0 1 1-.01-13.99A7 7 0 0 1 12 20z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconForceSubmit = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M15 2a2 2 0 0 1 2 2v4h-2V4H5v16h10v-8h2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h10zM9 12a1 1 0 0 1 1 .88V13a1 1 0 0 1-1 1H7a1 1 0 1 1 0-2h2zm9.54-4.47l.07.04 3.2 2.09a.4.4 0 0 1 .07.63l-.06.05-3.21 2.1a.4.4 0 0 1-.6-.27L18 12.1V11H7a1 1 0 0 1 0-2h11V7.9c0-.29.29-.48.54-.37zM13 6a1 1 0 0 1 1 .88V7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1 1 1 0 0 1 1-1h6z" />
            </svg>
        );
    }
);

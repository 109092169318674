import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsLinkProps } from "./type";

import { forwardRef, useMemo } from "react";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { nanoid } from "nanoid";

import { getChildByType, removeChildrenByType, tx } from "@/libs";

import { PdsLinkLeading, PdsLinkTrailing } from "./LinkIcon";

type PdsLinkNamespace = {
    Leading: typeof PdsLinkLeading;
    Trailing: typeof PdsLinkTrailing;
};

/**
 * `PdsLink` provides a pre-styled customisation of the anchor elements with PDS-specific theme colors & typography.
 *
 * `PdsLink` comes with recommended features:
 * - `Tab` to focus onto the link & `Enter` while focused opens it.
 * - `PdsLink.Leading` & `PdsLink.Trailing` to provide default spacing for injecting icons to the link
 *
 * Keep in mind that most internal links that redirects to EP's micro-frontends would have to take advantage of withMfeLink() higher-order component,
 * it is recommeneded `PdsLink`'s default use case is to redirect externally, hence opening a new tab by default.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=1468%3A8370).
 */
export const PdsLink = forwardRef<HTMLAnchorElement, PdsLinkProps>(
    ({ id, size = "medium", children, className, onKeyDown, ...props }: PdsLinkProps, ref) => {
        const linkId = useMemo(() => id ?? `pds-link-${nanoid()}`, [id]);

        const { isHovered, hoverProps } = useHover({});

        const leadingEl = getChildByType(children, "PdsLinkLeading");
        const trailingEl = getChildByType(children, "PdsLinkTrailing");
        const content = removeChildrenByType(children, ["PdsLinkLeading", "PdsLinkTrailing"]);

        return (
            <a
                ref={ref}
                id={linkId}
                role="link"
                tabIndex={0}
                onKeyDown={onKeyDown}
                className={tx(
                    "focusable inline-flex items-center rounded-xs font-medium text-primary",
                    {
                        "text-xs": size === "small",
                        "text-sm": size === "medium",
                        "text-base": size === "large",
                        "text-theme-800 underline": isHovered,
                    },
                    className
                )}
                {...mergeProps(hoverProps, props)}
            >
                {leadingEl}
                {content}
                {trailingEl}
            </a>
        );
    }
) as CompoundComponentWithRef<PdsLinkNamespace, PdsLinkProps, HTMLAnchorElement>;

PdsLink.Leading = PdsLinkLeading;
PdsLink.Trailing = PdsLinkTrailing;

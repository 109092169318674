import type { MutableRefObject } from "react";

import { useState, useRef, useEffect } from "react";

const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return (ref as MutableRefObject<T | undefined>).current;
};

export type UserFilterableProps<T extends unknown = string> = {
    keyword?: string;
    values?: T[];
    predicate?: (value: T, keyword: string) => boolean;
};

export const useFilterable = <T extends unknown = string>({
    keyword = "",
    values = [],
    predicate = (value: T, key: string) => (value as string).toLocaleLowerCase().includes(key.toLocaleLowerCase()),
}: UserFilterableProps<T>) => {
    const prevKey = usePrevious(keyword);
    const [filteredValues, setFilteredValues] = useState<T[]>([]);

    useEffect(
        () => {
            if (prevKey?.includes(keyword)) {
                setFilteredValues(filteredValues.filter((value) => predicate(value, keyword)));
            }

            setFilteredValues(values.filter((value) => predicate(value, keyword)));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [keyword, values]
    );

    return { filteredValues };
};

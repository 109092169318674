import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconPin = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M18.12 19.36a1 1 0 1 1-1.74 1l-3.5-6.06-3.35 1.94a1 1 0 0 1-1.5-1c.28-2.02.2-3.48-.2-4.38A10.82 10.82 0 0 0 5.12 7.6a1 1 0 0 1 .16-1.61l5.97-3.45a1 1 0 0 1 1.47.64c.43 1.84.93 3.17 1.52 3.99.55.77 1.77 1.56 3.67 2.38a1 1 0 0 1 .1 1.79l-3.4 1.96 3.5 6.06z" />
            </svg>
        );
    }
);

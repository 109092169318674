import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { isEmpty } from "lodash";

import App from "./App";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GDM || "",
};

if (!isEmpty(tagManagerArgs.gtmId)) {
    TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<App />, document.getElementById("root"));

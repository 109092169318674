import getSymbolFromCurrency from "currency-symbol-map";
import { Learner } from "@api/learner";
import api, { useApi } from ".";

export interface Payer {
    email: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    state?: string;
    type?: string;
    intent?: string;
}

export interface Product {
    id: number;
    createdAt: string;
    countryCode: string;
    currencyCode: string;
    numberOfLearners: number;
    numberOfSubjects: number;
    guid: string;
}

export interface Price {
    id: number;
    createdAt: string;
    productId: number;
    price: number;
    frequency: string;
    guid: string;
    countryCode: string;
    currencyCode: string;
    subtitle: string;
    allowsDiscounts: boolean;
    sources: PriceSource[];
}

export interface PriceSource {
    id: number;
    createdAt: string;
    productPriceId: number;
    source: string;
    guid: string;
}

export interface Subscription {
    id: number;
    guid: string;
    status: string;
    source?: string;
    currencyCode: string;
    curriculumCode: string;
    payer: Payer;
    createdAt: string;
    lastPaymentAt?: string;
    nextPaymentAt?: string;
    productPriceId?: number;
    learners: Learner[];
    product: Product;
    prices: Price[];
}

interface SubscriptionResponse {
    subscription: Subscription;
}

interface SubscriptionModel {
    product: string;
    payer: Payer;
    learners: Learner[];
    intent?: string;
    type?: string;
    params?: string[];
    affiliateName?: string;
    affiliate_ref?: object;
}

interface TrialPeriodResponse {
    trialPeriod: number;
}

export function getFrequencyLabel(price?: Price) {
    if (price) {
        switch (price.frequency) {
            case "yearly":
                return "year";
            case "daily":
                return "day";
            default:
                return "month";
        }
    }
    return "";
}

export function getPriceLabel(price?: Price) {
    return price ? `${price.currencyCode} ${getSymbolFromCurrency(price.currencyCode)}${price.price}` : "";
}

export function getPrice(subscription: Subscription, price: string): Price | undefined {
    return subscription!.prices.find((p) => p.guid === price);
}

export function getPriceDescription(plan: Price): string {
    return `EP for Home ${getPriceLabel(plan)} per ${getFrequencyLabel(plan)}`;
}

export function useSubscription() {
    const id = "5d23f805-a44d-4c8f-82a3-47163818e77f";
    const { data, ...rest } = useApi<SubscriptionResponse>(`/user/subscriptions/${id}`);
    return { subscription: data?.subscription, ...rest };
}
export function useTrialPeriod() {
    const { data, ...rest } = useApi<TrialPeriodResponse>("/common/trial-period");
    return { data, ...rest };
}

export const createSubscription = async (subscription: SubscriptionModel): Promise<Subscription> => {
    const res = await api.post<SubscriptionResponse>("subscriptions", subscription);
    return res.data.subscription;
};

import type { ElementType } from "react";
import type { PdsIconProps } from "@/components/icons";
import type { PropsOf, PolymorphicRef, PolymorphicProps, CompoundPolymorphicElement } from "@/typings/utilities";
import type { PdsDropdownItemProps } from "./type";

import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { useFocusManager } from "@react-aria/focus";
import { useState, useImperativeHandle, forwardRef } from "react";

import { useKeyDown, useRtL, useFocus } from "@/hooks";
import { ex, tx, getChildByType, removeChildrenByType, polymorphComponent } from "@/libs";

import { usePdsDropdownContext } from "./context";

const PdsDropdownItemWrapper = polymorphComponent("button");

/**
 * `PdsDropdownItem.Leading` is a reserved slot with default padding to ensure spacing within the item.
 *
 * It is styled as a decorative & medium-sized `PdsIcon` by default.
 */
export const PdsDropdownItemLeading = polymorphComponent<PdsIconProps, ElementType>(
    "div",
    ({ className, ...props }: PdsIconProps) => ({
        "aria-hidden": true,
        className: tx("me-2", className),
        ...props,
    })
);

/**
 * `PdsDropdownItem.Label` displays the label of the dropdown item.
 */
export const PdsDropdownItemLabel = polymorphComponent("div");

/**
 * `PdsDropdown.Item` displays a single menu item in a `PdsDropdown`.
 *
 * - `PdsDropdown.Item` is a button by default.
 * - `PdsDropdown.Item` has a [role='menuitem'](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/menuitem_role) by default.
 * - `PdsDropdown.Item.Leading` is `PdsIcon` by default, and should be decorative in most use cases.
 * - `PdsDropdown.Item.Label` is `p` by default.
 *
 * `PdsDropdown.Item` needs focus to trigger its keydown event handlers. For sake of keyboard & hover interactivity
 * consistency, the focused item is the one being selected.
 */
export const PdsDropdownItem = forwardRef(
    <E extends ElementType = "button">(
        {
            as = "button" as E,
            type = "button",
            disabled = false,
            active = false,
            label,
            onKeyDown: onKeyDownProps,
            onMouseMove: onMouseMoveProps,
            onClick: onClickProps,
            className,
            children,
            ...props
        }: PolymorphicProps<E, PdsDropdownItemProps>,
        ref: PolymorphicRef<E>
    ) => {
        /**
         * Context & focus manager hooks.
         */
        const { disableAutofocus, handleItemClick } = usePdsDropdownContext();
        const { focusNext, focusPrevious, focusFirst, focusLast } = useFocusManager();

        /**
         * Focus & hover state.
         */
        const { isFocused, focusProps } = useFocus({ isDisabled: disabled });
        const { isHovered, hoverProps } = useHover({ isDisabled: disabled });

        /**
         * Ref handler for item element.
         */
        const [itemEl, setItemEl] = useState<HTMLButtonElement | null>(null);
        useImperativeHandle(ref, () => itemEl as HTMLButtonElement, [itemEl]);

        /**
         * RtL hook to detect diretion.
         */
        const { isRtL } = useRtL(itemEl);

        /**
         * Mouse move handler to focus on hover.
         */
        const onMouseMove = () => {
            if (!disableAutofocus) {
                itemEl?.focus();
            }
        };

        /**
         * Keydown hook to handle keyboard navigation.
         * RtL is taken into consideration.
         */
        const { onKeyDown } = useKeyDown({
            isDisabled: disabled,
            ArrowUp: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => focusPrevious({ wrap: true, tabbable: true }),
            },
            ArrowDown: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => focusNext({ wrap: true, tabbable: true }),
            },
            ArrowLeft: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => !isRtL && handleItemClick(),
            },
            ArrowRight: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => isRtL && handleItemClick(),
            },
            Home: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => focusFirst(),
            },
            End: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => focusLast(),
            },
        });

        /**
         * Subcomponent filters. Fallbacks to remaining children if none is found.
         */
        const leadingSubcomponent = getChildByType(children, "PdsDropdownItemLeading");
        const labelSubcomponent = getChildByType(children, "PdsDropdownItemLabel");
        const remainingChildren = removeChildrenByType(children, ["PdsDropdownItemLeading", "PdsDropdownItemLabel"]);

        return (
            <PdsDropdownItemWrapper
                as={as}
                ref={setItemEl}
                type={as === "button" ? type : undefined}
                role="menuitem"
                disabled={disabled}
                aria-disabled={disabled ? "true" : undefined}
                aria-current={active ? "true" : undefined}
                onMouseMove={ex(onMouseMove, onMouseMoveProps)}
                onKeyDown={ex(onKeyDown, onKeyDownProps)}
                onClick={ex(handleItemClick, onClickProps)}
                className={tx(
                    "mx-1 flex flex-1 cursor-pointer items-center justify-start rounded-sm px-3 py-2.5 outline-none text-start",
                    {
                        "bg-neutral-100 text-primary": active || isHovered || isFocused,
                    },
                    className
                )}
                {...(mergeProps(focusProps, hoverProps, props) as unknown as PropsOf<E>)}
            >
                {leadingSubcomponent}
                {labelSubcomponent ?? <PdsDropdownItemLabel>{label}</PdsDropdownItemLabel>}
                {remainingChildren}
            </PdsDropdownItemWrapper>
        );
    }
) as unknown as CompoundPolymorphicElement<
    {
        Leading: typeof PdsDropdownItemLeading;
        Label: typeof PdsDropdownItemLabel;
    },
    PdsDropdownItemProps
>;

PdsDropdownItem.Leading = PdsDropdownItemLeading;
PdsDropdownItem.Label = PdsDropdownItemLabel;

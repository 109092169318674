import React, { FC } from "react";
import { PdsAlert } from "@educationperfect/web-pds-react";

const AlertContactSupport: FC = () => (
    <PdsAlert className="my-5" alertType="error">
        <PdsAlert.Title>
            There was a problem signing up. Please
            <a
                className="underline decoration-3 ml-1"
                target="_blank"
                href="mailto:support@educationperfect.com"
                rel="noreferrer"
            >
                contact support
            </a>
            .
        </PdsAlert.Title>
    </PdsAlert>
);

export default AlertContactSupport;

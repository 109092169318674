import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconTrendUp = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19 8h-3a1 1 0 0 0-1 1 1 1 0 0 0 1 1h.59l-3.79 3.78-3.35-1.67v.01A1 1 0 0 0 9 12c-.16 0-.31.05-.44.11v-.01l-4 2v.01A1 1 0 0 0 4 15a1 1 0 0 0 1 1c.16 0 .31-.05.44-.11v.01L9 14.12l3.55 1.78v-.01a1 1 0 0 0 1.16-.18l4.29-4.3V12a1 1 0 0 0 1 1 1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z" />
            </svg>
        );
    }
);

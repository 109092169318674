import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconTasksHomework = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M4 13h3c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1s.4-1 1-1zm10-4H4c-.5 0-1 .4-1 1s.5 1 1 1h10c.6 0 1-.4 1-1s-.4-1-1-1zm0-4H4c-.5 0-1 .4-1 1s.5 1 1 1h10c.6 0 1-.4 1-1s-.4-1-1-1zm6.6 6.3c.5.5.5 1.2.1 1.6l-5.8 5.8c-.4.4-1.1.4-1.5 0l-3.2-3.2c-.4-.4-.4-1.1 0-1.5l.1-.1c.4-.4 1.1-.4 1.5 0l2.4 2.4 4.9-5c.4-.4 1.1-.4 1.5 0z" />
            </svg>
        );
    }
);

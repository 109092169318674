import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconInfinity = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M20.22 6.86c-2-.6-4.06-.04-5.39 1.29L12 10.66 10.48 12h.01L7.8 14.39a3.41 3.41 0 0 1-3.12.92 3.39 3.39 0 1 1 3.16-5.66l.47.41a1 1 0 1 0 1.33-1.5l-.42-.36A5.4 5.4 0 0 0 0 12a5.4 5.4 0 0 0 9.17 3.85l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39a3.41 3.41 0 0 1 3.12-.92 3.39 3.39 0 1 1-3.16 5.66l-.48-.42a1 1 0 1 0-1.33 1.5l.42.37a5.39 5.39 0 1 0 5.45-8.94z" />
            </svg>
        );
    }
);

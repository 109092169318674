import type { PdsButtonProps } from "@/components/button";
import type {
    PdsPaginationPreviousProps,
    PdsPaginationNextProps,
    PdsPaginationItemProps,
    PdsPaginationEllipsisProps,
} from "./type";

import { forwardRef } from "react";

import { ex, tx } from "@/libs";
import { PdsButton } from "@/components/button";
import { PdsIconChevronLeft, PdsIconChevronRight } from "@/components/icons";

import { usePdsPaginationContext } from "./context";

export const PdsPaginationPrevious = forwardRef<HTMLButtonElement, PdsPaginationPreviousProps>(
    ({ onClick, ...props }: PdsPaginationPreviousProps, ref) => {
        const { disabled, value, handlePreviousPageClick } = usePdsPaginationContext();

        return (
            <PdsButton
                ref={ref}
                disabled={disabled || value === 0}
                aria-label="Go to previous page"
                variant="tertiary"
                onClick={ex(handlePreviousPageClick, onClick)}
                {...(props as PdsButtonProps)}
            >
                <PdsButton.Icon as={PdsIconChevronLeft} />
            </PdsButton>
        );
    }
);

export const PdsPaginationNext = forwardRef<HTMLButtonElement, PdsPaginationNextProps>(
    ({ onClick, ...props }: PdsPaginationNextProps, ref) => {
        const { disabled, value, total = 0, handleNextPageClick } = usePdsPaginationContext();

        return (
            <PdsButton
                ref={ref}
                disabled={disabled || value === total - 1}
                aria-label="Go to next page"
                variant="tertiary"
                onClick={ex(handleNextPageClick, onClick)}
                {...(props as PdsButtonProps)}
            >
                <PdsButton.Icon as={PdsIconChevronRight} />
            </PdsButton>
        );
    }
);

export const PdsPaginationItem = forwardRef<HTMLButtonElement, PdsPaginationItemProps>(
    ({ index, selected = false, onClick, ...props }: PdsPaginationItemProps, ref) => {
        const { value, disabled, handleItemClick } = usePdsPaginationContext();

        return (
            <PdsButton
                ref={ref}
                disabled={disabled}
                aria-current={selected ? "page" : undefined}
                aria-label={`Go to page ${index + 1}`}
                variant={selected || value === index ? "primary" : "tertiary"}
                onClick={ex(() => handleItemClick?.(index), onClick)}
                {...(props as PdsButtonProps)}
            >
                {/** Minimize layout shift between single-digit & double-digit pages. */}
                <PdsButton.Icon>
                    <span className="min-w-6">{index + 1}</span>
                </PdsButton.Icon>
            </PdsButton>
        );
    }
);

export const PdsPaginationEllipsis = forwardRef<HTMLSpanElement, PdsPaginationEllipsisProps>(
    ({ className, onClick, children, ...props }: PdsPaginationEllipsisProps, ref) => {
        return (
            <span role="presentation" ref={ref} className={tx("px-4 py-2", className)} {...props}>
                ...
            </span>
        );
    }
);

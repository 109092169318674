import React, { FC, useMemo, useState } from "react";
import { createContext } from "@helpers/context";
import { Curriculum, CurriculumOption, useCurriculum } from "@api/learner";
import { PdsSelectOption } from "@educationperfect/web-pds-react";
import store, { useStore } from "@services/store";
import { loginRoute } from "@pages/signup/constants";

export interface ILearner {
    id: number;
    firstName: string;
    lastName: string;
    email?: string;
    academicLevelCode?: PdsSelectOption[];
    curriculumCode?: PdsSelectOption[];
    curriculumOptionCode?: PdsSelectOption[];
    countryCode?: string;
}

export const defaultLearner: ILearner = {
    id: 0,
    firstName: "",
    lastName: "",
};

interface ILearnersContext {
    learners: ILearner[];
    curriculums?: Curriculum[];
    curriculumOptions: CurriculumOption[] | [];
    setLearners: (learners: ILearner[] | ((learners: ILearner[]) => void)) => void;
    setCurriculumOptions: (curriculumOptions: CurriculumOption[] | []) => void;
    loginRoute: string;
}

const initState = {
    learners: [defaultLearner],
    curriculums: [],
    curriculumOptions: [],
    schoolYearsList: [],
};

/**
 * Learners context
 */
const [useLearnersContext, Provider] = createContext<ILearnersContext>();

/**
 * Learners context provider
 * @constructor
 */
export const LearnersProvider: FC = ({ children }) => {
    const storedLearners = store.get("forhome:learners") as ILearner[];
    const [learners, setLearners] = useState<any>(storedLearners || initState.learners);
    const [curriculumOptions, setCurriculumOptions] = useStore(
        "forhome:curriculumOptions",
        initState.curriculumOptions
    );
    const { curriculums } = useCurriculum();

    const value = useMemo(
        () => ({
            learners,
            curriculumOptions,
            curriculums,
            setLearners,
            setCurriculumOptions,
            loginRoute,
        }),
        [curriculumOptions, curriculums, learners, setCurriculumOptions, setLearners]
    );

    return <Provider value={value}>{children}</Provider>;
};

export { useLearnersContext, Provider };

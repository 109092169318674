import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorMergeCells = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M5 14v2.5c0 .83.67 1.5 1.5 1.5H9v2H6.5A3.5 3.5 0 0 1 3 16.5V14h2zm16 0v2.5a3.5 3.5 0 0 1-3.5 3.5H15v-2h2.5c.83 0 1.5-.67 1.5-1.5V14h2zM8 9l3 3-3 3v-2H4a1 1 0 0 1-.12-2H8V9zm8 0v2h4a1 1 0 0 1 .12 2H16v2l-3-3 3-3zM9 4v2H6.5C5.67 6 5 6.67 5 7.5V10H3V7.5A3.5 3.5 0 0 1 6.5 4H9zm8.5 0A3.5 3.5 0 0 1 21 7.5V10h-2V7.5c0-.83-.67-1.5-1.5-1.5H15V4h2.5z" />
            </svg>
        );
    }
);

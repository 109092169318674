import type { ElementType } from "react";
import type { CompoundPolymorphicElement, PolymorphicProps, PolymorphicRef } from "@/typings/utilities";
import type { PdsDropdownSectionProps } from "./type";

import { forwardRef } from "react";

import { polymorphComponent, tx } from "@/libs";

const PdsDropdownSectionWrapper = polymorphComponent("div");

/**
 * `PdsDropdown.Section.Label` displays a label for the entire section.
 */
export const PdsDropdownSectionLabel = polymorphComponent("p", ({ className, ...props }) => ({
    className: "px-4 py-1 text-xs font-semibold uppercase text-neutral-600",
    ...props,
}));

/**
 * `PdsDropdown.Section` handles basic spacing, adding a divider and/or a heading to a group of items.
 *
 * Contextually, it could need a attribute role="group" or be polymorphed into an `ul` or `ol` via the `as` props,
 * depending on the usage of the dropdown menu. Strongly recommended that you consider these aspects.
 *
 * Handle spacing & divider differently depending on its order in the dropdown menu.
 */
export const PdsDropdownSection = forwardRef(
    <E extends ElementType = "div">(
        {
            as,
            label,
            divider = false,
            first = false,
            last = false,
            className,
            children,
            ...props
        }: PolymorphicProps<E, PdsDropdownSectionProps>,
        ref: PolymorphicRef<E>
    ) => (
        <PdsDropdownSectionWrapper
            as={as}
            ref={ref}
            className={tx(
                "my-1 flex flex-col py-1",
                {
                    "border-t border-b border-neutral-200": divider,
                    "mt-0 border-t-0 pt-0": first,
                    "mb-0 border-b-0 pb-0": last,
                },
                className
            )}
            {...(props as any)}
        >
            {label && <PdsDropdownSectionLabel>{label}</PdsDropdownSectionLabel>}
            {children}
        </PdsDropdownSectionWrapper>
    )
) as unknown as CompoundPolymorphicElement<
    {
        Label: typeof PdsDropdownSectionLabel;
    },
    PdsDropdownSectionProps
>;

PdsDropdownSection.Label = PdsDropdownSectionLabel;

import type { PropsWithChildren } from "react";

import { useCallback, useEffect, useState } from "react";
import { useFocusManager } from "react-aria";

import { useKeyDown, usePrevious } from "@/hooks";

import { PdsDataGridContext } from "./context";

type PdsDataGridRovingTabProviderProps = PropsWithChildren<{
    value: {
        headerDepth: number;
        rowDepth: number;
        colDepth: number;
    };
}>;

export const PdsDataGridRovingTabProvider = ({
    value: { headerDepth, rowDepth, colDepth },
    children,
}: PdsDataGridRovingTabProviderProps) => {
    /** Roving tab-index that moves upon Arrow-key. */
    const [mode, setMode] = useState<"navigation" | "action">("navigation");
    const [activeCell, setActiveCell] = useState({ colIndex: 0, rowIndex: 0 });
    const prevActiveCell = usePrevious(activeCell);

    /** Focus scope manager to move current focus on tabindex roving. */
    const { focusNext, focusPrevious } = useFocusManager();

    /** Keydown handler to shift current active cell. */
    const { onKeyDown: handleCellKeydown } = useKeyDown({
        Escape: {
            stopPropagation: true,
            preventDefault: true,
            callback: () => setMode("navigation"),
        },
        Enter: {
            isDisabled: mode === "action",
            stopPropagation: true,
            preventDefault: true,
            callback: () => setMode("action"),
        },
        ArrowDown: {
            isDisabled: mode === "action",
            stopPropagation: true,
            preventDefault: true,
            callback: () =>
                setActiveCell((prev) => ({
                    ...prev,
                    rowIndex: Math.min(activeCell.rowIndex + 1, headerDepth + rowDepth - 1),
                })),
        },
        ArrowUp: {
            isDisabled: mode === "action",
            stopPropagation: true,
            preventDefault: true,
            callback: () => setActiveCell((prev) => ({ ...prev, rowIndex: Math.max(activeCell.rowIndex - 1, 0) })),
        },
        ArrowRight: {
            isDisabled: mode === "action",
            stopPropagation: true,
            preventDefault: true,
            callback: () =>
                setActiveCell((prev) => ({
                    ...prev,
                    colIndex: Math.min(activeCell.colIndex + 1, colDepth - 1),
                })),
        },
        ArrowLeft: {
            isDisabled: mode === "action",
            stopPropagation: true,
            preventDefault: true,
            callback: () => setActiveCell((prev) => ({ ...prev, colIndex: Math.max(activeCell.colIndex - 1, 0) })),
        },
    });

    /** Utiltiy checker for the cell to check itself if it should be focusable. */
    const isCellActive = useCallback(
        (i: number, j: number) => i === activeCell.rowIndex && j === activeCell.colIndex,
        [activeCell]
    );

    /** Handle roving focus to the next/prev upon changing active cell. */
    useEffect(() => {
        if (!prevActiveCell) {
            return;
        }

        if (activeCell.colIndex === prevActiveCell.colIndex && activeCell.rowIndex === prevActiveCell.rowIndex) {
            return;
        }

        if (activeCell.colIndex < prevActiveCell.colIndex || activeCell.rowIndex < prevActiveCell.rowIndex) {
            focusPrevious({ tabbable: true });
            return;
        }

        focusNext({ tabbable: true });
    }, [activeCell, prevActiveCell, focusNext, focusPrevious]);

    return (
        <PdsDataGridContext.Provider value={{ mode, handleCellKeydown, isCellActive }}>
            {children}
        </PdsDataGridContext.Provider>
    );
};

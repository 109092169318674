import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import usePrevious from "@helpers/usePrevious";
import { PdsSpinner, PdsAlert } from "@educationperfect/web-pds-react";
import combineCss from "@helpers/combineCss";
import style from "./style.module.css";

const data = Array(4).fill("");
const emptyIndex = data.length + 1;

interface ICodeInputGroup {
    sendCode: (code: string) => void;
    isFetching: boolean;
    isError?: boolean;
    setIsShowCodeError?: (isError: boolean) => void;
}

const CodeInputGroup: FC<ICodeInputGroup> = ({ sendCode, isFetching, isError, setIsShowCodeError }) => {
    const [values, setValues] = useState<Record<number, string>>({});
    const [activeIndex, setActiveIndex] = useState<number>(emptyIndex);
    const [prevHandler, setPrevHandler] = useState(false);

    const prevValues = usePrevious(values);

    /**
     * Set input value
     */
    const setValue = (e: any) => setValues({ ...values, [e.target.dataset.id]: e.target.value });

    /**
     * Inputs refs
     */
    const refs = useRef<any>({});

    /**
     * Save input refs
     */
    const addRef = useCallback((el, id) => {
        refs.current[id] = el;
    }, []);

    /**
     * Previous and next inputs refs
     */
    const nextRef = useMemo(() => refs?.current?.[Number(activeIndex) + 1], [activeIndex]);
    const prevRef = useMemo(() => refs?.current?.[Number(activeIndex) - 1], [activeIndex]);

    /**
     * Check if each symbol of the code is entered
     */
    const isFullyFilled = useMemo(
        () => Object.values(values).filter((val) => val.length === 1).length === data.length,
        [values]
    );

    /**
     * Focus next field only if not deleted previous
     */
    useEffect(() => {
        if (values?.[activeIndex]?.length === 1 && !prevHandler) {
            nextRef?.focus();
        }
    }, [activeIndex, nextRef, prevHandler, values]);

    /**
     * Focus the previous field if was deleted previous value
     */
    useEffect(() => {
        if (values?.[activeIndex]?.length === 0 && prevValues?.[activeIndex]?.length === 1) {
            prevRef?.focus();
            setPrevHandler(true);
        }
    }, [activeIndex, prevRef, prevValues, values]);

    /**
     * Stop blocking focusing next field if pin writing is continuing
     */
    useEffect(() => {
        if (values?.[activeIndex]?.length === 1 && prevValues?.[activeIndex]?.length === 0) {
            setPrevHandler(false);
        }
    }, [activeIndex, prevValues, values]);

    /**
     * Send code if it is filled
     */
    useEffect(() => {
        if (!isFullyFilled) {
            setIsShowCodeError?.(false);
            return;
        }

        const codeString = Object.values(values).join("");
        sendCode(codeString);
    }, [isFullyFilled, sendCode, setIsShowCodeError, values]);

    /**
     * Remove empty input values
     */
    const onKeyDown = useCallback(
        (e) => {
            if (["Backspace", "Delete"].includes(e.key) && !values[activeIndex] && activeIndex > 0) {
                setValues({ ...values, [activeIndex - 1]: "" });
                setTimeout(() => prevRef?.focus(), 50);
            }
        },
        [activeIndex, prevRef, values]
    );

    /**
     * Get pasted value and set it to values values obj by char
     */
    const onPaste = useCallback((e: any) => {
        const pastedData = e.clipboardData.getData("Text").slice(0, 4);
        let newCode = {};

        for (let i = 0; i < pastedData.length; i += 1) {
            newCode = { ...newCode, [i]: pastedData[i] };
        }

        setValues(newCode);
    }, []);

    /**
     * Handle active input
     */
    const setActive = (e: any) => setActiveIndex(e.target.dataset.id);

    const onRemoveFocus = () => setActiveIndex(emptyIndex);

    /* eslint-disable react/no-array-index-key  */
    /* eslint-disable jsx-a11y/no-autofocus */

    return (
        <div className={style.container}>
            <div className={style.inputs}>
                {data.map((_, index) => {
                    return (
                        <div
                            key={index}
                            data-name={`pinCode-${index}`}
                            className={combineCss(style.input, isError ? style.error : "")}
                        >
                            <input
                                data-id={index}
                                autoFocus={index === 0}
                                onPaste={onPaste}
                                data-active={index === Number(activeIndex)}
                                ref={(e) => addRef(e, index)}
                                maxLength={1}
                                onFocus={setActive}
                                onBlur={onRemoveFocus}
                                inputMode="numeric"
                                onInput={setValue}
                                onKeyDown={onKeyDown}
                                value={values[index] ?? ""}
                                aria-label="Email code input"
                            />
                        </div>
                    );
                })}
            </div>
            {isFetching && (
                <div className="flex justify-between gap-4 mt-5 text-center content-center">
                    <div className="text-inherit text-sm">Validating code...</div>
                    <PdsSpinner aria-label="Spinner" color="muted-text" size="small" />
                </div>
            )}
            {isError && (
                <PdsAlert className="mt-4" alertType="error">
                    <PdsAlert.Title>Invalid verification code. Please check your code and try again.</PdsAlert.Title>
                </PdsAlert>
            )}
        </div>
    );
};

export default CodeInputGroup;

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { PdsButton } from "@/components/button";
import { PdsIconClose } from "@/components/icons";

import { filterClassName } from "../../utils/filterClassName";

export type ToastVariants = "success" | "error" | "info" | "neutral";

export type ToastProps = {
    variant?: ToastVariants;
    /** The action to display. It renders after the message, at the end of the alert. */
    action?: React.ReactNode;
    /** The message of toast */
    message: string;
    /** The leading icon of toast. */
    icon?: React.ReactNode;
    /** Set to `false` to remove the icon. */
    hideCloseIcon?: boolean;
    /** Override the default label for the close popup icon button. */
    closeIcon?: React.ReactNode;
    /** Callback fired when the component requests to be closed. */
    onClose?: (event: React.SyntheticEvent) => void;
    /** Users can add spacing/sizing and screen-width specific but cannot modify the internal component desgin(color variant, font sizes, …). */
    className?: string;
} & Omit<React.HtmlHTMLAttributes<HTMLElement>, "className">;

type MessageProps = {
    hasIcon?: boolean;
};
const StyledToastWrapper = styled.div<ToastProps>`
    ${tw`flex p-4 rounded text-white shadow-xl duration-200 font-semibold text-sm`};
    ${(props) => getToastBgColor(props.variant)};
`;
const StyledMessage = styled.span<MessageProps>`
    ${tw`flex flex-[2.5] items-center`};
    ${(props) => props.hasIcon && tw` ml-4`};
`;
const StyledTrailing = styled.div`
    ${tw`flex justify-end ml-3`};
    button {
        ${tw`p-0 bg-neutral-1000 text-white`};
    }
`;
const StyledAction = styled.div`
    ${tw`flex justify-end cursor-pointer`};
`;

const getToastBgColor = (status?: ToastVariants) => {
    switch (status) {
        case "error":
            return tw`bg-red-600`;
        case "success":
            return tw`bg-green-600`;
        case "info":
            return tw`bg-blue-600`;
        case "neutral":
            return tw`bg-neutral-1000`;
        default:
            return tw`bg-neutral-1000`;
    }
};
export const PdsToast = ({
    icon,
    message,
    variant = "neutral",
    action,
    hideCloseIcon = false,
    closeIcon,
    className,
    onClose,
    ...props
}: ToastProps) => {
    return (
        <StyledToastWrapper
            variant={variant}
            {...props}
            role="alert"
            message={message}
            className={className && filterClassName(className, ["font", "text", "bg", "rounded"])}
        >
            {icon}
            <StyledMessage hasIcon={!!icon}>{message}</StyledMessage>

            <StyledTrailing>
                {action && <StyledAction>{action}</StyledAction>}
                {!hideCloseIcon && (
                    <PdsButton variant="tertiary" className="ml-2" onClick={onClose}>
                        {closeIcon || <PdsIconClose className="cursor-pointer" color="white" aria-label="Close" />}
                    </PdsButton>
                )}
            </StyledTrailing>
        </StyledToastWrapper>
    );
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorFunction = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M16.8 9.08c.53 0 .96-.43.96-.97v-1.9c0-.63-.51-1.15-1.15-1.15H7.13c-.43 0-.8.23-.97.6-.17.37-.11.8.16 1.13l4.6 5.5-4.56 4.88c-.3.32-.38.76-.21 1.15.17.38.54.62.98.62h9.48c.64 0 1.15-.52 1.15-1.15v-2.26a.97.97 0 0 0-1.94 0V17H9.18l3.62-3.88c.4-.43.42-1.1.04-1.55L9.03 7h6.8v1.11c0 .54.43.97.96.97z" />
            </svg>
        );
    }
);

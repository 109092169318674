import type { FocusScopeProps } from "@react-aria/focus";
import type { OverlayProps } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";

import { useButton } from "@react-aria/button";
import { FocusScope } from "@react-aria/focus";
import { OverlayContainer, OverlayProvider, useOverlay } from "@react-aria/overlays";
import React, { isValidElement, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { PdsThemeContainer, usePdsThemeContainer } from "@/styles/theme";

export type PdsOverlayProps = {
    /**
     * Whether to close the overlay when the user interacts outside it.
     * @default true
     */
    dismissable?: boolean;
    shouldCloseOnBlur?: boolean;
    isKeyboardDismissDisabled?: boolean;
    shouldCloseOnInteractOutside?: (element: HTMLElement) => boolean;
    contain?: boolean;
    restoreFocus?: boolean;
    autoFocus?: boolean;
    /**
     * Manages state for an overlay trigger. Tracks whether the overlay is open, and provides
     * methods to toggle this state.
     */
    state: OverlayTriggerState;
    portalContainer?: HTMLElement;
    children?: React.ReactNode;
} & Omit<OverlayProps & FocusScopeProps, "isDismissable" | "onClose" | "isOpen">;

const OverlayMask = styled.div<{ portalContainer: HTMLElement | undefined }>`
    ${(props) => (props.portalContainer ? tw`absolute` : tw`fixed`)};
    ${tw`z-50 flex items-center justify-center bg-neutral-1000 bg-opacity-60 inset-[0px]`};
`;

const filterNode = (nodes: React.ReactNode, name: string): any => {
    if (Array.isArray(nodes)) {
        return nodes.filter(
            (children: React.ReactNode) => isValidElement(children) && (children.type as any).name === name
        );
    }
    return isValidElement(nodes) && (nodes.type as any).name === name ? nodes : undefined;
};

export const PdsOverlay = ({
    children,
    dismissable = true,
    contain = true,
    restoreFocus = true,
    autoFocus = true,
    state,
    portalContainer,
    ...props
}: PdsOverlayProps): JSX.Element => {
    const maskRef = useRef<HTMLDivElement>(null);
    const ref = useRef<HTMLDivElement>(null);
    const { mfeKey, theme } = usePdsThemeContainer();

    const { overlayProps, underlayProps } = useOverlay(
        {
            isDismissable: dismissable,
            isOpen: state.isOpen,
            onClose: state.close,
            ...props,
        } as any,
        ref
    );

    const openButtonRef = useRef<HTMLDivElement | null>(null);
    const { buttonProps: openButtonProps } = useButton(
        {
            onPress: () => state.open(),
        },
        openButtonRef
    );

    const anchor = filterNode(children, PdsOverlayAnchor.name);
    const content = filterNode(children, PdsOverlayContent.name);

    return (
        <OverlayProvider>
            <div {...openButtonProps} ref={openButtonRef}>
                {anchor}
            </div>
            {state.isOpen && (
                <OverlayContainer portalContainer={portalContainer}>
                    <OverlayMask
                        {...underlayProps}
                        ref={maskRef}
                        portalContainer={portalContainer}
                        data-testid="overlay-mask"
                    >
                        <FocusScope contain={contain} restoreFocus={restoreFocus} autoFocus={autoFocus}>
                            <PdsThemeContainer mfeKey={mfeKey} theme={theme}>
                                <div {...overlayProps} ref={ref}>
                                    {content}
                                </div>
                            </PdsThemeContainer>
                        </FocusScope>
                    </OverlayMask>
                </OverlayContainer>
            )}
        </OverlayProvider>
    );
};

export const PdsOverlayAnchor = ({ children, ...props }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
    return <div {...props}>{children}</div>;
};
export const PdsOverlayContent = ({ children, ...props }: React.HTMLAttributes<HTMLElement>): JSX.Element => {
    return <div {...props}>{children}</div>;
};

PdsOverlay.Anchor = PdsOverlayAnchor;
PdsOverlay.Content = PdsOverlayContent;

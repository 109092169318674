import type { FunctionComponentElement, HTMLAttributes, ReactElement } from "react";
import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsPopoverProps } from "./type";

import { nanoid } from "nanoid";
import {
    cloneElement,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useState,
    isValidElement,
    useEffect,
} from "react";
import { mergeProps } from "@react-aria/utils";

import { ex, getChildByType, removeChildrenByType, rx } from "@/libs";
import { useBoolean, useClickOutside, useKeyDown, useMountTransition, useRtL } from "@/hooks";
import { PdsExtendable } from "@/components/extendable";

import { PdsPopoverContext } from "./context";
import { PdsPopoverPanel } from "./PopoverPanel";
import { PdsPopoverTrigger } from "./PopoverTrigger";
import { usePdsPopoverTrigger } from "./usePdsPopover";

type PdsPopoverNamespace = {
    Trigger: typeof PdsPopoverTrigger;
    Panel: typeof PdsPopoverPanel;
};

/**
 * `PdsPopover` can be used to display some content on top of another.
 *
 * By technicality, it is a non-modal dialog. It does not disable or block the main page content.
 *
 * - `PdsPopover` can be triggered by a user click from interactive element (semantic-ideally a button), as opposed to `PdsTooltip` triggered by a hover/focus.
 * - `PdsPopover` can be auto-dismissable via the same trigger, `Esc`-keydown, or click outside of the trigger & the popover.
 * - `PdsPopover` is implemented as a focus trap, since the position of the popover in the DOM tree is not guaranteed.
 *  to be next to the trigger. Focus is placed inside it when it's shown and return focus to the trigger when it's closed.
 * - `PdsPopover` ideally should be labeled, via `aria-labelledby` to a heading or directly via `aria-label` for better accessibility.
 *
 *
 * `PdsPopover.Trigger` by default is a `PdsButton`.
 *
 * - `PdsPopover.Trigger` trigger must be interactive/focusable and have a [role="button"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role)
 * - `PdsPopover.Trigger` by default uses [aria-haspopup="dialog"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-haspopup)
 * to assistively display that it is associated with a dialog element.
 * - `PdsPopover.Trigger` uses [aria-expanded](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-expanded)
 * to assistively display as open or close.
 * - `PdsPopover.Trigger` uses [aria-controls](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-controls)
 * to assistively set to the HTML id of the region toggled by the popover trigger.
 *
 * `PdsPopover` leverages `PdsExtendable` to handle positioning around an trigger.
 *
 * - `PdsPopover.Panel` should have a focusable element inside it.
 * - `PdsPopover.Panel` should have a close button so screen readers have a specific close action to target.
 * - `PdsPopover.Panel` content must  have a [role="dialog"](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/dialog_role)
 *
 * Design is available in Canvas mode in Design addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/?node-id=10886%3A9580).
 */
export const PdsPopover = forwardRef<HTMLDivElement, PdsPopoverProps>(
    (
        {
            id,
            role = "dialog",
            open: openProps,
            disabled = false,
            disableDismissByEscKeydown = false,
            disableDismissByClickOutside = false,
            disableAutofocus = false,
            disablePersistentCloseButton = false,
            placement: placementProps = "bottom-start",
            trigger,
            label,
            container,
            children,
            onOpen,
            onClose,
            onKeyDown: onKeyDownProps,
            ...props
        }: PdsPopoverProps,
        ref
    ) => {
        /**
         * Memo to initialize popover ID.
         */
        const popoverId = useMemo(() => id ?? `pds-popover-${nanoid()}`, [id]);

        /**
         * State hook to manage open state & a delay mount/unmount transition animation.
         * If popover is controlled, disable internal stateful control.
         */
        const isControlled = useMemo(() => openProps !== undefined, [openProps]);
        const [isOpen, { open, close }] = useBoolean({
            isDisabled: disabled || isControlled,
            initialValue: openProps,
        });

        /**
         * Transition hook to deplay unmounting till mounting/unmounting animation finishes.
         */
        const { hasTransitionedIn } = useMountTransition(isOpen);

        /**
         * Refs to handle popover positioning & trigger.
         * Forward using imperative handler & effect hook to keep track of trigger & panel refs.
         */
        const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
        const [panelRef, setPanelRef] = useState<HTMLElement | null>(null);

        useEffect(() => {
            if (trigger && !isValidElement(trigger)) {
                setTriggerRef(trigger as HTMLElement);
            }
        }, [trigger]);
        useImperativeHandle(ref, () => panelRef as HTMLDivElement, [panelRef]);

        /**
         * Hook to handle RtL directional attr.
         * Added open to deps list to rerender when portaled.
         */
        const { isRtL } = useRtL(triggerRef, [openProps]);
        const placement = useMemo(
            () => placementProps ?? (isRtL ? "bottom-end" : "bottom-start"),
            [isRtL, placementProps]
        );

        /**
         * Callback handlers for all interactions.
         */
        const handleOpen = useCallback(() => {
            open();
            onOpen?.();
        }, [onOpen, open]);

        const handleClose = useCallback(() => {
            close();
            onClose?.();
        }, [close, onClose]);

        const handleToggle = useCallback(isOpen ? handleClose : handleOpen, [handleClose, handleOpen, isOpen]);

        /**
         * Hooks to handle dismiss via clicking outside & `Esc` keydown.
         */
        useClickOutside(() => !disableDismissByClickOutside && handleClose(), [triggerRef, panelRef]);

        const { onKeyDown } = useKeyDown({
            isDisabled: disabled,
            Escape: {
                preventDefault: true,
                stopPropagation: true,
                callback: () => !disableDismissByEscKeydown && handleClose(),
            },
        });

        /**
         * Subcomponent filter. Only the first valid child is selected.
         * If none are selected, fallback to shorthand props.
         */
        const triggerShorthand = isValidElement(trigger) ? trigger : null;
        const triggerComponent = (getChildByType(children, "PdsPopoverTrigger") ?? triggerShorthand) as ReactElement;
        const panelComponent = getChildByType(children, "PdsPopoverPanel") as ReactElement;
        const remainingChildren = removeChildrenByType(children, ["PdsPopoverTrigger", "PdsPopoverPanel"]);

        /**
         * Collage all required props for trigger & panel.
         */
        const { triggerProps: triggerAriaProps } = usePdsPopoverTrigger({
            role,
            disabled,
            id: popoverId,
            open: isOpen || hasTransitionedIn,
        });
        const triggerProps = {
            ...triggerAriaProps,
            ref: rx(setTriggerRef, (triggerComponent as FunctionComponentElement<HTMLAttributes<HTMLElement>>)?.ref),
            onClick: handleToggle,
        };
        const extendableProps = {
            container,
            placement,
            open: isOpen || hasTransitionedIn,
            trigger: triggerRef,
        };
        const panelProps = {
            role,
            id: popoverId,
            disableAutofocus,
            disablePersistentCloseButton,
            "aria-label": label,
            ref: rx(setPanelRef, (panelComponent as FunctionComponentElement<HTMLAttributes<HTMLElement>>)?.ref),
            onKeyDown: ex(onKeyDown, onKeyDownProps),
            ...props,
        };

        return (
            <>
                {triggerComponent
                    ? cloneElement(triggerComponent, mergeProps(triggerComponent.props, triggerProps))
                    : null}
                <PdsPopoverContext.Provider value={{ isOpen: isOpen && hasTransitionedIn, placement, handleClose }}>
                    <PdsExtendable {...extendableProps}>
                        {panelComponent ? (
                            cloneElement(panelComponent, mergeProps(panelComponent.props, panelProps))
                        ) : (
                            <PdsPopoverPanel {...panelProps}>{label ?? remainingChildren}</PdsPopoverPanel>
                        )}
                    </PdsExtendable>
                </PdsPopoverContext.Provider>
            </>
        );
    }
) as CompoundComponentWithRef<PdsPopoverNamespace, PdsPopoverProps, HTMLDivElement>;

PdsPopover.Trigger = PdsPopoverTrigger;
PdsPopover.Panel = PdsPopoverPanel;

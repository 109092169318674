import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorClearColour = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.41 6.7l1.32 1.32a1 1 0 0 0 .09.1l9.9 9.9a1 1 0 0 0 .1.09l.62.62a1 1 0 1 1-1.42 1.41l-1.38-1.38A6 6 0 0 1 6 13.98a9.1 9.1 0 0 1 1.16-3.7L5 8.12a1 1 0 0 1 1.41-1.41zM12 4c.95 0 6 6.66 6 9.98a6 6 0 0 1-.15 1.35L9.31 6.79C10.48 5.18 11.61 4 12 4z" />
            </svg>
        );
    }
);

import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsInputNamespace } from "@/components/input";
import type { PdsSelectInputProps } from "./type";

import { nanoid } from "nanoid";
import { forwardRef, useCallback, useMemo } from "react";
import { mergeProps } from "@react-aria/utils";

import { ex, tx } from "@/libs";
import { useKeyDown, useMountTransition } from "@/hooks";
import { usePdsPopoverTrigger } from "@/components/popover";
import { PdsInput } from "@/components/input";
import { PdsBadge } from "@/components/badge";
import { PdsButton } from "@/components/button";
import { PdsIconChevronDown, PdsIconClose } from "@/components/icons";

import { usePdsSelectContext } from "./context";

/**
 * `PdsSelect.Input` by default is a PdsInput, but could be polymorphed to any elements for design customisability.
 */
export const PdsSelectInput = forwardRef<HTMLInputElement, PdsSelectInputProps>(
    (
        {
            id,
            value: valueProps,
            placeholder,
            disabled = false,
            required = false,
            multiSelectable = false,
            autoComplete = false,
            clearButtonAriaLabel = "Click to clear the select input",
            openButtonAriaLabel = "Click to open the select listbox",
            closeButtonAriaLabel = "Click to close the select listbox",
            selectedItemsAriaLabel,
            className,
            children,
            onClick,
            onKeyDown,
            onChange,
            ...props
        }: PdsSelectInputProps,
        ref
    ) => {
        const [
            { isOpen, activeIndex, selectListboxId, selected, items },
            { close, toggleOpen, toggleSelect, resetSelect, unselect, setKeyword, setActiveIndex },
        ] = usePdsSelectContext();

        /**
         * Memo to intialize select ID.
         */
        const selectId = useMemo(() => id ?? `pds-select-${nanoid()}`, [id]);
        const ariaActiveDescendantId = useMemo(
            () => `${selectListboxId}-item-${items[activeIndex]?.value.toString()}`,
            [selectListboxId, items, activeIndex]
        );

        /**
         * All ARIA-related props for controlling Popover-family components.
         */
        const { triggerProps } = usePdsPopoverTrigger({ id: selectListboxId, open: isOpen, disabled, role: "listbox" });

        /** Properly place the select input value depends on select mode. */
        const value = multiSelectable ? valueProps ?? "" : valueProps || selected[0]?.label || selected[0]?.value || "";

        /** Add transition state for un/mouting the clear button */
        const hasKeyword = !!value?.toString()?.length;
        const { hasTransitionedIn } = useMountTransition(!!hasKeyword);

        /** Clear keyword & value handler. */
        const handleClearClick = useCallback(() => {
            resetSelect();
            setKeyword("");
        }, [resetSelect, setKeyword]);

        /**
         * Keyboard handling hook to open the dropdown on ArrowDown.
         * Only useful when input has focus, effectively only on `autoComplete` mode.
         */
        const { onKeyDown: onInputKeydown } = useKeyDown({
            isDisabled: disabled,
            ArrowDown: {
                preventDefault: true,
                callback: () => {
                    if (!isOpen) {
                        toggleOpen();
                    } else {
                        setActiveIndex((i) => (i + 1) % items.length);
                    }
                },
            },
            ArrowUp: {
                preventDefault: true,
                callback: () => {
                    if (!isOpen) {
                        toggleOpen();
                    } else {
                        setActiveIndex((i) => (i - 1) % items.length);
                    }
                },
            },
            Backspace: {
                callback: () => {
                    const lastValue = selected[selected.length - 1];

                    /** Remove the last selected value if input is empty. */
                    if (!lastValue) return;
                    if (!value || value.toString().length === 0) {
                        unselect(lastValue);
                    }
                },
            },
            Escape: {
                preventDefault: true,
                /** Explicitly handle dismiss by `Esc` since focus do not move to the panel on autocomplete mode. */
                callback: close,
            },
            Enter: {
                preventDefault: true,
                callback: () => {
                    const selectedItem = isOpen && activeIndex >= 0 && items[activeIndex];
                    if (selectedItem) {
                        toggleSelect({ label: selectedItem?.label, value: selectedItem?.value });
                    }
                },
            },
            Home: {
                preventDefault: true,
                callback: () => setActiveIndex(0),
            },
            End: {
                preventDefault: true,
                callback: () => setActiveIndex(items.length - 1),
            },
        });

        return (
            <PdsInput
                role="combobox"
                ref={ref}
                id={selectId}
                value={value}
                readOnly={!autoComplete}
                required={!autoComplete ? required : undefined}
                aria-required={!autoComplete ? required : undefined}
                placeholder={selected.length === 0 ? placeholder : undefined}
                aria-autocomplete={autoComplete ? "list" : undefined}
                aria-activedescendant={ariaActiveDescendantId}
                onChange={onChange}
                onClick={ex(toggleOpen, onClick)}
                onKeyDown={ex(onInputKeydown, onKeyDown)}
                className={tx(
                    "flex-wrap bg-white",
                    {
                        "cursor-not-allowed border-neutral-300 bg-neutral-300": disabled,
                    },
                    className
                )}
                {...mergeProps(triggerProps, props)}
            >
                {multiSelectable && (
                    <PdsSelectInput.Leading
                        as="div"
                        className="my-1.5 flex flex-wrap gap-2 -ms-2"
                        aria-hidden={false}
                        aria-label={selectedItemsAriaLabel}
                    >
                        {selected.map((badge) => (
                            <PdsBadge
                                key={badge.label}
                                variant="blue"
                                removeButtonAriaLabel={`Click to unselect ${badge.label}`}
                                onRemove={() => unselect(badge)}
                            >
                                {badge.label}
                            </PdsBadge>
                        ))}
                    </PdsSelectInput.Leading>
                )}
                <PdsInput.Trailing
                    as="span"
                    aria-hidden={false}
                    className="flex min-h-10 items-center justify-center ms-auto"
                >
                    <span
                        className={tx("min-w-[26px] opacity-0 transition-opacity duration-200", {
                            "opacity-100": hasKeyword && hasTransitionedIn,
                        })}
                    >
                        {hasKeyword && (
                            <PdsButton
                                size="xsmall"
                                variant="tertiary"
                                disabled={disabled}
                                aria-label={clearButtonAriaLabel}
                                onClick={handleClearClick}
                            >
                                <PdsButton.Icon as={PdsIconClose} size="xsmall" />
                            </PdsButton>
                        )}
                    </span>
                    <PdsButton
                        size="xsmall"
                        variant="tertiary"
                        tabIndex={-1}
                        disabled={disabled}
                        aria-label={isOpen ? openButtonAriaLabel : closeButtonAriaLabel}
                        onClick={toggleOpen}
                    >
                        <PdsButton.Icon
                            as={PdsIconChevronDown}
                            className={tx("focusable transition", {
                                "-rotate-180": isOpen,
                            })}
                        />
                    </PdsButton>
                </PdsInput.Trailing>
            </PdsInput>
        );
    }
) as CompoundComponentWithRef<PdsInputNamespace, PdsSelectInputProps, HTMLInputElement>;

PdsSelectInput.Leading = PdsInput.Leading;
PdsSelectInput.Trailing = PdsInput.Trailing;

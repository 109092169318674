import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconPaint = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.49 13.73c-1.6 1.6-.25 3.24-2.37 5.67-.95 1.1 3.93.77 6.36-1.67 1.02-1.03.73-2.53-.37-3.64-1.1-1.1-2.6-1.4-3.62-.36zm13.47-9.69c-.54-.54-6.74 4.25-8.5 6.06-.88.9-1.18 1.38-1.45 1.74-.11.16.04.2.11.24.35.18.84.5 1.16.83.31.32.66.81.84 1.16.03.07.08.23.23.1.36-.27.83-.57 1.7-1.46 1.77-1.81 6.44-8.12 5.9-8.67z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconRemoveCircle = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M15 11H9a1 1 0 0 0-1 1 1 1 0 0 0 1 1h6a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm-3-7a8 8 0 1 0 0 15.99A8 8 0 0 0 12 4zm0 14a6 6 0 1 1 0-12.01 6 6 0 0 1 0 12z" />
            </svg>
        );
    }
);

import { PdsDropdown } from "@/components/dropdown";

import { PdsDropdownSelectItem } from "./DropdownSelectItem";
import { PdsDropdownSelectSection } from "./DropdownSelectSection";
import { PdsDropdownSelectSearchInput } from "./DropdownSelectSearchInput";
import { PdsDropdownSelectTriggerChip } from "./DropdownSelectTriggerChip";
import { PdsDropdownSelect as PdsDropdownSelectNamespace } from "./DropdownSelect";

export * from "./type";

/**
 * Compound components were assigned here to avoid cyclic imports between PdsDropdown & PdsDropdownSubmenu.
 */
export const PdsDropdownSelect = PdsDropdownSelectNamespace as {
    Item: typeof PdsDropdownSelectItem;
    Trigger: typeof PdsDropdown.Trigger;
    TriggerChip: typeof PdsDropdownSelectTriggerChip;
    Section: typeof PdsDropdownSelectSection;
    SearchInput: typeof PdsDropdownSelectSearchInput;
} & typeof PdsDropdownSelectNamespace;

PdsDropdownSelect.Trigger = PdsDropdown.Trigger;
PdsDropdownSelect.TriggerChip = PdsDropdownSelectTriggerChip;
PdsDropdownSelect.Item = PdsDropdownSelectItem;
PdsDropdownSelect.Section = PdsDropdownSelectSection;
PdsDropdownSelect.SearchInput = PdsDropdownSelectSearchInput;

import type { PdsPaginationProps } from "./type";
import type { CompoundComponentWithRef } from "@/typings/utilities";

import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";

import { getChildByType, removeChildrenByType, tx } from "@/libs";
import { usePrevious } from "@/hooks";

import { PdsPaginationContext } from "./context";
import {
    PdsPaginationEllipsis,
    PdsPaginationItem,
    PdsPaginationNext,
    PdsPaginationPrevious,
} from "./PaginationComponent";

const getPageArray = ({
    total,
    value,
    sibling,
    boundary,
}: {
    total: number;
    value: number;
    sibling: number;
    boundary: number;
}) => {
    const length = boundary * 2 + sibling * 2 + 2 + 1;

    /** If total <= length => display all items.  */
    if (total <= length) {
        return Array.from(Array(total).keys());
    }

    const siblingStart = value - sibling;
    const siblingEnd = value + sibling;

    /** Create a new array filled with boundaryStart and boundaryEnd. */
    const rs = Array.from(Array(length).keys()).map((_, index) => {
        if (index < boundary) {
            return index;
        }

        if (index >= length - boundary && index <= length - 1) {
            return total - (length - index);
        }

        return null;
    }) as (number | string | null)[];

    if (boundary + 2 >= siblingStart) {
        /** Do not print ellipsis if only one away from sibling end. */
        rs[length - boundary - 1] = "end-ellipsis";
        for (let i = 0; i <= length - boundary - 2; i += 1) {
            rs[i] = i;
        }
    } else if (siblingEnd + 1 >= total - boundary) {
        /** Do not print ellipsis if only one away from sibling start. */
        rs[boundary] = "start-ellipsis";

        for (let i = length - 1; i >= boundary + 1; i -= 1) {
            rs[i] = total - (length - i);
        }
    } else {
        /** Print both ellipses if not. */
        rs[boundary] = "start-ellipsis";
        rs[length - boundary - 1] = "end-ellipsis";
        for (let i = boundary + 1; i <= boundary + 2 * sibling + 1; i += 1) {
            rs[i] = siblingStart + (i - boundary) - 1;
        }
    }

    return rs as (number | "start-ellipsis" | "end-ellipsis")[];
};

/**
 * `PdsPagination` allows users to navigate through multi-paged content or datasets, in order to find a specific item
 * in a list & click through to a destination page.
 *
 * `PdsPagination` should not be used to display a step-related status of each page, use `PdsStepper` instead.
 *
 * `PdsPagination` is built using `PdsButton` & retain all of its accessibility properties & features, and the following:
 *
 * - `PdsPagination` by nature is a `nav` element with [role='navigation'](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/navigation_role).
 * - `PdsPagination.Item` has [aria-current='page](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-current) if it is currently selected.
 * - `PdsPagination.Previous` & `PdsPagination.Next` is disabled when the current page is already the first or last page, respectively.
 * - `PdsPagination.Previous` & `PdsPagination.Next`'s aria-labels should be customised to better user context and/or i18n translation purposes.
 * - `PdsPagination.Ellipsis` has `aria-label` of `Collapsed previous/next pages` depending on where it is relatively to the current page.
 * - It is recommended that an `aria-label` is provided to `PdsPagination` as to give context about what other element
 * it is giving navigation to, especially when multiple `<nav />` are present on a single page.
 */
export const PdsPagination = forwardRef<HTMLElement, PdsPaginationProps>(
    (
        {
            value: valueProps = 0,
            total,
            sibling = 1,
            boundary = 1,
            disabled = false,
            className,
            children,
            previousCollapsedPagesAriaLabel = "Collapsed previous pages",
            followingCollapsedPagesAriaLabel = "Collapsed following pages",
            onPageChange,
            ...props
        }: PdsPaginationProps,
        ref
    ) => {
        /**
         * Value hook to keep track of the current page.
         */
        const [value, setValue] = useState(valueProps);
        const prevValue = usePrevious(value);

        useEffect(() => setValue(valueProps), [valueProps]);

        useEffect(() => {
            if (value !== prevValue) {
                onPageChange?.(value);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        /**
         * Callback handlers for previous, next, and item clicks.
         */
        const handleItemClick = useCallback((index?: number) => {
            if (index !== undefined) {
                setValue(index);
            }
        }, []);

        const handlePreviousPageClick = useCallback(() => setValue((i) => Math.max(i - 1, 0)), []);

        const handleNextPageClick = useCallback(() => setValue((i) => Math.min(i + 1, total - 1)), [total]);

        /**
         * Extract all subcomponents.
         */
        const prevEl = getChildByType(children, "PdsPaginationPrevious") ?? <PdsPaginationPrevious />;

        const nextEl = getChildByType(children, "PdsPaginationNext") ?? <PdsPaginationNext />;

        /**
         * Render all other subcomponents in place.
         *
         * If any is found, the automatic pagination layout list is disregarded, where ellipses must be handled manually.
         */
        const pages = useMemo(
            () => getPageArray({ total, value, sibling, boundary }),
            [boundary, sibling, total, value]
        );

        const pageEls = removeChildrenByType(children, ["PdsPaginationPrevious", "PdsPaginationNext"]);

        return (
            <nav ref={ref} role="navigation" className={tx("flex items-center", className)} {...props}>
                <PdsPaginationContext.Provider
                    value={{
                        value,
                        disabled,
                        total,
                        handleItemClick,
                        handlePreviousPageClick,
                        handleNextPageClick,
                    }}
                >
                    {prevEl}
                    {pageEls && pageEls.length > 0
                        ? pageEls
                        : pages.map((page) => {
                              if (page === "start-ellipsis") {
                                  return <PdsPaginationEllipsis aria-label={previousCollapsedPagesAriaLabel} />;
                              }

                              if (page === "end-ellipsis") {
                                  return <PdsPaginationEllipsis aria-label={followingCollapsedPagesAriaLabel} />;
                              }

                              return <PdsPaginationItem key={page} index={page} />;
                          })}
                    {nextEl}
                </PdsPaginationContext.Provider>
            </nav>
        );
    }
) as CompoundComponentWithRef<
    {
        Previous: typeof PdsPaginationPrevious;
        Next: typeof PdsPaginationNext;
        Item: typeof PdsPaginationItem;
        Ellipsis: typeof PdsPaginationEllipsis;
    },
    PdsPaginationProps,
    HTMLElement
>;

PdsPagination.Previous = PdsPaginationPrevious;
PdsPagination.Next = PdsPaginationNext;
PdsPagination.Item = PdsPaginationItem;
PdsPagination.Ellipsis = PdsPaginationEllipsis;

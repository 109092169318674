import type { RefObject } from "react";

import { useState } from "react";

import { useSafeLayoutEffect } from "@/hooks/useSafeLayoutEffect";

export type ScrollableResult = {
    horizontal?: boolean;
    vertical?: boolean;
};

export const useScrollable = (target: RefObject<HTMLElement> | null | HTMLElement): ScrollableResult => {
    const [scrollable, setScrollable] = useState<ScrollableResult>({
        horizontal: false,
        vertical: false,
    });

    useSafeLayoutEffect(() => {
        const targetEl = target instanceof HTMLElement ? target : target?.current;

        if (targetEl) {
            setScrollable({
                horizontal: targetEl.offsetWidth < targetEl.scrollWidth,
                vertical: targetEl.offsetHeight < targetEl.scrollHeight,
            });
        }
    }, [target]);

    return scrollable;
};

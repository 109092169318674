import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconFeed = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.18 15.64a2.18 2.18 0 1 1 0 4.36 2.18 2.18 0 0 1 0-4.36zM4 11.63c0-.85.75-1.54 1.59-1.4a9.9 9.9 0 0 1 8.19 8.18A1.4 1.4 0 0 1 12.37 20c-.71 0-1.28-.53-1.4-1.23a7.1 7.1 0 0 0-5.74-5.74A1.44 1.44 0 0 1 4 11.63zm1.42-7.12l.14.01a15.56 15.56 0 0 1 13.92 13.92c.08.83-.58 1.56-1.42 1.56a1.4 1.4 0 0 1-1.4-1.28A12.74 12.74 0 0 0 5.27 7.33 1.4 1.4 0 0 1 4 5.93c0-.83.73-1.5 1.56-1.41z" />
            </svg>
        );
    }
);

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconImpersonate = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="1 776.3 24 17.2"
                width={24}
                height={24}
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.6 786.9c2.9 0 5.3-2.4 5.3-5.3 0-.3 0-.7-.1-1H14c-.1.3-.1.6-.1 1 0 2.9 2.4 5.3 5.3 5.3s5.3-2.4 5.3-5.3-2.4-5.3-5.3-5.3c-1.8 0-3.4.9-4.3 2.3h-4c-1-1.4-2.6-2.3-4.3-2.3-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3zm12.8-8.6c1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.2-3.2 3.2-1.8 0-3.2-1.4-3.2-3.2-.1-1.7 1.4-3.2 3.2-3.2zm-12.8 0c1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.2-3.2 3.2-1.8 0-3.2-1.4-3.2-3.2 0-1.7 1.5-3.2 3.2-3.2zm18.3 12.1c-.2-.5-.8-.7-1.3-.6-.2 0-.7.1-1.4.1-1.9 0-5.3-2.2-5.3-2.2-1.6-1.2-3.9-.3-3.9-.2 0 0-2.3-.9-3.9.2 0 0-3.4 2.2-5.3 2.2-.7 0-1.2-.1-1.4-.1-.6-.1-1.1.1-1.3.6-.1.1-.1.2-.1.4v.4c0 .2.3.5.5.7 1.4 1 2.9 1.4 4.4 1.5.4 0 .7.1 1.1.1 1.9 0 4.2-.6 5.1-1.4.4-.3.7-.6.9-.9.2.3.6.6.9.9.9.7 3.2 1.4 5.1 1.4.4 0 .8 0 1.1-.1 1.4-.1 3-.6 4.4-1.5.2-.1.5-.5.5-.7v-.2c0-.1 0-.2-.1-.4z" />
            </svg>
        );
    }
);

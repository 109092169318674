import type { PdsChipProps } from "./type";
import type { CompoundComponentWithRef, InheritableElementProps } from "@/typings/utilities";

import { nanoid } from "nanoid";
import { forwardRef, useMemo, useEffect, useCallback } from "react";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";

import { useBoolean, useMountTransition, usePrevious } from "@/hooks";
import { ex, getChildByType, polymorphComponent, removeChildrenByTypeDeep, tx } from "@/libs";
import { PdsIconCheck } from "@/components/icons";
import { PdsCloseButton } from "@/components/closeButton";

import { PdsChipLeading, PdsChipTrailing } from "./ChipIcon";

export type PdsChipNamespace = {
    Leading: typeof PdsChipLeading;
    Trailing: typeof PdsChipTrailing;
};

const PdsChipWrapper = polymorphComponent<InheritableElementProps<"button", {}>>("button");

/**
 * `PdsChip` presents a user with visual prompt to take actions & make selections.
 *
 * - Unlike `PdsButton`, which performs transformative actions & should remain limited in number, `PdsChip` are best used to
 * present a series of multiple contextual & supplemental options.
 * - Unlike `PdsBadge`, which are non-focusable content-only visual cue, `PdsChip` are by default keyboard tabbable &
 * click/touch interactive.
 *
 * `PdsChip` features 4 recommended uses cases:
 * 1. **Filter** (_if either/both `checked` or/and `onChange` is provided_): represents a filter imposed on a collection.
 *    - Comes with a leading check icon by default.
 *    - Has `role="button"` and is focusable, & tabbable.
 *    - `Space` or `Enter` toggles the `checked` state & fires the `onChange` event.
 * 2. **Assist** (_if `onClick` is provided_): represents a contentful smart/quick action.
 *    - Often comes with an illustrative icon.
 *    - Has `role="button"` and is focusable, & tabbable.
 *    - `Space` or `Enter` fires the `onClick` event.
 * 3. **Input** (_if `onRemove` is provided_): displays a discrete input from a user.
 *    - Comes with a trailing remove button by defualt.
 *    - Only `PdsCloseButton` is focusable, & tabbable.
 *    - `Space` or `Enter` fires the `onRemove` event.
 * 4. **Suggestion** (_if none of the above is provided_): displays a contextual suggestion/action.
 *    - Text-only suggestions that provides context for a user.
 *    - Has `role="button"` and is focusable, & tabbable.
 *    - `Space` or `Enter` fires the `onClick` event.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=12%3A539).
 */
export const PdsChip = forwardRef<HTMLButtonElement, PdsChipProps>(
    (
        {
            id,
            value,
            disabled = false,
            checked: checkedProps,
            removeButtonAriaLabel = "Remove chip",
            className,
            onRemove,
            onChange,
            onClick: onClickProps,
            children,
            ...props
        }: PdsChipProps,
        ref
    ) => {
        const isControlled = useMemo(() => checkedProps !== undefined || !!onChange, [checkedProps, onChange]);
        const isInteractive = useMemo(() => isControlled || onClickProps, [isControlled, onClickProps]);

        /**
         * Memo to intialize chip ID.
         */
        const chipId = useMemo(() => id ?? `pds-chip-${nanoid()}`, [id]);

        /**
         * Hover & active management hook.
         */
        const { isHovered, hoverProps } = useHover({ isDisabled: disabled || !isInteractive });

        /**
         * Checked state hook.
         */
        const [checked, { toggle }] = useBoolean({
            initialValue: checkedProps,
            isDisabled: disabled || !isControlled,
        });
        const prevChecked = usePrevious(checked);

        /**
         * Transition mount delay hook to handle the icon mount & unmount with transition.
         */
        const { hasTransitionedIn } = useMountTransition(checked);

        /**
         * Keyboard & click handler.
         */
        const handleClick = useCallback(() => {
            if (!disabled && isControlled) {
                toggle();
            }
        }, [disabled, isControlled, toggle]);

        /*
         * Extract leading / trailing icons for manual placement.
         */
        const leadingIcon = getChildByType(children, "PdsChipLeading");
        const trailingIcon = getChildByType(children, "PdsChipTrailing");
        const content = removeChildrenByTypeDeep(children, ["PdsChipLeading", "PdsChipTrailing"]);

        /**
         * Effect hook to emit checked state change.
         */
        useEffect(() => {
            if (checked !== prevChecked) {
                onChange?.(checked);
            }
        }, [checked, prevChecked, onChange]);

        return (
            <PdsChipWrapper
                as={isInteractive ? "button" : "span"}
                ref={ref}
                id={chipId}
                value={value}
                disabled={disabled}
                aria-disabled={disabled}
                type={isInteractive ? "button" : undefined}
                role={isControlled ? "checkbox" : undefined}
                aria-checked={isControlled ? checked : undefined}
                className={tx(
                    "inline-flex min-h-9 w-fit cursor-default flex-wrap items-center px-4 text-sm",
                    "focusable rounded-full border border-neutral-300",
                    {
                        "cursor-pointer active:border-neutral-500 active:bg-neutral-200": isInteractive,
                        "border-neutral-400 bg-neutral-100": isHovered && isInteractive,
                        "border-primary bg-theme-100 text-primary": checked,
                        "active:border-theme-900 active:bg-theme-300": checked && isInteractive,
                        "border-theme-800 bg-theme-200": checked && isHovered,
                        "cursor-not-allowed border-neutral-300 bg-neutral-300 active:border-neutral-300 active:bg-neutral-300":
                            disabled,
                    },
                    className
                )}
                onClick={ex(handleClick, onClickProps)}
                {...mergeProps(hoverProps, props)}
            >
                {leadingIcon ??
                    (!onRemove && (checked || hasTransitionedIn) && (
                        <PdsIconCheck
                            size="small"
                            aria-hidden="true"
                            className={tx(
                                "w-0 transform opacity-0 transition-all duration-200 -ms-1 me-1 motion-reduce:transition-none",
                                {
                                    "w-5 opacity-100": checked && hasTransitionedIn,
                                }
                            )}
                        />
                    ))}
                {content}
                {trailingIcon ??
                    (onRemove && (
                        <PdsCloseButton
                            className="ms-2 -me-2"
                            disabled={disabled}
                            aria-label={removeButtonAriaLabel}
                            onClick={onRemove}
                        />
                    ))}
            </PdsChipWrapper>
        );
    }
) as CompoundComponentWithRef<PdsChipNamespace, PdsChipProps, HTMLButtonElement>;

PdsChip.Leading = PdsChipLeading;
PdsChip.Trailing = PdsChipTrailing;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconSave = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h9.6c.5 0 1 .2 1.4.6L20.4 8c.4.4.6.9.6 1.4V19c0 1.1-.9 2-2 2zM5 5v14h14V9.4L14.6 5H5zm8 6c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 2c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm-2-6H8c-.6 0-1 .4-1 1 0 .5.4.9.9 1H11c.5 0 .9-.4 1-.9v-.2c-.1-.5-.4-.8-.9-.9H11z" />
            </svg>
        );
    }
);

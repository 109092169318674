import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorVerticalAlignTop = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M9.21 11H11v9a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-9h1.79a.5.5 0 0 0 .35-.85l-2.79-2.79a.5.5 0 0 0-.71 0l-2.79 2.79a.5.5 0 0 0 .36.85zM4 4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1 1 1 0 0 0-1-1H5a1 1 0 0 0-1 1z" />
            </svg>
        );
    }
);

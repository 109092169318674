import type { RefObject } from "react";

import useResizeObserver from "@react-hook/resize-observer";
import { useState } from "react";

import { useSafeLayoutEffect } from "@/hooks/useSafeLayoutEffect";

export type ContainerSize = {
    width: number;
    height: number;
};

export const useContainerSize = (target: RefObject<HTMLElement> | null | HTMLElement): ContainerSize => {
    const [size, setSize] = useState<ContainerSize>({ width: 0, height: 0 });

    /**
     * Set initial sizing.
     */
    useSafeLayoutEffect(() => {
        const targetEl = target instanceof HTMLElement ? target : target?.current;

        if (targetEl) {
            setSize({ width: targetEl.offsetWidth, height: targetEl.offsetHeight });
        }
    }, [target]);

    /**
     * Where the magic happens.
     */
    useResizeObserver(target, (entry) => {
        const targetEl = entry.target as HTMLElement;
        setSize({ width: targetEl.offsetWidth, height: targetEl.offsetHeight });
    });

    return size;
};

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorNumberList = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M8 17v-1H6v-1h3v1h1v1H9v1H6v-1h2zm9-2a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2h6zm-8-4v1.33h-.6V13H9v1H6v-1h1.2v-.67H6V11h3zm8 0a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2h6zM8 7v3H7V8H6V7h2zm9 0a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2h6z" />
            </svg>
        );
    }
);

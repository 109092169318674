import React, { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "./styles.css";

// Swiper styles
import "swiper/css";
import "swiper/css/pagination";

interface ISwiperComponent {
    slides: Record<string, string>[];
}

const SwiperComponent: FC<ISwiperComponent> = ({ slides }) => {
    /*
     * Enable swiper autoplay
     */
    SwiperCore.use([Autoplay]);

    const pagination = {
        clickable: true,
        renderBullet(index: number, className: string) {
            return `<span class="${className}"></span>`;
        },
    };

    return (
        <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            speed={700}
            navigation
            pagination={pagination}
            modules={[Pagination]}
            autoplay={{
                delay: 3000,
            }}
        >
            {slides.map(({ image, alt, description }) => (
                <SwiperSlide key={description} className="flex-col gap-5">
                    <img className="w-32" src={image} alt={alt} />
                    <div className="text-neutral-900 text-sm leading-4 font-medium">{description}</div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SwiperComponent;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconTag = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19.71 12.29l-8-8A1 1 0 0 0 11 4H5a1 1 0 0 0-1 1v6a1 1 0 0 0 .29.71l8 8 .01-.01a1 1 0 0 0 .7.3 1 1 0 0 0 .71-.29l6-6A1 1 0 0 0 20 13a1 1 0 0 0-.29-.71zM8 10a2 2 0 0 1-2-2c0-1.1.9-2 2-2a2 2 0 0 1 2 2 2 2 0 0 1-2 2z" />
            </svg>
        );
    }
);

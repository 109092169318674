import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorSuperscript = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M6.7 11.69l2.48 2.47 2.48-2.47a1 1 0 0 1 1.41 1.41l-2.47 2.48 2.47 2.47a1 1 0 0 1-1.41 1.42l-2.48-2.48-2.47 2.48a1 1 0 1 1-1.42-1.42l2.48-2.47-2.48-2.48a1 1 0 0 1 1.42-1.41zM16.35 4a2.8 2.8 0 0 1 1.51.45c.23.16.42.37.56.64.14.28.21.59.21.94A2.1 2.1 0 0 1 18 7.55a4.9 4.9 0 0 1-1.18.85c-.18.1-.34.2-.47.3-.14.1-.2.18-.22.26h2c.4 0 .6.2.6.58V10c0 .4-.2.59-.6.59h-3.48a.65.65 0 0 1-.45-.15.55.55 0 0 1-.18-.44v-.2a2.81 2.81 0 0 1 .92-2.12c.26-.23.52-.43.78-.6.25-.17.47-.32.65-.46.18-.14.27-.26.27-.38 0-.16-.05-.28-.15-.34a.57.57 0 0 0-.32-.1.68.68 0 0 0-.49.19c-.3.29-.58.33-.83.13l-.44-.36c-.31-.24-.36-.5-.14-.79A2.45 2.45 0 0 1 16.34 4z" />
            </svg>
        );
    }
);

import type { PdsPaginationProps } from "./type";

import { createContext, useContext } from "react";

type PdsPaginationContextProps = Partial<PdsPaginationProps> & {
    handleItemClick?: (index?: number) => void;
    handlePreviousPageClick?: () => void;
    handleNextPageClick?: () => void;
};

export const PdsPaginationContext = createContext<PdsPaginationContextProps>({});

export const usePdsPaginationContext = () => useContext(PdsPaginationContext);

import type { LabelAriaProps } from "@react-aria/label";
import type { RadioGroupState } from "@react-stately/radio";
import type { KeyboardEvent } from "@react-types/shared";
import type { FormEvent, MouseEventHandler } from "react";

import { useRadio } from "@react-aria/radio";
import { useLabel } from "@react-aria/label";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { filterClassName } from "../../utils/filterClassName";

import { SegmentContext } from "./SegmentGroup";

export type SegmentOptionProps = {
    /** Label of segment option, required for accessibility */
    label: React.ReactText;
    /** Icon of segment option */
    icon?: React.ReactNode;
    /** Value of segment option */
    value: string;
    /**
     *A hook to target component instances when testing.
     */
    testId?: string;
    /** Determine whether segment option contains icon only */
    iconOnly?: boolean;
    /** Users can add spacing/sizing and screen-width specific but cannot modify the internal component desgin(color variant, font sizes, …). */
    className?: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "className">;

type LabelStyleProps = {
    isSelected?: boolean;
    isIconOnly?: boolean;
    onMouseEnter: MouseEventHandler<HTMLLabelElement>;
} & React.HTMLAttributes<HTMLLabelElement>;

const StyledLabel = styled.label<LabelStyleProps>`
    ${tw`flex flex-row items-center justify-center 
    hover:(text-theme-700 cursor-pointer) 
    `};
    ${(props) => (props.isSelected ? tw`(border-theme-700 bg-theme-100)` : tw`border-neutral-300`)};
    ${(props) => (props.isIconOnly ? tw`px-[11px] py-2` : tw`px-5 py-2`)};
`;

const InputWrapper = styled.div`
    ${tw`hidden`};
`;
type LabelWrapperProps = {
    isSelected: boolean;
    hasIcon: boolean;
    isIconOnly: boolean;
    isHovered: boolean;
};
const LabelWrapper = styled.label<LabelWrapperProps>`
    ${tw`font-medium text-sm leading-5 text-neutral-1000`};
    ${(props) => props.isSelected && tw`text-theme-700`};
    ${(props) => props.hasIcon && tw`pl-2`};
    ${(props) => props.isIconOnly && tw`hidden`};
    ${(props) => props.isHovered && tw`text-theme-700 cursor-pointer`};
`;

export const PdsSegmentOption = ({
    icon,
    label,
    testId,
    iconOnly = false,
    value,
    className,
    ...props
}: SegmentOptionProps): JSX.Element => {
    const segmentContextData = useContext(SegmentContext);
    let selected = Boolean(segmentContextData.selectedValue === value);
    const [isHovered, setIsHovered] = useState(false);
    const [selectedVal, setSelectedVal] = useState(segmentContextData.selectedValue);
    const [lastFocusedVal, setLastFocusedVal] = useState(segmentContextData.selectedValue);
    const segmentOptionContextData = {
        selectedValue: selectedVal,
        lastFocusedValue: lastFocusedVal,
        setSelectedValue: setSelectedVal,
        setLastFocusedValue: setLastFocusedVal,
    } as RadioGroupState;
    const ref = useRef(null);

    const segmentProps: any = {
        value,
        "aria-label": label as string,
    };
    const { inputProps } = useRadio(segmentProps, segmentOptionContextData, ref);
    const onUpdateSelection = (event: KeyboardEvent) => {
        if (event.key === "Enter" || event.key === " ") {
            const val = (ref.current as HTMLInputElement | null)?.value;
            if (val) {
                segmentContextData.onChange(val);
                selected = val === segmentContextData.selectedValue;
            }
        }
    };
    const handleOnChange = (event: FormEvent) => {
        const val = (event.target as HTMLInputElement).value;
        segmentContextData.onChange(val);
    };
    const labelPropsForHook: LabelAriaProps = {
        label,
    };
    const { labelProps, fieldProps } = useLabel(labelPropsForHook);
    const inputControl = (
        <input
            {...inputProps}
            {...fieldProps}
            name={segmentContextData.inputName}
            tabIndex={-1}
            autoComplete="off"
            checked={selected}
            ref={ref}
            onClick={handleOnChange}
            {...props}
        />
    );
    const hasIcon = !!icon;
    const iconColor = isHovered || selected ? "theme-700" : "neutral-1000";
    const clonedIcon = icon !== undefined ? React.cloneElement(icon as any, { color: iconColor }) : null;
    return (
        <StyledLabel
            tabIndex={0}
            data-testid={testId}
            isSelected={selected}
            onKeyPress={onUpdateSelection}
            isIconOnly={iconOnly}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={className && filterClassName(className, ["bg", "border", "text"])}
        >
            {hasIcon && clonedIcon}
            <InputWrapper>{inputControl}</InputWrapper>
            <LabelWrapper
                isIconOnly={iconOnly}
                hasIcon={hasIcon}
                isSelected={selected}
                isHovered={isHovered}
                {...labelProps}
            >
                {label}
            </LabelWrapper>
        </StyledLabel>
    );
};

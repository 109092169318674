import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconPi = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M9.18 7.98c-1.53 0-2.2.05-3.15 1.58-.32.54-1.19.2-1-.41.8-2.7 1.86-3.65 4.89-3.65h8.54c.28 0 .54.24.54.53v1.43c0 .28-.26.52-.54.52H15.8c-.11 1.24-.69 6.92 0 7.56.91.8 1.85.17 2.12-.81.16-.64 1.15-.47 1.08.17-.6 5.16-6.7 4.2-6.32.46.24-2.46.42-4.92.63-7.38h-2.3c-.14 2.53-.5 8.26-1.62 9.76-1.25 1.65-4.87.52-2.55-2.5 1.8-2.34 2.09-4.54 2.34-7.26z" />
            </svg>
        );
    }
);

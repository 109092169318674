import React from "react";

type Props = {
    errors: string[];
    showFirst: boolean;
    cls?: string;
};

const Errors = ({ errors, cls, showFirst = true }: Props) => {
    return (
        <>
            {errors && errors.length > 0 && (
                <div className={`grid text-sm gap-y-1 ${cls}`}>
                    {!showFirst ? (
                        errors.map((e) => {
                            return (
                                <div key={e} className="text-xs text-red-600 border-0">
                                    {e}
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-xs text-red-600 border-0">{errors[0]}</div>
                    )}
                </div>
            )}
        </>
    );
};

export default Errors;

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorAudio = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M19 4c-.07 0-.13.03-.19.04v-.02l-10 2v.02A.99.99 0 0 0 8 7v9.12A4.56 4.56 0 0 0 7 16c-1.66 0-3 .9-3 2s1.34 2 3 2 3-.9 3-2v-7.68l8-1.6v5.4a4.56 4.56 0 0 0-1-.12c-1.66 0-3 .9-3 2s1.34 2 3 2 3-.9 3-2V5a1 1 0 0 0-1-1z" />
            </svg>
        );
    }
);

import type { PdsButtonVariants } from "./type";
import type { PdsMinimalSizeKeys, PdsSizeKeys } from "@/typings/size";

import { tx } from "@/libs/tailwindMerge";

export const getVariantStyles = (variant: PdsButtonVariants, hovered: boolean, loading?: boolean) => {
    const primary = tx(
        "text-white bg-primary shadow-sm border-transparent",
        { "active:bg-theme-900 disabled:bg-theme-300": !loading },
        { "bg-theme-800": !loading && hovered },
        { "bg-primary": loading }
    );

    const secondary = tx(
        "text-text-default bg-white border-neutral-300 shadow-sm disabled:bg-white",
        {
            "active:bg-neutral-200 disabled:text-neutral-500": !loading,
        },
        { "bg-neutral-100": !loading && hovered }
    );

    const tertiary = tx(
        "text-text-default bg-transparent border-transparent",
        {
            "active:bg-neutral-600 active:bg-opacity-30 disabled:text-neutral-500 disabled:bg-transparent": !loading,
        },
        { "bg-neutral-500 bg-opacity-30": loading || hovered }
    );

    const danger = tx(
        "text-danger bg-white border-danger !ring-danger shadow-sm disabled:bg-white",
        {
            "disabled:border-red-400 disabled:text-red-400 active:bg-red-300": !loading,
        },
        { "bg-red-200": !loading && hovered }
    );

    const styles: Record<PdsButtonVariants, string> = {
        primary,
        secondary,
        tertiary,
        danger,
    };

    return styles[variant];
};

export const getPaddingSize = (size: PdsSizeKeys, iconOnly?: boolean) => {
    const styles: Record<PdsSizeKeys, string> = {
        xsmall: tx("px-3 py-1", { "p-1": iconOnly }),
        small: tx("px-3 py-2", { "p-1.5": iconOnly }),
        medium: tx("px-4 py-2", { "p-2": iconOnly }),
        large: tx("px-4 py-2", { "p-2": iconOnly }),
        xlarge: tx("px-6 py-3", { "p-3": iconOnly }),
    };

    return styles[size];
};

export const getTextStyles = (size: PdsSizeKeys) => {
    const styles: Record<PdsSizeKeys, string> = {
        xsmall: "text-xs leading-4",
        small: "text-sm leading-4",
        medium: "text-sm leading-5",
        large: "text-base leading-6",
        xlarge: "text-base leading-6",
    };

    return styles[size];
};

export const getSpinnerSize = (size: PdsSizeKeys): PdsMinimalSizeKeys => {
    switch (size) {
        case "xsmall":
        case "small":
            return "small";
        default:
            return "medium";
    }
};

export const getIconSize = (size: PdsSizeKeys): PdsSizeKeys => {
    switch (size) {
        case "xsmall":
        case "small":
            return "xsmall";
        case "medium":
        case "large":
            return "small";
        case "xlarge":
        default:
            return "medium";
    }
};

import React from "react";
import { PdsButton, PdsAlert } from "@educationperfect/web-pds-react";
import { Link, useHistory } from "react-router-dom";

const SignupExists = () => {
    const history = useHistory();

    return (
        <div className="mt-4 text-sm leading-5">
            <PdsAlert className="p-4 mt-7" alertType="error">
                <PdsAlert.Title>This email address already exists!</PdsAlert.Title>
                <PdsAlert.Detail>
                    It looks like you already have an account with EP. You can use a different email address or login
                    into your EP account and reset your password.
                </PdsAlert.Detail>
            </PdsAlert>

            <div className="mt-20 flex flex-col mx-auto gap-8 sm:gap-7 w-full sm:px-3 sm:max-w-md">
                <PdsButton id="verify-email-btn" onClick={() => history.push("/for-parents/start")} type="button">
                    Please enter a different email address
                </PdsButton>
                <Link to={{ pathname: "https://app.educationperfect.com" }} target="_blank">
                    <PdsButton id="login-email-btn" className="w-full" type="button" variant="secondary">
                        Proceed to login to reset your password
                    </PdsButton>
                </Link>
            </div>
        </div>
    );
};

export default SignupExists;

import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import store from "@services/store";
import { isValid, setValidated, subscriptionExists } from "@api/signup";
import { useHistory, useLocation } from "react-router-dom";

const emailIsValidated = async () => {
    const email = store.get("forhome:valid-email");
    if (email) {
        return isValid(email as string);
    }
    return false;
};

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const EmailGuard = ({ children }: { children: ReactNode }): JSX.Element => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [ready, setReady] = useState(false);

    const checkRoute = useCallback(async () => {
        const validated = await emailIsValidated();
        const exists = store.get("forhome:email-exists");
        if (validated && exists === false) {
            history.push("/for-parents/register");
        } else {
            setReady(true);
        }
    }, [history]);

    useEffect(() => {
        if (pathname === "/for-parents/start") {
            checkRoute();
        }
    }, [checkRoute, pathname]);

    return ready ? <>{children}</> : <></>;
};

const ValidateGuard = ({ children }: { children: ReactNode }): JSX.Element => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [ready, setReady] = useState(false);

    const checkRoute = useCallback(async () => {
        const validated = await emailIsValidated();

        if (validated) {
            history.push("/for-parents/register");
        } else if (!store.get("forhome:email")) {
            history.push("/for-parents/start");
        } else {
            setReady(true);
        }
    }, [history]);

    useEffect(() => {
        if (pathname === "/for-parents/validate") {
            checkRoute();
        }
    }, [checkRoute, pathname]);

    return ready ? <>{children}</> : <></>;
};

const ExistsGuard = ({ children }: { children: ReactNode }): JSX.Element => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [ready, setReady] = useState(false);

    const checkRoute = useCallback(async () => {
        const validated = await emailIsValidated();

        if (!validated) {
            history.push("/for-parents/start");
        } else {
            setReady(true);
        }
    }, [history]);

    useEffect(() => {
        if (pathname === "/for-parents/exists") {
            checkRoute();
        }
    }, [checkRoute, pathname]);

    return ready ? <>{children}</> : <></>;
};

const RegisterGuard = ({ children }: { children: ReactNode }): JSX.Element => {
    const history = useHistory();
    const query = useQuery();
    const { pathname } = useLocation();
    const [ready, setReady] = useState(false);

    const saveEmail = async (email: string) => {
        await setValidated(email);
        store.set("forhome:email", email);
        store.set("forhome:valid-email", email);
    };

    const checkRoute = useCallback(
        async (email: string) => {
            const exists = await subscriptionExists(email);
            store.set("forhome:email-exists", exists);

            if (exists) {
                history.push("/for-parents/exists");
            } else {
                setReady(true);
            }
        },
        [history]
    );

    useEffect(() => {
        if (pathname === "/for-parents/register") {
            const email = query.get("email");

            if (email) {
                saveEmail(email);
            }

            const storedEmail = store.get("forhome:email");
            const validated = store.get("forhome:valid-email");

            if (!storedEmail || !validated) {
                history.push("/for-parents/start");
            }

            checkRoute(storedEmail as string);
        }
    }, [checkRoute, history, pathname, query]);

    return ready ? <>{children}</> : <></>;
};

const LearnersGuard = ({ children }: { children: ReactNode }): JSX.Element => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [ready, setReady] = useState(false);

    const checkRoute = useCallback(async () => {
        const email = store.get("forhome:email");
        const validEmail = store.get("forhome:valid-email");
        const exists = store.get("forhome:email-exists");

        if (!email || !validEmail || exists) {
            history.push("/for-parents/start");
        } else if (!store.get("forhome:payer")) {
            history.push("/for-parents/register");
        } else {
            setReady(true);
        }
    }, [history]);

    useEffect(() => {
        if (pathname === "/for-parents/learners") {
            checkRoute();
        }
    }, [checkRoute, pathname]);

    return ready ? <>{children}</> : <></>;
};

const CompleteGuard = ({ children }: { children: ReactNode }): JSX.Element => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [ready, setReady] = useState(false);

    const checkRoute = useCallback(async () => {
        const email = store.get("forhome:email");
        const validEmail = store.get("forhome:valid-email");
        const exists = store.get("forhome:email-exists");

        if (!email || !validEmail || exists) {
            history.push("/for-parents/start");
        } else if (!store.get("forhome:payer")) {
            history.push("/for-parents/register");
        } else if (!store.get("forhome:learners")) {
            history.push("/for-parents/learners");
        } else {
            setReady(true);
        }
    }, [history]);

    useEffect(() => {
        if (pathname === "/for-parents/complete") {
            checkRoute();
        }
    }, [checkRoute, pathname]);

    return ready ? <>{children}</> : <></>;
};

export { EmailGuard, ValidateGuard, ExistsGuard, RegisterGuard, LearnersGuard, CompleteGuard };

import type { ElementType } from "react";
import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsEmptyImageProps, PdsEmptyTitleProps, PdsEmptyBodyProps, PdsEmptyProps } from "./type";

import { useMemo, forwardRef } from "react";
import { nanoid } from "nanoid";

import { tx, getChildByType, polymorphComponent } from "@/libs";
import { PdsButton } from "@/components/button";

/* All PdsEmpty subcomponents */

/**
 * `PdsEmpty.Image` displays a presentational image (hidden from screen readers) for visual contexts only.
 *
 * Could be polymorphed & customised visually via `as` and `className` props, respectively.
 */
export const PdsEmptyImage = polymorphComponent<PdsEmptyImageProps, ElementType, "img">(
    "img",
    ({ role = "presentation", alt = "", className, ...props }) => ({
        role,
        alt,
        className: tx("aspect-auto mb-6", className),
        ...props,
    })
);

/**
 * `PdsEmpty.Title` displays a title for the empty state message.
 *
 * Should be polymorphed to `h1`, `h2`, etc. depending on the user's contexts (default to `p`).
 * Could be customised visually via `className` props
 */
export const PdsEmptyTitle = polymorphComponent<PdsEmptyTitleProps, ElementType, "p">(
    "p",
    ({ className, ...props }) => ({
        className: tx("mb-1 text-heading", className),
        ...props,
    })
);

/**
 * `PdsEmpty.Body` displays a more detailed description of why the empty state is happening.
 *
 * Could be polymorphed & customised visually via `as` and `className` props, respectively.
 */
export const PdsEmptyBody = polymorphComponent<PdsEmptyBodyProps, ElementType, "p">("p");

/**
 * `PdsEmpty.PrimaryAction` displays primary CTA for the user to interact/get out of the empty.
 *
 * Could be polymorphed & customised visually via `as` and `className` props, respectively.
 * Default to a primary `PdsButton`.
 */
export const PdsEmptyPrimaryAction = polymorphComponent(PdsButton, ({ variant = "primary", ...props }) => ({
    variant,
    ...props,
}));

/**
 * `PdsEmpty.SecondaryAction` displays secondary CTA for the user to interact/get out of the empty.
 *
 * Could be polymorphed & customised visually via `as` and `className` props, respectively.
 * Default to secondary `PdsButton`.
 */
export const PdsEmptySecondaryAction = polymorphComponent(PdsButton, ({ variant = "secondary", ...props }) => ({
    variant,
    ...props,
}));

/**
 * `PdsEmpty` explains to an user what they should do next if content is either unavailable or cannot be displayed.
 *
 * - `PdsEmpty` content should be clear in why the user is seeing the empty state, and as concisely as possible.
 * - `PdsEmpty` should give forward direction & education the user in its actions.
 * - `PdsEmpty` should be encouraging & inspiring confidence.
 *
 * `PdsEmpty.Image` is skipped by screen reader via `alt=""` and `role="presentation"`,
 * as most empty state illustrations are considered decorative.
 *
 * `PdsEmpty.PrimaryAction` & `PdsEmpty.SecondaryAction` are by default `PdsButton`,
 * but could be polymorphed as `anchor` if the action is to redirect the user to another URL.
 *
 * `PdsEmpty.Title` & `PdsEmpty.Body` are by default `p`, but could be polymorphed
 * into another DOM element via the `as` prop.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or
 * [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=8729%3A8759).
 *
 * *Reference:* [Decorative images - W3.org](https://www.w3.org/WAI/tutorials/images/decorative/)
 */
export const PdsEmpty = forwardRef<HTMLDivElement, PdsEmptyProps>(
    (
        {
            id,
            title,
            body,
            imageSrc,
            primaryLabel,
            onPrimaryClick,
            secondaryLabel,
            onSecondaryClick,
            className,
            children,
            ...props
        }: PdsEmptyProps,
        ref
    ) => {
        const emptyId = useMemo(() => id ?? `pds-empty-${nanoid()}`, [id]);

        const imageEl = getChildByType(children, "PdsEmptyImage") ?? (imageSrc && <PdsEmptyImage src={imageSrc} />);
        const titleEl = getChildByType(children, "PdsEmptyTitle") ?? <PdsEmptyTitle>{title}</PdsEmptyTitle>;
        const bodyEl = getChildByType(children, "PdsEmptyBody") ?? <PdsEmptyBody>{body}</PdsEmptyBody>;
        const primaryActionEl =
            getChildByType(children, "PdsEmptyPrimaryAction") ??
            (primaryLabel && onPrimaryClick && (
                <PdsEmptyPrimaryAction onClick={onPrimaryClick}>{primaryLabel}</PdsEmptyPrimaryAction>
            ));
        const secondaryActionEl =
            getChildByType(children, "PdsEmptySecondaryAction") ??
            (secondaryLabel && onSecondaryClick && (
                <PdsEmptySecondaryAction onClick={onSecondaryClick}>{secondaryLabel}</PdsEmptySecondaryAction>
            ));

        return (
            <div
                ref={ref}
                id={emptyId}
                className={tx("flex flex-col items-center justify-center", className)}
                {...props}
            >
                {imageEl}
                {titleEl}
                {bodyEl}
                {(primaryActionEl || secondaryActionEl) && (
                    <div className="mt-6 flex flex-wrap items-center justify-center gap-4">
                        {primaryActionEl}
                        {secondaryActionEl}
                    </div>
                )}
            </div>
        );
    }
) as CompoundComponentWithRef<PdsEmptyNamespace, PdsEmptyProps, HTMLDivElement>;

/**
 * All PdsEmpty subcomponent namespace.
 */
export type PdsEmptyNamespace = {
    Image: typeof PdsEmptyImage;
    Title: typeof PdsEmptyTitle;
    Body: typeof PdsEmptyBody;
    PrimaryAction: typeof PdsEmptyPrimaryAction;
    SecondaryAction: typeof PdsEmptySecondaryAction;
};

PdsEmpty.Image = PdsEmptyImage;
PdsEmpty.Title = PdsEmptyTitle;
PdsEmpty.Body = PdsEmptyBody;
PdsEmpty.PrimaryAction = PdsEmptyPrimaryAction;
PdsEmpty.SecondaryAction = PdsEmptySecondaryAction;

import type { ReactElement } from "react";
import type { CompoundComponentWithRef } from "@/typings/utilities";
import type { PdsStepperProps } from "./type";

import { useEffect, forwardRef, cloneElement } from "react";

import { tx, getChildrenByType, polymorphComponent } from "@/libs";

import { usePdsStepper } from "./usePdsStepper";
import { PdsStepperContext } from "./context";
import { PdsStep } from "./Step";

const PdsStepperWrapper = polymorphComponent<PdsStepperProps>("ul");

/**
 * `PdsStepper` displays progress through a sequence of logical steps.
 *
 * - `PdsStepper` can either be horizontally or vertically oriented.
 * - `PdsStepper` can be non-linear, allowing to enter at any step.
 * - `PdsStepper` can be disabled for any step.
 * - `PdsStepper` uses [data-orientation] to assitively display the orientation of the stepper.
 *
 * `PdsStep` displays the single step inside a Stepper.
 *
 * - `PdsStep` uses [aria-current="step"](https://www.w3.org/TR/wai-aria-1.1/#aria-current)
 * to assistively display as being active.
 * - `PdsStep` uses [data-status="complete"](https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes)
 * to assistively display as being completed.
 *
 * `usePdsStepper` hook is provided to help manage active/complete state in case custom logic is needed.
 *
 * Design is available in `Canvas` mode in `Design` addon tab or [here](https://www.figma.com/file/V3uwvm05mn9o8JRTwoPIOV/Phorm?node-id=6212%3A8676).
 */
export const PdsStepper = forwardRef<HTMLUListElement | HTMLOListElement, PdsStepperProps>(
    (
        {
            steps = [],
            activeStep: activeStepProps = 0,
            nonLinear = false,
            orientation = "horizontal",
            children,
            className,
            onChange,
            ...props
        }: PdsStepperProps,
        ref
    ) => {
        /**
         * Hook to handle active & complete state management.
         */
        const { activeStep, completedSteps, handleStepChange } = usePdsStepper({
            nonLinear,
            activeStep: activeStepProps,
        });

        /**
         * Provide default change handler for active step number.
         */
        useEffect(() => onChange?.(activeStep), [activeStep, onChange]);

        /**
         * Filter step sub-components and inject index.
         */
        const stepSubComponents = getChildrenByType(children, "PdsStep").map((step, index) =>
            cloneElement(step as ReactElement, { index, ...(step as ReactElement).props })
        );

        return (
            <PdsStepperWrapper
                ref={ref}
                data-orientation={orientation}
                role={nonLinear ? "group" : "list"}
                as={nonLinear ? "ul" : "ol"}
                className={tx(
                    {
                        "flex w-full items-center gap-x-1": orientation === "horizontal",
                        "grid grid-flow-row gap-y-1": orientation === "vertical",
                    },
                    className
                )}
                {...props}
            >
                {/** Context provide all non-Step-specific state to avoid props pollution */}
                <PdsStepperContext.Provider
                    value={{
                        length: stepSubComponents.length || steps.length,
                        nonLinear,
                        orientation,
                        activeStep,
                        completedSteps,
                        handleStepChange,
                    }}
                >
                    {stepSubComponents.length > 0
                        ? stepSubComponents
                        : steps.map((stepProps, index) => (
                              <PdsStep
                                  /** Key is default to fallback label or aria-label unless specified */
                                  key={stepProps.label ?? stepProps["aria-label"]}
                                  index={index}
                                  {...stepProps}
                              />
                          ))}
                </PdsStepperContext.Provider>
            </PdsStepperWrapper>
        );
    }
) as CompoundComponentWithRef<PdsStepperNamespace, PdsStepperProps, HTMLOListElement | HTMLUListElement>;

type PdsStepperNamespace = { Step: typeof PdsStep };

PdsStepper.Step = PdsStep;

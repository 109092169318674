import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorUndo = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M15 8H7.41L8.7 6.71c.19-.18.3-.43.3-.71a1 1 0 0 0-1.71-.71l-3 3A1 1 0 0 0 4 9a1 1 0 0 0 .29.71l3 3a1 1 0 0 0 1.42-1.42L7.41 10H15a3 3 0 1 1 0 6h-4v2h4a5 5 0 0 0 0-10z" />
            </svg>
        );
    }
);

import type { ForwardedRef, ReactElement } from "react";
import type { PdsDropdownSelectSectionProps } from "./type";

import { nanoid } from "nanoid";
import { intersectionWith } from "lodash";
import { forwardRef, cloneElement, Children } from "react";

import { typeOfComponent } from "@/libs";
import { PdsDropdownSection } from "@/components/dropdown/DropdownSection";

import { usePdsDropdownSelectContext } from "./context";
import { PdsDropdownSelectItem } from "./DropdownSelectItem";

export const PdsDropdownSelectSection = forwardRef(
    <T extends unknown = string>(
        { label, value, disabled = false, children: childrenProps, ...props }: PdsDropdownSelectSectionProps<T>,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        /**
         * Context & id management hooks.
         */
        const labelId = `pds-dropdown-select-section-label-${nanoid()}`;
        const [selected, { select, toggle, isEqual }] = usePdsDropdownSelectContext<T>();

        /**
         * Extract all values of non-disabled children PdsDropdownSelectItem's.
         * Disable all children item if section is disabled.
         */
        const sectionValues: T[] = [];
        const children = Children.map(childrenProps, (child) => {
            const el = child as ReactElement;
            if (typeOfComponent(el) === "PdsDropdownSelectItem") {
                if (!el.props.disabled) {
                    sectionValues.push(el.props.value as T);
                }

                return cloneElement(el, disabled ? { disabled } : {});
            }

            return el;
        });

        /**
         * Verify against contextual selected values.
         */
        const selectedSectionValues = intersectionWith<T, T>(sectionValues, selected, isEqual);
        const isAllChecked = selectedSectionValues.length === sectionValues.length;
        const isIndeterminate = selectedSectionValues.length > 0 && !isAllChecked;

        /**
         * Click event handler.
         */
        const handleSectionClick = () => {
            if (isIndeterminate) {
                select(sectionValues);
            } else {
                toggle(sectionValues);
            }
        };

        return (
            <PdsDropdownSection role="group" {...props}>
                <PdsDropdownSelectItem
                    ref={ref}
                    id={labelId}
                    value={value ?? ""}
                    label={label ?? ""}
                    disabled={disabled}
                    checked={selectedSectionValues.length > 0 && selectedSectionValues.length === sectionValues.length}
                    indeterminate={selectedSectionValues.length > 0}
                    className="font-semibold"
                    onClick={handleSectionClick}
                />
                <div className="flex flex-1 flex-col ps-2">{children}</div>
            </PdsDropdownSection>
        );
    }
) as <T extends unknown = string>(
    props: PdsDropdownSelectSectionProps<T> & { ref?: ForwardedRef<HTMLButtonElement> }
) => ReactElement | null;

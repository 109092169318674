/**
 * A custom color palette for PDS.
 *
 * Written as a CommonJS module so it can be consumed by `tailwind.config.js`。
 *
 * Note: Make sure to update `@/typings/color.ts` (if necessary) when adding/removing colors.
 */
const pdsColors = {
    red: {
        100: "#fef2f2",
        200: "#fee2e2",
        300: "#fecaca",
        400: "#fca5a5",
        500: "#f87171",
        600: "#ef4444",
        700: "#dc2626",
        800: "#b91c1c",
        900: "#991b1b",
        1000: "#7f1d1d",
    },
    green: {
        100: "#ecfdf5",
        200: "#d1fae5",
        300: "#a7f3d0",
        400: "#6ee7b7",
        500: "#34d399",
        600: "#10b981",
        700: "#059669",
        800: "#047857",
        900: "#065f46",
        1000: "#064e3b",
    },
    emerald: {
        100: "#d5fbfa",
        200: "#aff8f5",
        300: "#8af5f0",
        400: "#64f1eb",
        500: "#3feee6",
        600: "#15eae1",
        700: "#11c0b8",
        800: "#0faba4",
        900: "#0da59e",
        1000: "#0a6c67",
    },
    blue: {
        100: "#eef2ff",
        200: "#e0e7ff",
        300: "#c7d2fe",
        400: "#a5b4fc",
        500: "#818cf8",
        600: "#6366f1",
        700: "#4f46e5",
        800: "#4338ca",
        900: "#3730a3",
        1000: "#312e81",
    },
    purple: {
        100: "#f7ebff",
        200: "#e5bdff",
        300: "#d48fff",
        400: "#c261ff",
        500: "#ae2eff",
        600: "#9900fa",
        700: "#8a00e1",
        800: "#7a00c7",
        900: "#6b00ad",
        1000: "#5b0094",
    },
    sky: {
        100: "#ecfeff",
        200: "#cffafe",
        300: "#a5f3fc",
        400: "#67e8f9",
        500: "#22d3ee",
        600: "#06b6d4",
        700: "#0891b2",
        800: "#0e7490",
        900: "#155e75",
        1000: "#164e63",
    },
    pink: {
        100: "#fffbfc",
        200: "#ffe6f0",
        300: "#ffbdd7",
        400: "#ff95be",
        500: "#ff6ca6",
        600: "#ff438d",
        700: "#ff1571",
        800: "#e6005b",
        900: "#a10040",
        1000: "#8a0036",
    },
    gold: {
        100: "#fffbeb",
        200: "#fef3c7",
        300: "#fde68a",
        400: "#fcd34d",
        500: "#ffb100",
        600: "#f59e0b",
        700: "#d97706",
        800: "#b45309",
        900: "#92400e",
        1000: "#78350f",
    },
    neutral: {
        100: "#f8fbff",
        200: "#edf1f5",
        300: "#e0e4e9",
        400: "#babecb",
        500: "#a3a8ba",
        600: "#6b748e",
        700: "#5d6686",
        800: "#465175",
        900: "#2f3b63",
        1000: "#182552",
    },
    theme: {
        100: "var(--pds-color-theme-100)",
        200: "var(--pds-color-theme-200)",
        300: "var(--pds-color-theme-300)",
        400: "var(--pds-color-theme-400)",
        500: "var(--pds-color-theme-500)",
        600: "var(--pds-color-theme-600)",
        700: "var(--pds-color-theme-700)",
        800: "var(--pds-color-theme-800)",
        900: "var(--pds-color-theme-900)",
        1000: "var(--pds-color-theme-1000)",
    },
    white: "#ffffff",
    black: "#000000",
    transparent: "transparent",
    current: "currentColor",
};

module.exports = {
    ...pdsColors,
    ["text-default"]: pdsColors.neutral[1000],
    primary: pdsColors.theme[700],
    secondary: pdsColors.white,
    tertiary: pdsColors.black,
    success: pdsColors.green[600],
    info: pdsColors.sky[600],
    warning: pdsColors.gold[600],
    danger: pdsColors.red[600],
};

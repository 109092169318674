import { Step } from "@components/common/header/stepper";

export const pathsWithSidebar = ["/for-parents/start", "/for-parents/validate", "/for-parents/exists"];
export const loginRoute = process.env.REACT_APP_LOGIN_URL || "https://app.educationperfect.com";

export const steps: Step[] = [
    {
        step: 0,
        path: "for-parents/register",
    },
    {
        step: 1,
        path: "for-parents/learners",
    },
    {
        step: 2,
        path: "for-parents/complete",
    },
];

import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconClassroom = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M12.061 12.718c-1.187 0-2.312-.505-3.124-1.263a4.358 4.358 0 01-1.25-3.096c0-1.263.5-2.337 1.312-3.096A4.376 4.376 0 0112.061 4c1.188 0 2.25.442 3.063 1.263.75.759 1.25 1.896 1.25 3.096 0 1.2-.438 2.274-1.25 3.096a4.378 4.378 0 01-3.063 1.263zm6.937.884c-.563 0-1.125-.252-1.562-.631-.375-.442-.625-.948-.625-1.58 0-.632.25-1.2.625-1.58.437-.378.937-.63 1.562-.63s1.187.252 1.562.63c.375.443.625.948.625 1.58 0 .632-.25 1.2-.625 1.58-.437.379-.937.631-1.562.631zm0 .506c1.875 0 3.062.948 3 1.832 0 .442-1.25.947-3.063.947-.5 0-1-.063-1.375-.126v.253c0 .884-2.311 1.769-5.561 1.769-3.5 0-5.562-.885-5.562-1.77 0-.063.063-.19.063-.252-.438.063-.938.126-1.438.126-1.937 0-3.062-.442-3.062-.947 0-.948 1.187-1.895 3.062-1.895 1.125 0 2 .379 2.5.884.937-.821 2.437-1.39 4.437-1.39 1.937 0 3.437.569 4.437 1.39.562-.442 1.374-.821 2.562-.821zm-13.873-.506c-.625 0-1.188-.252-1.625-.568-.375-.443-.625-.948-.625-1.58 0-.631.25-1.2.625-1.579.437-.379.937-.632 1.562-.632s1.188.253 1.563.632c.437.379.687.884.687 1.516s-.25 1.2-.625 1.58c-.437.379-.937.631-1.562.631z" />
            </svg>
        );
    }
);

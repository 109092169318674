import React, { FC } from "react";
import { PdsIconEp } from "@educationperfect/web-pds-react";
import BrokenImg from "@assets/images/broken-hi.svg";

const Failed: FC = () => {
    return (
        <div className="w-full min-h-screen flex flex-col justify-center items-center px-3">
            <PdsIconEp size="xlarge" className="absolute top-4 left-6" />

            <div>
                <header className="flex flex-col items-center">
                    <div className="w-[102px] h-[100px]">
                        <img className="w-[102px]" src={BrokenImg} alt="BrokenHi" />
                    </div>
                    <h1 className="mt-5 text-lg font-bold text-neutral-900 sm:text-2xl">Something went wrong!</h1>
                </header>
                <main className="flex flex-col items-center">
                    <div className="mt-4 text-xs text-left font-normal sm:text-sm sm:font-normal whitespace-pre-wrap">
                        Please check your email inbox for next steps,
                    </div>
                    <div className="text-xs text-left font-normal sm:text-sm sm:font-normal whitespace-pre-wrap">
                        or contact our
                        <a
                            className="text-blue-700 ml-1 no-underline hover:underline"
                            href="https://www.educationperfect.com/contact-us/"
                        >
                            Support Team
                        </a>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Failed;

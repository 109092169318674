import type { ForwardedRef } from "react";
import type { PdsIconProps } from "../type";
import { forwardRef } from "react";
import { getIconClassnames } from "../iconCommon";
export const PdsIconEditorArithmetic = forwardRef(
    (
        { size = "medium", variant = "inherit", "aria-label": ariaLabel, className, ...props }: PdsIconProps,
        ref: ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                viewBox="0 0 24 24"
                ref={ref}
                role={ariaLabel ? "img" : undefined}
                aria-label={ariaLabel}
                aria-hidden={ariaLabel ? undefined : "true"}
                className={getIconClassnames(className, size, variant)}
                {...props}
            >
                <path d="M17 17a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h10zM12 4a1 1 0 0 1 1 1v3h3a1 1 0 0 1 0 2h-3v3a1 1 0 0 1-2 0v-3H8a1 1 0 1 1 0-2h3V5a1 1 0 0 1 1-1z" />
            </svg>
        );
    }
);

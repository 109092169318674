import React, { FC, useCallback, useEffect, useState } from "react";
import Input from "@components/common/form/input";
import { isEmail, isPresent, useForm } from "@components/common/form";
import { PdsButton } from "@educationperfect/web-pds-react";
import store from "@services/store";
import { sendCode, submitEmail } from "@api/signup";
import { useHistory } from "react-router-dom";

const initState = {
    email: store.get("forhome:email") as string,
};

interface IEmailForm {
    setIsError: (isError: boolean) => void;
}

const EmailForm: FC<IEmailForm> = ({ setIsError }) => {
    const form = useForm();
    const history = useHistory();

    const [isFetching, setIsFetching] = useState(false);
    const [email, setEmail] = useState("");

    const onEmailVerify = useCallback(
        async (e) => {
            e.preventDefault();
            if (!form.isValid()) {
                return;
            }

            setIsFetching(true);

            try {
                store.set("forhome:email", email);
                store.remove("forhome:valid-email");
                store.remove("forhome:email-exists");

                await sendCode(email);
                await submitEmail(email);
                history.push("/for-parents/validate");
            } catch (error) {
                setIsError(true);
            } finally {
                setIsFetching(false);
            }
        },
        [email, form, history, setIsError]
    );

    useEffect(() => () => setIsFetching(false), []);

    return (
        <form onSubmit={onEmailVerify} noValidate className="form w-full lg:max-w-md px-1 sm:mt-20">
            <Input
                form={form}
                value={email ?? initState.email}
                onChange={setEmail}
                name="email"
                inputMode="email"
                label="Email address"
                placeholder="you@example.com"
                description="Please use an email not affiliated with a school"
                validators={[
                    isPresent("A valid email address is required"),
                    isEmail("Please enter a valid email address"),
                ]}
            />
            <PdsButton
                type="submit"
                loading={isFetching}
                loadingAriaLabel="Sending code..."
                disabled={!email || isFetching}
                className="w-full mt-7"
            >
                Verify your email
            </PdsButton>
        </form>
    );
};

export default EmailForm;

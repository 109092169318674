import React, { ReactNode } from "react";
import { PdsIconEp } from "@educationperfect/web-pds-react";
import RocketImg from "@assets/images/rocket.svg";

const Wrapper = ({ children }: { children: ReactNode }) => {
    return (
        <main className="flex min-h-screen flex-auto justify-start">
            <div className="hidden sm:flex sm:flex-row relative flex-col justify-center items-center w-[375px] p-6 bg-neutral-100">
                <PdsIconEp size="xlarge" className="absolute top-4 left-6" />
                <img className="w-[130px]" src={RocketImg} alt="Rocket" />
            </div>

            <div className="container flex-1">
                <PdsIconEp size="xlarge" className="sm:hidden absolute top-4 left-3" />
                <div className="h-full flex flex-col pt-4 sm:pt-0 items-center sm:justify-start">{children}</div>
            </div>
        </main>
    );
};

export default Wrapper;
